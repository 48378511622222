<template>
  <div v-test="'service-variation-resources'">
    <BaseToggle
      v-if="activeVariations.length > 1"
      v-model="requirementsPerVariation"
      :label="$t('service.resources.different_per_variation_toggle')"
      mb
      v-test="'requirements-per-variation-toggle'"
    />
    <BaseText v-if="requirementsPerVariation" inline>
      {{ $t('service.resources.different_per_variation_info') }}
    </BaseText>
    <BaseText
      v-if="requirementsPerVariation"
      inline
      :href="$t('service.resources.different_per_variation_url')"
      :mb="2"
    >
      {{ $t('service.resources.different_per_variation_more_info') }}
    </BaseText>
    <RequirementsForm v-if="!requirementsPerVariation" :variation="formData" />
    <div v-else v-test="'requirements-per-variation'">
      <div
        v-for="(variation, index) in activeVariations"
        :key="index"
        :class="$style.requirementsContainer"
      >
        <BaseHeading icon="layers" mb>{{
          variation.variationName
        }}</BaseHeading>
        <RequirementsForm :variation="variation" startCollapsed />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ServiceVariation, ServiceVariationsFormData } from '../types';
import RequirementsForm from './RequirementsForm.vue';
const formData = inject<ServiceVariationsFormData>('serviceFormData')!;
const requirementsPerVariation = inject<ServiceVariationsFormData>(
  'requirementsPerVariation'
)!;
const activeVariations = inject<ServiceVariation[]>('activeVariations')!;
const mixpanel = inject<any>('mixpanel')!;

watch(requirementsPerVariation, (newValue) => {
  mixpanel.track('toggle_variation_per_resource', {
    value: newValue
  });
});
</script>

<style module lang="scss">
.requirementsContainer:not(:last-child) {
  margin-bottom: $spacing * 4;
}
</style>
