<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <div :class="$style.controls">
      <BaseSwitch
        v-model="range"
        :options="[
          {
            label: $t('global.items.day', 1),
            value: 'day'
          },
          {
            label: $t('global.items.week', 1),
            value: 'week'
          },
          {
            label: $t('global.items.month', 1),
            value: 'month'
          },
          {
            label: $t('global.items.year', 1),
            value: 'year'
          }
        ]"
        v-test="'insights-range'"
      />
      <Flex wrap>
        <BaseButton
          color="inverted"
          @click="selectedDate = dayjs().format('YYYY-MM-DD')"
          v-test="'insights-today'"
        >
          {{ $t('global.today') }}
        </BaseButton>
        <BaseDatePicker
          v-model="selectedDate"
          :range="range"
          :hideCalendar="range === 'month'"
          arrows
          v-test="'insights-date'"
        />
      </Flex>
    </div>
    <div
      v-if="
        !loading && appointments && appointments.all && !appointments.all.length
      "
    >
      <EmptyState large />
    </div>
    <div v-else>
      <div
        v-if="topServices.length && topEmployees.length"
        :class="$style.stats"
      >
        <BaseCard :loading="loading" v-test="'total-appointments'">
          <BaseHeading size="s" :mb="0.5">{{
            $t('insights.total_appointments')
          }}</BaseHeading>
          <BaseHeading>
            {{ totalAppointments }}
          </BaseHeading>
        </BaseCard>
        <BaseCard :loading="loading" v-test="'top-service'">
          <BaseHeading size="s" :mb="0.5">{{
            $t('insights.top_service')
          }}</BaseHeading>
          <BaseHeading>{{ topServices[0].name }}</BaseHeading>
          <BaseText size="s"
            >{{ topServices[0].sum }}
            {{ $t('global.items.appointment', topServices[0].sum) }}</BaseText
          >
        </BaseCard>
        <BaseCard
          v-if="!hasSingleEmployee"
          :loading="loading"
          v-test="'top-employee'"
        >
          <BaseHeading size="s" :mb="0.5">{{
            $t('insights.top_employee')
          }}</BaseHeading>
          <BaseHeading>{{ topEmployees[0].name }}</BaseHeading>
          <BaseText size="s"
            >{{ topEmployees[0].sum }}
            {{ $t('global.items.appointment', topEmployees[0].sum) }}</BaseText
          >
        </BaseCard>
      </div>
      <Occupancy
        v-if="range !== 'day' && range !== 'year'"
        v-bind="{ loading, days }"
        :mb="1.5"
      />
      <Appointments v-bind="{ appointments, loading }" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Occupancy from './occupancy/index.vue';
import Appointments from './appointments/index.vue';
import dayjs from '@/dayjs';
import { useStorage } from '@vueuse/core';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { groupBy } from '@/helpers/formatting';
import { useServicesStore } from '@/stores/services';
import { useResourcesStore } from '@/stores/resources';
import EmptyState from './EmptyState.vue';
import { storeToRefs } from 'pinia';

const range = useStorage<'day' | 'week' | 'month' | 'year'>(
  'insightsRange',
  'week'
);
const selectedDate = useStorage(
  'insightsSelectedDate',
  dayjs().format('YYYY-MM-DD')
);

const fromDate = computed(() =>
  dayjs(selectedDate.value)
    .startOf(range.value === 'week' ? 'isoWeek' : range.value)
    .format('YYYY-MM-DD')
);

const toDate = computed(() =>
  dayjs(selectedDate.value)
    .endOf(range.value === 'week' ? 'isoWeek' : range.value)
    .format('YYYY-MM-DD')
);

provide('fromDate', fromDate);
provide('toDate', toDate);
provide('range', range);

const { hasSingleEmployee } = useResourcesStore();

const { locationId, dataScope } = storeToRefs(useLocationsStore());

const { result, loading: loadingHeatmap } = useQuery(
  gql`
    query heatMapInsights(
      $dataScope: DataScope!
      $endAt: ISO8601DateTime!
      $locationId: ID
      $ids: [ID!]
      $startAt: ISO8601DateTime!
    ) {
      heatMapInsights(
        dataScope: $dataScope
        endAt: $endAt
        locationId: $locationId
        ids: $ids
        startAt: $startAt
      ) {
        date
        part
        ratio
        sum
      }
    }
  `,
  () => ({
    dataScope: dataScope.value,
    endAt: toDate.value,
    locationId: locationId.value,
    ids: [],
    startAt: fromDate.value
  }),
  () => ({
    enabled: range.value === 'week' || range.value === 'month'
  })
);

const data = computed(() => result.value?.heatMapInsights || []);
const days = computed(() =>
  groupBy(data.value, 'date').map((group) => ({
    ...group,
    total: group.items.reduce((acc, val) => acc + val.sum, 0),
    totalRatio: group.items.reduce((acc, val) => acc + val.ratio / 6, 0)
  }))
);

const unit = computed<'day' | 'week' | 'month'>(() => {
  if (range.value === 'year') {
    return 'month';
  } else if (range.value === 'month') {
    return 'week';
  } else {
    return 'day';
  }
});

const { onResult, loading: loadingAppointments } = useQuery(
  gql`
    query appointmentInsights(
      $dataScope: DataScope!
      $endAt: ISO8601DateTime!
      $locationId: ID
      $period: String!
      $startAt: ISO8601DateTime!
    ) {
      appointmentInsights(
        dataScope: $dataScope
        endAt: $endAt
        locationId: $locationId
        period: $period
        startAt: $startAt
      ) {
        all {
          id
          series
          type
        }
        employees {
          id
          series
          type
        }
        services {
          id
          series
          type
        }
      }
    }
  `,
  () => ({
    dataScope: dataScope.value,
    endAt: toDate.value,
    locationId: locationId.value,
    period: unit.value,
    startAt: fromDate.value
  })
);

const appointments = ref({
  all: [],
  employees: [],
  services: []
});

onResult(({ data: { appointmentInsights } }) => {
  appointments.value = appointmentInsights;
});

const totalAppointments = computed(() => {
  if (!appointments.value.all[0]) {
    return 0;
  }

  return appointments.value.all[0].series.reduce(
    (acc, val) => acc + val.sum,
    0
  );
});

const loading = computed(
  () => loadingHeatmap.value || loadingAppointments.value
);

const topServices = computed(() => {
  const { serviceById } = useServicesStore();
  const serviceData = appointments.value?.services;
  return serviceData
    ? serviceData
        .map((s) => {
          const service = serviceById(parseInt(s.id));
          if (service) {
            return {
              id: service.id,
              name: service.name,
              sum: s.series.reduce((acc, val) => acc + val.sum, 0)
            };
          }
        })
        .sort((a, b) => b.sum - a.sum)
    : [];
});

const topEmployees = computed(() => {
  const { resourceById } = useResourcesStore();
  const employeeData = appointments.value?.employees;
  return employeeData
    ? employeeData
        .map((e) => {
          const employee = resourceById(parseInt(e.id));
          if (employee) {
            return {
              id: employee.id,
              name: employee.name,
              sum: e.series.reduce((acc, val) => acc + val.sum, 0)
            };
          }
        })
        .sort((a, b) => b.sum - a.sum)
    : [];
});
</script>

<style lang="scss" module>
.base {
  max-width: 1000px;
  margin: 0 auto;
}

.controls {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: $spacing * 1.5;

  .base.smallScreen & {
    & > * {
      width: 100%;
    }
  }
}

.stats {
  display: flex;
  gap: $spacing;
  margin-bottom: $spacing * 1.5;

  .base.smallScreen & {
    flex-wrap: wrap;
  }

  & > * {
    .base:not(.smallScreen) & {
      flex-basis: 33%;
      flex-grow: 1;
    }

    .base.smallScreen & {
      width: calc(50% - #{$spacing * 0.5});
    }
  }
}
</style>
