<template>
  <div
    :class="[
      $style.base,
      {
        [$style.darkerColors]: darkerColors,
        [$style.hasCustomColor]: !!color,
        [$style.default]: !color && !state,
        [$style.disabled]: state === 'disabled',
        [$style.selected]: state === 'selected',
        [$style.closeable]: !!closeable,
        [$style.clickable]: !!clickable
      }
    ]"
    :style="color ? { 'border-color': color } : {}"
  >
    <div :class="$style.inner">
      <div :class="$style.content" @click="$emit('click')">
        <transition>
          <div v-if="state === 'selected'" :class="$style.icon">
            <BaseIcon name="check" color="inverted" size="s" />
          </div>
          <div v-else-if="icon" :class="$style.icon">
            <BaseIcon :name="icon" size="s" />
          </div>
        </transition>
        <div :class="$style.text">
          {{ text }}
        </div>
      </div>
      <div
        v-if="closeable"
        :class="$style.close"
        @click="$emit('close')"
        v-test="'_base-chip-delete'"
      >
        <BaseIcon
          name="close"
          :color="state === 'selected' ? 'inverted' : undefined"
          size="s"
        />
      </div>
    </div>
    <div
      :class="$style.background"
      :style="color ? { 'background-color': color } : {}"
    />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import type { IconName } from '@/components/base-icon/types';

defineProps<{
  clickable?: boolean;
  closeable?: boolean;
  color?: string;
  icon?: IconName;
  text: string;
  state?: 'disabled' | 'selected';
}>();

defineEmits(['click', 'close']);

const darkerColors = computed(() => {
  const { companySettings } = useCompanyStore();
  return companySettings.agenda?.darkerColors;
});
</script>

<style lang="scss" module>
$grey: #e4e4e4;

.base {
  display: inline-block;
  position: relative;
  flex-shrink: 0;
  border-radius: $radius;
  overflow: hidden;
  max-width: 100%;

  &.disabled {
    pointer-events: none;
  }

  &.selected {
    color: white;
  }

  &.clickable {
    cursor: pointer;

    @include hover {
      &.default {
        .background {
          background-color: #e7e6fa;
        }
      }
    }
  }
}

.text {
  opacity: 0.9;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 30px;
  max-width: 270px;

  .base.disabled & {
    opacity: 0.5;
  }
}

.content {
  display: flex;
  align-items: center;
  padding: 5px 7px;
  gap: 4px;
  overflow: hidden;

  .base.closeable & {
    padding-right: 0;
  }
}

.icon {
  display: flex;
  align-items: center;
  height: 100%;

  &:global(.v-enter-active) {
    transition:
      transform 0.2s $easeOutBack,
      opacity 0.1s;
  }

  &:global(.v-enter-from) {
    transform: scale(0.2);
    opacity: 0;
  }

  &:global(.v-leave-active) {
    position: absolute;
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100%;
  cursor: pointer;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: inherit;
  transition: background-color 0.1s;

  .base.default & {
    border-color: #c9c8ee;
    background-color: #eeedff;
  }

  .base.selected & {
    background-color: $color-primary;
    border-color: $color-primary;
  }

  .base.disabled & {
    background-color: $grey;
    border-color: $grey;
  }

  .base.hasCustomColor:not(.darkerColors) & {
    opacity: 0.3;
  }

  .base.hasCustomColor.darkerColors & {
    opacity: 0.5;
  }
}
</style>
