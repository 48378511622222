import gql from 'graphql-tag';
import i18n from '@/i18n';
import apolloClient from '@/apollo/client';
import { defineStore } from 'pinia';
import type { User } from '@/types';
import { useCompanyStore } from '@/stores/company';
import { companyFragment } from './_shared/graphql-fragments';

interface State {
  user: User | null;
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    user: null
  }),
  getters: {
    needsVerification: (state: State): boolean =>
      !!state.user && !state.user.verified,
    hasFeatureFlag:
      (state: State) =>
      (flag: string): boolean =>
        !!state.user?.featureFlags?.[flag]
  },
  actions: {
    getUser() {
      return new Promise<void>((resolve) => {
        apolloClient
          .query({
            query: gql`
              query getCurrentUser {
                currentUser {
                  admin
                  basic
                  company {
                    ...companyAll
                  }
                  calendarIcsUrl
                  email
                  featureFlags
                  feedbacks
                  firstName
                  franchiseAdmin
                  fullName
                  id
                  impersonated
                  intercomHash
                  lastName
                  manager
                  owner
                  salonizedAdmin
                  treatwellAdmin
                  verified
                  reports
                  settings {
                    pushNotifications {
                      newAppointmentInDays
                    }
                    notifications {
                      dayReports
                      updates
                      tradeShowDemoUser
                    }
                    security {
                      autoSessionTimeout
                    }
                  }
                  npsFeedbackDue
                  showNewFeaturesNotification
                }
              }
              ${companyFragment}
            `
          })
          .then(({ data: { currentUser } }) => {
            if (currentUser) {
              const { company, ...user } = currentUser;

              this.user = user;

              const { _setCompany } = useCompanyStore();
              _setCompany(company);

              if (!user.verified) {
                i18n.locale.value = 'en';
              }
            }

            resolve();
          });
      });
    },

    updateUser(input: any) {
      if (!this.user) {
        return;
      }

      const mutationVars = {
        ...input,
        id: this.user?.id
      };

      return new Promise<void>((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation updateUser($input: UpdateUserInput!) {
                updateUser(input: $input) {
                  user {
                    id
                    firstName
                    lastName
                    fullName
                    email
                    settings {
                      pushNotifications {
                        newAppointmentInDays
                      }
                      notifications {
                        dayReports
                        updates
                      }
                    }
                  }
                  errors
                }
              }
            `,
            variables: {
              input: mutationVars
            }
          })
          .then(
            ({
              data: {
                updateUser: { user, errors }
              }
            }) => {
              if (errors && Object.keys(errors)?.length) {
                reject(Object.keys(errors));
              } else {
                this.user = {
                  ...this.user,
                  ...user
                };
                resolve();
              }
            }
          );
      });
    }
  }
});
