<template>
  <BaseModalSmall @close="$emit('close')">
    <BaseText>
      {{
        $t('customers.async_export_disclaimer', {
          email: user && sentToUser ? user.email : company.notificationEmail
        })
      }}
    </BaseText>
    <BaseRadio
      v-if="user && user.email !== company.notificationEmail"
      v-model="sentToUser"
      mt
      :options="[
        {
          label: $t('export_page.user_email', { email: user?.email }),
          value: true
        },
        {
          label: $t('export_page.company_email', {
            email: company.notificationEmail
          }),
          value: false
        }
      ]"
      v-test="'export-modal-radio'"
    />
    <template #footer>
      <BaseButton @click="onSubmit" v-test="'export-modal-submit'">
        {{ $t('global.actions.export') }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
const { company } = useCompanyStore();
const { user } = useUserStore();

const emit = defineEmits(['close', 'submit']);

const sentToUser = ref(true);

const onSubmit = () => {
  emit('submit', sentToUser.value ? user?.email : undefined);
  emit('close');
};
</script>
