import axios from '@/axios';
import eventBus from '@/event-bus';
import {
  sendNativeAppMessage,
  NativeAppMessage
} from '@/helpers/native-app-bridge';

export const logout = () => {
  axios.delete('/sessions').then(() => {
    sendNativeAppMessage({
      message: NativeAppMessage.UserLogout
    });
    eventBus.$emit('logout');
  });
};
