<template>
  <ModuleBase noBorder>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div :class="$style.itemsWrap">
        <div :class="$style.items">
          <Item v-for="item in changeLogs" :key="item.id" :data="item" />
          <Pagination
            :items="changeLogs"
            :loading="$apollo.loading"
            fieldName="changeLogs"
            :apolloQuery="$apollo.queries.changeLogs"
            :perPage="perPage"
          />
        </div>
      </div>
    </div>
  </ModuleBase>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import ModuleBase from '@/modules/ModuleBase.vue';
import Item from './Item.vue';
import Pagination from '@/components/_deprecated/Pagination.vue';

import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ModuleBase,
    Item,
    Pagination
  },
  data() {
    return {
      perPage: 25
    };
  },
  computed: {
    ...mapState(useUserStore, ['user'])
  },
  created() {
    this.user.showNewFeaturesNotification = false;
  },
  apollo: {
    changeLogs: {
      query: gql`
        query getChangeLogs($pagination: PaginationAttributes) {
          changeLogs(pagination: $pagination) {
            id
            date
            title
            category
            helpPageUrl
            description
            imagePublicId
          }
        }
      `,
      variables() {
        return {
          pagination: {
            currentPage: 1,
            perPage: this.perPage
          }
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  }
});
</script>

<style lang="scss" module>
.itemsWrap {
  display: flex;
  justify-content: center;
}

.items {
  max-width: 600px;
}
</style>
