<template>
  <div :class="$style.base">
    <SendTest v-if="showSendTestModal" @close="showSendTestModal = false" />
    <BaseGrid container>
      <BaseGrid>
        <BaseHeading size="s" :mb="0.5">
          {{ $t('marketing.email.preview.how_your_customers') }}
        </BaseHeading>
        <div :class="$style.inner" class="mb-1">
          <BaseText
            link
            iconBefore="refresh"
            mr
            @click="onReset"
            v-test="'reset-content'"
          >
            {{ $t('global.actions.reset_content') }}
          </BaseText>
          <BaseText
            v-if="showSendTest"
            link
            iconBefore="paper-plane"
            @click="onSendTest"
            v-test="'send-test'"
          >
            {{ $t('global.actions.send_test') }}
          </BaseText>
        </div>
      </BaseGrid>
      <BaseGrid v-if="$screen === 'l'" alignRight>
        <BaseSwitch
          v-model="selectedView"
          :options="[
            {
              value: 'DESKTOP',
              icon: 'monitor'
            },
            {
              value: 'MOBILE',
              icon: 'mobile'
            }
          ]"
          v-test="'select-view'"
        />
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import SendTest from '@/modules/marketing/modals/SendTest.vue';
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';
import { useCompanyStore } from '@/stores/company';

export default defineComponent({
  components: {
    SendTest
  },
  setup() {
    const { company } = useCompanyStore();

    return {
      company
    };
  },
  data() {
    return {
      showSendTestModal: false
    };
  },
  computed: {
    ...mapGetters('marketing/builder', ['showSendTest']),
    selectedView: {
      get() {
        return this.$store.getters['marketing/builder/selectedView'];
      },
      set(value) {
        this.$store.commit('marketing/builder/SET_SELECTED_VIEW', value);
      }
    }
  },
  methods: {
    onReset() {
      this.$store.commit('marketing/RESET_TO_DEFAULT_STATE');
    },
    onSendTest() {
      this.showSendTestModal = true;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  max-width: 800px;
  width: 100%;
}

.inner {
  display: flex;
}
</style>
