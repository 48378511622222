<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <BaseHeading mb size="l">
      {{
        $t('register.salonized_pay.verification.welcome', {
          user: user?.firstName
        })
      }}
    </BaseHeading>
    <BaseCard mb>
      <div :class="$style.card">
        <img :class="$style.visual" src="/img/salonized-pay/verification.svg" />
        <div :class="$style.info">
          <BaseHeading :mb="0.5">{{
            $t('register.salonized_pay.verification.getting_verified.heading')
          }}</BaseHeading>
          <BaseText>
            {{
              $t(
                'register.salonized_pay.verification.getting_verified.description'
              )
            }}
          </BaseText>
        </div>
        <div :class="$style.task">
          <BaseTask
            state="required"
            :text="$t('register.salonized_pay.verification.complete')"
            @click="generateOnboardingLink"
          />
        </div>
      </div>
      <BaseSpinner v-if="loading" inline mt />
      <div v-else :class="$style.errorContainer">
        <BaseCard
          v-if="showGenericError"
          gray
          :heading="
            $t('register.salonized_pay.verification.wrong_information.heading')
          "
          v-test="'error-generic'"
        >
          <template #header>
            <BaseLabel color="warning">
              {{ $t('register.salonized_pay.verification.needs_fix') }}
            </BaseLabel>
          </template>
          {{
            $t(
              'register.salonized_pay.verification.wrong_information.description'
            )
          }}
        </BaseCard>
        <BaseCard
          v-if="showIdUploadError"
          gray
          mt
          :heading="$t('register.salonized_pay.verification.id_upload.heading')"
          v-test="'error-id'"
        >
          <template #header>
            <BaseLabel color="warning">
              {{ $t('register.salonized_pay.verification.needs_fix') }}
            </BaseLabel>
          </template>
          <div :class="$style.idUpload">
            <div>
              <div>
                <img src="/img/salonized-pay/id-upload/1.png" />
              </div>
              {{ $t('register.salonized_pay.verification.id_upload.step1') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/id-upload/2.png" />
              </div>
              {{ $t('register.salonized_pay.verification.id_upload.step2') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/id-upload/3.png" />
              </div>
              {{ $t('register.salonized_pay.verification.id_upload.step3') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/id-upload/4.png" />
              </div>
              {{ $t('register.salonized_pay.verification.id_upload.step4') }}
            </div>
          </div>
        </BaseCard>
        <BaseCard
          v-if="showConstitutionalError"
          gray
          mt
          :heading="
            $t('register.salonized_pay.verification.consitutional.heading')
          "
          v-test="'error-constitutional'"
        >
          <template #header>
            <BaseLabel color="warning">
              {{ $t('register.salonized_pay.verification.needs_fix') }}
            </BaseLabel>
          </template>
          <div :class="$style.idUpload">
            <div>
              <div>
                <img src="/img/salonized-pay/upload/1.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step1') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/upload/2.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step2') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/upload/3.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step3') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/upload/4.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step4') }}
            </div>
          </div>
        </BaseCard>
        <BaseCard
          v-if="showBankError"
          gray
          mt
          :heading="
            $t('register.salonized_pay.verification.bank_statement.heading')
          "
          v-test="'error-bank'"
        >
          <template #header>
            <BaseLabel color="warning">
              {{ $t('register.salonized_pay.verification.needs_fix') }}
            </BaseLabel>
          </template>
          <div :class="$style.idUpload">
            <div>
              <div>
                <img src="/img/salonized-pay/upload/1.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step1') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/upload/2.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step2') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/upload/3.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step3') }}
            </div>
            <div>
              <div>
                <img src="/img/salonized-pay/upload/4.svg" />
              </div>
              {{ $t('register.salonized_pay.verification.upload.step4') }}
            </div>
          </div>
        </BaseCard>
      </div>
    </BaseCard>
    <BaseCard :mb="2" :gray="disableFinalize">
      <div
        :class="[
          $style.card,
          {
            [$style.disabled]: disableFinalize
          }
        ]"
      >
        <div :class="$style.visual">
          <BaseLabel v-if="disableFinalize" color="primary" mb>
            {{ $t('register.salonized_pay.onboarding.up_next') }}
          </BaseLabel>
          <img src="/img/salonized-pay/getting-paid.svg" />
        </div>
        <div :class="$style.info">
          <BaseHeading :mb="0.5">{{
            $t('register.salonized_pay.verification.getting_paid.heading')
          }}</BaseHeading>
          <BaseText>
            {{
              $t('register.salonized_pay.verification.getting_paid.description')
            }}
          </BaseText>
        </div>
        <div :class="$style.task">
          <BaseTask
            :state="disableFinalize ? 'blocked' : undefined"
            :text="$t('register.salonized_pay.verification.getting_paid.task')"
          />
        </div>
      </div>
    </BaseCard>
    <BaseHeading size="l" mb>
      {{ $t('register.salonized_pay.verification.help_you') }}
    </BaseHeading>
    <div :class="$style.bottom">
      <div :class="$style.videoWrap">
        <div :class="$style.videoWrapInner">
          <iframe
            :class="$style.video"
            :src="vimeoLink"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
      <div :class="$style.bottomRight">
        <BaseCard mb>
          <a
            :href="$t('register.salonized_pay.verification.links.demo')"
            target="_blank"
          >
            <Flex>
              <img
                :class="$style.visual"
                src="/img/salonized-pay/book-demo.svg"
              />
              <BaseHeading>{{ $t('onboarding.book_demo') }}</BaseHeading>
            </Flex>
          </a>
        </BaseCard>
        <BaseCard>
          <a
            :href="$t('register.salonized_pay.verification.links.help_center')"
            target="_blank"
          >
            <Flex>
              <img
                :class="$style.visual"
                src="/img/salonized-pay/help-center.svg"
              />
              <BaseHeading>{{ $t('global.help_center') }}</BaseHeading>
            </Flex>
          </a>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { generateOnboardingLink } from '../helpers';

const disableFinalize = ref(true);
const { user } = useUserStore();
const { company } = useCompanyStore();

const { result, loading } = useQuery(gql`
  query adyenVerificationErrors {
    adyenVerificationErrors {
      errorCode
      errorMessage
      errorType
      remediatingActions
    }
  }
`);

const errors = computed(() => result.value?.adyenVerificationErrors || []);
const showIdUploadError = computed(
  () =>
    !!errors.value.find(
      (error: any) =>
        error.errorCode.includes('1_34') ||
        error.errorCode.includes('1_35') ||
        error.errorCode.includes('2_8071')
    )
);
const showConstitutionalError = computed(
  () =>
    !!errors.value.find(
      (error: any) =>
        error.errorCode.includes('1_60') ||
        error.errorCode.includes('1_61') ||
        error.errorCode.includes('2_8192')
    )
);
const showBankError = computed(
  () =>
    !!errors.value.find(
      (error: any) =>
        error.errorCode.includes('1_75') || error.errorCode.includes('2_8037')
    )
);
const showGenericError = computed(
  () =>
    !showIdUploadError.value &&
    !showConstitutionalError.value &&
    !showBankError.value &&
    !!errors.value.length
);

const vimeoLink = computed(
  () =>
    `https://player.vimeo.com/video/${company.country === 'de' ? '980575413' : '956002155'}?h=d02a244b44&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
);
</script>

<style lang="scss" module>
.base {
  width: 850px;
  max-width: 100%;
  padding-top: $spacing * 2;
}

.card {
  display: flex;
  align-items: center;
  gap: $spacing;
  padding: $spacing * 0.5 0;

  &.disabled {
    padding-top: 0;
  }

  .base.smallScreen & {
    flex-wrap: wrap;
  }
}

.visual {
  width: 100px;
  height: auto;
}

.info {
  .base.smallScreen & {
    width: calc(100% - (100px + $spacing));
  }
}

.task {
  width: 50%;
  flex-shrink: 0;

  .base.smallScreen & {
    width: 100%;
  }
}

.errorContainer {
  margin-top: $spacing;

  &:empty {
    display: none;
  }
}

.idUpload {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 2;

  & > * {
    text-align: center;

    .base:not(.smallScren) & {
      width: calc(50% - #{$spacing});
    }

    .base.smallScreen & {
      width: 100%;
    }

    img {
      height: 62px;
      margin-bottom: $spacing;
    }
  }
}

.bottom {
  .base:not(.smallScreen) & {
    display: flex;
    gap: $spacing;
  }
}

$videoWidth: 490px;

.bottomRight {
  width: calc(100% - #{$videoWidth - $spacing});

  .base.mediumScreen & {
    width: calc(50% - $spacing);
  }

  .base.smallScreen & {
    width: 100%;
  }
}
.videoWrap {
  width: $videoWidth;

  .base.mediumScreen & {
    width: 50%;
  }

  .base.smallScreen & {
    width: 100%;
    margin-bottom: $spacing;
  }
}

.videoWrapInner {
  position: relative;
  width: 100%;
  padding-bottom: 56.4%;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
