<template>
  <BaseModal
    :heading="$t('marketing.email.automated_emails.rebook_default.title')"
    small
    grey
    :parentRoute="{
      name: parentRouteName
    }"
  >
    <div :class="$style.rebookDisabledVisual">
      <RebookDisabledVisual :mb="2" />
    </div>
    <BaseCard label="default">
      <template #header>
        <BaseText
          iconAfter="edit"
          :routerLink="{ name: 'rebook-reminder-services' }"
          >{{ $t('global.actions.edit') }}</BaseText
        >
      </template>
      <BaseText mb>
        {{ $t('marketing.email.automated_emails.rebook_default.info') }}
      </BaseText>
    </BaseCard>
    <template #footer>
      <BaseButton @click="onSave" v-test="'rebook-default-setup-save'">
        {{
          $t('marketing.email.automated_emails.rebook_default.go_to_dashboard')
        }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import router from '@/router';
import RebookDisabledVisual from '../../email/templates/rebook-reminder/RebookDisabledVisual.vue';
import BaseText from '@/components/BaseText.vue';

const parentRouteName = 'rebook-reminder-template';

const onSave = () => {
  router.push({ name: parentRouteName });
};
</script>

<style lang="scss" module>
.rebookDisabledVisual {
  display: flex;
  justify-content: center;
}
</style>
