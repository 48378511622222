import { useMutation } from '@vue/apollo-composable';
import { GENERATE_ADYEN_ONBOARDING_LINK } from './onboarding/graphql';

export const generateOnboardingLink = () => {
  const { mutate } = useMutation(GENERATE_ADYEN_ONBOARDING_LINK);
  return new Promise<void>((resolve) => {
    mutate({ input: {} }).then((result: any) => {
      const url = result.data.generateAdyenOnboardingLink?.onboardingLink.url;

      if (url) {
        window.location.href = url;
      }

      resolve();
    });
  });
};
