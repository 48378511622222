import gql from 'graphql-tag';

export const UPDATE_SERVICES = gql`
  mutation updateServices($input: UpdateServicesInput!) {
    updateServices(input: $input) {
      services {
        id
      }
    }
  }
`;
