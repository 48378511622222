<template>
  <BaseCard gray :mb="1.5">
    <div
      :class="[
        $style.base,
        {
          [$style.mediumScreen]: $screen === 's' || $screen === 'm'
        }
      ]"
      v-test="'service-variation-about'"
    >
      <BaseInput
        v-model="formData.name"
        :label="$t('service.name')"
        :maxLength="255"
        required
        mb
        v-test="'form-service-name'"
      />
      <div :class="$style.row">
        <div :class="$style.rowGroup">
          <BaseInput
            v-model="formData.variations[firstVariationIndex].price"
            :label="$t('global.price')"
            type="currency"
            :mr="0.5"
            :mb="$screen !== 'l'"
            v-test="'service-price'"
          />
          <BaseDropdown
            v-model="formData.vatRateId"
            :options="[
              {
                value: null,
                label: $t('products.default_vat')
              },
              ...vatRates.map((rate) => ({
                value: rate.id,
                label: rate.label
              }))
            ]"
            :label="$t('global.items.vat_rate', 1)"
          />
        </div>
        <div :class="$style.rowGroup">
          <BaseInput
            v-model="formData.variations[firstVariationIndex].duration"
            :label="$t('global.duration')"
            unitLabel="minute"
            type="number"
            :disabled="
              formData.variations[firstVariationIndex].requiresProcessingTime
            "
            :minValue="0"
            :maxValue="maxDuration"
            :mr="0.5"
            :mb="$screen !== 'l'"
            v-test="'service-duration'"
          />
          <div :class="$style.moreOptions">
            <BaseButton
              color="inverted"
              :disabled="moreOptions.length <= 0"
              :options="moreOptions"
              @select="handleMoreOptionsSelect"
              v-test="'service-duration-more-options'"
            >
              {{ $t('global.more_options') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
  <BaseCard
    v-if="formData.variations[firstVariationIndex].requiresProcessingTime"
    gray
    :mb="1.5"
    noBorder
    v-test="'service-processing-time-card'"
  >
    <div :class="$style.row">
      <BaseHeading mb>{{ $t('service.processing_time.heading') }}</BaseHeading>
      <BaseIcon
        clickable
        name="delete"
        @click="
          formData.variations[firstVariationIndex].requiresProcessingTime =
            false
        "
      />
    </div>
    <BaseText mb>{{ $t('service.processing_time.description') }}</BaseText>
    <div :class="$style.row">
      <BaseInput
        v-model="formData.variations[firstVariationIndex].durationSetup"
        unitLabel="minute"
        type="number"
        :label="$t('service.duration_processing.setup')"
        :required="
          formData.variations[firstVariationIndex].requiresProcessingTime
        "
        :minValue="
          formData.variations[firstVariationIndex].requiresProcessingTime
            ? 1
            : 0
        "
        :maxValue="maxDurationProcessing"
        :mb="$screen === 's'"
        v-test="'service-duration-setup'"
      />
      <BaseInput
        v-model="formData.variations[firstVariationIndex].durationProcessing"
        :label="$t('service.duration_processing.processing')"
        :minValue="0"
        :maxValue="maxDurationProcessing"
        unitLabel="minute"
        type="number"
        :mb="$screen === 's'"
        :info="$t('service.duration_processing.description')"
        v-test="'service-duration-processing'"
      />
      <BaseInput
        v-model="formData.variations[firstVariationIndex].durationFinish"
        unitLabel="minute"
        type="number"
        :label="$t('service.duration_processing.finish')"
        :minValue="0"
        :maxValue="maxDurationProcessing"
        v-test="'service-duration-finish'"
      />
    </div>
  </BaseCard>
  <BaseCard
    v-if="formData.variations[firstVariationIndex].buffer || hasBufferTime"
    gray
    :mb="1.5"
    v-test="'service-buffer-time-card'"
  >
    <div :class="$style.row">
      <BaseHeading mb>{{ $t('service.buffer_time.heading') }}</BaseHeading>
      <BaseIcon clickable name="delete" @click="removeBufferTime" />
    </div>
    <BaseText mb>{{ $t('service.buffer_time.description') }}</BaseText>
    <BaseInput
      v-model="formData.variations[firstVariationIndex].buffer"
      :label="$t('service.buffer_time.heading')"
      :minValue="0"
      :maxValue="maxDuration"
      unitLabel="minute"
      type="number"
      :info="$t('service.buffer_time.info')"
      v-test="'service-buffer'"
    />
  </BaseCard>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { ServiceVariationsFormData } from '../types';
import { storeToRefs } from 'pinia';
import { useVatRatesStore } from '@/stores/vat-rates';

const { t } = useI18n();

const formData = inject<ServiceVariationsFormData>('serviceFormData')!;
const maxDuration = 1439;
const { vatRates } = storeToRefs(useVatRatesStore());
const moreOptionsPopoverShow = ref(false);
const hasBufferTime = ref(false);

const maxDurationProcessing = Math.floor(maxDuration / 3);

const firstVariationIndex = computed(() =>
  formData.variations.findIndex((variation) => !variation.destroy)
);

watchEffect(() => {
  if (formData.variations[firstVariationIndex.value].requiresProcessingTime) {
    formData.variations[firstVariationIndex.value].duration =
      formData.variations[firstVariationIndex.value].durationSetup +
      formData.variations[firstVariationIndex.value].durationProcessing +
      formData.variations[firstVariationIndex.value].durationFinish;
  }
});

const moreOptions = computed(() => {
  const options = [];

  if (!formData.variations[firstVariationIndex.value].requiresProcessingTime) {
    options.push({
      label: t('service.processing_time.heading'),
      value: 'processingTime'
    });
  }

  if (
    !(
      formData.variations[firstVariationIndex.value].buffer ||
      hasBufferTime.value
    )
  ) {
    options.push({
      label: t('service.buffer_time.heading'),
      value: 'bufferTime'
    });
  }

  return options;
});

const removeBufferTime = () => {
  formData.variations[firstVariationIndex.value].buffer = 0;
  hasBufferTime.value = false;
};

const handleMoreOptionsSelect = (value: 'processingTime' | 'bufferTime') => {
  moreOptionsPopoverShow.value = false;

  if (value === 'processingTime') {
    formData.variations[firstVariationIndex.value].requiresProcessingTime =
      true;
  }

  if (value === 'bufferTime') {
    hasBufferTime.value = true;
  }
};
</script>

<style module lang="scss">
.row {
  display: flex;
  justify-content: space-between;
  gap: $spacing * 0.5;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
  }
}

.rowGroup {
  display: flex;
  align-items: flex-end;
  margin-right: $spacing * 1.5;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
    margin-right: 0;
    margin-bottom: $spacing;
  }

  & > *:first-child {
    flex-grow: 1;
  }
}

.moreOptions {
  position: relative;
}
</style>
