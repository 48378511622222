<template>
  <BaseModal
    :heading="
      !reportId
        ? $t('global.actions.create_report')
        : $t('global.actions.edit_report')
    "
    small
    testId="create-report"
    useForm
    :parentRoute="parentRouteName"
    @submit="submit"
  >
    <BaseDropdown
      v-if="!appointmentId"
      v-model="employeeId"
      :label="$t('global.items.employee', 1)"
      :options="
        employees.map((e) => ({
          value: e.id,
          label: e.name
        }))
      "
      required
      resources
      mb
      v-test="'select-employee'"
    />
    <BaseInput
      v-model="report"
      :label="$t('global.report')"
      type="textarea"
      :rows="6"
      required
      mb
      v-test="'createReportInput'"
    />
    <BaseTableRow
      v-for="(image, index) in attachments"
      v-show="!image.destroy"
      :key="index"
      :options="['delete']"
      mb
      @optionClick="$event === 'delete' ? deleteAttachment(image) : null"
    >
      <BaseTableCell small>
        <BaseImage :cloudinaryId="image.publicId" width="40" format="jpg" />
      </BaseTableCell>
      <BaseTableCell>
        <BaseText bold>
          {{ image.filename }}
        </BaseText>
      </BaseTableCell>
    </BaseTableRow>
    <BaseUpload
      preset="attachments"
      multiupload
      showSupport
      dropfield
      @uploadResults="onUpload"
    />
    <template #footer>
      <BaseButton :loading="isSaving" submitForm v-test="'createReportSave'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { reportFragment } from '@/graphql-fragments';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const GET_APPOINTMENT_REPORT = gql`
  query getAppointmentReport($id: Int!) {
    appointmentReport(id: $id) {
      ...report
    }
  }
  ${reportFragment}
`;

const UPDATE_APPOINTMENT_REPORT = gql`
  mutation updateAppointmentReport($input: UpdateAppointmentReportInput!) {
    updateAppointmentReport(input: $input) {
      appointmentReport {
        ...report
      }
    }
  }
  ${reportFragment}
`;

const CREATE_APPOINTMENT_REPORT = gql`
  mutation createAppointmentReport($input: CreateAppointmentReportInput!) {
    createAppointmentReport(input: $input) {
      appointmentReport {
        ...report
      }
      errors
    }
  }
  ${reportFragment}
`;
</script>

<script setup lang="ts">
import { useResourcesStore } from '@/stores/resources';
import { updateCalendarEvents } from '@/modules/calendar/actions/calendar-events';
import { ref } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { useRoute, useRouter } from 'vue-router';
import { useSessionStorage } from '@vueuse/core';
import { useQuery, useMutation } from '@vue/apollo-composable';
import type {
  CreateAppointmentReportInput,
  UpdateAppointmentReportInput,
  AttachmentAttributes
} from '@/types';

const props = defineProps<{
  parentRouteName: string;
}>();

const emit = defineEmits(['updated']);

const route = useRoute();
const router = useRouter();
const { employees, hasSingleEmployee } = useResourcesStore();

const reportId = ref(
  typeof route.params.id === 'string' ? parseInt(route.params.id) : null
);
const customerId = ref(
  typeof route.params.customerId === 'string'
    ? parseInt(route.params.customerId)
    : null
);
const appointmentId = ref(
  typeof route.params.appointmentId === 'string'
    ? parseInt(route.params.appointmentId)
    : null
);

// Create a unique id for each ref
// This way session storage will get scoped to the right appointment / customer id
// Session storage will get cleared after saving
const sessionId = appointmentId.value
  ? `Appointment-${appointmentId.value}`
  : customerId.value
    ? `Customer-${customerId.value}`
    : '';

const report = useSessionStorage(`createReportText${sessionId}`, '');
const employeeId = useSessionStorage(
  `createReportEmployee${sessionId}`,
  hasSingleEmployee ? employees[0].id : null,
  {
    serializer: {
      read: (id: any) => (id ? parseInt(id) : null),
      write: (id: any) => (id ? id.toString() : '')
    }
  }
);
const attachments = useSessionStorage<AttachmentAttributes[]>(
  `createReportAttachments${sessionId}`,
  []
);

if (reportId.value) {
  const { onResult } = useQuery(GET_APPOINTMENT_REPORT, {
    id: reportId.value
  });

  onResult(({ data: { appointmentReport } }) => {
    attachments.value = appointmentReport.attachments.map((file: any) => ({
      filename: file.filename,
      id: file.id,
      publicId: file.publicId
    }));
    employeeId.value = appointmentReport.employeeId;
    report.value = appointmentReport.report;
  });
}

const deleteAttachment = (attachment: any) => {
  if (attachment.id) {
    const image = attachments.value.find(
      (image: any) => image.id === attachment.id
    );
    if (image) {
      image.destroy = true;
    }
  } else if (attachment.publicId) {
    attachments.value = attachments.value.filter(
      (image: any) => image.publicId !== attachment.publicId
    );
  }
};

const onUpload = (file: any) => {
  attachments.value.push({
    bytes: file.bytes,
    filename: file.filename,
    format: file.format,
    publicId: file.public_id,
    type: file.type
  });
};

const isSaving = ref(false);

const { t } = useI18n();

const submit = () => {
  isSaving.value = true;

  const input = reportId.value
    ? ({
        attachmentsAttributes: attachments.value,
        id: reportId.value,
        employeeId: employeeId.value,
        report: report.value
      } as UpdateAppointmentReportInput)
    : ({
        appointmentId: appointmentId.value,
        attachmentsAttributes: attachments.value,
        customerId: customerId.value,
        employeeId: employeeId.value,
        report: report.value
      } as CreateAppointmentReportInput);

  const { mutate, onDone } = useMutation(
    reportId.value ? UPDATE_APPOINTMENT_REPORT : CREATE_APPOINTMENT_REPORT,
    { variables: { input } }
  );
  mutate();
  onDone(({ data: { createAppointmentReport, updateAppointmentReport } }) => {
    isSaving.value = false;
    const responseReport = reportId.value
      ? updateAppointmentReport.appointmentReport
      : createAppointmentReport.appointmentReport;

    flash(
      t(
        reportId.value
          ? 'global.flash.report_updated'
          : 'global.flash.report_created'
      )
    );

    if (appointmentId.value) {
      if (responseReport.appointment?.calendarAppointments) {
        updateCalendarEvents(responseReport.appointment.calendarAppointments);
      }
    } else {
      emit('updated');
    }

    // Reset state to clear session storage
    report.value = '';
    employeeId.value = null;
    attachments.value = [];

    router.push({
      name: props.parentRouteName
    });
  });
};
</script>
