<template>
  <div :class="$style.base">
    <BaseIcon name="close" size="l" />
  </div>
</template>

<style lang="scss" module>
$size: 48px;

.base {
  position: relative;
  z-index: 2;
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include background-hover;
}
</style>
