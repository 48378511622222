<template>
  <div v-if="!loading" :class="$style.base">
    <div
      v-if="
        ($route.name === 'rebook-reminder-template' ||
          $route.name === 'default-rebook-reminder-services') &&
        !enabled
      "
      :class="$style.emptyPageWrapper"
    >
      <EmptyPageContent
        :loading="loading"
        :text="{
          title: $t('rebook_reminder.empty_state.title'),
          description: $t('rebook_reminder.empty_state.description'),
          btnPrimary: $t('rebook_reminder.empty_state.enable')
        }"
        :moreLink="{
          url:
            company.language === 'nl' || company.language === 'de'
              ? `https://help.salonized.com/${company.language}/articles/4582588`
              : 'https://help.salonized.com/en/articles/4582588',
          text: $t('rebook_reminder.empty_state.more_info')
        }"
        @click="enableTemplate"
        v-test="'rebook-reminder-empty-state'"
      >
        <template #visual>
          <RebookDisabledVisual />
        </template>
      </EmptyPageContent>
    </div>
    <BaseGrid v-else container noPadding>
      <BaseGrid
        :class="$style.index"
        :size="4"
        noPadding
        v-intercom="
          settings.emailType
            ? `marketing-builder-settings-${settings.emailType.toLowerCase()}`
            : null
        "
      >
        <Settings>
          <slot name="actions" />
        </Settings>
      </BaseGrid>
      <BaseGrid :size="8" noPadding>
        <FiltersBar
          v-show="showFilters"
          @setFilter="setFilter"
          @close="closeFilters"
        />
        <Preview />
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import RebookDisabledVisual from '../templates/rebook-reminder/RebookDisabledVisual.vue';
import Settings from './Settings.vue';
import Preview from './preview/index.vue';
import FiltersBar from '@/components/customer-filters/FiltersBar.vue';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { emailTemplateFragment } from '@/graphql-fragments';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { defineComponent, ref } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { useServicesStore } from '@/stores/services';

export default defineComponent({
  components: {
    Settings,
    Preview,
    FiltersBar,
    EmptyPageContent,
    RebookDisabledVisual
  },
  inject: ['mixpanel'],
  props: {
    settings: {
      type: Object,
      required: true,
      validator: (value) =>
        !!value.title &&
        !!value.description &&
        !!value.sentDescription &&
        !!value.receiveDescription
    },
    modelValue: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const { company } = useCompanyStore();

    if (route?.params?.id) {
      loading.value = true;

      const { result, onResult } = useQuery(
        gql`
          query getEmailTemplate($id: ID!, $emailType: EmailTemplateEnum!) {
            emailTemplate(id: $id, emailType: $emailType) {
              ...emailTemplate
            }
            emailTemplateDefault: emailTemplate(
              id: $id
              emailType: $emailType
              defaultValues: true
            ) {
              ...emailTemplate
            }
          }
          ${emailTemplateFragment}
        `,
        {
          id: route.params.id,
          emailType: 'MARKETING_CAMPAIGN'
        }
      );

      onResult(() => {
        if (result.value.emailTemplate) {
          result.value.emailTemplate.emailTemplateDefault =
            result.value.emailTemplateDefault;
          store.commit('marketing/SET_ATTRS', result.value.emailTemplate);
        }

        loading.value = false;
      });

      onResult(() => {
        loading.value = false;
      });
    }

    return {
      loading,
      company
    };
  },
  watch: {
    enabled: {
      handler(newValue, oldValue) {
        if (oldValue !== null) {
          this.$emit('update:modelValue', newValue);
        }
      }
    }
  },
  computed: {
    showFilters() {
      return this.$store.getters['marketing/showFilters'];
    },
    enabled() {
      return this.$store.getters['marketing/builder/enabled'];
    }
  },
  methods: {
    enableTemplate() {
      this.loading = true;
      this.mixpanel.track('Rebook reminder feature enable click');

      const { rebookEnabledServicesCount } = useServicesStore();

      if (rebookEnabledServicesCount === 0) {
        this.setAllServicesEnabled();
        this.$router.push({ name: 'default-rebook-reminder-services' });
      } else {
        this.$store.commit('marketing/builder/SET_ENABLED', true);
        this.loading = false;
      }
    },
    setAllServicesEnabled() {
      const { categoriesWithServices } = useServicesStore();
      const bulkUpdateServiceAttributes = categoriesWithServices
        .map((category) =>
          category.services.map((service) => ({
            id: service.id,
            name: service.name,
            rebookEnabled: true,
            rebookPeriod: 30
          }))
        )
        .reduce((acc, val) => acc.concat(val), []);

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateServices($input: UpdateServicesInput!) {
              updateServices(input: $input) {
                services {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              bulkUpdateServiceAttributes
            }
          }
        })
        .then(() => {
          const { getServices } = useServicesStore();
          getServices();
          this.$store.commit('marketing/builder/SET_ENABLED', true);
          this.loading = false;
        });
    },
    setFilter(filter) {
      this.$store.commit('marketing/SET_FILTER', filter);
    },
    closeFilters() {
      this.$store.commit('marketing/SET_SHOW_FILTERS', false);
    }
  },
  created() {
    this.$store.commit('marketing/builder/SET_SETTINGS', this.settings);

    if (this.settings.showToggle) {
      this.$store.commit('marketing/builder/SET_ENABLED', this.modelValue);
    }
  },
  beforeUnmount() {
    this.$store.commit('marketing/RESET_TO_EMPTY_STATE');
    this.$store.commit('marketing/builder/RESET_STATE');
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
  margin: -$spacing;
}

.index {
  z-index: 2;
}

.emptyPageWrapper {
  padding: 0.5 * $spacing;
}
</style>
