<template>
  <BaseCard :heading="$t('global.feedback')">
    <div :class="$style.feedback" v-test="'feedback'">
      <BaseIcon
        :name="feedback.score >= 3 ? 'smiley_happy' : 'smiley_sad'"
        :mr="0.25"
      />
      {{ feedback.comment }}
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    feedback: {
      type: Object,
      required: true
    }
  }
});
</script>

<style lang="scss" module>
.feedback {
  display: flex;
  align-items: center;
}
</style>
