<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <BaseCard :heading="$t('insights.appointments_amount')" :loading="loading">
      <template #header>
        <div :class="$style.controls">
          <BaseDropdown
            v-if="context === 'employees'"
            v-model="selectedEmployeeIds"
            :options="
              employees.map((employee) => ({
                label: employee.name,
                value: employee.id
              }))
            "
            small
          />
          <BaseDropdown
            v-if="context === 'services'"
            v-model="selectedServiceIds"
            :options="
              services.map((service) => ({
                label: service.name,
                value: service.id,
                groupValue: service.category.id
              }))
            "
            :groups="
              serviceCategories.map((category) => ({
                value: category.id,
                label: category.name
              }))
            "
            small
          />
          <BaseSwitch
            v-model="context"
            :options="
              [
                {
                  label: $t('insights.filters.all'),
                  value: 'all',
                  hide: range === 'day'
                },
                {
                  label: $t('insights.filters.employee'),
                  value: 'employees',
                  hide: hasSingleEmployee
                },
                {
                  label: $t('insights.filters.service'),
                  value: 'services'
                }
              ].filter((item) => !item.hide)
            "
            small
          />
        </div>
      </template>
      <EmptyState
        v-if="!chartData.datasets.length || !chartData.datasets[0].data.length"
      />
      <div v-else :class="$style.wrap">
        <DoughnutChart
          v-if="range === 'day'"
          :chartData="chartData"
          :class="$style.doughnutChart"
        />
        <BarChart v-else :chartData="chartData" :class="$style.barChart" />
        <div v-if="legendData.length" :class="$style.legend">
          <div
            v-for="(item, index) in legendData"
            :key="index"
            :class="$style.legendItem"
          >
            <div
              :class="$style.legendColor"
              :style="{
                'background-color': `${context === 'services' ? item.category.color : item.color}40`,
                'border-color':
                  context === 'services' ? item.category.color : item.color
              }"
            ></div>
            <BaseText oneLine>
              {{ item.name }}
            </BaseText>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script setup lang="ts">
import BarChart from '../BarChart.vue';
import DoughnutChart from '../DoughnutChart.vue';
import { useResourcesStore } from '@/stores/resources';
import { useServicesStore } from '@/stores/services';
import { storeToRefs } from 'pinia';
import filters from '@/filters';
import { useStorage } from '@vueuse/core';
import dayjs from '@/dayjs';
import { useI18n } from 'vue-i18n';
import EmptyState from '../EmptyState.vue';

const range = inject<any>('range');

const props = defineProps<{
  appointments: any;
  loading: boolean;
}>();

const context = useStorage<'all' | 'employees' | 'services'>(
  'insightsContext',
  'all'
);

watch(
  () => props.loading,
  (value) => {
    if (value) {
      return;
    }

    if (!selectedEmployeeIds.value.length) {
      selectedEmployeeIds.value = props.appointments.employees
        .slice(0, 3)
        .map((employee: any) => parseInt(employee.id));
    }

    if (!selectedServiceIds.value.length) {
      selectedServiceIds.value = props.appointments.services
        .slice(0, 3)
        .map((service: any) => parseInt(service.id));
    }
  }
);

const { resourceById, employees, hasSingleEmployee } = useResourcesStore();
if (hasSingleEmployee && context.value === 'employees') {
  context.value = 'all';
}
const selectedEmployeeIds = ref([]);
const selectedEmployees = computed(() =>
  selectedEmployeeIds.value.map((id) => resourceById(id))
);

const selectedServiceIds = ref([]);
const { serviceById, serviceCategories } = useServicesStore();
const { services } = storeToRefs(useServicesStore());
const selectedServices = computed(() =>
  selectedServiceIds.value.map((id) => serviceById(id))
);

const legendData = computed(() => {
  if (context.value === 'employees') {
    return selectedEmployees.value;
  } else if (context.value === 'services') {
    return selectedServices.value;
  } else {
    return [];
  }
});

type DataSet = {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  data: number[];
  label: string;
  type: string;
};

const allData = computed(() => {
  let labels: string[] = [];
  let datasets: any[] = [];

  const data = props.appointments;
  if (data?.all[0]) {
    labels = data.all[0].series.map((item: any) => {
      if (range.value === 'month') {
        return (
          filters.capitalize(t('global.items.week', 1)) +
          ' ' +
          dayjs(item.label).isoWeek()
        );
      } else {
        return filters.date(item.label);
      }
    });

    datasets = [
      {
        backgroundColor: '#4c49c540',
        borderColor: '#4c49c5',
        borderWidth: 1,
        data: data.all[0].series.map((item: any) => item.sum),
        label: 'appointments',
        type: 'bar'
      }
    ];
  }

  return {
    labels,
    datasets
  };
});

const employeeData = computed(() => {
  let labels: string[] = [];
  const datasets: DataSet[] = [];

  const data = props.appointments;

  if (data?.employees[0]) {
    labels = generateLabels(data.employees[0].series);

    selectedEmployees.value.forEach((employee) => {
      if (employee) {
        const employeeData = data.employees.find(
          (e: any) => parseInt(e.id) === employee.id
        );
        if (employeeData) {
          datasets.push(
            generateDataSet({
              data: employeeData.series.map((item: any) => item.sum),
              color: employee.color || '#4c49c5',
              label: employee?.name || ''
            })
          );
        }
      }
    });
  }

  return {
    datasets,
    labels
  };
});

const { t } = useI18n();

const serviceData = computed(() => {
  let labels: string[] = [];
  const datasets: DataSet[] = [];

  const data = props.appointments;

  if (data?.services[0]) {
    labels = generateLabels(data.services[0].series);

    selectedServices.value.forEach((service) => {
      if (service) {
        const serviceData = data.services.find(
          (e: any) => parseInt(e.id) === service.id
        );
        if (serviceData) {
          datasets.push(
            generateDataSet({
              data: serviceData.series.map((item: any) => item.sum),
              color: service?.category?.color || '#4c49c5',
              label: service?.name || ''
            })
          );
        }
      }
    });
  }

  return {
    datasets,
    labels
  };
});

const generateDataSet = ({
  data,
  color,
  label
}: {
  data: DataSet['data'];
  color: string;
  label: DataSet['label'];
}): DataSet => ({
  backgroundColor: `${color}40`,
  borderColor: color,
  borderWidth: 1,
  data,
  label,
  type: 'bar'
});

const generateLabels = (labels: any[]) =>
  labels.map((item: any) => {
    if (range.value === 'year') {
      return filters.date(item.label, { format: 'monthLong' });
    } else if (range.value === 'month') {
      return (
        filters.capitalize(t('global.items.week', 1)) +
        ' ' +
        dayjs(item.label).isoWeek()
      );
    } else {
      return filters.date(item.label);
    }
  });

watch(range, (value) => {
  if (value === 'day' && context.value === 'all') {
    context.value = 'employees';
  }
});

const chartData = computed(() => {
  switch (context.value) {
    case 'all':
      return allData.value;
    case 'employees':
      if (range.value === 'day') {
        return employeeDataDaily.value;
      } else {
        return employeeData.value;
      }
    case 'services':
      if (range.value === 'day') {
        return serviceDataDaily.value;
      } else {
        return serviceData.value;
      }
  }
});

const formatDataDaily = (type: 'employees' | 'services') => {
  let labels: string[] = [];
  let datasets: any[] = [];

  const data = props.appointments;

  if (data) {
    const dataItems = data[type].filter((item) =>
      type === 'services'
        ? selectedServiceIds.value.includes(parseInt(item.id))
        : selectedEmployeeIds.value.includes(parseInt(item.id))
    );

    const items = dataItems.map((item: any) =>
      type === 'services'
        ? serviceById(parseInt(item.id))
        : resourceById(parseInt(item.id))
    );

    labels = items.map((item: any) => item.name);
    datasets = [
      {
        data: dataItems.map((item: any) => item.series[0].sum),
        borderColor: items.map((item: any) =>
          type === 'services' ? item.category.color : item.color
        ),
        borderWidth: 1,
        backgroundColor: items.map(
          (item: any) =>
            `${type === 'services' ? item.category.color : item.color}50`
        ),
        hoverOffset: 4,
        hoverBackgroundColor: items.map(
          (item: any) =>
            `${type === 'services' ? item.category.color : item.color}80`
        )
      }
    ];
  }

  return {
    labels,
    datasets
  };
};

const serviceDataDaily = computed(() => formatDataDaily('services'));
const employeeDataDaily = computed(() => formatDataDaily('employees'));
</script>

<style lang="scss" module>
.charts {
  display: flex;
  gap: $spacing * 2;
  flex-wrap: wrap;
  margin-bottom: $spacing * 2;

  & > * {
    width: calc(50% - #{$spacing});
  }
}

.wrap {
  .base:not(.smallScreen) & {
    display: flex;
  }
}

.barChart {
  .base:not(.smallScreen) & {
    width: 75%;
  }
}

.doughnutChart {
  .base:not(.smallScreen) & {
    width: 50%;
    margin: 0 12.5%;
  }
}

.legend {
  .base:not(.smallScreen) & {
    width: 25%;
    max-height: 400px;
    overflow-y: auto;
  }

  display: flex;
  flex-direction: column;
  gap: $spacing * 0.5;
  padding: $spacing;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
}

.legendColor {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  flex-shrink: 0;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;

  .base.smallScreen & {
    & > *:first-child {
      order: 2;
    }
  }
}
</style>
