<template>
  <BaseCard
    v-if="!errors.length && isSubscribed"
    noPadding
    :mb="2"
    v-test="`billing-plan-card`"
  >
    <div :class="$style.container">
      <div v-if="!loading" :class="$style.billingInfoContainer">
        <BaseHeading size="s">{{
          t('subscription.your_membership')
        }}</BaseHeading>
        <ManageSubscription v-if="isCardDataReady" />
      </div>
      <div v-if="!loading && quote && basePrice" :class="$style.quoteContainer">
        <div :class="$style.row">
          <BaseHeading size="m">
            {{ t(`subscription.types.${quote.plan.toLowerCase()}.name_long`) }}
          </BaseHeading>
          <BaseHeading v-test="`billing-plan-card-plan-price`">
            {{ filters.currency(basePrice.amountSubtotal, basePrice.currency) }}
          </BaseHeading>
        </div>
        <div v-if="resourcesPrice" :class="$style.row">
          <BaseText>
            {{ t('subscription.payment.employees') }}
          </BaseText>
          <BaseText bold v-test="`billing-plan-card-resources-price`">
            {{
              filters.currency(
                resourcesPrice.amountSubtotal,
                resourcesPrice.currency
              )
            }}
          </BaseText>
        </div>
        <div v-if="boostPrice" :class="$style.row">
          <BaseText>
            {{ t('subscription.payment.boost') }}
          </BaseText>
          <BaseText bold v-test="`billing-plan-card-boost-price`">
            {{
              filters.currency(boostPrice.amountSubtotal, boostPrice.currency)
            }}
          </BaseText>
        </div>
        <div v-if="locationsPrice" :class="$style.row">
          <BaseText>
            {{ t('subscription.payment.locations') }}
          </BaseText>
          <BaseText bold v-test="`billing-plan-card-locations-price`">
            {{
              filters.currency(
                locationsPrice.amountSubtotal,
                locationsPrice.currency
              )
            }}
          </BaseText>
        </div>
        <div
          v-if="quote.amountSubtotal !== quote.amountTotal"
          :class="[$style.row, $style.priceTotal]"
        >
          <BaseText>
            {{ t('subscription.payment.subtotal') }}
          </BaseText>
          <BaseText bold v-test="`billing-plan-card-subtotal-price`">
            {{ filters.currency(quote.amountSubtotal, basePrice.currency) }}
          </BaseText>
        </div>
        <div :class="$style.row">
          <div :class="$style.textContainer">
            <BaseText
              inline
              :mr="0.5"
              bold
              v-test="`billing-plan-card-total-price`"
            >
              {{ t('subscription.payment.total') }}
            </BaseText>
            <BaseIcon
              v-if="quote.amountSubtotal !== quote.amountTotal"
              name="info"
              size="s"
              :tooltip="{
                text: $t('subscription.payment.in_vat'),
                touch: true
              }"
            />
          </div>
          <div>
            <BaseText bold inline v-test="`billing-plan-card-total-price`">
              {{ filters.currency(quote.amountTotal, basePrice.currency) }}
            </BaseText>
            <BaseText inline>
              /
              {{
                t(`global.items.${quote.period.toLowerCase()}_short`)
              }}</BaseText
            >
          </div>
        </div>
      </div>
      <BaseCard
        v-if="
          isCardDataReady &&
          company.activeSubscription?.collectionMethod ===
            StripeCollectionMethod.SendInvoice
        "
        mt
      >
        <div :class="$style.billingInfoContainer">
          <BaseText>{{ t('admin.cards.plan.change_plan_card') }}</BaseText>
          <BaseText
            link
            @click="handleChangePlan"
            v-test="'billing-plan-card-set-automatic-pay'"
            >{{ t('admin.cards.plan.change_plan_card_cta') }}</BaseText
          >
        </div>
      </BaseCard>
    </div>
    <BoostBanner v-if="!loading" @success="onBoostSuccess" />
    <div v-if="loading" :class="$style.spinnerContainer">
      <BaseSpinner />
    </div>
    <BaseModal
      v-if="showChangePaymentModal"
      small
      @close="showChangePaymentModal = false"
      v-test="'billing-plan-card-change-payment-modal'"
    >
      <BaseText mb>{{
        t('admin.cards.plan.change_modal_description')
      }}</BaseText>
      <BaseButton
        @click="goToStripePortal"
        v-test="'billing-plan-card-change-payment-button'"
      >
        {{ t('admin.cards.plan.change_modal_cta') }}
      </BaseButton>
    </BaseModal>
  </BaseCard>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

import { StripeCollectionMethod } from '@/types';
import filters from '@/filters';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useStripe } from '@/helpers/stripe';
import { flash } from '@/helpers/ui';

import { useCurrentPlanQuote } from './store';
import getCompanySubscription from './get-company-subscription';
import ManageSubscription from './ManageSubscription.vue';
import BoostBanner from '@/modules/_shared/boost-banner/index.vue';

const { company, isSubscribed, isCollectivePayment } = useCompanyStore();
const { t } = useI18n();

const showChangePaymentModal = ref(false);

const { activeSubscription, refetch: refetchActiveSubscription } =
  getCompanySubscription();
const {
  errors,
  loading,
  quote,
  basePrice,
  resourcesPrice,
  boostPrice,
  locationsPrice
} = storeToRefs(useCurrentPlanQuote());
const { getData } = useCurrentPlanQuote();
const { goToStripePortal, setAutomaticPayment } = useStripe();

const isCardDataReady = computed(
  () => quote && !activeSubscription.value?.cancelled && !isCollectivePayment
);

const handleChangePlan = () => {
  setAutomaticPayment().then(
    ({
      data: {
        updateSubscriptionCollectionMethod: { errors }
      }
    }) => {
      if (
        errors?.length > 0 &&
        errors.find(
          (item: any) => item.type === 'collection_method_update_failed'
        )
      ) {
        showChangePaymentModal.value = true;
        return;
      }

      const { getUser } = useUserStore();
      getUser().then(() => {
        flash(t('global.flash.company_updated'));
      });
    }
  );
};

if (isSubscribed) {
  getData();
}

const onBoostSuccess = () => {
  refetchActiveSubscription();
  getData();
};
</script>

<style lang="scss" module>
.container {
  padding: $spacing;
}

.spinnerContainer {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing;

  &:last-child {
    margin-bottom: 0;
  }
}

.priceTotal {
  padding-top: $spacing;
  border-top: 1px solid $color-border;
}

.textContainer {
  display: flex;
  align-items: center;
}

.billingInfoContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $spacing;
  justify-content: space-between;
}

.planBaseInfoContainer {
  margin: $spacing 0;
}
</style>
