<template>
  <div :class="$style.wrapper">
    <transition appear :duration="1000">
      <svg viewBox="0 0 211 185" fill="none" :class="$style.base">
        <path
          d="M116.052 1.49682L34.8906 60.5L120.175 139L205.891 61L124.293 1.50266C121.838 -0.287059 118.51 -0.289423 116.052 1.49682Z"
          fill="url(#paint0_linear_316_15464)"
          :class="$style.envelope"
        />
        <g :class="$style.letter_1">
          <path
            d="M41.5244 30.676C41.4524 29.5058 42.3979 28.5249 43.57 28.5539L180.94 31.9518C182.025 31.9786 182.891 32.8658 182.891 33.9511V145.5C182.891 148.261 180.652 150.5 177.891 150.5H53.5926C50.9504 150.5 48.7642 148.444 48.6021 145.807L41.5244 30.676Z"
            fill="#F2F2F9"
          />
          <path
            d="M117.891 79.5C117.891 78.3954 118.786 77.5 119.891 77.5H157.891C158.995 77.5 159.891 78.3954 159.891 79.5C159.891 80.6046 158.995 81.5 157.891 81.5H119.891C118.786 81.5 117.891 80.6046 117.891 79.5Z"
            fill="#ECEBFC"
          />
          <path
            d="M117.891 87.5C117.891 86.3954 118.786 85.5 119.891 85.5H157.891C158.995 85.5 159.891 86.3954 159.891 87.5C159.891 88.6046 158.995 89.5 157.891 89.5H119.891C118.786 89.5 117.891 88.6046 117.891 87.5Z"
            fill="#ECEBFC"
          />
          <path
            d="M117.891 95.5C117.891 94.3954 118.786 93.5 119.891 93.5H157.891C158.995 93.5 159.891 94.3954 159.891 95.5C159.891 96.6046 158.995 97.5 157.891 97.5H119.891C118.786 97.5 117.891 96.6046 117.891 95.5Z"
            fill="#ECEBFC"
          />
          <path
            d="M61.8906 79.5C61.8906 78.3954 62.7861 77.5 63.8906 77.5H98.8906C99.9952 77.5 100.891 78.3954 100.891 79.5V109.5C100.891 110.605 99.9952 111.5 98.8906 111.5H63.8906C62.7861 111.5 61.8906 110.605 61.8906 109.5V79.5Z"
            fill="#EAF8FF"
          />
          <path
            d="M82.9741 66.6001L79.4171 60.1654L77.6901 60.2242L77.9131 66.7724L74.579 66.886L74 49.8798L80.6442 49.6536C83.9542 49.5409 86.1426 51.7477 86.243 54.698C86.3239 57.0726 84.9939 58.9189 82.7853 59.6425L86.668 66.4744L82.9741 66.6001ZM77.5929 57.3699L80.2794 57.2784C81.9584 57.2213 82.9092 56.2283 82.8618 54.8371C82.8128 53.398 81.7975 52.496 80.1185 52.5532L77.4321 52.6446L77.5929 57.3699Z"
            fill="#F7C8E5"
          />
          <path
            d="M91.0374 59.0734L96.3143 58.8937C96.2263 57.72 95.4196 56.5948 93.5966 56.6569C91.9416 56.7133 91.0726 57.9916 91.0374 59.0734ZM96.7307 61.9533L99.4201 62.6542C98.8899 64.7135 97.0769 66.4802 94.1746 66.579C90.9365 66.6892 88.0029 64.4598 87.8673 60.4781C87.7391 56.7123 90.4382 54.1709 93.4605 54.068C97.1064 53.9439 99.3683 56.1962 99.4973 59.9861C99.5129 60.4418 99.4812 60.9231 99.4829 60.9711L91.0397 61.2586C91.1648 62.8152 92.5224 63.8977 94.1055 63.8438C95.5926 63.7931 96.3828 63.0218 96.7307 61.9533Z"
            fill="#F7C8E5"
          />
          <path
            d="M105.375 65.8374L102.281 65.9428L101.69 48.5768L104.832 48.4699L105.066 55.3299C105.565 54.4724 106.834 53.6608 108.585 53.6011C112.039 53.4835 114.12 56.0782 114.24 59.5802C114.361 63.1541 112.245 65.8917 108.887 66.006C107.256 66.0616 105.984 65.3844 105.326 64.3983L105.375 65.8374ZM111.05 59.7128C110.977 57.554 109.641 56.4228 108.01 56.4783C106.403 56.5331 105.1 57.7541 105.174 59.9129C105.246 62.0476 106.631 63.2252 108.238 63.1705C109.869 63.1149 111.124 61.8715 111.05 59.7128Z"
            fill="#F7C8E5"
          />
          <path
            d="M122.397 62.7124C123.956 62.6593 125.357 61.483 125.281 59.2523C125.205 57.0216 123.727 55.9432 122.168 55.9963C120.609 56.0494 119.208 57.2257 119.284 59.4564C119.36 61.6632 120.838 62.7655 122.397 62.7124ZM122.07 53.094C125.595 52.9739 128.347 55.4978 128.471 59.1437C128.594 62.7656 126.022 65.4947 122.496 65.6147C118.97 65.7348 116.218 63.187 116.094 59.5651C115.97 55.9192 118.544 53.214 122.07 53.094Z"
            fill="#F7C8E5"
          />
          <path
            d="M136.592 62.2291C138.151 62.176 139.552 60.9997 139.476 58.769C139.4 56.5383 137.922 55.4599 136.363 55.513C134.804 55.5661 133.403 56.7424 133.479 58.9732C133.554 61.1799 135.033 62.2822 136.592 62.2291ZM136.264 52.6107C139.79 52.4906 142.542 55.0145 142.666 58.6604C142.789 62.2823 140.217 65.0114 136.691 65.1314C133.165 65.2515 130.412 62.7037 130.289 59.0818C130.165 55.4359 132.738 52.7307 136.264 52.6107Z"
            fill="#F7C8E5"
          />
          <path
            d="M156.444 52.2838L151.888 57.4099L156.942 64.0817L153.032 64.2149L149.76 59.8116L148.489 61.2477L148.595 64.3659L145.405 64.4746L144.813 47.1086L148.003 47L148.342 56.9542L152.271 52.4259L156.444 52.2838Z"
            fill="#F7C8E5"
          />
        </g>
        <g :class="$style.letter_2">
          <g filter="url(#filter0_d_316_15464)">
            <path
              d="M54.8906 51.0278C54.8906 49.9633 55.7244 49.0854 56.7874 49.0305L121.692 45.6771L192.679 43.5659C193.862 43.5307 194.817 44.5262 194.733 45.7075L187.222 150.856C187.035 153.473 184.858 155.5 182.235 155.5H59.8906C57.1292 155.5 54.8906 153.262 54.8906 150.5V51.0278Z"
              fill="white"
            />
          </g>
          <path
            d="M130.891 90.5C130.891 89.3954 131.786 88.5 132.891 88.5H170.891C171.995 88.5 172.891 89.3954 172.891 90.5C172.891 91.6046 171.995 92.5 170.891 92.5H132.891C131.786 92.5 130.891 91.6046 130.891 90.5Z"
            fill="#ECEBFC"
          />
          <path
            d="M130.891 98.5C130.891 97.3954 131.786 96.5 132.891 96.5H170.891C171.995 96.5 172.891 97.3954 172.891 98.5C172.891 99.6046 171.995 100.5 170.891 100.5H132.891C131.786 100.5 130.891 99.6046 130.891 98.5Z"
            fill="#ECEBFC"
          />
          <path
            d="M130.891 106.5C130.891 105.395 131.786 104.5 132.891 104.5H170.891C171.995 104.5 172.891 105.395 172.891 106.5C172.891 107.605 171.995 108.5 170.891 108.5H132.891C131.786 108.5 130.891 107.605 130.891 106.5Z"
            fill="#ECEBFC"
          />
          <path
            d="M74.8906 90.5C74.8906 89.3954 75.7861 88.5 76.8906 88.5H111.891C112.995 88.5 113.891 89.3954 113.891 90.5V120.5C113.891 121.605 112.995 122.5 111.891 122.5H76.8906C75.7861 122.5 74.8906 121.605 74.8906 120.5V90.5Z"
            fill="#EAF8FF"
          />
          <path
            d="M82.9967 77.9326L79.4395 71.498L77.7125 71.5568L77.9357 78.105L74.6016 78.2187L74.0221 61.2125L80.6662 60.9861C83.9763 60.8733 86.1647 63.0801 86.2653 66.0303C86.3462 68.405 85.0163 70.2513 82.8077 70.975L86.6906 77.8067L82.9967 77.9326ZM77.6153 68.7025L80.3017 68.6109C81.9807 68.5537 82.9315 67.5608 82.884 66.1696C82.835 64.7304 81.8197 63.8285 80.1407 63.8857L77.4542 63.9772L77.6153 68.7025Z"
            fill="#F7C8E5"
          />
          <path
            d="M91.0598 70.4056L96.3367 70.2257C96.2487 69.0521 95.4419 67.9269 93.6189 67.989C91.9639 68.0454 91.0949 69.3238 91.0598 70.4056ZM96.7531 73.2853L99.4426 73.9861C98.9124 76.0454 97.0995 77.8122 94.1972 77.9111C90.9591 78.0214 88.0254 75.7921 87.8898 71.8104C87.7614 68.0445 90.4604 65.5031 93.4827 65.4002C97.1286 65.2759 99.3906 67.5282 99.5197 71.318C99.5353 71.7737 99.5036 72.2551 99.5053 72.303L91.0622 72.5908C91.1873 74.1474 92.5449 75.2298 94.128 75.1758C95.6151 75.1252 96.4053 74.3538 96.7531 73.2853Z"
            fill="#F7C8E5"
          />
          <path
            d="M105.494 77.1659L102.304 77.2746L101.901 65.4735L104.948 65.3696L104.997 66.8088C105.605 65.6354 107.095 64.9603 108.39 64.9161C109.997 64.8614 111.316 65.5128 111.959 66.7636C112.846 65.2926 114.075 64.7224 115.634 64.6693C117.817 64.5949 119.949 65.843 120.056 69.0092L120.317 76.6607L117.223 76.7662L116.984 69.7622C116.941 68.491 116.285 67.5528 114.822 67.6026C113.454 67.6492 112.675 68.7324 112.718 70.0037L112.953 76.9117L109.787 77.0196L109.549 70.0156C109.505 68.7444 108.825 67.807 107.386 67.856C105.995 67.9035 105.214 68.9627 105.258 70.2579L105.494 77.1659Z"
            fill="#F7C8E5"
          />
          <path
            d="M126.646 76.4451L123.455 76.5538L123.053 64.7526L126.243 64.6439L126.646 76.4451ZM122.538 60.9039C122.5 59.8006 123.358 58.8829 124.437 58.8461C125.54 58.8085 126.434 59.6665 126.472 60.7699C126.508 61.8253 125.674 62.7422 124.571 62.7798C123.492 62.8166 122.574 61.9593 122.538 60.9039Z"
            fill="#F7C8E5"
          />
          <path
            d="M132.856 69.4375L133.087 76.2255L129.897 76.3343L129.495 64.5331L132.589 64.4277L132.639 65.8908C133.317 64.643 134.713 64.0431 135.984 63.9998C138.911 63.9 140.325 65.9411 140.413 68.5315L140.667 75.9672L137.477 76.0759L137.242 69.1919C137.197 67.8727 136.514 66.8634 134.979 66.9157C133.588 66.9631 132.809 68.0703 132.856 69.4375Z"
            fill="#F7C8E5"
          />
          <path
            d="M154.636 58.1051L155.155 73.3362C155.182 74.1278 155.26 74.9896 155.324 75.4677L152.278 75.5715C152.222 75.3333 152.13 74.7601 152.111 74.2084C151.616 75.1619 150.441 75.9223 148.858 75.9763C145.5 76.0907 143.011 73.534 142.889 69.96C142.772 66.5061 145.006 63.7163 148.34 63.6027C150.379 63.5332 151.367 64.34 151.724 64.9522L151.494 58.2122L154.636 58.1051ZM146.103 69.8505C146.175 71.9613 147.465 73.1661 149.144 73.1088C150.775 73.0533 152.004 71.7627 151.931 69.6279C151.859 67.5171 150.548 66.3851 148.917 66.4407C147.286 66.4963 146.032 67.7397 146.103 69.8505Z"
            fill="#F7C8E5"
          />
          <path
            d="M160.769 68.03L166.046 67.8501C165.958 66.6764 165.151 65.5513 163.328 65.6134C161.673 65.6698 160.804 66.9481 160.769 68.03ZM166.463 70.9097L169.152 71.6105C168.622 73.6698 166.809 75.4365 163.907 75.5354C160.669 75.6458 157.735 73.4164 157.599 69.4347C157.471 65.6689 160.17 63.1275 163.192 63.0245C166.838 62.9003 169.1 65.1525 169.229 68.9423C169.245 69.3981 169.213 69.8794 169.215 69.9274L160.772 70.2151C160.897 71.7718 162.254 72.8542 163.838 72.8002C165.325 72.7495 166.115 71.9782 166.463 70.9097Z"
            fill="#F7C8E5"
          />
          <path
            d="M178.805 62.8046L178.915 66.0188C178.553 65.9591 178.24 65.9457 177.952 65.9555C176.321 66.0111 174.933 66.8509 175.019 69.3934L175.203 74.7903L172.013 74.899L171.611 63.0978L174.705 62.9924L174.765 64.7434C175.431 63.1597 177.053 62.8163 178.06 62.782C178.324 62.773 178.565 62.7888 178.805 62.8046Z"
            fill="#F7C8E5"
          />
        </g>
        <g clip-path="url(#clip0_316_15464)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M119.838 129.452L149.581 184.997H201.894C204.103 184.997 205.894 183.206 205.894 180.997V60.9971L119.838 129.452Z"
            fill="url(#paint1_linear_316_15464)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M120.301 129.5L90.7417 185H38.778C36.5688 185 34.778 183.209 34.778 181V61.1L120.301 129.5Z"
            fill="url(#paint2_linear_316_15464)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M205.891 185H34.7783L114.561 128.583C118.021 126.136 122.648 126.136 126.108 128.583L205.891 185Z"
            fill="#A1A6F8"
          />
        </g>
        <g :class="$style.circle">
          <g style="mix-blend-mode: multiply">
            <path
              d="M35 99.5C61.9281 89.6142 68.878 63.5466 70 52.5L35 77.5V99.5Z"
              fill="#3F39AC"
              fill-opacity="0.28"
            />
          </g>
          <path
            d="M70.5894 46.338C70.5894 65.8547 54.7874 81.6761 35.2947 81.6761C15.802 81.6761 0 65.8547 0 46.338C0 26.8214 15.802 11 35.2947 11C54.7874 11 70.5894 26.8214 70.5894 46.338Z"
            fill="url(#paint3_linear_316_15464)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M50.5815 32.3384C50.4543 31.292 49.1891 30.9013 48.5293 31.6981L45.2735 35.6347C44.2097 34.3387 42.8906 33.1986 41.4162 32.2812C38.7053 30.5871 35.329 29.9657 32.1365 30.4409C24.9448 31.453 20.2455 36.921 19.3209 43.0201C18.4041 48.973 21.0578 55.1024 26.9084 58.578C32.3919 61.872 37.9007 61.4488 42.4134 57.9132L42.4426 57.8897C44.4089 56.3492 42.5351 53.1455 40.3057 54.2633C36.9519 55.9483 32.8211 55.364 30.1988 53.266C27.5759 51.1955 26.511 47.2851 27.2688 44.5674C27.97 42.0485 30.3843 39.5261 33.2394 39.2394C35.3136 39.0155 37.0796 39.9568 38.5666 41.579L34.2239 44.5474C33.3913 45.118 33.5833 46.4512 34.5519 46.7351C39.8863 48.2938 45.7771 48.4052 48.0621 48.4052C48.9057 48.4052 49.6542 48.4263 49.8506 47.4256C49.9161 47.0966 51.4328 39.3211 50.5815 32.3384Z"
            fill="white"
            :class="$style.arrow"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_316_15464"
            x="38.8906"
            y="31.565"
            width="171.848"
            height="143.935"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="8" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_316_15464"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_316_15464"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_316_15464"
            x1="119.891"
            y1="32.5"
            x2="120.391"
            y2="167"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C8BAF5" />
            <stop offset="1" stop-color="#EB4762" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_316_15464"
            x1="77.0448"
            y1="117.943"
            x2="218.408"
            y2="137.748"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DADCFF" />
            <stop offset="1" stop-color="#A8ACF8" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_316_15464"
            x1="122.302"
            y1="140.036"
            x2="23.8101"
            y2="156.247"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#BFC2F9" />
            <stop offset="1" stop-color="#A8ACF8" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_316_15464"
            x1="35.2947"
            y1="11"
            x2="35.2947"
            y2="81.676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#A1FFD8" />
            <stop offset="1" stop-color="#00B7AA" />
          </linearGradient>
          <clipPath id="clip0_316_15464">
            <path
              d="M35 60H206V177C206 181.418 202.418 185 198 185H43C38.5817 185 35 181.418 35 177V60Z"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </transition>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  justify-content: center;
}
.base {
  width: 250px;
  max-width: 70%;
  max-height: 250px;
  margin-right: 41px;
  @include hover {
    .letter_2 {
      transform: translateY(23px);
    }
  }
}
.letter_1 {
  .base:global(.v-enter-active) & {
    transition: transform 0.3s $easeOutCubic;
    transition-delay: 0.1s;
  }
  .base:global(.v-enter-from) & {
    transform: translateY(100px);
    opacity: 0;
  }
}
.letter_2 {
  transition:
    transform 0.3s $easeOutCubic,
    fill 0.2s $easeOutCubic;
  .base:global(.v-enter-active) & {
    transition-delay: 0.3s;
  }
  .base:global(.v-enter-from) & {
    transform: translateY(100px);
    fill: #ffffff;
    opacity: 0;
  }
}
.circle {
  .base:global(.v-enter-active) & {
    transition: opacity 0.3s $easeInCubic;
    transition-delay: 0.5s;
  }
  .base:global(.v-enter-from) & {
    opacity: 0;
  }
}
.arrow {
  transform-origin: 17% 25%;
  .base:global(.v-enter-active) & {
    transition: transform 0.32s $easeOutCubic;
    transition-delay: 0.75s;
  }
  .base:global(.v-enter-from) & {
    transform: rotate(-45deg);
  }
}
</style>
