<template>
  <BaseScroll :contentWidth="1700">
    <BaseTableRow head>
      <BaseTableCell>
        {{ $t('reports.ranges.date') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.orders') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.transactions') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.cash') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.pin') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.bank') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.online') }}
      </BaseTableCell>
      <BaseTableCell
        v-if="hasActiveVenue"
        right
        textAlign="right"
        v-test="'treatwell-online-payment-header'"
      >
        {{ $t('reports.ranges.treatwell_online_payment') }}
      </BaseTableCell>
      <BaseTableCell v-if="hasSalonizedPay" right textAlign="right">
        {{ $t('reports.ranges.pos') }}
      </BaseTableCell>
      <BaseTableCell right textAlign="right">
        {{ $t('reports.ranges.creditcard') }}
      </BaseTableCell>
      <BaseTableCell right textAlign="right">
        {{ $t('reports.ranges.giftcard') }}
      </BaseTableCell>
      <BaseTableCell right textAlign="right">
        {{ $t('reports.ranges.prepaid_card') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.coupon') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.other') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.vat') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('reports.ranges.total') }}
      </BaseTableCell>
    </BaseTableRow>
    <BaseTableRow
      v-for="(month, index) in data"
      :key="index"
      v-test="'rangeDataRow'"
    >
      <BaseTableCell>
        {{ formatDate(month.date) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ month.orders }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.transactions) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.cash) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.pin) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.bank) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.online) }}
      </BaseTableCell>
      <BaseTableCell
        v-if="hasActiveVenue"
        currency
        v-test="'treatwell-online-payment-value'"
      >
        {{ filters.currency(month.treatwellOnlinePayment) }}
      </BaseTableCell>
      <BaseTableCell v-if="hasSalonizedPay" currency>
        {{ filters.currency(month.pos) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.creditcard) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.giftcard) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.prepaidCard) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.coupon) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.other) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.vat) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(month.total) }}
        <BaseText v-if="month.totalInc" size="s" color="secondary" oneLine>
          {{ $t('reports.ranges.total_incl') }}
          {{ filters.currency(month.totalInc) }}
        </BaseText>
      </BaseTableCell>
    </BaseTableRow>
    <BaseTableRow v-if="totals" footer v-test="'totalsRow'">
      <BaseTableCell>
        {{ $t('reports.ranges.total') }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ totals.totalOrders }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalTransactions) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalCash) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalPin) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalBank) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalOnline) }}
      </BaseTableCell>
      <BaseTableCell
        v-if="hasActiveVenue"
        currency
        v-test="'treatwell-online-payment-total'"
      >
        {{ filters.currency(totals.totalTreatwellOnlinePayment) }}
      </BaseTableCell>
      <BaseTableCell v-if="hasSalonizedPay" currency>
        {{ filters.currency(totals.totalPos) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalCreditcard) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalGiftcard) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalPrepaidCard) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalCoupon) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalOther) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.totalVat) }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(totals.total) }}
        <BaseText v-if="totals.totalInc" size="s" color="secondary" oneLine>
          {{ $t('reports.ranges.total_incl') }}
          {{ filters.currency(totals.totalInc) }}
        </BaseText>
      </BaseTableCell>
    </BaseTableRow>
  </BaseScroll>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { mapState } from 'pinia';
import { useTreatwellStore } from '@/stores/treatwell';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';
import unleash from '@/unleash';
import gql from 'graphql-tag';

export default defineComponent({
  name: 'RangesDataTable',
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    totals: {
      type: Object,
      required: true
    },
    day: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      filters
    };
  },
  apollo: {
    posTerminals: {
      query: gql`
        query posTerminals {
          posTerminals {
            id
          }
        }
      `
    }
  },
  computed: {
    ...mapState(useTreatwellStore, ['hasActiveVenue']),
    hasSalonizedPay() {
      return unleash.isEnabled('SalonizedPay') && this.posTerminals?.length;
    }
  },
  methods: {
    formatDate(newDate) {
      const date = dayjs(newDate).format();
      return this.day
        ? this.filters.date(date, { format: 'long' })
        : this.filters.monthLong(date);
    }
  }
});
</script>
