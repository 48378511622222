<template>
  <BaseModalLarge
    :routes="
      isEditing
        ? [
            {
              name: $route.name,
              heading: $t('global.actions.edit_appointment')
            }
          ]
        : [
            {
              name: 'create-appointment',
              label: $t('global.items.appointment', 1),
              heading: $t('global.actions.create_appointment')
            },
            {
              name: 'create-absence',
              label: $t('global.items.absence', 1),
              heading: $t('global.actions.create_absence')
            },
            {
              name: 'create-chore',
              label: $t('global.items.chore', 1),
              heading: $t('global.actions.create_chore')
            },
            {
              name: 'create-note',
              label: $t('global.items.note', 1),
              heading: $t('global.actions.create_note')
            }
          ]
    "
    testId="create-event"
    :parentRoute="{
      name: 'calendar'
    }"
    noPadding
    :previewMode="previewModeActive"
  >
    <template #headerRight>
      <PreviewModeToggle />
    </template>
    <router-view />
  </BaseModalLarge>
</template>

<script lang="ts">
import { useCreateEventStore } from '@/stores/calendar-create-event';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
import PreviewModeToggle from '@/modules/calendar/PreviewModeToggle.vue';

export default defineComponent({
  name: 'CreateEvent',
  components: {
    PreviewModeToggle
  },
  setup() {
    const { previewModeActive } = storeToRefs(useCalendarPreviewStore());
    const { setDefaultState: setDefaultEventState } = useCreateEventStore();
    setDefaultEventState();

    const { setDefaultState: setDefaultAppointmentState } =
      useCreateAppointmentStore();
    setDefaultAppointmentState();

    return {
      previewModeActive
    };
  },
  computed: {
    isEditing() {
      return !!this.$route.params.appointmentId;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const preCreateStore = usePreCreateStore();
      preCreateStore.$reset();
    });
  },
  unmounted() {
    const { resetExistingAppointmentData, resetFormData } =
      useCreateAppointmentStore();
    resetExistingAppointmentData();
    resetFormData();

    const { resetState } = useCreateEventStore();
    resetState();
  }
});
</script>
