<template>
  <BaseModal
    :heading="$t('register.salonized_pay.onboarding.heading')"
    useForm
    @close="$emit('close')"
    @submit="
      () => {
        updateCompanyDetails();
        if ($gtm) {
          $gtm.trackEvent({ event: 'sp-company-details-save' });
        }
      }
    "
    v-test="'sp-onboarding-modal'"
  >
    <BaseSpinner v-if="loading && microSiteLoading" />
    <div v-if="!loading && !microSiteLoading">
      <div :class="$style.groupedInputs">
        <BaseInput
          v-model="companyDetails.name"
          required
          :label="$t('company.name')"
          v-test="'sp-onboarding-input-name'"
        />
        <BaseInput
          v-model="companyDetails.phone"
          required
          type="tel"
          :label="$t('company.phone_number')"
          :minLength="9"
          :maxLength="16"
          ml
          v-test="'sp-onboarding-input-phone'"
        />
      </div>
      <div :class="$style.groupedInputs">
        <BaseDropdown
          v-model="selectedLegalEntityType"
          required
          :label="$t('register.salonized_pay.new_onboarding.company_structure')"
          :options="adyenLegalEntityTypes"
          v-test="'sp-onboarding-input-selectedLegalEntityType'"
        />
        <BaseDropdown
          v-model="selectedIndustryCode"
          ml
          required
          :label="$t('register.salonized_pay.new_onboarding.company_type')"
          :options="aydenIndustryCodes"
          v-test="'sp-onboarding-input-selectedIndustryCode'"
        />
      </div>
      <div
        v-if="
          selectedLegalEntityType &&
          selectedLegalEntityType !== LegalEntity.Organization
        "
        :class="$style.groupedInputs"
      >
        <BaseInput
          v-model="userDetails.firstName"
          :required="
            selectedLegalEntityType &&
            selectedLegalEntityType !== LegalEntity.Organization
          "
          :label="$t('register.salonized_pay.new_onboarding.owner_first_name')"
          v-test="'sp-onboarding-input-user-firstName'"
        />
        <BaseInput
          v-model="userDetails.lastName"
          ml
          :required="
            selectedLegalEntityType &&
            selectedLegalEntityType !== LegalEntity.Organization
          "
          :label="$t('register.salonized_pay.new_onboarding.owner_last_name')"
          v-test="'sp-onboarding-input-user-lastName'"
        />
      </div>

      <div :class="$style.groupedInputs">
        <BaseInput
          v-model="registrationNumber"
          :required="
            !(
              selectedLegalEntityType == LegalEntity.SoleProprietorship &&
              company.country == 'de'
            )
          "
          :label="
            formats[company.country as keyof typeof formats].registrationNumber
              .translation
          "
          :error="
            registrationNumberError
              ? formats[company.country as keyof typeof formats]
                  .registrationNumber.validation_error
              : null
          "
          :regex="
            formats[company.country as keyof typeof formats].registrationNumber
              .regex
          "
          :placeholder="
            formats[company.country as keyof typeof formats].registrationNumber
              .label
          "
          v-test="'sp-onboarding-input-registrationNumber'"
        />
        <div class="ml-1">
          <BaseInput
            v-model="vatNumber"
            :required="!vatExemption"
            :disabled="vatExemption"
            :label="$t('register.salonized_pay.new_onboarding.vat_number')"
            :error="
              vatNumberError
                ? formats[company.country as keyof typeof formats].vat
                    .validation_error
                : null
            "
            :regex="formats[company.country as keyof typeof formats].vat.regex"
            :placeholder="
              formats[company.country as keyof typeof formats].vat.label
            "
            v-test="'sp-onboarding-input-vatNumber'"
          />
          <BaseCheckbox
            v-model="vatExemption"
            mt
            :label="$t('register.salonized_pay.new_onboarding.vat_exemption')"
            v-test="'sp-onboarding-input-vatExemption'"
          />
        </div>
      </div>
      <BaseInput
        v-model="website"
        required
        :label="$t('register.salonized_pay.new_onboarding.website_url')"
        :mb="2"
        v-test="'sp-onboarding-input-website'"
      />
      <BaseInput
        v-model="companyDetails.address"
        required
        mb
        :label="$t('company.address')"
        v-test="'sp-onboarding-input-address'"
      />
      <div :class="$style.groupedInputs">
        <BaseInput
          v-model="companyDetails.postalcode"
          required
          type="postalcode"
          :label="$t('company.postal_code')"
          v-test="'sp-onboarding-input-postalcode'"
        />
        <BaseInput
          v-model="companyDetails.city"
          required
          :label="$t('company.city')"
          ml
          v-test="'sp-onboarding-input-city'"
        />
      </div>

      <BaseCheckbox
        v-if="
          selectedLegalEntityType &&
          selectedLegalEntityType == LegalEntity.SoleProprietorship
        "
        v-model="residentialAddressSame"
        :label="
          $t('register.salonized_pay.new_onboarding.residential_address_same')
        "
        mb
        v-test="'sp-onboarding-input-residentialAddressSame'"
      />

      <div
        v-if="
          selectedLegalEntityType &&
          selectedLegalEntityType == LegalEntity.SoleProprietorship &&
          !residentialAddressSame
        "
      >
        <BaseInput
          v-model="residentialDetails.address"
          required
          mb
          :label="
            $t('register.salonized_pay.new_onboarding.residential_address')
          "
          v-test="'sp-onboarding-input-residentialAddress'"
        />
        <div :class="$style.groupedInputs">
          <BaseInput
            v-model="residentialDetails.postalcode"
            required
            type="postalcode"
            :label="$t('company.postal_code')"
            v-test="'sp-onboarding-input-residentialPostalcode'"
          />
          <BaseInput
            v-model="residentialDetails.city"
            required
            :label="$t('company.city')"
            ml
            v-test="'sp-onboarding-input-residentialCity'"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <div :class="$style.buttons">
        <BaseButton color="inverted" :ml="0.5" @click="$emit('close')">
          {{ $t('global.actions.cancel') }}
        </BaseButton>
        <BaseButton
          ml
          :loading="adyenOnboardingLoading"
          submitForm
          v-test="'sp-onboarding-save'"
        >
          {{ $t('global.actions.save') }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { reactive, ref, inject } from 'vue';
import {
  CREATE_ADYEN_ONBOARDING,
  GET_ADYEN_ONBOARDING_DATA,
  GET_MICRO_SITE
} from './graphql';
import { LegalEntity } from '@/types';
import type { IndustryCode } from '@/types';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import {
  KVK_NUMBER_VALIDATION_REGEX,
  NL_VAT_NUMBER_VALIDATION_REGEX,
  HRB_NUMBER_VALIDATION_REGEX,
  DE_VAT_NUMBER_VALIDATION_REGEX,
  BE_REGISTRATION_NUMBER_REGEX,
  BE_VAT_NUMBER_REGEX
} from '@/helpers/regex';

const { t } = useI18n();
const mixpanel = inject<any>('mixpanel');
mixpanel.track('Salonized Pay - Company details clicked');

const emit = defineEmits(['close', 'refetch']);

const formats = {
  nl: {
    registrationNumber: {
      regex: KVK_NUMBER_VALIDATION_REGEX,
      label: '12345678',
      translation: t(
        'register.salonized_pay.new_onboarding.registration_number'
      ),
      validation_error: t(
        'register.salonized_pay.new_onboarding.registration_number_error'
      )
    },
    vat: {
      regex: NL_VAT_NUMBER_VALIDATION_REGEX,
      label: 'NLXXXXXXXXXBXX',
      validation_error: t(
        'register.salonized_pay.new_onboarding.vat_number_error'
      )
    }
  },
  de: {
    registrationNumber: {
      regex: HRB_NUMBER_VALIDATION_REGEX,
      label: 'HRB123456',
      translation: t(
        'register.salonized_pay.new_onboarding.registration_number_hrn'
      ),
      validation_error: t(
        'register.salonized_pay.new_onboarding.registration_number_error_hrn'
      )
    },
    vat: {
      regex: DE_VAT_NUMBER_VALIDATION_REGEX,
      label: 'DE123456789',
      validation_error: t(
        'register.salonized_pay.new_onboarding.mwst_number_error'
      )
    }
  },
  be: {
    registrationNumber: {
      regex: BE_REGISTRATION_NUMBER_REGEX,
      label: '1234567890',
      translation: t(
        'register.salonized_pay.new_onboarding.registration_number_be'
      ),
      validation_error: t(
        'register.salonized_pay.new_onboarding.registration_number_error_be'
      )
    },
    vat: {
      regex: BE_VAT_NUMBER_REGEX,
      label: 'BE1234567890',
      validation_error: t(
        'register.salonized_pay.new_onboarding.vat_number_error_be'
      )
    }
  }
};

const { company } = useCompanyStore();
const { user } = useUserStore();
const { onResult: onAdyenDataResult, loading } = useQuery(
  GET_ADYEN_ONBOARDING_DATA
);
const { onResult: onMicroSiteResult, loading: microSiteLoading } =
  useQuery(GET_MICRO_SITE);
const { mutate: createAdyenOnboarding, loading: adyenOnboardingLoading } =
  useMutation(CREATE_ADYEN_ONBOARDING);

const adyenLegalEntityTypes = ref();
const selectedLegalEntityType = ref(LegalEntity.SoleProprietorship);
const aydenIndustryCodes = ref();
const selectedIndustryCode = ref();
const registrationNumber = ref('');
const vatNumber = ref(company.billingInfo?.stripeTaxId?.value || '');
const vatExemption = ref(company.billingInfo?.dontHaveVatNumber || false);
const residentialAddressSame = ref(true);
const website = ref();

const registrationNumberError = computed(() => {
  const registrationNumberInvalid = !registrationNumber.value.match(
    formats[company.country as keyof typeof formats].registrationNumber.regex
  );
  return registrationNumber.value && registrationNumberInvalid;
});

const vatNumberError = computed(
  () =>
    vatNumber.value &&
    !vatNumber.value.match(
      formats[company.country as keyof typeof formats].vat.regex
    )
);

onMicroSiteResult(({ data }) => {
  website.value = data.microSite?.customDomain
    ? data.microSite.customDomain
    : data.microSite.url;
});

onAdyenDataResult(({ data }) => {
  aydenIndustryCodes.value = data.adyenOnboardingData.industryCodes.map(
    (code: IndustryCode) => ({
      label: t(
        `register.salonized_pay.onboarding.company_types.${code.name.toLowerCase()}`
      ),
      value: code.id
    })
  );
  adyenLegalEntityTypes.value = data.adyenOnboardingData.legalEntityTypes.map(
    (type: LegalEntity) => ({
      label: t(
        `register.salonized_pay.new_onboarding.legal_entity.${type.toLowerCase()}`
      ),
      value: type
    })
  );
});

const companyDetails = reactive({
  name: company.name || '',
  phone: company.phone || '',
  address: company.address || '',
  postalcode: company.postalcode || '',
  city: company.city || ''
});

const residentialDetails = reactive({
  address: '',
  postalcode: '',
  city: ''
});

const userDetails = reactive({
  firstName: user?.firstName || '',
  lastName: user?.lastName || ''
});

const updateCompanyDetails = () => {
  createAdyenOnboarding({
    input: {
      companyAttributes: companyDetails,
      residenceAttributes: residentialDetails,
      userAttributes: userDetails,
      microSiteUrl: website.value,
      industryCodeId: selectedIndustryCode.value,
      legalEntityType: selectedLegalEntityType.value,
      registrationNumber: registrationNumber.value,
      vatNumber: vatNumber.value,
      vatExemption: vatExemption.value,
      residentialAddressSame: residentialAddressSame.value
    }
  }).then(() => {
    mixpanel.track('Salonized Pay - Company details saved', {
      choice: selectedLegalEntityType.value
    });
    emit('close');
    emit('refetch');
  });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.groupedInputs {
  display: flex;
  margin-bottom: $spacing;

  & > div {
    width: 100%;
  }
}
</style>
