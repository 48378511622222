import dayjs from '@/dayjs';
import filters from '@/filters';

export const formatTime = (timeString: string) => {
  const firstPart = parseInt(timeString.slice(0, 2));
  const secondPart = parseInt(timeString.slice(3, 5));

  return (
    filters.time(dayjs().hour(firstPart).minute(0)) +
    ' - ' +
    filters.time(dayjs().hour(secondPart).minute(0))
  );
};
