<template>
  <div :class="$style.base" v-test="'two-factor-verification'">
    <BaseHeading size="xl" mb center>
      {{ $t('two_factor.header') }}
    </BaseHeading>
    <EnterToken
      :isLoading="isLoading"
      :tokenError="tokenError"
      :description="parseDescription"
      baseName="two_factor"
      @sendCode="verify"
    />
    <SendSMS
      v-if="isSMS"
      :email="params.email"
      :password="params.password"
      mt
      @smsSent="setMaskedNumber"
    />
  </div>
</template>
<script lang="ts">
import EnterToken from '../verification/EnterToken.vue';
import SendSMS from './SendSMS.vue';
import axios from '@/axios';
import { redirectRoute } from '@/router/helpers';
import { useSessionStore } from '@/stores/session';
import {
  sendNativeAppMessage,
  NativeAppMessage
} from '@/helpers/native-app-bridge';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TwoFactorAuthentication',
  components: {
    EnterToken,
    SendSMS
  },
  data() {
    return {
      isLoading: false,
      tokenError: false,
      maskedNumber: ''
    };
  },
  computed: {
    params() {
      return this.$route.params;
    },
    isSMS() {
      return this.params.authenticator_mode === 'sms';
    },
    parseDescription() {
      if (this.isSMS) {
        return this.$t('two_factor.enter_code_description.sms', {
          mobile_number: this.maskedNumber
        });
      } else {
        return this.$t('two_factor.enter_code_description.authenticator_app');
      }
    }
  },
  methods: {
    setMaskedNumber(masked_phone) {
      this.maskedNumber = masked_phone;
    },
    verify(token) {
      this.tokenError = false;
      this.isLoading = true;

      axios
        .post('/sessions', {
          user: {
            email: this.params.email,
            password: this.params.password,
            two_factor_code: token
          }
        })
        .then(({ data }) => {
          if (data.error) {
            this.tokenError = true;
            this.isLoading = false;
          } else {
            sendNativeAppMessage({
              message: NativeAppMessage.UserLogin
            });
            const { fetchAllData } = useSessionStore();
            fetchAllData().then(() => {
              this.$router.push(redirectRoute({ query: this.$route.query }));
            });
          }
        })
        .catch(() => {
          this.tokenError = true;
          this.isLoading = false;
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.email || !to.params.password) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}
</style>
