<template>
  <div>
    <BaseDropdown
      v-model="variables.categoryIds"
      :label="$t('global.category')"
      :options="
        productCategoriesWithDefault.map((c) => ({
          value: c.id,
          label: c.name
        }))
      "
      disableSelectAll
      mb
      v-test="'select-product-categories'"
    />
    <BaseDropdown
      v-model="selectedProductsIds"
      :label="$t('global.items.product', 2)"
      :options="
        filteredProducts.map((f) => ({
          value: f.id,
          label: f.name
        }))
      "
      searchable
      customSearch
      disableSelectAll
      @scrolledToBottom="
        () => {
          if (!allDataFetched) {
            fetchMore();
          }
        }
      "
      @search="onSearch"
      @update:modelValue="emitValue"
      v-test="'select-products'"
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import gql from 'graphql-tag';
import { usePagination } from '@/apollo/pagination';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  setup() {
    const { products, allDataFetched, fetchMore, variables } = usePagination({
      fieldName: 'products',
      query: gql`
        query getProducts(
          $locationId: ID
          $pagination: PaginationAttributes!
          $search: String
          $categoryIds: [Int!]
        ) {
          products(
            locationId: $locationId
            pagination: $pagination
            search: $search
            categoryIds: $categoryIds
          ) {
            id
            name
            category {
              id
              name
            }
          }
        }
      `,
      variables: {
        search: '',
        categoryIds: []
      }
    });

    const onSearch = (value: string) => {
      variables.search = value;
    };

    return {
      products,
      allDataFetched,
      fetchMore,
      onSearch,
      variables
    };
  },
  data() {
    return {
      productCategories: [],
      selectedCategoryId: null,
      selectedProductsIds: []
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['companyId']),
    productCategoriesWithDefault() {
      const arr = [...this.productCategories];
      if (this.selectedCategoryId) {
        if (!arr.find((item) => item.default)) {
          arr.unshift({
            id: null,
            name: this.$t('global.show_all'),
            default: true
          });
        }
      }
      return arr;
    },
    filteredProducts() {
      const filtered = this.products.filter(
        (product) => product.category?.id === this.selectedCategoryId
      );
      return this.selectedCategoryId ? filtered : this.products;
    }
  },
  methods: {
    emitValue() {
      const values = this.selectedProductsIds;
      const obj = {
        filter_name: 'products',
        values
      };
      this.$emit('selected', obj);
    }
  },
  created() {
    this.emitValue(null);
  },
  apollo: {
    productCategories: {
      query: gql`
        query getProductCategories($search: String) {
          productCategories(search: $search) {
            id
            name
          }
        }
      `,
      variables() {
        return {
          search: ''
        };
      }
    }
  }
});
</script>
