<template>
  <div v-if="customer" :class="$style.customer">
    <Customer
      :customer="customerWithDetails"
      :options="options"
      @select="$emit('select', $event)"
      v-test="'appointment-customer'"
    />
    <div v-if="showAlerts" :class="$style.alerts">
      <BaseAlert
        v-if="alerts.firstAppointment"
        size="s"
        :text="`🎉 ${$t('customer_details.first_appointment', { name: customerWithDetails.firstName })}`"
        v-test="'newCustomerNotification'"
      />
      <BaseAlert
        v-if="alerts.lastAppointmentAt"
        icon="calendar"
        size="s"
        :tooltip="filters.dateTime(details.lastAppointmentAt)"
        :text="`${$t('customer_details.last_appointment')} ${filters.fromNow(details.lastAppointmentAt)}`"
      />
      <BaseAlert
        v-if="alerts.alertNotes"
        color="error"
        size="s"
        :text="customerWithDetails.alertNotes"
        v-test="'customerAlertNotification'"
      />
      <BaseAlert
        v-if="alerts.blocked"
        color="error"
        size="s"
        :text="$t('customers.blocked_customer')"
        v-test="'blockedCustomerNotification'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import Customer from './Customer.vue';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Customer
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    appointmentStartAt: {
      type: String,
      required: false
    },
    options: {
      type: Array
    }
  },
  emits: ['select'],
  setup() {
    return {
      filters
    };
  },
  apollo: {
    details: {
      query: gql`
        query getCustomer($date: DateTime!, $id: Int!) {
          customer(id: $id) {
            lastAppointmentAt(date: $date)
            totalDue
          }
        }
      `,
      update: (data) => data.customer,
      variables() {
        return {
          date: this.appointmentStartAt,
          id: this.customer.id
        };
      },
      skip() {
        return !this.appointmentStartAt || !this.customer?.id;
      }
    }
  },
  computed: {
    customerWithDetails() {
      return { ...this.customer, ...this.details };
    },
    showAlerts() {
      if (!this.details) {
        return false;
      }

      return !!Object.keys(this.alerts).find((key) => this.alerts[key]);
    },
    alerts() {
      return {
        firstAppointment: !this.customerWithDetails.lastAppointmentAt,
        lastAppointmentAt:
          this.details.lastAppointmentAt &&
          !dayjs(this.details.lastAppointmentAt).isSameOrAfter(
            dayjs(this.appointmentStartAt),
            'day'
          ),
        alertNotes: !!this.customerWithDetails.alertNotes,
        blocked: !!this.customerWithDetails.blocked
      };
    }
  }
});
</script>

<style lang="scss" module>
.alerts {
  padding: $spacing * 0.5 $spacing;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    &:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}
</style>
