<template>
  <div>
    <div v-if="!!activeBannerName" :class="$style.base">
      <Banner
        v-for="(banner, index) in banners"
        :key="index"
        :data="banner"
        :isActive="activeBannerName === banner.name"
        @buttonClick="onButtonClick"
        @dismissClick="customHandleDismiss"
      />
    </div>
    <PrintUrl v-if="promptPrint" :url="printUrl" @close="onPrint" />
  </div>
</template>

<script lang="ts">
import Banner from './Banner.vue';
import PrintUrl from '@/components/PrintUrl.vue';
import { mapState, storeToRefs } from 'pinia';
import { useVersionMismatchStore } from '@/stores/version-mismatch';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useFloatingBannerStore } from '@/stores/floating-banner';
import { defineComponent } from 'vue';
import { browser } from '@/user-context';
import { useFiscalizationStore } from '@/modules/register/stores/fiscalization';
import dayjs from '@/dayjs';
import { BlockReason } from '@/types';
import { useAdyenStore } from '@/stores/adyen';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useTreatwellStore } from '@/stores/treatwell';

export default defineComponent({
  name: 'FloatingBanner',
  components: {
    Banner,
    PrintUrl
  },
  inject: ['mixpanel'],
  setup() {
    const userStore = useUserStore();
    const { handlePrintableOrder } = useFiscalizationStore();
    const { adyenAppState } = useAdyenStore();

    return {
      isAdmin: userStore.user?.admin,
      handlePrintableOrder,
      adyenAppState
    };
  },
  data() {
    return {
      promptPrint: false
    };
  },
  watch: {
    activeBannerName: {
      handler(value) {
        const floatingBannerStore = useFloatingBannerStore();
        floatingBannerStore.activeBanner = value;

        if (value) {
          this.mixpanel.track('Floating banner - Shown', { banner: value });
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    ...mapState(useVersionMismatchStore, [
      'versionMismatchMessage',
      'versionMismatchScope'
    ]),
    ...mapState(useCompanyStore, [
      'company',
      'trialDaysRemaining',
      'isBlocked',
      'isTrial',
      'isInsideTestBucket',
      'isCollectivePayment'
    ]),
    ...mapState(useTreatwellStore, [
      'needsToCompleteKYC',
      'treatwellStatus',
      'getTreatwellStatus',
      'hasActiveVenue'
    ]),
    hasOutstandingInvoice() {
      return !!this.company.billingInfo?.hasOutstandingInvoice;
    },
    messageLimitExceeded() {
      return this.company.messageLimitExceeded;
    },
    daysToBeBlockedAt() {
      const isRoleAllowed =
        this.user?.owner || this.user?.admin || this.user?.manager;
      const amountOfDaysToBeBlockedAt = this.company.toBeBlockedAt
        ? dayjs(this.company.toBeBlockedAt).diff(dayjs(), 'day')
        : null;
      return (
        isRoleAllowed &&
        !this.isBlocked &&
        this.company.toBeBlockedAt &&
        amountOfDaysToBeBlockedAt
      );
    },
    printUrl() {
      const { printUrl } = storeToRefs(useFiscalizationStore());

      return printUrl.value;
    },
    banners() {
      const { printableOrder } = storeToRefs(useFiscalizationStore());
      const baseRoute = this.$route.matched.find(
        (route) => route.meta.moduleName
      );
      const routeName = (baseRoute?.meta.moduleName as string) || '';

      return [
        {
          name: 'toBeBlockedAt',
          title: this.$t('banner.message_to_be_blocked_at.title'),
          message: this.$t(
            'banner.message_to_be_blocked_at.message',
            { days: this.daysToBeBlockedAt >= 0 ? this.daysToBeBlockedAt : 0 },
            this.daysToBeBlockedAt
          ),
          buttonText: this.$t('banner.message_to_be_blocked_at.button_text'),
          show:
            typeof this.daysToBeBlockedAt === 'number' &&
            this.company.blockReason !== BlockReason.PausedCollection,
          dismissable: true,
          remindLater: true
        },
        {
          name: 'needsToCompleteKYC',
          message: this.$t('banner.kyc_message'),
          buttonText: this.$t('banner.kyc_button'),
          show:
            this.needsToCompleteKYC &&
            this.hasActiveVenue &&
            this.$route.name !== 'treatwell-admin',
          dismissable: true,
          remindLater: true
        },
        {
          name: 'messageLimitExceeded',
          message: this.$t('banner.message_limit_exceeded'),
          show: this.messageLimitExceeded,
          dismissable: true
        },
        {
          name: 'mismatch',
          message: this.$t('banner.new_version'),
          buttonText: this.$t('global.refresh_button'),
          show:
            this.versionMismatchMessage &&
            (this.versionMismatchScope === 'BASE' ||
              (routeName &&
                this.versionMismatchScope === routeName.toUpperCase())),
          dismissable: false,
          remindLater: true
        },
        {
          name: 'duePayment',
          message: this.$t('banner.invoice_payment_error_message'),
          buttonText: this.$t('banner.invoice_manage_payment'),
          show:
            this.hasOutstandingInvoice &&
            this.isAdmin &&
            !this.isCollectivePayment &&
            this.$route.name !== 'admin-billing',
          dismissable: true,
          remindLater: true
        },
        {
          name: 'trial',
          message: this.$t(
            'banner.trial_expiring_multiple',
            { days: this.trialDaysRemaining },
            this.trialDaysRemaining
          ),
          buttonText: this.$t('banner.trial_expiring_button'),
          show:
            this.$route.name !== 'dashboard' &&
            this.isTrial &&
            this.trialDaysRemaining >= 0 &&
            this.trialDaysRemaining <= 7,
          dismissable: true,
          remindLater: true
        },
        {
          name: 'oldbrowser',
          message: this.$t('banner.old_browser'),
          show: browser.isNotSupported,
          dismissable: true,
          remindLater: true
        },
        {
          name: 'printReceipt',
          message: this.$t('banner.print_order', {
            number: printableOrder.value?.number
          }),
          show: printableOrder.value?.number,
          icon: 'printer',
          buttonText: this.$t('banner.print_now'),
          dismissable: true,
          remindLater: false,
          customHandleDismiss: true
        },
        {
          name: 'nps',
          show: this.showNpsBanner
        },
        {
          name: 'terms-conditions',
          show:
            (!this.company.termsAcceptedAt ||
              dayjs(this.company.termsAcceptedAt).isBefore(
                dayjs('2024-04-15')
              )) &&
            !this.company.treatwellSales,
          message: this.$t('banner.terms_conditions'),
          buttonText: this.$t('banner.terms_conditions_button'),
          dismissable: true,
          customHandleDismiss: true
        },
        {
          name: 'verification',
          show:
            (this.adyenAppState.verificationStatus === 'PENDING' ||
              this.adyenAppState.verificationStatus === 'INVALID') &&
            dayjs(this.adyenAppState.createdAt).isBefore(
              dayjs().subtract(1, 'day')
            ) &&
            !this.adyenAppState.verificationModalDismissedAt &&
            this.$route.name !== 'salonized-pay',
          message: this.$t('banner.sp_verification.message'),
          buttonText: this.$t('banner.sp_verification.button_text'),
          dismissable: true,
          customHandleDismiss: true
        }
      ];
    },
    activeBannerName() {
      // Makes sure to only show 1 active banner. Items with a lower array index get higher priority.
      return this.banners.find((banner) => banner.show)?.name || null;
    },
    showNpsBanner() {
      return this.user?.npsFeedbackDue && !this.inRegister;
    },
    inRegister() {
      return (
        this.$route.name === 'register-builder' ||
        this.$route.name === 'register-payment'
      );
    }
  },
  methods: {
    onButtonClick() {
      switch (this.activeBannerName) {
        case 'needsToCompleteKYC':
          this.mixpanel.track('kyc_status_banner_clicked');
          this.$router.push({
            name: 'treatwell-kyc-start'
          });
          break;
        case 'mismatch':
          location.reload();
          break;
        case 'invoice':
          this.$router.push({
            name: 'admin-invoice',
            params: { id: this.invoiceDueNumber }
          });
          break;
        case 'duePayment':
        case 'toBeBlockedAt':
          this.$router.push({ name: 'admin-billing' });
          break;
        case 'trial':
          this.mixpanel.track('Floating banner - Trial: Upgrade now');
          this.$router.push({ name: 'subscription' });
          break;
        case 'printReceipt':
          this.promptPrint = true;
          break;
        case 'dispute':
          this.$router.push({ name: 'admin-billing' });
          break;
        case 'terms-conditions':
          window.open(this.$t('signup.terms_conditions_url'), '_blank');
          break;
        case 'verification':
          this.$router.push({ name: 'salonized-pay' });
          break;
      }

      this.mixpanel.track('Floating banner - CTA clicked', {
        banner: this.activeBannerName
      });
    },
    customHandleDismiss() {
      switch (this.activeBannerName) {
        case 'printReceipt':
          this.handlePrintableOrder();
          break;
        case 'terms-conditions':
          this.updateTermsConditionsDate();
          break;
        case 'verification':
          this.dismissVerificationReminder();
          break;
      }

      this.mixpanel.track('Floating banner - Dismissed', {
        banner: this.activeBannerName
      });
    },
    onPrint() {
      this.promptPrint = false;
      this.handlePrintableOrder();
    },
    updateTermsConditionsDate() {
      const { updateCompany } = useCompanyStore();
      updateCompany({
        termsAcceptedAt: dayjs().format()
      });
    },
    dismissVerificationReminder() {
      const { mutate } = useMutation(gql`
        mutation dismissVerificationReminder(
          $input: DismissVerificationReminderInput!
        ) {
          dismissVerificationReminder(input: $input) {
            adyenAppState {
              verificationModalDismissedAt
            }
          }
        }
      `);

      mutate({
        input: {}
      }).then(() => {
        this.adyenAppState.verificationModalDismissedAt = dayjs().format();
      });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 222;
  margin: auto;
  max-width: 920px;
}

@media print {
  .base {
    display: none;
  }
}
</style>
