export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: any;
  /** An ISO 8601-encoded datetime */
  DateTime: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export type Absence = {
  __typename?: 'Absence';
  allDay?: Maybe<Scalars['Boolean']>;
  calendarAbsence?: Maybe<CalendarAbsence>;
  chore?: Maybe<Scalars['Boolean']>;
  endAt: Scalars['DateTime'];
  fibers?: Maybe<Array<EventFiber>>;
  holiday: Scalars['Boolean'];
  id: Scalars['Int'];
  locationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  resourceId?: Maybe<Scalars['Int']>;
  rrule?: Maybe<Scalars['JSON']>;
  startAt: Scalars['DateTime'];
};

export type AbsenceInsight = {
  __typename?: 'AbsenceInsight';
  date: Scalars['ISO8601DateTime'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
};

/** Autogenerated input type of ActivateAppointment */
export type ActivateAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  recurrenceStartAt: Scalars['ISO8601Date'];
  viewType?: CalendarView;
};

/** Autogenerated return type of ActivateAppointment. */
export type ActivateAppointmentPayload = {
  __typename?: 'ActivateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of ActivateIncomingGoogleCalendar */
export type ActivateIncomingGoogleCalendarInput = {
  calendarId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ActivateIncomingGoogleCalendar. */
export type ActivateIncomingGoogleCalendarPayload = {
  __typename?: 'ActivateIncomingGoogleCalendarPayload';
  activated: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of ActivateTrial */
export type ActivateTrialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  moduleName: Scalars['String'];
};

/** Autogenerated return type of ActivateTrial. */
export type ActivateTrialPayload = {
  __typename?: 'ActivateTrialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
};

export enum AddressFields {
  Hidden = 'HIDDEN',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export enum AdjustmentType {
  PercentageDecrease = 'PERCENTAGE_DECREASE',
  PercentageIncrease = 'PERCENTAGE_INCREASE'
}

export type AdyenAppState = {
  __typename?: 'AdyenAppState';
  adyenLegalEntityId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  industryCode?: Maybe<Scalars['ID']>;
  terminalModel?: Maybe<TerminalModel>;
  verificationModalDismissedAt?: Maybe<Scalars['DateTime']>;
  verificationStatus: AdyenAppVerificationStatus;
};

export enum AdyenAppVerificationStatus {
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Valid = 'VALID'
}

export type AdyenShippingLocation = {
  __typename?: 'AdyenShippingLocation';
  deliveryAddressDetails?: Maybe<DeliveryAddressDetails>;
  deliveryContactDetails?: Maybe<DeliveryContactDetails>;
  id: Scalars['Int'];
  shippingLocationId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of AdyenVerificationStatus. */
export type AdyenVerificationStatusPayload = {
  __typename?: 'AdyenVerificationStatusPayload';
  adyenLegalEntityId?: Maybe<Scalars['ID']>;
  terminalModel?: Maybe<Scalars['String']>;
  verificationStatus: AdyenAppVerificationStatus;
};

export type Allocation = {
  __typename?: 'Allocation';
  id: Scalars['Int'];
  partId: Scalars['Int'];
  requirement: Requirement;
  resourceId: Scalars['Int'];
};

export type AllocationAttributes = {
  id?: InputMaybe<Scalars['Int']>;
  requirementId?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['Int']>;
};

export type App = {
  __typename?: 'App';
  active: Scalars['Boolean'];
  beta?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  companyIds?: Maybe<Array<Scalars['ID']>>;
  countries?: Maybe<Array<Scalars['String']>>;
  enableDirectly?: Maybe<Scalars['Boolean']>;
  enablePath?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  requiredFields?: Maybe<Array<Scalars['String']>>;
  settings?: Maybe<Scalars['Boolean']>;
};

export type Appointment = RegisterSearchableItemInterface & {
  __typename?: 'Appointment';
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  calendarLabel?: Maybe<CalendarLabel>;
  calendarLabelId?: Maybe<Scalars['Int']>;
  cancelled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  customerMember?: Maybe<CustomerMember>;
  customerName?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  discountCode?: Maybe<DiscountCode>;
  discountPercentage?: Maybe<Scalars['Int']>;
  draggable: Scalars['Boolean'];
  duration: Scalars['Int'];
  employeeNames?: Maybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  endBuffer?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Feedback>;
  fibers?: Maybe<Array<EventFiber>>;
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  name: Scalars['String'];
  noShow: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  orders: Array<Order>;
  original: Scalars['Boolean'];
  originalId: Scalars['Int'];
  /** Use only in registerSearch query, will make a N+1 if not. */
  paidThroughTreatwell: Scalars['Boolean'];
  parts: Array<AppointmentPart>;
  pendingConfirmation: Scalars['Boolean'];
  price: Scalars['Int'];
  priceAdjustment?: Maybe<PriceAdjustment>;
  priceAfterDiscount?: Maybe<Scalars['Int']>;
  pricePaidThroughTreatwell: Scalars['Int'];
  productId?: Maybe<Scalars['Int']>;
  report?: Maybe<AppointmentReport>;
  reschedulable: Scalars['Boolean'];
  resources: Array<Resource>;
  rrule?: Maybe<Scalars['JSON']>;
  selectedResourceId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  serviceIds?: Maybe<Array<Scalars['Int']>>;
  serviceNames?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  timelineItems?: Maybe<Array<TimelineItem>>;
  treatwell: Scalars['Boolean'];
  treatwellOrder?: Maybe<TreatwellOrder>;
  /** @deprecated Use 'treatwell_order.id' instead */
  treatwellOrderId?: Maybe<Scalars['ID']>;
  type: RegisterSearchResultEnum;
};

export type AppointmentInsight = {
  __typename?: 'AppointmentInsight';
  all: Array<AppointmentInsightData>;
  employees: Array<AppointmentInsightData>;
  services: Array<AppointmentInsightData>;
};

export type AppointmentInsightData = {
  __typename?: 'AppointmentInsightData';
  id?: Maybe<Scalars['ID']>;
  series: Scalars['JSON'];
  type: AppointmentInsightType;
};

export enum AppointmentInsightType {
  Appointment = 'APPOINTMENT',
  Employee = 'EMPLOYEE',
  Service = 'SERVICE'
}

export type AppointmentPart = {
  __typename?: 'AppointmentPart';
  allocations: Array<Allocation>;
  appointmentId: Scalars['Int'];
  buffer: Scalars['Int'];
  duration: Scalars['Int'];
  durationFinish?: Maybe<Scalars['Int']>;
  durationProcessing?: Maybe<Scalars['Int']>;
  durationSetup?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  requiresProcessingTime?: Maybe<Scalars['Boolean']>;
  resourceId?: Maybe<Scalars['Int']>;
  resources: Array<Resource>;
  service: Service;
  serviceId?: Maybe<Scalars['Int']>;
  sortOrder: Scalars['Int'];
};

export type AppointmentPartAttributes = {
  allocationsAttributes?: InputMaybe<Array<AllocationAttributes>>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  duration?: InputMaybe<Scalars['Int']>;
  durationFinish?: InputMaybe<Scalars['Int']>;
  durationProcessing?: InputMaybe<Scalars['Int']>;
  durationSetup?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  resourceIds?: InputMaybe<Array<Scalars['Int']>>;
  serviceId?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type AppointmentPartAvailability = {
  __typename?: 'AppointmentPartAvailability';
  availableResourceIds: Array<Scalars['Int']>;
  partId?: Maybe<Scalars['Int']>;
};

export type AppointmentReport = {
  __typename?: 'AppointmentReport';
  appointment?: Maybe<Appointment>;
  attachments?: Maybe<Array<Attachment>>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['Int'];
  employeeId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  report: Scalars['String'];
  treatedAt?: Maybe<Scalars['DateTime']>;
};

export type ArrayAnswer = {
  __typename?: 'ArrayAnswer';
  answer?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  questionId: Scalars['Int'];
};

/** Autogenerated input type of AssignCustomerTags */
export type AssignCustomerTagsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of AssignCustomerTags. */
export type AssignCustomerTagsPayload = {
  __typename?: 'AssignCustomerTagsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerTags?: Maybe<Array<CustomerTag>>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of AssignPosTerminal */
export type AssignPosTerminalInput = {
  adyenStoreId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  posTerminalId: Scalars['ID'];
};

/** Autogenerated return type of AssignPosTerminal. */
export type AssignPosTerminalPayload = {
  __typename?: 'AssignPosTerminalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  posTerminal?: Maybe<PosTerminal>;
};

export type Attachment = {
  __typename?: 'Attachment';
  appointmentReportId: Scalars['Int'];
  bytes: Scalars['Int'];
  customerId: Scalars['Int'];
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  gataway: Scalars['String'];
  id: Scalars['Int'];
  pageCount?: Maybe<Scalars['Int']>;
  publicId: Scalars['String'];
  type: Scalars['String'];
};

export type AttachmentAttributes = {
  bytes?: InputMaybe<Scalars['Int']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  filename?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Availability = {
  __typename?: 'Availability';
  appointmentPartAvailabilities: Array<AppointmentPartAvailability>;
  available: Scalars['Boolean'];
};

/** Autogenerated input type of BecomeFranchise */
export type BecomeFranchiseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of BecomeFranchise. */
export type BecomeFranchisePayload = {
  __typename?: 'BecomeFranchisePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of Become */
export type BecomeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['ID'];
};

/** Autogenerated return type of Become. */
export type BecomePayload = {
  __typename?: 'BecomePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  billingEmail?: Maybe<Scalars['String']>;
  ccBrand?: Maybe<Scalars['String']>;
  ccExpMonth?: Maybe<Scalars['String']>;
  ccExpYear?: Maybe<Scalars['String']>;
  ccLast4?: Maybe<Scalars['String']>;
  /** @deprecated Will be removed soon! */
  disputeReason?: Maybe<Scalars['String']>;
  dontHaveVatNumber: Scalars['Boolean'];
  hasDispute: Scalars['Boolean'];
  hasOutstandingInvoice: Scalars['Boolean'];
  iban?: Maybe<Scalars['String']>;
  ibanBic?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentMethod: Scalars['String'];
  stripeTaxId?: Maybe<StripeTaxId>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type BillingInfoAttributes = {
  billingEmail?: InputMaybe<Scalars['String']>;
  dontHaveVatNumber?: InputMaybe<Scalars['Boolean']>;
  iban?: InputMaybe<Scalars['String']>;
  ibanBic?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
};

export enum BlockReason {
  Billing = 'BILLING',
  EarlyCancellation = 'EARLY_CANCELLATION',
  Other = 'OTHER',
  PausedCollection = 'PAUSED_COLLECTION',
  Spam = 'SPAM'
}

export type BookingWidget = {
  __typename?: 'BookingWidget';
  categoryIds?: Maybe<Array<Scalars['Int']>>;
  dateMax?: Maybe<Scalars['DateTime']>;
  dateMin?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  locationId?: Maybe<Array<Scalars['Int']>>;
  publicId: Scalars['String'];
  resourceIds?: Maybe<Array<Scalars['Int']>>;
  serviceIds?: Maybe<Array<Scalars['Int']>>;
};

export type BooleanAnswer = {
  __typename?: 'BooleanAnswer';
  answer?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  questionId: Scalars['Int'];
};

export type BulkUpdateServiceAttributes = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  popular?: InputMaybe<Scalars['Boolean']>;
  rebookEnabled?: InputMaybe<Scalars['Boolean']>;
  /** This field can be bulk set to nil */
  rebookPeriod?: InputMaybe<Scalars['Int']>;
  twTreatmentIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type BusinessHours = {
  __typename?: 'BusinessHours';
  daysOfWeek: Array<Scalars['Int']>;
  endTime: Scalars['String'];
  resource?: Maybe<Scalars['Int']>;
  startTime: Scalars['String'];
};

export enum ButtonSize {
  Full = 'FULL',
  Normal = 'NORMAL'
}

export type CalendarAbsence = {
  __typename?: 'CalendarAbsence';
  allDay: Scalars['Boolean'];
  duration?: Maybe<Scalars['JSON']>;
  durationEditable: Scalars['Boolean'];
  end: Scalars['DateTime'];
  exdate?: Maybe<Array<Scalars['String']>>;
  extendedProps: CalendarAbsenceExtendedProps;
  id: Scalars['ID'];
  resourceEditable: Scalars['Boolean'];
  resourceId?: Maybe<Scalars['Int']>;
  rrule?: Maybe<Scalars['JSON']>;
  start: Scalars['DateTime'];
  startEditable: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CalendarAbsenceExtendedProps = {
  __typename?: 'CalendarAbsenceExtendedProps';
  chore: Scalars['Boolean'];
  color: Scalars['String'];
  company: Scalars['Boolean'];
  /** @deprecated Use 'event_duration' instead. */
  duration: Scalars['Int'];
  entryId: Scalars['Int'];
  eventDuration: Scalars['Int'];
  importProvider?: Maybe<Scalars['String']>;
  importRemoteUrl?: Maybe<Scalars['String']>;
  imported: Scalars['Boolean'];
  locationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  originalStartAt?: Maybe<Scalars['DateTime']>;
  recurs: Scalars['Boolean'];
  rrule?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
};

export type CalendarAppointment = {
  __typename?: 'CalendarAppointment';
  duration?: Maybe<Scalars['JSON']>;
  durationEditable: Scalars['Boolean'];
  end: Scalars['DateTime'];
  exdate?: Maybe<Array<Scalars['String']>>;
  extendedProps: CalendarAppointmentExtendedProps;
  id: Scalars['ID'];
  partId: Scalars['Int'];
  resourceEditable: Scalars['Boolean'];
  resourceId?: Maybe<Scalars['Int']>;
  resourceIds?: Maybe<Array<Scalars['Int']>>;
  rrule?: Maybe<Scalars['JSON']>;
  start: Scalars['DateTime'];
  startEditable: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CalendarAppointmentExtendedProps = {
  __typename?: 'CalendarAppointmentExtendedProps';
  activated: Scalars['Boolean'];
  color: Scalars['String'];
  customerId?: Maybe<Scalars['Int']>;
  customerMember?: Maybe<CustomerMember>;
  customerName?: Maybe<Scalars['String']>;
  entryId: Scalars['Int'];
  eventDuration: Scalars['Int'];
  features?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  originalStartAt?: Maybe<Scalars['DateTime']>;
  partId: Scalars['Int'];
  pending: Scalars['Boolean'];
  request: Scalars['Boolean'];
  resourceIds?: Maybe<Array<Scalars['Int']>>;
  rrule?: Maybe<Scalars['JSON']>;
  totalDuration: Scalars['Int'];
  type: Scalars['String'];
};

export type CalendarDynamicPrice = {
  __typename?: 'CalendarDynamicPrice';
  background: Scalars['Boolean'];
  duration?: Maybe<Scalars['JSON']>;
  durationEditable: Scalars['Boolean'];
  end: Scalars['DateTime'];
  exdate?: Maybe<Array<Scalars['String']>>;
  extendedProps: CalendarDynamicPriceExtendedProps;
  id: Scalars['ID'];
  resourceEditable: Scalars['Boolean'];
  rrule?: Maybe<Scalars['JSON']>;
  start: Scalars['DateTime'];
  startEditable: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CalendarDynamicPriceExtendedProps = {
  __typename?: 'CalendarDynamicPriceExtendedProps';
  entryId: Scalars['Int'];
  eventDuration: Scalars['Int'];
  recurs: Scalars['Boolean'];
  rrule?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
};

/** Autogenerated return type of CalendarEvents. */
export type CalendarEventsPayload = {
  __typename?: 'CalendarEventsPayload';
  action: Scalars['String'];
  calendarAbsences?: Maybe<Array<CalendarAbsence>>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  calendarNotes?: Maybe<Array<CalendarNote>>;
  calendarWaitingLists?: Maybe<Array<CalendarWaitingList>>;
};

export type CalendarLabel = {
  __typename?: 'CalendarLabel';
  color: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type CalendarNote = {
  __typename?: 'CalendarNote';
  allDay: Scalars['Boolean'];
  durationEditable: Scalars['Boolean'];
  extendedProps: CalendarNoteExtendedProps;
  id: Scalars['ID'];
  resourceEditable: Scalars['Boolean'];
  resourceId?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['ISO8601Date']>;
  startEditable: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CalendarNoteExtendedProps = {
  __typename?: 'CalendarNoteExtendedProps';
  color: Scalars['String'];
  company: Scalars['Boolean'];
  customerId?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
  done: Scalars['Boolean'];
  entryId: Scalars['Int'];
  resourceId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export enum CalendarView {
  Employee = 'EMPLOYEE',
  Equipment = 'EQUIPMENT',
  Room = 'ROOM'
}

export type CalendarWaitingList = {
  __typename?: 'CalendarWaitingList';
  allDay: Scalars['Boolean'];
  durationEditable: Scalars['Boolean'];
  extendedProps: CalendarWaitingListExtendedProps;
  id: Scalars['ID'];
  resourceEditable: Scalars['Boolean'];
  start?: Maybe<Scalars['ISO8601Date']>;
  startEditable: Scalars['Boolean'];
};

export type CalendarWaitingListExtendedProps = {
  __typename?: 'CalendarWaitingListExtendedProps';
  amount: Scalars['Int'];
  entryId: Scalars['Int'];
  type: Scalars['String'];
};

/** Autogenerated input type of CancelProductChangeSchedule */
export type CancelProductChangeScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CancelProductChangeSchedule. */
export type CancelProductChangeSchedulePayload = {
  __typename?: 'CancelProductChangeSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  explanation?: InputMaybe<Scalars['String']>;
  otherSoftware?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

/** Autogenerated return type of CancelSubscription. */
export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  subscription?: Maybe<CompanySubscription>;
};

export type CashCount = {
  __typename?: 'CashCount';
  amount?: Maybe<Scalars['Int']>;
  moneyType: Money;
  value: Scalars['Int'];
};

export type CashCountAttributes = {
  amount?: InputMaybe<Scalars['Int']>;
  value: Scalars['Int'];
};

export type Cashup = {
  __typename?: 'Cashup';
  cashCount?: Maybe<Array<CashCount>>;
  cashFloatAmount?: Maybe<Scalars['Int']>;
  closedBy?: Maybe<User>;
  closedOn?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  countedTotal?: Maybe<Scalars['Int']>;
  earnings?: Maybe<Scalars['Int']>;
  expectedTotal?: Maybe<Scalars['Int']>;
  expectedTotalCash?: Maybe<Scalars['Int']>;
  expenses?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  location: Location;
  number: Scalars['Int'];
  openedBy: User;
  openedOn: Scalars['DateTime'];
  openingAmount: Scalars['Int'];
  /** Only use when fetching a single cashup */
  orderCount: Scalars['Int'];
  orders?: Maybe<Array<Order>>;
  payments: Array<CashupPayment>;
  total?: Maybe<Scalars['Int']>;
  totalDifference?: Maybe<Scalars['Int']>;
  /** Only use when fetching a single cashup */
  transactionCount: Scalars['Int'];
  transactions?: Maybe<Array<Transaction>>;
  transactionsTotal?: Maybe<Scalars['Int']>;
  vatSummary?: Maybe<Array<VatSummary>>;
};

export type CashupPayment = {
  __typename?: 'CashupPayment';
  countable: Scalars['Boolean'];
  counted?: Maybe<Scalars['Int']>;
  difference?: Maybe<Scalars['Int']>;
  expected: Scalars['Int'];
  type: PaymentType;
};

export type ChangeLog = {
  __typename?: 'ChangeLog';
  category: Scalars['String'];
  date?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  helpPageUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imagePublicId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ChangeStripeSubscription */
export type ChangeStripeSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  period: SubscriptionPeriod;
  plan: SubscriptionPlan;
};

/** Autogenerated return type of ChangeStripeSubscription. */
export type ChangeStripeSubscriptionPayload = {
  __typename?: 'ChangeStripeSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  status?: Maybe<Status>;
};

export enum Clock {
  Military = 'MILITARY',
  Regular = 'REGULAR'
}

/** Autogenerated input type of CloseCashup */
export type CloseCashupInput = {
  bankDeposit: Scalars['Int'];
  cashCount: Array<CashCountAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  countedCash: Scalars['Int'];
  countedCreditcard: Scalars['Int'];
  countedPin: Scalars['Int'];
  locationId: Scalars['ID'];
};

/** Autogenerated return type of CloseCashup. */
export type CloseCashupPayload = {
  __typename?: 'CloseCashupPayload';
  cashup: Cashup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Cname = {
  __typename?: 'Cname';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum Colour {
  Category = 'CATEGORY',
  Resource = 'RESOURCE'
}

export type Company = {
  __typename?: 'Company';
  activeSubscription?: Maybe<CompanySubscription>;
  address?: Maybe<Scalars['String']>;
  /** @deprecated Will be removed soon */
  agendaType: Scalars['String'];
  allowedToMassSend: Scalars['Boolean'];
  apps?: Maybe<Array<CompanyApp>>;
  billingInfo?: Maybe<BillingInfo>;
  blockReason?: Maybe<BlockReason>;
  blocked: Scalars['Boolean'];
  blockedAt?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  collectivePayment: Scalars['Boolean'];
  companyType?: Maybe<CompanyType>;
  contactEmail?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  currency: Currency;
  emailDomain?: Maybe<EmailDomain>;
  facebookId?: Maybe<Scalars['String']>;
  flipper: Scalars['JSON'];
  franchiseId?: Maybe<Scalars['Int']>;
  fullAddress: Scalars['String'];
  giftcardPersonalMessage?: Maybe<Scalars['String']>;
  hasFillableForms: Scalars['Boolean'];
  hasReferrer: Scalars['Boolean'];
  id: Scalars['Int'];
  instagramId?: Maybe<Scalars['String']>;
  integrations: Scalars['JSON'];
  internalName: Scalars['String'];
  invoiceFooter?: Maybe<Scalars['String']>;
  invoiceFooterThermal?: Maybe<Scalars['String']>;
  invoiceHeader?: Maybe<Scalars['String']>;
  invoiceImage?: Maybe<Scalars['String']>;
  invoiceRecipientTemplate?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  languagePublic: CompanyPublicLanguage;
  locale: Locale;
  localeCode: Scalars['String'];
  logo?: Maybe<Logo>;
  logoUrl?: Maybe<Scalars['String']>;
  mailchimp: Scalars['Boolean'];
  marketingTrialEndsAt?: Maybe<Scalars['DateTime']>;
  medical: Scalars['Boolean'];
  messageLimitExceeded: Scalars['Boolean'];
  /** @deprecated Will be removed soon */
  messages: Scalars['JSON'];
  mixpanelData: Scalars['JSON'];
  name: Scalars['String'];
  nameWasChanged: Scalars['Boolean'];
  notificationEmail?: Maybe<Scalars['String']>;
  overrideAccessUntil?: Maybe<Scalars['DateTime']>;
  paymentGateway?: Maybe<Scalars['Boolean']>;
  paymentGatewayStatus?: Maybe<PaymentGatewayStatus>;
  phone?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  primaryLocationId: Scalars['Int'];
  publicId?: Maybe<Scalars['String']>;
  referralRewardAmount?: Maybe<Scalars['String']>;
  registerPaymentTypes: Array<PaymentType>;
  settings: Settings;
  signupReason?: Maybe<SignupReason>;
  smsCreditPricing: Array<Scalars['Int']>;
  smsCredits: Scalars['Int'];
  smsNotificationNumber?: Maybe<Scalars['String']>;
  smsSenderId?: Maybe<Scalars['String']>;
  stockWarnings: Scalars['JSON'];
  subscribed: Scalars['Boolean'];
  termsAcceptedAt?: Maybe<Scalars['DateTime']>;
  timeZoneName: Scalars['String'];
  toBeBlockedAt?: Maybe<Scalars['DateTime']>;
  treatwellSales: Scalars['Boolean'];
  treatwellVenue?: Maybe<TreatwellVenue>;
  trialEndAt?: Maybe<Scalars['DateTime']>;
  twitterId?: Maybe<Scalars['String']>;
  unleashContextProperties: Scalars['JSON'];
};

export type CompanyApp = {
  __typename?: 'CompanyApp';
  id: Scalars['Int'];
  key: Scalars['String'];
  settings: CompanyAppSettings;
  state: Scalars['String'];
};

export type CompanyAppSettings = {
  __typename?: 'CompanyAppSettings';
  logs?: Maybe<Scalars['JSON']>;
  profileName?: Maybe<Scalars['String']>;
};

export type CompanyAttributes = {
  address: Scalars['String'];
  city: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalcode: Scalars['String'];
};

export type CompanyBillingAttributes = {
  address: Scalars['String'];
  billingInfoAttributes?: InputMaybe<BillingInfoAttributes>;
  city: Scalars['String'];
  country: Scalars['String'];
  phone: Scalars['String'];
  postalcode: Scalars['String'];
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type CompanyEmailTemplate = {
  __typename?: 'CompanyEmailTemplate';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  variables: Array<TemplateVariableEnum>;
};

export enum CompanyLanguage {
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Nl = 'NL',
  Pt = 'PT'
}

export enum CompanyPublicLanguage {
  Da = 'DA',
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Hr = 'HR',
  It = 'IT',
  Nl = 'NL',
  No = 'NO',
  Pt = 'PT',
  Sk = 'SK'
}

export type CompanySmsTemplate = {
  __typename?: 'CompanySmsTemplate';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  variables: Array<TemplateVariableEnum>;
};

export type CompanySubscription = {
  __typename?: 'CompanySubscription';
  billingPeriod: Scalars['String'];
  boostable: Scalars['Boolean'];
  cancelReasons: Scalars['JSON'];
  cancelled?: Maybe<Scalars['Boolean']>;
  collectionMethod: StripeCollectionMethod;
  currency: Scalars['String'];
  endAt?: Maybe<Scalars['DateTime']>;
  finalPrice: Scalars['Int'];
  id: Scalars['Int'];
  incomplete: Scalars['Boolean'];
  plan: Scalars['String'];
  resourcePrice?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
  stripeStatus?: Maybe<StripeSubscriptionStatus>;
};

export enum CompanyType {
  Barbershop = 'BARBERSHOP',
  BeautySalon = 'BEAUTY_SALON',
  DogTrimSalon = 'DOG_TRIM_SALON',
  HairSalon = 'HAIR_SALON',
  ManiPedicure = 'MANI_PEDICURE',
  MassageSalon = 'MASSAGE_SALON',
  NailStudio = 'NAIL_STUDIO',
  Other = 'OTHER',
  Physiotherapy = 'PHYSIOTHERAPY',
  Therapist = 'THERAPIST'
}

/** Autogenerated input type of CompleteTerminalCheckout */
export type CompleteTerminalCheckoutInput = {
  addressLine: Scalars['String'];
  city: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locationId?: InputMaybe<Scalars['ID']>;
  postalCode: Scalars['String'];
  terminalModel: TerminalModel;
};

/** Autogenerated return type of CompleteTerminalCheckout. */
export type CompleteTerminalCheckoutPayload = {
  __typename?: 'CompleteTerminalCheckoutPayload';
  adyenShippingLocation?: Maybe<AdyenShippingLocation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of ConfirmAppointment */
export type ConfirmAppointmentInput = {
  appointmentId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ConfirmAppointment. */
export type ConfirmAppointmentPayload = {
  __typename?: 'ConfirmAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

export type ContractTemplate = {
  __typename?: 'ContractTemplate';
  content: Scalars['String'];
  downloadUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of CreateAbsence */
export type CreateAbsenceInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  chore?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  resourceId?: InputMaybe<Scalars['Int']>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt: Scalars['DateTime'];
};

/** Autogenerated return type of CreateAbsence. */
export type CreateAbsencePayload = {
  __typename?: 'CreateAbsencePayload';
  absence?: Maybe<Absence>;
  calendarAbsence?: Maybe<CalendarAbsence>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of CreateAppointment */
export type CreateAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerAttributes?: InputMaybe<CustomerAttributes>;
  customerId?: InputMaybe<Scalars['Int']>;
  locationId: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  partsAttributes: Array<AppointmentPartAttributes>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt: Scalars['DateTime'];
  viewType?: CalendarView;
};

/** Autogenerated return type of CreateAppointment. */
export type CreateAppointmentPayload = {
  __typename?: 'CreateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateAppointmentReport */
export type CreateAppointmentReportInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  attachmentsAttributes?: InputMaybe<Array<AttachmentAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  employeeId?: InputMaybe<Scalars['Int']>;
  report: Scalars['String'];
};

/** Autogenerated return type of CreateAppointmentReport. */
export type CreateAppointmentReportPayload = {
  __typename?: 'CreateAppointmentReportPayload';
  appointmentReport?: Maybe<AppointmentReport>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateBookingWidget */
export type CreateBookingWidgetInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dateMax?: InputMaybe<Scalars['DateTime']>;
  dateMin?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['Int']>;
  resourceIds?: InputMaybe<Array<Scalars['Int']>>;
  serviceIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CreateBookingWidget. */
export type CreateBookingWidgetPayload = {
  __typename?: 'CreateBookingWidgetPayload';
  bookingWidget?: Maybe<BookingWidget>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateBoostExtension */
export type CreateBoostExtensionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateBoostExtension. */
export type CreateBoostExtensionPayload = {
  __typename?: 'CreateBoostExtensionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  requested: Scalars['Boolean'];
};

/** Autogenerated input type of CreateCalendarLabel */
export type CreateCalendarLabelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Autogenerated return type of CreateCalendarLabel. */
export type CreateCalendarLabelPayload = {
  __typename?: 'CreateCalendarLabelPayload';
  calendarLabel?: Maybe<CalendarLabel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateCustomBookingWidget */
export type CreateCustomBookingWidgetInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  resourceIds?: InputMaybe<Array<Scalars['Int']>>;
  serviceIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CreateCustomBookingWidget. */
export type CreateCustomBookingWidgetPayload = {
  __typename?: 'CreateCustomBookingWidgetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customBookingWidget?: Maybe<CustomBookingWidget>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateCustomMessage */
export type CreateCustomMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<Array<CustomersOverviewFilterAttributes>>;
  gateway?: InputMaybe<MessageGatewayEnum>;
  message: Scalars['String'];
  query?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCustomMessage. */
export type CreateCustomMessagePayload = {
  __typename?: 'CreateCustomMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customMessage?: Maybe<Scalars['JSON']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateCustomerFilterGroup */
export type CreateCustomerFilterGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  filters?: Array<CustomerFilterInput>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateCustomerFilterGroup. */
export type CreateCustomerFilterGroupPayload = {
  __typename?: 'CreateCustomerFilterGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerFilterGroup?: Maybe<CustomerFilterGroup>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateCustomer */
export type CreateCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerAttributes?: InputMaybe<CustomerAttributes>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCustomer. */
export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateCustomerTag */
export type CreateCustomerTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateCustomerTag. */
export type CreateCustomerTagPayload = {
  __typename?: 'CreateCustomerTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerTag?: Maybe<CustomerTag>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateCustomersExport */
export type CreateCustomersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  fileFormat?: ExportFormat;
  filters?: InputMaybe<Array<CustomersOverviewFilterAttributes>>;
  query?: InputMaybe<Scalars['String']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCustomersExport. */
export type CreateCustomersExportPayload = {
  __typename?: 'CreateCustomersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateDiscountCode */
export type CreateDiscountCodeInput = {
  appliesTo?: InputMaybe<DiscountCodeAppliesToEnum>;
  category?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  discountPercentage: Scalars['Int'];
  expiresIn: DiscountCodeExpiresInEnum;
  hasLimit: Scalars['Boolean'];
  oncePerCustomer: Scalars['Boolean'];
  state: DiscountCodeStateEnum;
  usageLimit?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateDiscountCode. */
export type CreateDiscountCodePayload = {
  __typename?: 'CreateDiscountCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  discountCode?: Maybe<DiscountCode>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateDynamicPrice */
export type CreateDynamicPriceInput = {
  adjustmentType?: InputMaybe<AdjustmentType>;
  amount?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endAt: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['Int']>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt: Scalars['DateTime'];
};

/** Autogenerated return type of CreateDynamicPrice. */
export type CreateDynamicPricePayload = {
  __typename?: 'CreateDynamicPricePayload';
  calendarDynamicPrice?: Maybe<CalendarDynamicPrice>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dynamicPrice?: Maybe<DynamicPrice>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of CreateEmailTemplate */
export type CreateEmailTemplateInput = {
  buttonTitle?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  deliverAt?: InputMaybe<Scalars['DateTime']>;
  discountCodeId?: InputMaybe<Scalars['ID']>;
  discountMessage?: InputMaybe<Scalars['String']>;
  dispatchType?: InputMaybe<DispatchTypeEnum>;
  emailType: EmailTemplateEnum;
  filterGroupId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<CustomerFilterInput>>;
  hasButton?: InputMaybe<Scalars['Boolean']>;
  hasDeliverySchedule?: InputMaybe<Scalars['Boolean']>;
  hasDiscount?: InputMaybe<Scalars['Boolean']>;
  hasFooter?: InputMaybe<Scalars['Boolean']>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  hasLogo?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateEmailTemplate. */
export type CreateEmailTemplatePayload = {
  __typename?: 'CreateEmailTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<EmailTemplate>;
};

/** Autogenerated input type of CreateExport */
export type CreateExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataScope?: InputMaybe<DataScope>;
  exportType: ExportData;
  fileFormat?: ExportFormat;
  locationId?: InputMaybe<Scalars['ID']>;
  month?: InputMaybe<Scalars['DateTime']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateExport. */
export type CreateExportPayload = {
  __typename?: 'CreateExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateFiscalizationApp */
export type CreateFiscalizationAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateFiscalizationApp. */
export type CreateFiscalizationAppPayload = {
  __typename?: 'CreateFiscalizationAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  fiscalizationApp?: Maybe<CompanyApp>;
};

/** Autogenerated input type of CreateFormSubmission */
export type CreateFormSubmissionInput = {
  answersAttributes: Array<FormAnswerAttributes>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  templateId: Scalars['Int'];
};

/** Autogenerated return type of CreateFormSubmission. */
export type CreateFormSubmissionPayload = {
  __typename?: 'CreateFormSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  formSubmission?: Maybe<FormSubmission>;
};

/** Autogenerated input type of CreateFormTemplate */
export type CreateFormTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FormTemplateType>;
  introText?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  questionsAttributes?: InputMaybe<Array<FormQuestionAttributes>>;
  sendAutomatically?: InputMaybe<Scalars['Boolean']>;
  serviceIds?: InputMaybe<Array<Scalars['Int']>>;
  text?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateFormTemplate. */
export type CreateFormTemplatePayload = {
  __typename?: 'CreateFormTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  formTemplate?: Maybe<FormTemplate>;
};

/** Autogenerated input type of CreateFranchiseApp */
export type CreateFranchiseAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<FranchiseAppPermissionAttributes>;
  token: Scalars['String'];
};

/** Autogenerated return type of CreateFranchiseApp. */
export type CreateFranchiseAppPayload = {
  __typename?: 'CreateFranchiseAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  franchiseApp?: Maybe<FranchiseApp>;
};

/** Autogenerated input type of CreateGiftcard */
export type CreateGiftcardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  customerId?: InputMaybe<Scalars['Int']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  source?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  totalAmount: Scalars['Int'];
};

/** Autogenerated return type of CreateGiftcard. */
export type CreateGiftcardPayload = {
  __typename?: 'CreateGiftcardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  giftcard?: Maybe<Giftcard>;
};

/** Autogenerated input type of CreateGoogleReserveApp */
export type CreateGoogleReserveAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  formattedPhoneNumber?: InputMaybe<Scalars['String']>;
  internationalPhoneNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  placeId: Scalars['String'];
  rating?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
  url?: InputMaybe<Scalars['String']>;
  userRatingsTotal?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateGoogleReserveApp. */
export type CreateGoogleReserveAppPayload = {
  __typename?: 'CreateGoogleReserveAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  googleReserveApp?: Maybe<CompanyApp>;
};

/** Autogenerated input type of CreateIncomingGoogleCalendars */
export type CreateIncomingGoogleCalendarsInput = {
  calendars: Array<IncomingGoogleCalendarInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateIncomingGoogleCalendars. */
export type CreateIncomingGoogleCalendarsPayload = {
  __typename?: 'CreateIncomingGoogleCalendarsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  incomingGoogleCalendars?: Maybe<Array<IncomingGoogleCalendar>>;
};

/** Autogenerated input type of CreateIplTreatment */
export type CreateIplTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  description: Scalars['String'];
  treatments: Scalars['Int'];
};

/** Autogenerated return type of CreateIplTreatment. */
export type CreateIplTreatmentPayload = {
  __typename?: 'CreateIplTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  iplTreatment?: Maybe<IplTreatment>;
};

/** Autogenerated input type of CreateIplTreatmentReport */
export type CreateIplTreatmentReportInput = {
  amount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['ISO8601Date'];
  employeeId: Scalars['Int'];
  iplTreatmentId: Scalars['ID'];
  notes: Scalars['String'];
  power: Scalars['Float'];
  treatment: Scalars['Int'];
};

/** Autogenerated return type of CreateIplTreatmentReport. */
export type CreateIplTreatmentReportPayload = {
  __typename?: 'CreateIplTreatmentReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  iplTreatmentReport?: Maybe<IplTreatmentReport>;
};

/** Autogenerated input type of CreateLocation */
export type CreateLocationInput = {
  address?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  internalName: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateLocation. */
export type CreateLocationPayload = {
  __typename?: 'CreateLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  location?: Maybe<Location>;
};

/** Autogenerated input type of CreateLoyaltyTransaction */
export type CreateLoyaltyTransactionInput = {
  amount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['ID']>;
  transactionAt: Scalars['DateTime'];
};

/** Autogenerated return type of CreateLoyaltyTransaction. */
export type CreateLoyaltyTransactionPayload = {
  __typename?: 'CreateLoyaltyTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  loyaltyTransaction?: Maybe<LoyaltyTransaction>;
};

/** Autogenerated input type of CreateMailchimpSegment */
export type CreateMailchimpSegmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<Array<CustomersOverviewFilterAttributes>>;
  name: Scalars['String'];
  query?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMailchimpSegment. */
export type CreateMailchimpSegmentPayload = {
  __typename?: 'CreateMailchimpSegmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateMessage */
export type CreateMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  destination: Scalars['String'];
  emailSubject?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<MessageGatewayEnum>;
  message?: InputMaybe<Scalars['String']>;
  messageType: MessageTypeEnum;
  orderId?: InputMaybe<Scalars['ID']>;
  stockOrderId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateMessage. */
export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateNote */
export type CreateNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
};

/** Autogenerated return type of CreateNote. */
export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  calendarNote?: Maybe<CalendarNote>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  note?: Maybe<Note>;
};

/** Autogenerated input type of CreateOnboardingRoster */
export type CreateOnboardingRosterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  roster: WeeklyRoster;
};

/** Autogenerated return type of CreateOnboardingRoster. */
export type CreateOnboardingRosterPayload = {
  __typename?: 'CreateOnboardingRosterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  appointmentIds?: InputMaybe<Array<Scalars['Int']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  draft?: InputMaybe<Scalars['Boolean']>;
  invoicedAt?: InputMaybe<Scalars['DateTime']>;
  items: Array<OrderItemAttributes>;
  locationId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  originalOrderId?: InputMaybe<Scalars['ID']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<Array<TransactionAttributes>>;
};

/** Autogenerated return type of CreateOrder. */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  order?: Maybe<Order>;
};

/** Autogenerated input type of CreatePayTerminalCheckoutSession */
export type CreatePayTerminalCheckoutSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  type: TerminalModel;
};

/** Autogenerated return type of CreatePayTerminalCheckoutSession. */
export type CreatePayTerminalCheckoutSessionPayload = {
  __typename?: 'CreatePayTerminalCheckoutSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of CreatePosTerminal */
export type CreatePosTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  terminalUid: Scalars['ID'];
};

/** Autogenerated return type of CreatePosTerminal. */
export type CreatePosTerminalPayload = {
  __typename?: 'CreatePosTerminalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  posTerminal: PosTerminal;
};

/** Autogenerated input type of CreateProductCategory */
export type CreateProductCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateProductCategory. */
export type CreateProductCategoryPayload = {
  __typename?: 'CreateProductCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  productCategory?: Maybe<ProductCategory>;
};

/** Autogenerated input type of CreateProductChangeSchedule */
export type CreateProductChangeScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
  productChangesAttributes?: InputMaybe<Array<ProductChangeAttributes>>;
  scheduledAt?: InputMaybe<Scalars['DateTime']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateProductChangeSchedule. */
export type CreateProductChangeSchedulePayload = {
  __typename?: 'CreateProductChangeSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  productChangeSchedule?: Maybe<ProductChangeSchedule>;
};

/** Autogenerated input type of CreateProduct */
export type CreateProductInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  costPrice?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['ID']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['String']>;
  stockAmount?: InputMaybe<Scalars['Int']>;
  stockEnabled?: InputMaybe<Scalars['Boolean']>;
  stockMaximum?: InputMaybe<Scalars['Int']>;
  stockMinimum?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  vatRateId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateProduct. */
export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  product?: Maybe<Product>;
};

/** Autogenerated input type of CreateResource */
export type CreateResourceInput = {
  bookable?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  customRoster?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  rosterLocationId?: InputMaybe<Scalars['Int']>;
  serviceIds?: InputMaybe<Array<Scalars['Int']>>;
  state?: InputMaybe<ResourceState>;
  type: ResourceType;
};

/** Autogenerated return type of CreateResource. */
export type CreateResourcePayload = {
  __typename?: 'CreateResourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Resource>;
};

/** Autogenerated input type of CreateServiceCategory */
export type CreateServiceCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateServiceCategory. */
export type CreateServiceCategoryPayload = {
  __typename?: 'CreateServiceCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceCategory?: Maybe<ServiceCategory>;
};

/** Autogenerated input type of CreateServiceGroup */
export type CreateServiceGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  servicesAttributes: Array<ServiceAttributes>;
  top?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateServiceGroup. */
export type CreateServiceGroupPayload = {
  __typename?: 'CreateServiceGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  serviceGroup?: Maybe<ServiceGroup>;
};

/** Autogenerated input type of CreateService */
export type CreateServiceInput = {
  bookable?: InputMaybe<Scalars['Boolean']>;
  bookableLocationIds?: InputMaybe<Array<Scalars['Int']>>;
  buffer?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  durationFinish?: InputMaybe<Scalars['Int']>;
  durationProcessing?: InputMaybe<Scalars['Int']>;
  durationSetup?: InputMaybe<Scalars['Int']>;
  extraInformation?: InputMaybe<Scalars['String']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  rebookEnabled?: InputMaybe<Scalars['Boolean']>;
  requirementsAttributes: Array<RequirementAttributes>;
  requiresProcessingTime?: InputMaybe<Scalars['Boolean']>;
  resourceAdjustmentsAttributes?: InputMaybe<
    Array<ResourceAdjustmentAttributes>
  >;
  rwgName?: InputMaybe<Scalars['String']>;
  serviceOfferingEnabled?: InputMaybe<Scalars['Boolean']>;
  vatRateId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of CreateServiceOffering */
export type CreateServiceOfferingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  offeringType: ServiceOfferingType;
  payload: WeekdaysInput;
  serviceId: Scalars['ID'];
};

/** Autogenerated return type of CreateServiceOffering. */
export type CreateServiceOfferingPayload = {
  __typename?: 'CreateServiceOfferingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of CreateService. */
export type CreateServicePayload = {
  __typename?: 'CreateServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of CreateServiceVariationGroup */
export type CreateServiceVariationGroupInput = {
  bookable?: InputMaybe<Scalars['Boolean']>;
  bookableLocationIds?: InputMaybe<Array<Scalars['ID']>>;
  categoryId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  extraInformation?: InputMaybe<Scalars['String']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  requirementsAttributes: Array<RequirementAttributes>;
  rwgName?: InputMaybe<Scalars['String']>;
  serviceOfferingAttributes?: InputMaybe<ServiceOfferingAttributes>;
  serviceOfferingEnabled?: InputMaybe<Scalars['Boolean']>;
  top?: InputMaybe<Scalars['Boolean']>;
  variations: Array<ServiceVariationAttributes>;
  vatRateId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateServiceVariationGroup. */
export type CreateServiceVariationGroupPayload = {
  __typename?: 'CreateServiceVariationGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  serviceVariationGroup?: Maybe<ServiceVariationGroup>;
};

/** Autogenerated input type of CreateSesIdentity */
export type CreateSesIdentityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailDomainId: Scalars['ID'];
};

/** Autogenerated return type of CreateSesIdentity. */
export type CreateSesIdentityPayload = {
  __typename?: 'CreateSesIdentityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailDomain?: Maybe<EmailDomain>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of CreateSmsCheckoutSession */
export type CreateSmsCheckoutSessionInput = {
  amount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSmsCheckoutSession. */
export type CreateSmsCheckoutSessionPayload = {
  __typename?: 'CreateSmsCheckoutSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateStockOrder */
export type CreateStockOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['Int'];
};

/** Autogenerated return type of CreateStockOrder. */
export type CreateStockOrderPayload = {
  __typename?: 'CreateStockOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  stockOrder?: Maybe<StockOrder>;
};

/** Autogenerated input type of CreateStripeCheckoutSession */
export type CreateStripeCheckoutSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  hasTreatwellIntention?: InputMaybe<Scalars['Boolean']>;
  period: SubscriptionPeriod;
  plan: SubscriptionPlan;
};

/** Autogenerated return type of CreateStripeCheckoutSession. */
export type CreateStripeCheckoutSessionPayload = {
  __typename?: 'CreateStripeCheckoutSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  sessionId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateStripeCurrentPlanQuote */
export type CreateStripeCurrentPlanQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateStripeCurrentPlanQuote. */
export type CreateStripeCurrentPlanQuotePayload = {
  __typename?: 'CreateStripeCurrentPlanQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Error>>;
  quotes?: Maybe<Quote>;
};

/** Autogenerated input type of CreateStripeDisputeCheckoutSession */
export type CreateStripeDisputeCheckoutSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['String'];
};

/** Autogenerated return type of CreateStripeDisputeCheckoutSession. */
export type CreateStripeDisputeCheckoutSessionPayload = {
  __typename?: 'CreateStripeDisputeCheckoutSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  sessionId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateStripeQuote */
export type CreateStripeQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  plan: SubscriptionPlan;
};

/** Autogenerated return type of CreateStripeQuote. */
export type CreateStripeQuotePayload = {
  __typename?: 'CreateStripeQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quotes?: Maybe<Array<Quote>>;
};

/** Autogenerated input type of CreateSubscription */
export type CreateSubscriptionInput = {
  billingPeriod?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyAttributes?: InputMaybe<CompanyBillingAttributes>;
  plan: Scalars['String'];
};

/** Autogenerated return type of CreateSubscription. */
export type CreateSubscriptionPayload = {
  __typename?: 'CreateSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  stripeSessionId?: Maybe<Scalars['String']>;
  subscription?: Maybe<CompanySubscription>;
};

/** Autogenerated input type of CreateSupplier */
export type CreateSupplierInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  postalcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSupplier. */
export type CreateSupplierPayload = {
  __typename?: 'CreateSupplierPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  supplier?: Maybe<Supplier>;
};

/** Autogenerated input type of CreateTerminalOrder */
export type CreateTerminalOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  terminalModel: TerminalModel;
};

/** Autogenerated return type of CreateTerminalOrder. */
export type CreateTerminalOrderPayload = {
  __typename?: 'CreateTerminalOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of CreateTradeShowTrial */
export type CreateTradeShowTrialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  salonizedEmployeeId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateTradeShowTrial. */
export type CreateTradeShowTrialPayload = {
  __typename?: 'CreateTradeShowTrialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of CreateTransaction */
export type CreateTransactionInput = {
  amount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  locationId: Scalars['ID'];
  transactionAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of CreateTransaction. */
export type CreateTransactionPayload = {
  __typename?: 'CreateTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  drawer?: Maybe<Drawer>;
  transaction?: Maybe<Transaction>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  admin?: InputMaybe<Scalars['Boolean']>;
  basic?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  manager?: InputMaybe<Scalars['Boolean']>;
  owner?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  reports?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<UserSettingsAttributes>;
};

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of CreateWaitingListEntry */
export type CreateWaitingListEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  date?: InputMaybe<Scalars['ISO8601Date']>;
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  locationId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  partsOfDay?: InputMaybe<Array<PartsOfDay>>;
  resourceId?: InputMaybe<Scalars['ID']>;
  serviceIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of CreateWaitingListEntry. */
export type CreateWaitingListEntryPayload = {
  __typename?: 'CreateWaitingListEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  waitingListEntry?: Maybe<WaitingListEntry>;
};

/** Autogenerated input type of CreateWelcomeServices */
export type CreateWelcomeServicesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateWelcomeServices. */
export type CreateWelcomeServicesPayload = {
  __typename?: 'CreateWelcomeServicesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Service>>;
};

export enum Currency {
  Aed = 'AED',
  Ang = 'ANG',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Cop = 'COP',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hrk = 'HRK',
  Isk = 'ISK',
  Mad = 'MAD',
  Mxn = 'MXN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Qar = 'QAR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Srd = 'SRD',
  Try = 'TRY',
  Usd = 'USD',
  Zar = 'ZAR',
  Zmw = 'ZMW'
}

export type CustomBookingWidget = {
  __typename?: 'CustomBookingWidget';
  categoryIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
  locationId?: Maybe<Scalars['Int']>;
  publicId: Scalars['String'];
  resourceIds: Array<Scalars['Int']>;
  serviceIds: Array<Scalars['Int']>;
};

export type Customer = {
  __typename?: 'Customer';
  activity?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  alertNotes?: Maybe<Scalars['String']>;
  appointmentReports?: Maybe<Array<AppointmentReport>>;
  birthdayWishesEnabled: Scalars['Boolean'];
  blocked?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  custom1?: Maybe<Scalars['String']>;
  custom2?: Maybe<Scalars['String']>;
  custom3?: Maybe<Scalars['String']>;
  custom4?: Maybe<Scalars['String']>;
  custom5?: Maybe<Scalars['String']>;
  custom6?: Maybe<Scalars['String']>;
  custom7?: Maybe<Scalars['String']>;
  custom8?: Maybe<Scalars['String']>;
  custom9?: Maybe<Scalars['String']>;
  custom10?: Maybe<Scalars['String']>;
  customerNotes?: Maybe<Scalars['String']>;
  customerTags?: Maybe<Array<CustomerTag>>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  email?: Maybe<Scalars['String']>;
  feedbackEmailEnabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Gender>;
  id: Scalars['Int'];
  invoiceNotes?: Maybe<Scalars['String']>;
  lastAppointmentAt?: Maybe<Scalars['DateTime']>;
  lastEmailStatus?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastSmsStatus?: Maybe<Scalars['String']>;
  loyaltyPoints?: Maybe<Scalars['Int']>;
  mobilePhone?: Maybe<Scalars['String']>;
  newsletterEnabled: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  rebookEmailEnabled: Scalars['Boolean'];
  remindersEmailEnabled: Scalars['Boolean'];
  remindersSmsEnabled: Scalars['Boolean'];
  state?: Maybe<CustomerStateEnum>;
  totalDue?: Maybe<Scalars['Int']>;
  totalSpent?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type CustomerAppointmentReportsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirectionEnum>;
};

export type CustomerLastAppointmentAtArgs = {
  date: Scalars['DateTime'];
};

export type CustomerAttributes = {
  address?: InputMaybe<Scalars['String']>;
  alertNotes?: InputMaybe<Scalars['String']>;
  birthdayWishesEnabled?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  custom1?: InputMaybe<Scalars['String']>;
  custom2?: InputMaybe<Scalars['String']>;
  custom3?: InputMaybe<Scalars['String']>;
  custom4?: InputMaybe<Scalars['String']>;
  custom5?: InputMaybe<Scalars['String']>;
  custom6?: InputMaybe<Scalars['String']>;
  custom7?: InputMaybe<Scalars['String']>;
  custom8?: InputMaybe<Scalars['String']>;
  custom9?: InputMaybe<Scalars['String']>;
  custom10?: InputMaybe<Scalars['String']>;
  customerNotes?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  facebookId?: InputMaybe<Scalars['String']>;
  feedbackEmailEnabled?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  instagramId?: InputMaybe<Scalars['String']>;
  invoiceNotes?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  mobilePhone?: InputMaybe<Scalars['String']>;
  newsletterEnabled?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  rebookEmailEnabled?: InputMaybe<Scalars['Boolean']>;
  remindersEmailEnabled?: InputMaybe<Scalars['Boolean']>;
  remindersSmsEnabled?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<CustomerStateEnum>;
  twitterId?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CustomerFilter = {
  __typename?: 'CustomerFilter';
  filterName: Scalars['String'];
  values?: Maybe<Scalars['JSON']>;
};

export enum CustomerFilterEnum {
  /** values accepts Hash { "from": Integer # 10, "to": Integer #100 } */
  Age = 'AGE',
  /** values accepts Hash { "from": String # 2021-09-30T12:00:00+02:00, "to": String # 2022-09-01 } */
  AppointmentBetween = 'APPOINTMENT_BETWEEN',
  /** values accepts Null */
  AppointmentCancelled = 'APPOINTMENT_CANCELLED',
  /** values accepts Hash { "from": Integer # 10, "to": Integer #100 } */
  AppointmentCount = 'APPOINTMENT_COUNT',
  /** values accepts Hash { "from": Integer # 10, "to": Integer #100 } */
  AppointmentCountMigrated = 'APPOINTMENT_COUNT_MIGRATED',
  /** values accepts Null */
  AppointmentToday = 'APPOINTMENT_TODAY',
  /** values accepts Null */
  AppointmentTomorrow = 'APPOINTMENT_TOMORROW',
  /** values accepts Null */
  AppointmentYesterday = 'APPOINTMENT_YESTERDAY',
  /** values accepts Null */
  Best = 'BEST',
  /** values accepts Hash { "from": String # 2021-09-30T12:00:00+02:00, "to": String # 2022-09-01 } */
  BirthdayBetween = 'BIRTHDAY_BETWEEN',
  /** values accepts Null */
  BirthdayUpcoming = 'BIRTHDAY_UPCOMING',
  /** values accepts Null */
  Blocked = 'BLOCKED',
  /** values accepts Hash { "from": String # 2021-09-30T12:00:00+02:00, "to": String # 2022-09-01 } */
  CreatedAt = 'CREATED_AT',
  /** values accepts Null */
  Debtors = 'DEBTORS',
  /** values accepts Null */
  Deleted = 'DELETED',
  /** values accepts NULL | (String "f" | "m") */
  Gender = 'GENDER',
  /** values accepts Hash { "date": String # 2021-09-01 } */
  LastAppointmentBeforeDate = 'LAST_APPOINTMENT_BEFORE_DATE',
  /** values accepts Hash { "days": Integer # 3 } */
  LastAppointmentDaysAgo = 'LAST_APPOINTMENT_DAYS_AGO',
  /** values accepts Hash { "from": Integer # 10, "to": Integer #100 } */
  LoyaltyPoints = 'LOYALTY_POINTS',
  /** values accepts Null */
  NextAppointmentEmpty = 'NEXT_APPOINTMENT_EMPTY',
  /** values accepts Hash { "from": Integer # 10, "to": Integer #100 } */
  NoShows = 'NO_SHOWS',
  /** values accepts Array[Integer] */
  Products = 'PRODUCTS',
  /** values accepts Array[Integer] */
  Services = 'SERVICES',
  /** values accepts Hash { "from": Integer # 10, "to": Integer #100 } */
  SpentPastYear = 'SPENT_PAST_YEAR',
  /** values accepts Array[Integer] */
  Tags = 'TAGS',
  /** values accepts Hash { "from": Integer # 10, "to": Integer #100 } */
  TotalSpent = 'TOTAL_SPENT',
  /** values accepts Array[Integer] */
  TreatedBy = 'TREATED_BY',
  /** values accepts Array[Integer] */
  TreatedByV2 = 'TREATED_BY_V2'
}

export type CustomerFilterGroup = {
  __typename?: 'CustomerFilterGroup';
  filters: Array<CustomerFilter>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CustomerFilterInput = {
  filterName: CustomerFilterEnum;
  values?: InputMaybe<Scalars['JSON']>;
};

export type CustomerImport = {
  __typename?: 'CustomerImport';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CustomerImportMappingOrder = {
  email?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['Int']>;
};

export type CustomerMember = {
  __typename?: 'CustomerMember';
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
};

export type CustomerOrderHistoryItem = {
  __typename?: 'CustomerOrderHistoryItem';
  id: Scalars['ID'];
  lastPurchasedAt: Scalars['DateTime'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  type: Scalars['String'];
};

export enum CustomerStateEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Merged = 'MERGED',
  ReallyDeleted = 'REALLY_DELETED'
}

export type CustomerSummary = {
  __typename?: 'CustomerSummary';
  appointmentCount: Scalars['Int'];
  cancellationCount: Scalars['Int'];
  lastAppointmentAt?: Maybe<Scalars['DateTime']>;
  lastEmailStatus?: Maybe<Scalars['String']>;
  lastSmsStatus?: Maybe<Scalars['String']>;
  loyaltyPoints: Scalars['Int'];
  nextAppointmentAt?: Maybe<Scalars['DateTime']>;
  noShowCount: Scalars['Int'];
  orderCount: Scalars['Int'];
  totalDue: Scalars['Int'];
  totalSpent: Scalars['Int'];
  totalSpentPastYear: Scalars['Int'];
};

export type CustomerTag = {
  __typename?: 'CustomerTag';
  id: Scalars['Int'];
  name: Scalars['String'];
  salonized: Scalars['Boolean'];
};

export type CustomerTagInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CustomerTags = {
  __typename?: 'CustomerTags';
  companyTags: Array<CustomerTag>;
  salonizedTags: Array<CustomerTag>;
};

export type CustomersOverview = {
  __typename?: 'CustomersOverview';
  customers: Array<Customer>;
  totalCount: Scalars['Int'];
};

export type CustomersOverviewFilterAttributes = {
  filterName: CustomersOverviewFilterEnum;
  labelIndex: Scalars['Int'];
  values?: InputMaybe<Scalars['JSON']>;
};

export enum CustomersOverviewFilterEnum {
  Age = 'AGE',
  AppointmentBetween = 'APPOINTMENT_BETWEEN',
  AppointmentCancelled = 'APPOINTMENT_CANCELLED',
  AppointmentCount = 'APPOINTMENT_COUNT',
  AppointmentToday = 'APPOINTMENT_TODAY',
  AppointmentTomorrow = 'APPOINTMENT_TOMORROW',
  AppointmentYesterday = 'APPOINTMENT_YESTERDAY',
  Best = 'BEST',
  BirthdayBetween = 'BIRTHDAY_BETWEEN',
  BirthdayUpcoming = 'BIRTHDAY_UPCOMING',
  Blocked = 'BLOCKED',
  CreatedAt = 'CREATED_AT',
  Debtors = 'DEBTORS',
  Deleted = 'DELETED',
  Gender = 'GENDER',
  LastAppointmentBeforeDate = 'LAST_APPOINTMENT_BEFORE_DATE',
  LastAppointmentDaysAgo = 'LAST_APPOINTMENT_DAYS_AGO',
  LoyaltyPoints = 'LOYALTY_POINTS',
  NextAppointmentEmpty = 'NEXT_APPOINTMENT_EMPTY',
  NoShows = 'NO_SHOWS',
  Products = 'PRODUCTS',
  Services = 'SERVICES',
  SpentPastYear = 'SPENT_PAST_YEAR',
  Tags = 'TAGS',
  TotalSpent = 'TOTAL_SPENT',
  TreatedBy = 'TREATED_BY'
}

export type DailyReport = {
  __typename?: 'DailyReport';
  chartData: DailyReportChartData;
  data: Array<DailyReportData>;
  totals: DailyReportTotals;
};

export type DailyReportChartData = {
  __typename?: 'DailyReportChartData';
  orders: Array<Scalars['Float']>;
  totals: Array<Scalars['Float']>;
};

export type DailyReportData = {
  __typename?: 'DailyReportData';
  bank: Scalars['Float'];
  cash: Scalars['Float'];
  coupon: Scalars['Float'];
  creditcard: Scalars['Float'];
  date: Scalars['String'];
  discount: Scalars['Float'];
  giftcard: Scalars['Float'];
  online: Scalars['Float'];
  orders: Scalars['Float'];
  other?: Maybe<Scalars['Float']>;
  pin: Scalars['Float'];
  pos?: Maybe<Scalars['Float']>;
  prepaidCard: Scalars['Float'];
  total: Scalars['Float'];
  totalInc: Scalars['Float'];
  transactions: Scalars['Float'];
  treatwellOnlinePayment?: Maybe<Scalars['Float']>;
  vat: Scalars['Float'];
};

export type DailyReportTotals = {
  __typename?: 'DailyReportTotals';
  total: Scalars['Float'];
  totalBank: Scalars['Float'];
  totalCash: Scalars['Float'];
  totalCoupon: Scalars['Float'];
  totalCreditcard: Scalars['Float'];
  totalGiftcard: Scalars['Float'];
  totalInc: Scalars['Float'];
  totalOnline: Scalars['Float'];
  totalOrders: Scalars['Float'];
  totalOther?: Maybe<Scalars['Float']>;
  totalPin: Scalars['Float'];
  totalPos?: Maybe<Scalars['Float']>;
  totalPrepaidCard: Scalars['Float'];
  totalTransactions: Scalars['Float'];
  totalTreatwellOnlinePayment?: Maybe<Scalars['Float']>;
  totalVat: Scalars['Float'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  customersToday?: Maybe<DashboardCustomers>;
  facebookUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Note>>;
  resourcesToday: Array<DashboardResource>;
  utilizationSevenDays?: Maybe<DashboardUtilization>;
  utilizationToday?: Maybe<DashboardUtilization>;
  waitingList?: Maybe<DashboardWaitingList>;
};

export type DashboardCompanyStats = {
  __typename?: 'DashboardCompanyStats';
  totalAppointments?: Maybe<Scalars['Int']>;
  totalCustomers?: Maybe<Scalars['Int']>;
  totalInvoices?: Maybe<Scalars['Int']>;
};

export type DashboardCustomers = {
  __typename?: 'DashboardCustomers';
  newPercentage?: Maybe<Scalars['Float']>;
  returningPercentage?: Maybe<Scalars['Float']>;
  totalCount?: Maybe<Scalars['Int']>;
  waitingListCount?: Maybe<Scalars['Int']>;
};

export type DashboardData = {
  __typename?: 'DashboardData';
  availableBalance: Scalars['Int'];
  hasPosTransaction: Scalars['Boolean'];
  lastTerminalOrderAt?: Maybe<Scalars['DateTime']>;
  processingBalance: Scalars['Int'];
  terminals: Array<PosTerminal>;
  verificationStatus: AdyenAppVerificationStatus;
};

export type DashboardResource = {
  __typename?: 'DashboardResource';
  appointments?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
  occupied?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<Scalars['Int']>;
  roster?: Maybe<Array<Array<Scalars['String']>>>;
  sortOrder?: Maybe<Scalars['Int']>;
  timePresent?: Maybe<Scalars['Int']>;
};

export type DashboardUtilization = {
  __typename?: 'DashboardUtilization';
  available?: Maybe<Scalars['Int']>;
  occupied?: Maybe<Scalars['Int']>;
  timePresent?: Maybe<Scalars['Int']>;
};

export type DashboardWaitingList = {
  __typename?: 'DashboardWaitingList';
  today?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export enum DataScope {
  Global = 'GLOBAL',
  Local = 'LOCAL'
}

export enum DateOfBirth {
  Disabled = 'DISABLED',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export enum DateRange {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum DaysOfWeek {
  Fr = 'FR',
  Mo = 'MO',
  Sa = 'SA',
  Su = 'SU',
  Th = 'TH',
  Tu = 'TU',
  We = 'WE'
}

/** Autogenerated input type of DeactivateIncomingGoogleCalendar */
export type DeactivateIncomingGoogleCalendarInput = {
  calendarId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DeactivateIncomingGoogleCalendar. */
export type DeactivateIncomingGoogleCalendarPayload = {
  __typename?: 'DeactivateIncomingGoogleCalendarPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deactivated: Scalars['Boolean'];
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of DeleteAbsence */
export type DeleteAbsenceInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  chore?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  locationId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of DeleteAbsence. */
export type DeleteAbsencePayload = {
  __typename?: 'DeleteAbsencePayload';
  absence?: Maybe<Absence>;
  calendarAbsence?: Maybe<CalendarAbsence>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of DeleteApp */
export type DeleteAppInput = {
  appName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteApp. */
export type DeleteAppPayload = {
  __typename?: 'DeleteAppPayload';
  app?: Maybe<CompanyApp>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of DeleteAppointment */
export type DeleteAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  requestRefund?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteAppointment. */
export type DeleteAppointmentPayload = {
  __typename?: 'DeleteAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteAppointmentReport */
export type DeleteAppointmentReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteAppointmentReport. */
export type DeleteAppointmentReportPayload = {
  __typename?: 'DeleteAppointmentReportPayload';
  appointmentReport?: Maybe<AppointmentReport>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteCalendarLabel */
export type DeleteCalendarLabelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteCalendarLabel. */
export type DeleteCalendarLabelPayload = {
  __typename?: 'DeleteCalendarLabelPayload';
  calendarLabel?: Maybe<CalendarLabel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteCompany */
export type DeleteCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

/** Autogenerated return type of DeleteCompany. */
export type DeleteCompanyPayload = {
  __typename?: 'DeleteCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of DeleteCustomerFilterGroup */
export type DeleteCustomerFilterGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteCustomerFilterGroup. */
export type DeleteCustomerFilterGroupPayload = {
  __typename?: 'DeleteCustomerFilterGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerFilterGroup?: Maybe<CustomerFilterGroup>;
};

/** Autogenerated input type of DeleteCustomer */
export type DeleteCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteCustomer. */
export type DeleteCustomerPayload = {
  __typename?: 'DeleteCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer: Customer;
};

/** Autogenerated input type of DeleteCustomerTag */
export type DeleteCustomerTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteCustomerTag. */
export type DeleteCustomerTagPayload = {
  __typename?: 'DeleteCustomerTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerTag: CustomerTag;
};

/** Autogenerated input type of DeleteCustomers */
export type DeleteCustomersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<Array<CustomersOverviewFilterAttributes>>;
  query?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteCustomers. */
export type DeleteCustomersPayload = {
  __typename?: 'DeleteCustomersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of DeleteDiscountCode */
export type DeleteDiscountCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteDiscountCode. */
export type DeleteDiscountCodePayload = {
  __typename?: 'DeleteDiscountCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  discountCode: DiscountCode;
};

/** Autogenerated input type of DeleteDynamicPrice */
export type DeleteDynamicPriceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteDynamicPrice. */
export type DeleteDynamicPricePayload = {
  __typename?: 'DeleteDynamicPricePayload';
  calendarDynamicPrice?: Maybe<DynamicPrice>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dynamicPrice?: Maybe<DynamicPrice>;
};

/** Autogenerated input type of DeleteEmailTemplate */
export type DeleteEmailTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteEmailTemplate. */
export type DeleteEmailTemplatePayload = {
  __typename?: 'DeleteEmailTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<EmailTemplate>;
};

/** Autogenerated input type of DeleteFeedback */
export type DeleteFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteFeedback. */
export type DeleteFeedbackPayload = {
  __typename?: 'DeleteFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  feedback: Feedback;
};

/** Autogenerated input type of DeleteFormSubmission */
export type DeleteFormSubmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteFormSubmission. */
export type DeleteFormSubmissionPayload = {
  __typename?: 'DeleteFormSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  formSubmission?: Maybe<FormSubmission>;
};

/** Autogenerated input type of DeleteFormTemplate */
export type DeleteFormTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteFormTemplate. */
export type DeleteFormTemplatePayload = {
  __typename?: 'DeleteFormTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplate>;
};

/** Autogenerated input type of DeleteGiftcard */
export type DeleteGiftcardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteGiftcard. */
export type DeleteGiftcardPayload = {
  __typename?: 'DeleteGiftcardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  giftcard?: Maybe<Giftcard>;
};

/** Autogenerated input type of DeleteImportedProducts */
export type DeleteImportedProductsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** Autogenerated return type of DeleteImportedProducts. */
export type DeleteImportedProductsPayload = {
  __typename?: 'DeleteImportedProductsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  import: Import;
};

/** Autogenerated input type of DeleteIplTreatment */
export type DeleteIplTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteIplTreatment. */
export type DeleteIplTreatmentPayload = {
  __typename?: 'DeleteIplTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  iplTreatment?: Maybe<IplTreatment>;
};

/** Autogenerated input type of DeleteIplTreatmentReport */
export type DeleteIplTreatmentReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteIplTreatmentReport. */
export type DeleteIplTreatmentReportPayload = {
  __typename?: 'DeleteIplTreatmentReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  iplTreatmentReport?: Maybe<IplTreatmentReport>;
};

/** Autogenerated input type of DeleteLocation */
export type DeleteLocationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteLocation. */
export type DeleteLocationPayload = {
  __typename?: 'DeleteLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

/** Autogenerated input type of DeleteLoyaltyTransaction */
export type DeleteLoyaltyTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteLoyaltyTransaction. */
export type DeleteLoyaltyTransactionPayload = {
  __typename?: 'DeleteLoyaltyTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  loyaltyTransaction?: Maybe<LoyaltyTransaction>;
};

/** Autogenerated input type of DeleteNote */
export type DeleteNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteNote. */
export type DeleteNotePayload = {
  __typename?: 'DeleteNotePayload';
  calendarNote?: Maybe<CalendarNote>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  note?: Maybe<Note>;
};

/** Autogenerated input type of DeleteOrder */
export type DeleteOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteOrder. */
export type DeleteOrderPayload = {
  __typename?: 'DeleteOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

/** Autogenerated input type of DeleteProductCategory */
export type DeleteProductCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  deleteProducts?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteProductCategory. */
export type DeleteProductCategoryPayload = {
  __typename?: 'DeleteProductCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  productCategory?: Maybe<ProductCategory>;
};

/** Autogenerated input type of DeleteProduct */
export type DeleteProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteProduct. */
export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

/** Autogenerated input type of DeleteServiceCategory */
export type DeleteServiceCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteServiceCategory. */
export type DeleteServiceCategoryPayload = {
  __typename?: 'DeleteServiceCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  serviceCategory?: Maybe<ServiceCategory>;
};

/** Autogenerated input type of DeleteServiceGroup */
export type DeleteServiceGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteServiceGroup. */
export type DeleteServiceGroupPayload = {
  __typename?: 'DeleteServiceGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of DeleteService */
export type DeleteServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteService. */
export type DeleteServicePayload = {
  __typename?: 'DeleteServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of DeleteServiceVariationGroup */
export type DeleteServiceVariationGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteServiceVariationGroup. */
export type DeleteServiceVariationGroupPayload = {
  __typename?: 'DeleteServiceVariationGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of DeleteStockOrder */
export type DeleteStockOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteStockOrder. */
export type DeleteStockOrderPayload = {
  __typename?: 'DeleteStockOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  stockOrder?: Maybe<StockOrder>;
};

/** Autogenerated input type of DeleteSupplier */
export type DeleteSupplierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  deleteProducts?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteSupplier. */
export type DeleteSupplierPayload = {
  __typename?: 'DeleteSupplierPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
};

/** Autogenerated input type of DeleteTransaction */
export type DeleteTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteTransaction. */
export type DeleteTransactionPayload = {
  __typename?: 'DeleteTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  drawer?: Maybe<Drawer>;
  transaction?: Maybe<Transaction>;
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteUser. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

/** Autogenerated input type of DeleteVatRate */
export type DeleteVatRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteVatRate. */
export type DeleteVatRatePayload = {
  __typename?: 'DeleteVatRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  vatRate?: Maybe<VatRate>;
};

/** Autogenerated input type of DeleteWaitingListEntry */
export type DeleteWaitingListEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteWaitingListEntry. */
export type DeleteWaitingListEntryPayload = {
  __typename?: 'DeleteWaitingListEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  waitingListEntry?: Maybe<WaitingListEntry>;
};

export type DeliveryAddressDetails = {
  __typename?: 'DeliveryAddressDetails';
  address: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
};

export type DeliveryContactDetails = {
  __typename?: 'DeliveryContactDetails';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  appliesTo: DiscountCodeAppliesToEnum;
  category?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  discountPercentage: Scalars['Int'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  expiresIn: DiscountCodeExpiresInEnum;
  hasLimit: Scalars['Boolean'];
  id: Scalars['ID'];
  oncePerCustomer: Scalars['Boolean'];
  state: DiscountCodeStateEnum;
  usageLimit?: Maybe<Scalars['Int']>;
  usedCount: Scalars['Int'];
};

export enum DiscountCodeAppliesToEnum {
  /** Apply discount to all services listed on the invoice */
  AllServices = 'ALL_SERVICES'
}

export enum DiscountCodeExpiresInEnum {
  /** Usable forever */
  Never = 'NEVER',
  /** Usable until one month after creation */
  OneMonth = 'ONE_MONTH',
  /** Usable until one year after creation */
  OneYear = 'ONE_YEAR',
  /** Usable until six months after creation */
  SixMonths = 'SIX_MONTHS',
  /** Usable until three months after creation */
  ThreeMonths = 'THREE_MONTHS'
}

export enum DiscountCodeStateEnum {
  /** Disallowed for usage in booking widget */
  Disabled = 'DISABLED',
  /** Allowed for usage in booking widget */
  Enabled = 'ENABLED'
}

export type DiscountCodeUsage = {
  __typename?: 'DiscountCodeUsage';
  appointment: Appointment;
  appointmentDate: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  usedAt: Scalars['DateTime'];
};

/** Autogenerated input type of DismissMollieErrors */
export type DismissMollieErrorsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DismissMollieErrors. */
export type DismissMollieErrorsPayload = {
  __typename?: 'DismissMollieErrorsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of DismissVerificationReminder */
export type DismissVerificationReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DismissVerificationReminder. */
export type DismissVerificationReminderPayload = {
  __typename?: 'DismissVerificationReminderPayload';
  adyenAppState?: Maybe<AdyenAppState>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

export enum DispatchTypeEnum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  SemiAutomatic = 'SEMI_AUTOMATIC'
}

export type Drawer = {
  __typename?: 'Drawer';
  creditCard: Scalars['Int'];
  pin: Scalars['Int'];
  today: Scalars['Int'];
  total: Scalars['Int'];
};

/** Autogenerated input type of DuplicateAppointment */
export type DuplicateAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  resourceId?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['DateTime'];
  viewType?: CalendarView;
};

/** Autogenerated return type of DuplicateAppointment. */
export type DuplicateAppointmentPayload = {
  __typename?: 'DuplicateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of DuplicateServiceGroup */
export type DuplicateServiceGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DuplicateServiceGroup. */
export type DuplicateServiceGroupPayload = {
  __typename?: 'DuplicateServiceGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  serviceGroup?: Maybe<ServiceGroup>;
};

/** Autogenerated input type of DuplicateService */
export type DuplicateServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DuplicateService. */
export type DuplicateServicePayload = {
  __typename?: 'DuplicateServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of DuplicateServiceVariationGroup */
export type DuplicateServiceVariationGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DuplicateServiceVariationGroup. */
export type DuplicateServiceVariationGroupPayload = {
  __typename?: 'DuplicateServiceVariationGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  serviceVariationGroup?: Maybe<ServiceVariationGroup>;
};

export type DurationInsight = {
  __typename?: 'DurationInsight';
  duration: Scalars['Int'];
  id: Scalars['ID'];
  ratio: Scalars['Float'];
  sum: Scalars['Int'];
};

export type DynamicPrice = {
  __typename?: 'DynamicPrice';
  adjustmentType: AdjustmentType;
  amount: Scalars['Int'];
  /** @deprecated Use 'adjustment_type' & 'amount' instead */
  discountPercentage: Scalars['Int'];
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  rrule?: Maybe<Scalars['JSON']>;
  startAt: Scalars['DateTime'];
};

export type EmailDomain = {
  __typename?: 'EmailDomain';
  cnames?: Maybe<Array<Cname>>;
  createdAt: Scalars['DateTime'];
  dkimRecordValue?: Maybe<Scalars['String']>;
  emailProvider?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: EmailDomainStatus;
};

export enum EmailDomainStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Unverified = 'UNVERIFIED'
}

export enum EmailStatusEnum {
  /** Email has already been requested */
  AlreadyRequested = 'ALREADY_REQUESTED',
  /** Email has not been sent */
  NotSent = 'NOT_SENT',
  /** Email has been sent */
  Sent = 'SENT'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  buttonTitle?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  deliverAt?: Maybe<Scalars['DateTime']>;
  discountCode?: Maybe<DiscountCode>;
  discountCodeId?: Maybe<Scalars['ID']>;
  discountMessage?: Maybe<Scalars['String']>;
  dispatchType?: Maybe<DispatchTypeEnum>;
  emailType: EmailTemplateEnum;
  filters?: Maybe<Array<CustomerFilter>>;
  hasButton?: Maybe<Scalars['Boolean']>;
  hasDeliverySchedule: Scalars['Boolean'];
  hasDiscount?: Maybe<Scalars['Boolean']>;
  hasFooter?: Maybe<Scalars['Boolean']>;
  hasImage?: Maybe<Scalars['Boolean']>;
  hasLogo?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export enum EmailTemplateEnum {
  Birthday = 'BIRTHDAY',
  CustomerAppointments = 'CUSTOMER_APPOINTMENTS',
  DefaultMarketing = 'DEFAULT_MARKETING',
  MarketingCampaign = 'MARKETING_CAMPAIGN',
  OnlineForms = 'ONLINE_FORMS',
  PriceIncrease = 'PRICE_INCREASE',
  RebookReminder = 'REBOOK_REMINDER',
  WaitingList = 'WAITING_LIST'
}

export type EmployeeReport = {
  __typename?: 'EmployeeReport';
  data: Array<EmployeeReportData>;
  totals: EmployeeReportTotals;
};

export type EmployeeReportData = {
  __typename?: 'EmployeeReportData';
  appointments: Scalars['Int'];
  averageOrderValueExcl: Scalars['Float'];
  averageOrderValueIncl: Scalars['Float'];
  bookedHours: Scalars['Float'];
  discount: Scalars['Int'];
  hours: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  orders: Scalars['Int'];
  productCount: Scalars['Int'];
  productTotal: Scalars['Float'];
  productTotalExcl: Scalars['Float'];
  productsPerHour: Scalars['Float'];
  productsPerHourExcl: Scalars['Float'];
  serviceCount: Scalars['Int'];
  serviceTotal: Scalars['Float'];
  serviceTotalExcl: Scalars['Float'];
  servicesPerHour: Scalars['Float'];
  servicesPerHourExcl: Scalars['Float'];
  utilization: Scalars['Int'];
  workedHours: Scalars['Float'];
};

export type EmployeeReportTotals = {
  __typename?: 'EmployeeReportTotals';
  appointments: Scalars['Int'];
  averageOrderValueExcl: Scalars['Float'];
  averageOrderValueIncl: Scalars['Float'];
  bookedHours: Scalars['Float'];
  discount: Scalars['Int'];
  hours: Scalars['Float'];
  id: Scalars['Int'];
  orders: Scalars['Int'];
  productCount: Scalars['Int'];
  productTotal: Scalars['Float'];
  productTotalExcl: Scalars['Float'];
  productsPerHour: Scalars['Float'];
  productsPerHourExcl: Scalars['Float'];
  serviceCount: Scalars['Int'];
  serviceTotal: Scalars['Float'];
  serviceTotalExcl: Scalars['Float'];
  servicesPerHour: Scalars['Float'];
  servicesPerHourExcl: Scalars['Float'];
  utilization: Scalars['Int'];
  workedHours: Scalars['Float'];
};

/** Autogenerated input type of EnableTreatwellMarketplace */
export type EnableTreatwellMarketplaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  venueType?: InputMaybe<TreatwellVenueType>;
};

/** Autogenerated return type of EnableTreatwellMarketplace. */
export type EnableTreatwellMarketplacePayload = {
  __typename?: 'EnableTreatwellMarketplacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellVenue?: Maybe<TreatwellVenue>;
};

export enum EnabledDisabled {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type Error = {
  __typename?: 'Error';
  attribute?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EventFiber = {
  __typename?: 'EventFiber';
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  duration: Scalars['Int'];
  endAt: Scalars['DateTime'];
  eventId: Scalars['ID'];
  eventSourceId?: Maybe<Scalars['ID']>;
  eventType: Scalars['String'];
  fiberId: Scalars['ID'];
  fiberType: Scalars['String'];
  locationId?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type EventUnion = Absence | Appointment;

/** Autogenerated input type of ExecuteImport */
export type ExecuteImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['ID'];
};

/** Autogenerated return type of ExecuteImport. */
export type ExecuteImportPayload = {
  __typename?: 'ExecuteImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  import: Import;
};

export type Export = {
  __typename?: 'Export';
  months: Array<Scalars['ISO8601Date']>;
};

export enum ExportData {
  Absences = 'ABSENCES',
  Appointments = 'APPOINTMENTS',
  CurrentProductStock = 'CURRENT_PRODUCT_STOCK',
  CurrentStock = 'CURRENT_STOCK',
  Customers = 'CUSTOMERS',
  Datev = 'DATEV',
  DayTotals = 'DAY_TOTALS',
  DeletedAppointments = 'DELETED_APPOINTMENTS',
  DrawerTransactions = 'DRAWER_TRANSACTIONS',
  Gobd = 'GOBD',
  Orders = 'ORDERS',
  OrderItems = 'ORDER_ITEMS',
  Products = 'PRODUCTS',
  TreatmentReports = 'TREATMENT_REPORTS',
  WorkedHours = 'WORKED_HOURS'
}

export enum ExportFormat {
  Csv = 'CSV',
  Xls = 'XLS',
  Zip = 'ZIP'
}

export type Extension = {
  __typename?: 'Extension';
  currency: Currency;
  period: SubscriptionPeriod;
  plan: SubscriptionPlan;
  price: Scalars['Int'];
};

export type Feedback = {
  __typename?: 'Feedback';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  feedbackReplies?: Maybe<Array<FeedbackReply>>;
  id: Scalars['Int'];
  /** @deprecated Deprecated in favor of 'feedbackReplies' */
  messages: Array<Message>;
  messagesPublished?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  source: FeedbackSource;
  state: Scalars['String'];
  submittedAt?: Maybe<Scalars['DateTime']>;
  twPublishedAt?: Maybe<Scalars['DateTime']>;
};

export type FeedbackAggregation = {
  __typename?: 'FeedbackAggregation';
  batchAvg: Scalars['Float'];
  starsCount1: Scalars['Int'];
  starsCount2: Scalars['Int'];
  starsCount3: Scalars['Int'];
  starsCount4: Scalars['Int'];
  starsCount5: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FeedbackPublishingStatusFilterEnum {
  /** The feedback is allowed to be published */
  Allowed = 'ALLOWED',
  /** The feedback is not allowed to be published */
  Disallowed = 'DISALLOWED',
  /** The feedback is already published */
  Published = 'PUBLISHED',
  /** The feedback has been requesed */
  Requested = 'REQUESTED'
}

export enum FeedbackReactionFilterEnum {
  /** Negative feedback reaction */
  Negative = 'NEGATIVE',
  /** Possitive feedback reaction */
  Positive = 'POSITIVE'
}

export type FeedbackReply = {
  __typename?: 'FeedbackReply';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export enum FeedbackSource {
  /** Feedback came from Salonized */
  Salonized = 'SALONIZED',
  /** Feedback came from Treatwell */
  Treatwell = 'TREATWELL'
}

export enum FeedbackState {
  Allowed = 'ALLOWED',
  Published = 'PUBLISHED'
}

export type FeedbackWidget = {
  __typename?: 'FeedbackWidget';
  feedbackUrl: Scalars['String'];
  miniFeedbackUrl: Scalars['String'];
};

export enum FilterScope {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Online = 'ONLINE',
  Outstanding = 'OUTSTANDING',
  Paid = 'PAID'
}

export enum FirstDayOfWeek {
  Monday = 'MONDAY',
  Sunday = 'SUNDAY'
}

/** Autogenerated return type of FiscalizedOrder. */
export type FiscalizedOrderPayload = {
  __typename?: 'FiscalizedOrderPayload';
  order?: Maybe<Order>;
};

export type FormAnswerAttributes = {
  answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  multipleAnswers?: InputMaybe<Array<Scalars['String']>>;
  questionId?: InputMaybe<Scalars['Int']>;
};

export type FormAnswerUnion = ArrayAnswer | BooleanAnswer | StringAnswer;

export type FormQuestion = {
  __typename?: 'FormQuestion';
  id: Scalars['Int'];
  options: Array<Scalars['String']>;
  question: Scalars['String'];
  questionType: Scalars['String'];
  sortIndex: Scalars['Int'];
};

export type FormQuestionAttributes = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<Array<Scalars['String']>>;
  question?: InputMaybe<Scalars['String']>;
  questionType?: InputMaybe<FormQuestionType>;
  sortIndex?: InputMaybe<Scalars['Int']>;
};

export enum FormQuestionType {
  Boolean = 'BOOLEAN',
  Checkbox = 'CHECKBOX',
  Dropdown = 'DROPDOWN',
  Paragraph = 'PARAGRAPH',
  Signature = 'SIGNATURE',
  Text = 'TEXT'
}

export type FormSubmission = {
  __typename?: 'FormSubmission';
  answers: Array<FormAnswerUnion>;
  appointment?: Maybe<Appointment>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  id: Scalars['Int'];
  online: Scalars['Boolean'];
  state: Scalars['String'];
  submittedAt?: Maybe<Scalars['DateTime']>;
  template: FormTemplate;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  archivedAt?: Maybe<Scalars['DateTime']>;
  corona: Scalars['Boolean'];
  formType: Scalars['String'];
  id: Scalars['Int'];
  introText?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  questions: Array<FormQuestion>;
  sendAutomatically: Scalars['Boolean'];
  serviceIds: Array<Scalars['Int']>;
  submissionsCount: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
};

export type FormTemplateQuestionsArgs = {
  scopes?: InputMaybe<Array<Scalars['String']>>;
};

export enum FormTemplateType {
  Consultation = 'CONSULTATION',
  Contract = 'CONTRACT'
}

export type Franchise = {
  __typename?: 'Franchise';
  app?: Maybe<FranchiseApp>;
  franchise?: Maybe<FranchiseFranchise>;
};

export type FranchiseApp = {
  __typename?: 'FranchiseApp';
  id?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  settings: FranchiseAppSettings;
  state: Scalars['String'];
};

export type FranchiseAppPermissionAttributes = {
  admin: Scalars['Boolean'];
  basic: Scalars['Boolean'];
  manager: Scalars['Boolean'];
  owner: Scalars['Boolean'];
  reports: Scalars['Boolean'];
};

export type FranchiseAppPermissions = {
  __typename?: 'FranchiseAppPermissions';
  admin: Scalars['Boolean'];
  basic: Scalars['Boolean'];
  manager: Scalars['Boolean'];
  owner: Scalars['Boolean'];
  reports: Scalars['Boolean'];
};

export type FranchiseAppSettings = {
  __typename?: 'FranchiseAppSettings';
  franchiseId?: Maybe<Scalars['Int']>;
  permissions?: Maybe<FranchiseAppPermissions>;
  token?: Maybe<Scalars['String']>;
};

export type FranchiseFranchise = {
  __typename?: 'FranchiseFranchise';
  beta?: Maybe<Scalars['Boolean']>;
  billing?: Maybe<Scalars['Boolean']>;
  constantPrice?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  stripePaymentType?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Nonbinary = 'NONBINARY'
}

/** Autogenerated input type of GenerateOnboardingLink */
export type GenerateOnboardingLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateOnboardingLink. */
export type GenerateOnboardingLinkPayload = {
  __typename?: 'GenerateOnboardingLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Error>;
  onboardingLink?: Maybe<OnboardingLink>;
};

/** Autogenerated input type of GeneratePosPayment */
export type GeneratePosPaymentInput = {
  amount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
  terminalUid: Scalars['ID'];
};

/** Autogenerated return type of GeneratePosPayment. */
export type GeneratePosPaymentPayload = {
  __typename?: 'GeneratePosPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type Giftcard = {
  __typename?: 'Giftcard';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  orders: Array<Order>;
  permissions: GiftcardPermissions;
  redeemedOrders?: Maybe<Array<Order>>;
  remainingAmount: Scalars['Int'];
  source: GiftcardSource;
  status: GiftcardStatus;
  totalAmount: Scalars['Int'];
  usedAmount: Scalars['Int'];
};

export type GiftcardAttributes = {
  code: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['ISO8601Date']>;
  source?: InputMaybe<GiftcardSource>;
  state?: InputMaybe<GiftcardStatus>;
  totalAmount: Scalars['Int'];
};

export enum GiftcardFilterScope {
  Active = 'ACTIVE',
  Empty = 'EMPTY',
  Expired = 'EXPIRED',
  Online = 'ONLINE',
  Usable = 'USABLE'
}

export type GiftcardPermissions = {
  __typename?: 'GiftcardPermissions';
  delete: Scalars['Boolean'];
};

export enum GiftcardSource {
  Imported = 'IMPORTED',
  Legacy = 'LEGACY',
  Online = 'ONLINE',
  Sale = 'SALE'
}

export enum GiftcardStatus {
  Active = 'ACTIVE',
  Credited = 'CREDITED',
  Empty = 'EMPTY',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export type GoogleCalendar = {
  __typename?: 'GoogleCalendar';
  app: GoogleCalendarApp;
  unwritableLists: Array<GoogleCalendarCalendar>;
  writableLists: Array<GoogleCalendarCalendar>;
};

export type GoogleCalendarApp = {
  __typename?: 'GoogleCalendarApp';
  id: Scalars['Int'];
  key: Scalars['String'];
  settings: GoogleCalendarAppSettings;
  state: Scalars['String'];
};

export type GoogleCalendarAppSettings = {
  __typename?: 'GoogleCalendarAppSettings';
  calendars?: Maybe<Scalars['JSON']>;
  companyCalendar?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
};

export type GoogleCalendarCalendar = {
  __typename?: 'GoogleCalendarCalendar';
  id: Scalars['ID'];
  summary: Scalars['String'];
};

export type GoogleCalendarCalendarAttributes = {
  calendarId: Scalars['String'];
  id: Scalars['ID'];
};

export type HeatMapInsight = {
  __typename?: 'HeatMapInsight';
  date: Scalars['String'];
  part: Scalars['String'];
  ratio: Scalars['Float'];
  sum: Scalars['Int'];
};

export type HtmlToPlainText = {
  __typename?: 'HtmlToPlainText';
  text: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  bytes: Scalars['Int'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  mimeType: Scalars['String'];
  originalUrl: Scalars['String'];
  publicId: Scalars['ID'];
  transformedUrl: Scalars['String'];
  usage: UploadedImageUsage;
};

export type Import = {
  __typename?: 'Import';
  createdAt: Scalars['DateTime'];
  dataPreview?: Maybe<Array<Array<Scalars['String']>>>;
  headers?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  productsCount: Scalars['Int'];
  state: Scalars['String'];
};

export type IncomingGoogleCalendar = {
  __typename?: 'IncomingGoogleCalendar';
  calendarId: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  resourceId?: Maybe<Scalars['Int']>;
};

export type IncomingGoogleCalendarInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['Int']>;
};

export type IndustryCode = {
  __typename?: 'IndustryCode';
  id: Scalars['ID'];
  name: IndustryCodeName;
};

export enum IndustryCodeName {
  HairNailSkin = 'HAIR_NAIL_SKIN',
  HealthBeautySpa = 'HEALTH_BEAUTY_SPA',
  Massage = 'MASSAGE'
}

/** Autogenerated input type of IntegrationRequestAcceptContract */
export type IntegrationRequestAcceptContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractTemplateId: Scalars['ID'];
};

/** Autogenerated return type of IntegrationRequestAcceptContract. */
export type IntegrationRequestAcceptContractPayload = {
  __typename?: 'IntegrationRequestAcceptContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

/** Autogenerated input type of IntegrationRequestAccept */
export type IntegrationRequestAcceptInput = {
  bankingDetails?: InputMaybe<TreatwellBankDetails>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  freeCommissionPeriod?: InputMaybe<Scalars['Int']>;
  images: Array<Scalars['String']>;
  salonDescription: Scalars['String'];
  venueType: TreatwellVenueType;
};

/** Autogenerated return type of IntegrationRequestAccept. */
export type IntegrationRequestAcceptPayload = {
  __typename?: 'IntegrationRequestAcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

/** Autogenerated input type of IntegrationRequestCreate */
export type IntegrationRequestCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of IntegrationRequestCreate. */
export type IntegrationRequestCreatePayload = {
  __typename?: 'IntegrationRequestCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

/** Autogenerated input type of IntegrationRequestReject */
export type IntegrationRequestRejectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rejectionReason: Scalars['String'];
};

/** Autogenerated return type of IntegrationRequestReject. */
export type IntegrationRequestRejectPayload = {
  __typename?: 'IntegrationRequestRejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

/** Autogenerated input type of IntegrationRequestUpdateBankingDetails */
export type IntegrationRequestUpdateBankingDetailsInput = {
  bankingDetails: TreatwellBankDetails;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of IntegrationRequestUpdateBankingDetails. */
export type IntegrationRequestUpdateBankingDetailsPayload = {
  __typename?: 'IntegrationRequestUpdateBankingDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

/** Autogenerated input type of IntegrationRequestUpdateContract */
export type IntegrationRequestUpdateContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractTemplateId: Scalars['ID'];
};

/** Autogenerated return type of IntegrationRequestUpdateContract. */
export type IntegrationRequestUpdateContractPayload = {
  __typename?: 'IntegrationRequestUpdateContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

/** Autogenerated input type of IntegrationRequestUpdateFee */
export type IntegrationRequestUpdateFeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fee: TreatwellIntegrationFee;
};

/** Autogenerated return type of IntegrationRequestUpdateFee. */
export type IntegrationRequestUpdateFeePayload = {
  __typename?: 'IntegrationRequestUpdateFeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

export enum InterestedSource {
  AnalyzeToggle = 'ANALYZE_TOGGLE',
  InAppLandingPage = 'IN_APP_LANDING_PAGE',
  LandingPageNewTrial = 'LANDING_PAGE_NEW_TRIAL',
  SparklesButton = 'SPARKLES_BUTTON',
  Unknown = 'UNKNOWN'
}

export type InternalPage = {
  __typename?: 'InternalPage';
  category: Scalars['String'];
  id: Scalars['ID'];
  path: Scalars['String'];
  title: Scalars['String'];
  titleEn: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  currency: Scalars['String'];
  date: Scalars['ISO8601Date'];
  dueDate: Scalars['ISO8601Date'];
  id: Scalars['Int'];
  invoiceNumber?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Open = 'OPEN',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Processing = 'PROCESSING',
  Uncollectible = 'UNCOLLECTIBLE',
  Unrelated = 'UNRELATED',
  Void = 'VOID'
}

export type IplTreatment = {
  __typename?: 'IplTreatment';
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  treatments: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IplTreatmentReport = {
  __typename?: 'IplTreatmentReport';
  amount: Scalars['Int'];
  date: Scalars['ISO8601Date'];
  employeeId: Scalars['Int'];
  employeeName: Scalars['String'];
  id: Scalars['ID'];
  iplTreatmentId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  power: Scalars['Float'];
  treatment: Scalars['Int'];
};

export enum LastMinuteDiscountWindowEnum {
  /** Time slot within 4 hours from now */
  FourHours = 'FOUR_HOURS',
  /** Time slot until tomorrow end of day */
  OneDayFromNow = 'ONE_DAY_FROM_NOW',
  /** Time slot within 1 hours from now */
  OneHour = 'ONE_HOUR',
  /** Time slot until end of current day */
  SameDay = 'SAME_DAY',
  /** Time slot until day after tomorrow end of day */
  TwoDaysFromNow = 'TWO_DAYS_FROM_NOW',
  /** Time slot within 2 hours from now */
  TwoHours = 'TWO_HOURS'
}

export type LastMinuteDiscounts = {
  __typename?: 'LastMinuteDiscounts';
  bookings: Scalars['Int'];
  discountPercentage: Scalars['Int'];
  discountWindow: LastMinuteDiscountWindowEnum;
  enabled: Scalars['Boolean'];
};

export enum LegalEntity {
  Organization = 'ORGANIZATION',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP'
}

export type Locale = {
  __typename?: 'Locale';
  clock: Scalars['String'];
  countryCode: Scalars['String'];
  defaultCurrency: Scalars['String'];
  defaultLanguage: Scalars['String'];
  eu: Scalars['Boolean'];
  firstDayOfWeek: Scalars['String'];
  radix: Scalars['String'];
  sepa: Scalars['Boolean'];
};

export type LocaleOptions = {
  __typename?: 'LocaleOptions';
  currency: Scalars['JSON'];
  timeZones: Scalars['JSON'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bookable: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  fullAddressWithoutPhone?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  internalName: Scalars['String'];
  name: Scalars['String'];
  openCashup?: Maybe<Cashup>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  previousCashFloatAmount: Scalars['Int'];
  sortOrder: Scalars['Int'];
};

export type Logo = {
  __typename?: 'Logo';
  large?: Maybe<Scalars['String']>;
  small?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type Logs = {
  __typename?: 'Logs';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  recordId?: Maybe<Scalars['Int']>;
  recordType?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userName?: Maybe<Scalars['String']>;
};

export enum LoyaltyPointsType {
  Disabled = 'DISABLED',
  PerProduct = 'PER_PRODUCT',
  PerService = 'PER_SERVICE',
  PerVisit = 'PER_VISIT',
  SpentAmount = 'SPENT_AMOUNT',
  SpentAmountProducts = 'SPENT_AMOUNT_PRODUCTS',
  SpentAmountServices = 'SPENT_AMOUNT_SERVICES'
}

export type LoyaltyTransaction = {
  __typename?: 'LoyaltyTransaction';
  amount: Scalars['Int'];
  customerId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderId?: Maybe<Scalars['ID']>;
  orderNumber?: Maybe<Scalars['String']>;
  transactionAt: Scalars['DateTime'];
};

export type LoyaltyTransactions = {
  __typename?: 'LoyaltyTransactions';
  loyaltyTransactions: Array<LoyaltyTransaction>;
  metadata: Scalars['JSON'];
};

export type Mailchimp = {
  __typename?: 'Mailchimp';
  app?: Maybe<CompanyApp>;
  listId?: Maybe<Scalars['ID']>;
  lists?: Maybe<Array<MailchimpCustomer>>;
};

export type MailchimpCustomer = {
  __typename?: 'MailchimpCustomer';
  id: Scalars['ID'];
  memberCount: Scalars['Int'];
  name: Scalars['String'];
};

export type MarketingStats = {
  __typename?: 'MarketingStats';
  bookingsFromReminder: Scalars['Int'];
  discountCodesUsed: Scalars['Int'];
  dynamicPriceUsed: Scalars['Int'];
  lastMinuteDiscountUsed: Scalars['Int'];
  marketingMailings: Scalars['Int'];
  marketingMessages: Scalars['Int'];
  marketingMessagesButtonClicked: Scalars['Int'];
  marketingMessagesOpened: Scalars['Int'];
  onlineBookings: Scalars['Int'];
  rebookButtonClicked: Scalars['Int'];
  rebookMessages: Scalars['Int'];
  rebookMessagesOpened: Scalars['Int'];
  waitingListEntries: Scalars['Int'];
};

/** Autogenerated input type of MassiveAddCustomerTags */
export type MassiveAddCustomerTagsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<Array<CustomersOverviewFilterAttributes>>;
  query?: InputMaybe<Scalars['String']>;
  tagIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of MassiveAddCustomerTags. */
export type MassiveAddCustomerTagsPayload = {
  __typename?: 'MassiveAddCustomerTagsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of MergeCustomer */
export type MergeCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sourceId: Scalars['ID'];
};

/** Autogenerated return type of MergeCustomer. */
export type MergeCustomerPayload = {
  __typename?: 'MergeCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  errors?: Maybe<Array<Error>>;
};

export type Message = {
  __typename?: 'Message';
  buttonClickAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  destination: Scalars['String'];
  emailTemplate?: Maybe<EmailTemplate>;
  gateway: Scalars['String'];
  gatewayCode?: Maybe<Scalars['String']>;
  gatewayMessage?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  messageType: Scalars['String'];
  openedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export enum MessageGatewayEnum {
  /** The message will be send through email */
  Email = 'EMAIL',
  /** The message will be send through sms */
  Sms = 'SMS'
}

export enum MessageTypeEnum {
  /** The message will be an invoice sent to the customer */
  OrderInvoice = 'ORDER_INVOICE',
  /** The message will be a stock order sent to the supplier */
  StockOrder = 'STOCK_ORDER'
}

export type MicroSite = {
  __typename?: 'MicroSite';
  baseColor?: Maybe<Scalars['String']>;
  customCss?: Maybe<Scalars['String']>;
  customDomain?: Maybe<Scalars['String']>;
  gaId?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  headerImageTransparency?: Maybe<Scalars['Boolean']>;
  headerSubtitle?: Maybe<Scalars['String']>;
  headerTitle?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  impressum?: Maybe<Scalars['String']>;
  introText?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  showVoucherWidget?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type Mollie = {
  __typename?: 'Mollie';
  app?: Maybe<MollieApp>;
  profileIds?: Maybe<Array<Scalars['ID']>>;
};

export type MollieApp = {
  __typename?: 'MollieApp';
  id: Scalars['Int'];
  key: Scalars['String'];
  settings: MollieAppSettings;
  state: Scalars['String'];
};

export type MollieAppSettings = {
  __typename?: 'MollieAppSettings';
  profileId?: Maybe<Scalars['String']>;
};

export enum Money {
  Coin = 'COIN',
  Paper = 'PAPER'
}

export type MonthlyReport = {
  __typename?: 'MonthlyReport';
  chartData: MonthlyReportChartData;
  data: Array<MonthlyReportData>;
  totals: MonthlyReportTotals;
};

export type MonthlyReportChartData = {
  __typename?: 'MonthlyReportChartData';
  categories: Array<Scalars['String']>;
  orders: Array<Scalars['Int']>;
  totals: Array<Scalars['Float']>;
};

export type MonthlyReportData = {
  __typename?: 'MonthlyReportData';
  bank: Scalars['Float'];
  cash: Scalars['Float'];
  coupon: Scalars['Float'];
  creditcard: Scalars['Float'];
  date: Scalars['String'];
  discount: Scalars['Float'];
  giftcard: Scalars['Float'];
  online: Scalars['Float'];
  orders: Scalars['Float'];
  other?: Maybe<Scalars['Float']>;
  pin: Scalars['Float'];
  pos?: Maybe<Scalars['Float']>;
  prepaidCard: Scalars['Float'];
  total: Scalars['Float'];
  totalInc: Scalars['Float'];
  transactions: Scalars['Float'];
  treatwellOnlinePayment?: Maybe<Scalars['Float']>;
  vat: Scalars['Float'];
};

export type MonthlyReportTotals = {
  __typename?: 'MonthlyReportTotals';
  total: Scalars['Float'];
  totalBank: Scalars['Float'];
  totalCash: Scalars['Float'];
  totalCoupon: Scalars['Float'];
  totalCreditcard: Scalars['Float'];
  totalGiftcard: Scalars['Float'];
  totalInc: Scalars['Float'];
  totalOnline: Scalars['Float'];
  totalOrders: Scalars['Float'];
  totalOther?: Maybe<Scalars['Float']>;
  totalPin: Scalars['Float'];
  totalPos?: Maybe<Scalars['Float']>;
  totalPrepaidCard: Scalars['Float'];
  totalTransactions: Scalars['Float'];
  totalTreatwellOnlinePayment?: Maybe<Scalars['Float']>;
  totalVat: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateAppointment?: Maybe<ActivateAppointmentPayload>;
  activateIncomingGoogleCalendar?: Maybe<ActivateIncomingGoogleCalendarPayload>;
  activateTrial?: Maybe<ActivateTrialPayload>;
  assignCustomerTags?: Maybe<AssignCustomerTagsPayload>;
  assignPosTerminal?: Maybe<AssignPosTerminalPayload>;
  become?: Maybe<BecomePayload>;
  becomeFranchise?: Maybe<BecomeFranchisePayload>;
  cancelProductChangeSchedule?: Maybe<CancelProductChangeSchedulePayload>;
  cancelSubscription?: Maybe<CancelSubscriptionPayload>;
  changeStripeSubscription?: Maybe<ChangeStripeSubscriptionPayload>;
  closeCashup?: Maybe<CloseCashupPayload>;
  completeTerminalCheckout?: Maybe<CompleteTerminalCheckoutPayload>;
  confirmAppointment?: Maybe<ConfirmAppointmentPayload>;
  createAbsence?: Maybe<CreateAbsencePayload>;
  createAppointment?: Maybe<CreateAppointmentPayload>;
  createAppointmentReport?: Maybe<CreateAppointmentReportPayload>;
  createBookingWidget?: Maybe<CreateBookingWidgetPayload>;
  createBoostExtension?: Maybe<CreateBoostExtensionPayload>;
  createCalendarLabel?: Maybe<CreateCalendarLabelPayload>;
  createCustomBookingWidget?: Maybe<CreateCustomBookingWidgetPayload>;
  createCustomMessage?: Maybe<CreateCustomMessagePayload>;
  createCustomer?: Maybe<CreateCustomerPayload>;
  createCustomerFilterGroup?: Maybe<CreateCustomerFilterGroupPayload>;
  createCustomerTag?: Maybe<CreateCustomerTagPayload>;
  createCustomersExport?: Maybe<CreateCustomersExportPayload>;
  createDiscountCode?: Maybe<CreateDiscountCodePayload>;
  createDynamicPrice?: Maybe<CreateDynamicPricePayload>;
  createEmailTemplate?: Maybe<CreateEmailTemplatePayload>;
  createExport?: Maybe<CreateExportPayload>;
  createFiscalizationApp?: Maybe<CreateFiscalizationAppPayload>;
  createFormSubmission?: Maybe<CreateFormSubmissionPayload>;
  createFormTemplate?: Maybe<CreateFormTemplatePayload>;
  createFranchiseApp?: Maybe<CreateFranchiseAppPayload>;
  createGiftcard?: Maybe<CreateGiftcardPayload>;
  createGoogleReserveApp?: Maybe<CreateGoogleReserveAppPayload>;
  createIncomingGoogleCalendars?: Maybe<CreateIncomingGoogleCalendarsPayload>;
  createIplTreatment?: Maybe<CreateIplTreatmentPayload>;
  createIplTreatmentReport?: Maybe<CreateIplTreatmentReportPayload>;
  createLocation?: Maybe<CreateLocationPayload>;
  createLoyaltyTransaction?: Maybe<CreateLoyaltyTransactionPayload>;
  createMailchimpSegment?: Maybe<CreateMailchimpSegmentPayload>;
  createMessage?: Maybe<CreateMessagePayload>;
  createNote?: Maybe<CreateNotePayload>;
  createOnboardingRoster?: Maybe<CreateOnboardingRosterPayload>;
  createOrder?: Maybe<CreateOrderPayload>;
  createPayTerminalCheckoutSession?: Maybe<CreatePayTerminalCheckoutSessionPayload>;
  createPosTerminal?: Maybe<CreatePosTerminalPayload>;
  createProduct?: Maybe<CreateProductPayload>;
  createProductCategory?: Maybe<CreateProductCategoryPayload>;
  createProductChangeSchedule?: Maybe<CreateProductChangeSchedulePayload>;
  createResource?: Maybe<CreateResourcePayload>;
  createService?: Maybe<CreateServicePayload>;
  createServiceCategory?: Maybe<CreateServiceCategoryPayload>;
  createServiceGroup?: Maybe<CreateServiceGroupPayload>;
  createServiceOffering?: Maybe<CreateServiceOfferingPayload>;
  createServiceVariationGroup?: Maybe<CreateServiceVariationGroupPayload>;
  createSesIdentity?: Maybe<CreateSesIdentityPayload>;
  createSmsCheckoutSession?: Maybe<CreateSmsCheckoutSessionPayload>;
  createStockOrder?: Maybe<CreateStockOrderPayload>;
  createStripeCheckoutSession?: Maybe<CreateStripeCheckoutSessionPayload>;
  createStripeCurrentPlanQuote?: Maybe<CreateStripeCurrentPlanQuotePayload>;
  createStripeDisputeCheckoutSession?: Maybe<CreateStripeDisputeCheckoutSessionPayload>;
  createStripeQuote?: Maybe<CreateStripeQuotePayload>;
  createSubscription?: Maybe<CreateSubscriptionPayload>;
  createSupplier?: Maybe<CreateSupplierPayload>;
  createTerminalOrder?: Maybe<CreateTerminalOrderPayload>;
  createTradeShowTrial?: Maybe<CreateTradeShowTrialPayload>;
  createTransaction?: Maybe<CreateTransactionPayload>;
  createUser?: Maybe<CreateUserPayload>;
  createWaitingListEntry?: Maybe<CreateWaitingListEntryPayload>;
  createWelcomeServices?: Maybe<CreateWelcomeServicesPayload>;
  deactivateIncomingGoogleCalendar?: Maybe<DeactivateIncomingGoogleCalendarPayload>;
  deleteAbsence?: Maybe<DeleteAbsencePayload>;
  deleteApp?: Maybe<DeleteAppPayload>;
  deleteAppointment?: Maybe<DeleteAppointmentPayload>;
  deleteAppointmentReport?: Maybe<DeleteAppointmentReportPayload>;
  deleteCalendarLabel?: Maybe<DeleteCalendarLabelPayload>;
  deleteCompany?: Maybe<DeleteCompanyPayload>;
  deleteCustomer?: Maybe<DeleteCustomerPayload>;
  deleteCustomerFilterGroup?: Maybe<DeleteCustomerFilterGroupPayload>;
  deleteCustomerTag?: Maybe<DeleteCustomerTagPayload>;
  deleteCustomers?: Maybe<DeleteCustomersPayload>;
  deleteDiscountCode?: Maybe<DeleteDiscountCodePayload>;
  deleteDynamicPrice?: Maybe<DeleteDynamicPricePayload>;
  deleteEmailTemplate?: Maybe<DeleteEmailTemplatePayload>;
  deleteFeedback?: Maybe<DeleteFeedbackPayload>;
  deleteFormSubmission?: Maybe<DeleteFormSubmissionPayload>;
  deleteFormTemplate?: Maybe<DeleteFormTemplatePayload>;
  deleteGiftcard?: Maybe<DeleteGiftcardPayload>;
  deleteImportedProducts?: Maybe<DeleteImportedProductsPayload>;
  deleteIplTreatment?: Maybe<DeleteIplTreatmentPayload>;
  deleteIplTreatmentReport?: Maybe<DeleteIplTreatmentReportPayload>;
  deleteLocation?: Maybe<DeleteLocationPayload>;
  deleteLoyaltyTransaction?: Maybe<DeleteLoyaltyTransactionPayload>;
  deleteNote?: Maybe<DeleteNotePayload>;
  deleteOrder?: Maybe<DeleteOrderPayload>;
  deleteProduct?: Maybe<DeleteProductPayload>;
  deleteProductCategory?: Maybe<DeleteProductCategoryPayload>;
  deleteService?: Maybe<DeleteServicePayload>;
  deleteServiceCategory?: Maybe<DeleteServiceCategoryPayload>;
  deleteServiceGroup?: Maybe<DeleteServiceGroupPayload>;
  deleteServiceVariationGroup?: Maybe<DeleteServiceVariationGroupPayload>;
  deleteStockOrder?: Maybe<DeleteStockOrderPayload>;
  deleteSupplier?: Maybe<DeleteSupplierPayload>;
  deleteTransaction?: Maybe<DeleteTransactionPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteVatRate?: Maybe<DeleteVatRatePayload>;
  deleteWaitingListEntry?: Maybe<DeleteWaitingListEntryPayload>;
  dismissMollieErrors?: Maybe<DismissMollieErrorsPayload>;
  dismissVerificationReminder?: Maybe<DismissVerificationReminderPayload>;
  duplicateAppointment?: Maybe<DuplicateAppointmentPayload>;
  duplicateService?: Maybe<DuplicateServicePayload>;
  duplicateServiceGroup?: Maybe<DuplicateServiceGroupPayload>;
  duplicateServiceVariationGroup?: Maybe<DuplicateServiceVariationGroupPayload>;
  enableTreatwellMarketplace?: Maybe<EnableTreatwellMarketplacePayload>;
  executeImport?: Maybe<ExecuteImportPayload>;
  generateAdyenOnboardingLink?: Maybe<GenerateOnboardingLinkPayload>;
  generatePosPayment?: Maybe<GeneratePosPaymentPayload>;
  massiveAddCustomerTags?: Maybe<MassiveAddCustomerTagsPayload>;
  mergeCustomer?: Maybe<MergeCustomerPayload>;
  notInterested?: Maybe<NotInterestedPayload>;
  openCashup?: Maybe<OpenCashupPayload>;
  pauseSubscription?: Maybe<PauseSubscriptionPayload>;
  publishFeedback?: Maybe<PublishFeedbackPayload>;
  publishFeedbackBatch?: Maybe<PublishFeedbackBatchPayload>;
  reactivateSubscription?: Maybe<ReactivateSubscriptionPayload>;
  reactivateTrial?: Maybe<ReactivateTrialPayload>;
  rejectAppointment?: Maybe<RejectAppointmentPayload>;
  requestFeedbackPublication?: Maybe<RequestFeedbackPublicationPayload>;
  requestTreatwell?: Maybe<RequestTreatwellPayload>;
  rescheduleAppointment?: Maybe<RescheduleAppointmentPayload>;
  resendMessage?: Maybe<ResendMessagePayload>;
  resizeAppointment?: Maybe<ResizeAppointmentPayload>;
  restoreCustomer?: Maybe<RestoreCustomerPayload>;
  restoreCustomers?: Maybe<RestoreCustomersPayload>;
  restoreImportedProducts?: Maybe<RestoreImportedProductsPayload>;
  revertProductChangeSchedule?: Maybe<RevertProductChangeSchedulePayload>;
  saveAdyenOnboardingData?: Maybe<SaveOnboardingDataPayload>;
  saveAdyenSelectedTerminalModel?: Maybe<SaveSelectedTerminalModelPayload>;
  sendAppointmentCancellationEmail?: Maybe<SendAppointmentCancellationEmailPayload>;
  sendAppointmentConfirmationEmail?: Maybe<SendAppointmentConfirmationEmailPayload>;
  sendAppointmentMovedEmail?: Maybe<SendAppointmentMovedEmailPayload>;
  sendAppointmentNoShowEmail?: Maybe<SendAppointmentNoShowEmailPayload>;
  sendAppointmentsOverview?: Maybe<SendAppointmentsOverviewPayload>;
  sendAvailableTimeslotEmail?: Maybe<SendAvailableTimeslotEmailPayload>;
  sendAvailableTimeslotEmailAll?: Maybe<SendAvailableTimeslotEmailAllPayload>;
  sendFeedbackResponse?: Maybe<SendFeedbackResponsePayload>;
  sendMarketingEmail?: Maybe<SendMarketingEmailPayload>;
  sendTwoFactorSms?: Maybe<SendTwoFactorSmsPayload>;
  sortResources?: Maybe<SortResourcesPayload>;
  sortServiceCategories?: Maybe<SortServiceCategoriesPayload>;
  sortServiceVariationGroups?: Maybe<SortServiceVariationGroupsPayload>;
  sortServices?: Maybe<SortServicesPayload>;
  stripeCancelSubscription?: Maybe<StripeCancelSubscriptionPayload>;
  toggleMessagesPublishedFeedback?: Maybe<ToggleMessagesPublishedFeedbackPayload>;
  toggleRegisterDesign?: Maybe<ToggleRegisterDesignPayload>;
  treatwellIntegrationRequestAccept?: Maybe<IntegrationRequestAcceptPayload>;
  treatwellIntegrationRequestAcceptContract?: Maybe<IntegrationRequestAcceptContractPayload>;
  treatwellIntegrationRequestCreate?: Maybe<IntegrationRequestCreatePayload>;
  treatwellIntegrationRequestReject?: Maybe<IntegrationRequestRejectPayload>;
  treatwellIntegrationRequestUpdateBankingDetails?: Maybe<IntegrationRequestUpdateBankingDetailsPayload>;
  treatwellIntegrationRequestUpdateContract?: Maybe<IntegrationRequestUpdateContractPayload>;
  treatwellIntegrationRequestUpdateFee?: Maybe<IntegrationRequestUpdateFeePayload>;
  treatwellUpdateDirectDebit?: Maybe<UpdateDirectDebitPayload>;
  treatwellUpdateKycStatus?: Maybe<UpdateKycStatusPayload>;
  treatwellUpdatePilotSubscription?: Maybe<UpdateTreatwellPilotSubscriptionPayload>;
  treatwellUpdateTreatwellPilotSubscription?: Maybe<UpdateTreatwellPilotSubscriptionPayload>;
  treatwellUpsertChangeRequest?: Maybe<UpsertChangeRequestPayload>;
  unpauseSubscription?: Maybe<UnpauseSubscriptionPayload>;
  unpublishFeedback?: Maybe<UnpublishFeedbackPayload>;
  updateAbsence?: Maybe<UpdateAbsencePayload>;
  updateAdyenAppState?: Maybe<UpdateAdyenAppStatePayload>;
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  updateAppointmentReport?: Maybe<UpdateAppointmentReportPayload>;
  updateBillingInfo?: Maybe<UpdateBillingInfoPayload>;
  updateCalendarLabel?: Maybe<UpdateCalendarLabelPayload>;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  updateCompanyEmailTemplate?: Maybe<UpdateCompanyEmailTemplatePayload>;
  updateCompanySmsTemplate?: Maybe<UpdateCompanySmsTemplatePayload>;
  updateCustomer?: Maybe<UpdateCustomerPayload>;
  updateCustomerFilterGroup?: Maybe<UpdateCustomerFilterGroupPayload>;
  updateCustomerTag?: Maybe<UpdateCustomerTagPayload>;
  updateCustomerTags?: Maybe<UpdateCustomerTagsPayload>;
  updateDiscountCode?: Maybe<UpdateDiscountCodePayload>;
  updateDrawer?: Maybe<UpdateDrawerPayload>;
  updateDynamicPrice?: Maybe<UpdateDynamicPricePayload>;
  updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  updateFeedback?: Maybe<UpdateFeedbackPayload>;
  updateFeedbackResponse?: Maybe<UpdateFeedbackResponsePayload>;
  updateFormSubmission?: Maybe<UpdateFormSubmissionPayload>;
  updateFormTemplate?: Maybe<UpdateFormTemplatePayload>;
  updateFranchiseApp?: Maybe<UpdateFranchiseAppPayload>;
  updateGoogleCalendarApp?: Maybe<UpdateGoogleCalendarAppPayload>;
  updateImport?: Maybe<UpdateImportPayload>;
  updateIplTreatment?: Maybe<UpdateIplTreatmentPayload>;
  updateIplTreatmentReport?: Maybe<UpdateIplTreatmentReportPayload>;
  updateLastMinuteDiscounts?: Maybe<UpdateLastMinuteDiscountsPayload>;
  updateLastVisit?: Maybe<UpdateLastVisitPayload>;
  updateLocation?: Maybe<UpdateLocationPayload>;
  updateMailchimpApp?: Maybe<UpdateMailchimpAppPayload>;
  updateMicroSite?: Maybe<UpdateMicroSitePayload>;
  updateMollieApp?: Maybe<UpdateMollieAppPayload>;
  updateNote?: Maybe<UpdateNotePayload>;
  updateOnboardingTask?: Maybe<UpdateOnboardingTaskPayload>;
  updateOrder?: Maybe<UpdateOrderPayload>;
  updatePosTerminal?: Maybe<UpdatePosTerminalPayload>;
  updateProduct?: Maybe<UpdateProductPayload>;
  updateProductCategory?: Maybe<UpdateProductCategoryPayload>;
  updateResource?: Maybe<UpdateResourcePayload>;
  updateService?: Maybe<UpdateServicePayload>;
  updateServiceCategory?: Maybe<UpdateServiceCategoryPayload>;
  updateServiceGroup?: Maybe<UpdateServiceGroupPayload>;
  updateServiceVariationGroup?: Maybe<UpdateServiceVariationGroupPayload>;
  updateServices?: Maybe<UpdateServicesPayload>;
  updateStockOrder?: Maybe<UpdateStockOrderPayload>;
  updateStripeTaxId?: Maybe<UpdateStripeTaxIdPayload>;
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  updateSubscriptionCollectionMethod?: Maybe<UpdateSubscriptionCollectionMethodPayload>;
  updateSupplier?: Maybe<UpdateSupplierPayload>;
  updateTopServiceVariationGroups?: Maybe<UpdateTopServiceVariationGroupsPayload>;
  updateTreatwellIntent?: Maybe<UpdateTreatwellIntentPayload>;
  updateTreatwellRoster?: Maybe<UpdateTreatwellRosterPayload>;
  updateTreatwellVenue?: Maybe<UpdateTreatwellVenuePayload>;
  updateTwoFactorSettings?: Maybe<UpdateTwoFactorSettingsPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateVatRates?: Maybe<UpdateVatRatesPayload>;
  upgradeSubscription?: Maybe<UpgradeSubscriptionPayload>;
  uploadImage?: Maybe<UploadImagePayload>;
  uploadImport?: Maybe<UploadImportPayload>;
  upsertNpsFeedback?: Maybe<UpsertNpsFeedbackPayload>;
  validateImport?: Maybe<ValidateImportPayload>;
  verifyDomain?: Maybe<VerifyDomainPayload>;
};

export type MutationActivateAppointmentArgs = {
  input: ActivateAppointmentInput;
};

export type MutationActivateIncomingGoogleCalendarArgs = {
  input: ActivateIncomingGoogleCalendarInput;
};

export type MutationActivateTrialArgs = {
  input: ActivateTrialInput;
};

export type MutationAssignCustomerTagsArgs = {
  input: AssignCustomerTagsInput;
};

export type MutationAssignPosTerminalArgs = {
  input: AssignPosTerminalInput;
};

export type MutationBecomeArgs = {
  input: BecomeInput;
};

export type MutationBecomeFranchiseArgs = {
  input: BecomeFranchiseInput;
};

export type MutationCancelProductChangeScheduleArgs = {
  input: CancelProductChangeScheduleInput;
};

export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};

export type MutationChangeStripeSubscriptionArgs = {
  input: ChangeStripeSubscriptionInput;
};

export type MutationCloseCashupArgs = {
  input: CloseCashupInput;
};

export type MutationCompleteTerminalCheckoutArgs = {
  input: CompleteTerminalCheckoutInput;
};

export type MutationConfirmAppointmentArgs = {
  input: ConfirmAppointmentInput;
};

export type MutationCreateAbsenceArgs = {
  input: CreateAbsenceInput;
};

export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};

export type MutationCreateAppointmentReportArgs = {
  input: CreateAppointmentReportInput;
};

export type MutationCreateBookingWidgetArgs = {
  input: CreateBookingWidgetInput;
};

export type MutationCreateBoostExtensionArgs = {
  input: CreateBoostExtensionInput;
};

export type MutationCreateCalendarLabelArgs = {
  input: CreateCalendarLabelInput;
};

export type MutationCreateCustomBookingWidgetArgs = {
  input: CreateCustomBookingWidgetInput;
};

export type MutationCreateCustomMessageArgs = {
  input: CreateCustomMessageInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateCustomerFilterGroupArgs = {
  input: CreateCustomerFilterGroupInput;
};

export type MutationCreateCustomerTagArgs = {
  input: CreateCustomerTagInput;
};

export type MutationCreateCustomersExportArgs = {
  input: CreateCustomersExportInput;
};

export type MutationCreateDiscountCodeArgs = {
  input: CreateDiscountCodeInput;
};

export type MutationCreateDynamicPriceArgs = {
  input: CreateDynamicPriceInput;
};

export type MutationCreateEmailTemplateArgs = {
  input: CreateEmailTemplateInput;
};

export type MutationCreateExportArgs = {
  input: CreateExportInput;
};

export type MutationCreateFiscalizationAppArgs = {
  input: CreateFiscalizationAppInput;
};

export type MutationCreateFormSubmissionArgs = {
  input: CreateFormSubmissionInput;
};

export type MutationCreateFormTemplateArgs = {
  input: CreateFormTemplateInput;
};

export type MutationCreateFranchiseAppArgs = {
  input: CreateFranchiseAppInput;
};

export type MutationCreateGiftcardArgs = {
  input: CreateGiftcardInput;
};

export type MutationCreateGoogleReserveAppArgs = {
  input: CreateGoogleReserveAppInput;
};

export type MutationCreateIncomingGoogleCalendarsArgs = {
  input: CreateIncomingGoogleCalendarsInput;
};

export type MutationCreateIplTreatmentArgs = {
  input: CreateIplTreatmentInput;
};

export type MutationCreateIplTreatmentReportArgs = {
  input: CreateIplTreatmentReportInput;
};

export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};

export type MutationCreateLoyaltyTransactionArgs = {
  input: CreateLoyaltyTransactionInput;
};

export type MutationCreateMailchimpSegmentArgs = {
  input: CreateMailchimpSegmentInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

export type MutationCreateOnboardingRosterArgs = {
  input: CreateOnboardingRosterInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreatePayTerminalCheckoutSessionArgs = {
  input: CreatePayTerminalCheckoutSessionInput;
};

export type MutationCreatePosTerminalArgs = {
  input: CreatePosTerminalInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationCreateProductCategoryArgs = {
  input: CreateProductCategoryInput;
};

export type MutationCreateProductChangeScheduleArgs = {
  input: CreateProductChangeScheduleInput;
};

export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

export type MutationCreateServiceArgs = {
  input: CreateServiceInput;
};

export type MutationCreateServiceCategoryArgs = {
  input: CreateServiceCategoryInput;
};

export type MutationCreateServiceGroupArgs = {
  input: CreateServiceGroupInput;
};

export type MutationCreateServiceOfferingArgs = {
  input: CreateServiceOfferingInput;
};

export type MutationCreateServiceVariationGroupArgs = {
  input: CreateServiceVariationGroupInput;
};

export type MutationCreateSesIdentityArgs = {
  input: CreateSesIdentityInput;
};

export type MutationCreateSmsCheckoutSessionArgs = {
  input: CreateSmsCheckoutSessionInput;
};

export type MutationCreateStockOrderArgs = {
  input: CreateStockOrderInput;
};

export type MutationCreateStripeCheckoutSessionArgs = {
  input: CreateStripeCheckoutSessionInput;
};

export type MutationCreateStripeCurrentPlanQuoteArgs = {
  input: CreateStripeCurrentPlanQuoteInput;
};

export type MutationCreateStripeDisputeCheckoutSessionArgs = {
  input: CreateStripeDisputeCheckoutSessionInput;
};

export type MutationCreateStripeQuoteArgs = {
  input: CreateStripeQuoteInput;
};

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};

export type MutationCreateTerminalOrderArgs = {
  input: CreateTerminalOrderInput;
};

export type MutationCreateTradeShowTrialArgs = {
  input: CreateTradeShowTrialInput;
};

export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateWaitingListEntryArgs = {
  input: CreateWaitingListEntryInput;
};

export type MutationCreateWelcomeServicesArgs = {
  input: CreateWelcomeServicesInput;
};

export type MutationDeactivateIncomingGoogleCalendarArgs = {
  input: DeactivateIncomingGoogleCalendarInput;
};

export type MutationDeleteAbsenceArgs = {
  input: DeleteAbsenceInput;
};

export type MutationDeleteAppArgs = {
  input: DeleteAppInput;
};

export type MutationDeleteAppointmentArgs = {
  input: DeleteAppointmentInput;
};

export type MutationDeleteAppointmentReportArgs = {
  input: DeleteAppointmentReportInput;
};

export type MutationDeleteCalendarLabelArgs = {
  input: DeleteCalendarLabelInput;
};

export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};

export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};

export type MutationDeleteCustomerFilterGroupArgs = {
  input: DeleteCustomerFilterGroupInput;
};

export type MutationDeleteCustomerTagArgs = {
  input: DeleteCustomerTagInput;
};

export type MutationDeleteCustomersArgs = {
  input: DeleteCustomersInput;
};

export type MutationDeleteDiscountCodeArgs = {
  input: DeleteDiscountCodeInput;
};

export type MutationDeleteDynamicPriceArgs = {
  input: DeleteDynamicPriceInput;
};

export type MutationDeleteEmailTemplateArgs = {
  input: DeleteEmailTemplateInput;
};

export type MutationDeleteFeedbackArgs = {
  input: DeleteFeedbackInput;
};

export type MutationDeleteFormSubmissionArgs = {
  input: DeleteFormSubmissionInput;
};

export type MutationDeleteFormTemplateArgs = {
  input: DeleteFormTemplateInput;
};

export type MutationDeleteGiftcardArgs = {
  input: DeleteGiftcardInput;
};

export type MutationDeleteImportedProductsArgs = {
  input: DeleteImportedProductsInput;
};

export type MutationDeleteIplTreatmentArgs = {
  input: DeleteIplTreatmentInput;
};

export type MutationDeleteIplTreatmentReportArgs = {
  input: DeleteIplTreatmentReportInput;
};

export type MutationDeleteLocationArgs = {
  input: DeleteLocationInput;
};

export type MutationDeleteLoyaltyTransactionArgs = {
  input: DeleteLoyaltyTransactionInput;
};

export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};

export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};

export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};

export type MutationDeleteProductCategoryArgs = {
  input: DeleteProductCategoryInput;
};

export type MutationDeleteServiceArgs = {
  input: DeleteServiceInput;
};

export type MutationDeleteServiceCategoryArgs = {
  input: DeleteServiceCategoryInput;
};

export type MutationDeleteServiceGroupArgs = {
  input: DeleteServiceGroupInput;
};

export type MutationDeleteServiceVariationGroupArgs = {
  input: DeleteServiceVariationGroupInput;
};

export type MutationDeleteStockOrderArgs = {
  input: DeleteStockOrderInput;
};

export type MutationDeleteSupplierArgs = {
  input: DeleteSupplierInput;
};

export type MutationDeleteTransactionArgs = {
  input: DeleteTransactionInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationDeleteVatRateArgs = {
  input: DeleteVatRateInput;
};

export type MutationDeleteWaitingListEntryArgs = {
  input: DeleteWaitingListEntryInput;
};

export type MutationDismissMollieErrorsArgs = {
  input: DismissMollieErrorsInput;
};

export type MutationDismissVerificationReminderArgs = {
  input: DismissVerificationReminderInput;
};

export type MutationDuplicateAppointmentArgs = {
  input: DuplicateAppointmentInput;
};

export type MutationDuplicateServiceArgs = {
  input: DuplicateServiceInput;
};

export type MutationDuplicateServiceGroupArgs = {
  input: DuplicateServiceGroupInput;
};

export type MutationDuplicateServiceVariationGroupArgs = {
  input: DuplicateServiceVariationGroupInput;
};

export type MutationEnableTreatwellMarketplaceArgs = {
  input: EnableTreatwellMarketplaceInput;
};

export type MutationExecuteImportArgs = {
  input: ExecuteImportInput;
};

export type MutationGenerateAdyenOnboardingLinkArgs = {
  input: GenerateOnboardingLinkInput;
};

export type MutationGeneratePosPaymentArgs = {
  input: GeneratePosPaymentInput;
};

export type MutationMassiveAddCustomerTagsArgs = {
  input: MassiveAddCustomerTagsInput;
};

export type MutationMergeCustomerArgs = {
  input: MergeCustomerInput;
};

export type MutationNotInterestedArgs = {
  input: NotInterestedInput;
};

export type MutationOpenCashupArgs = {
  input: OpenCashupInput;
};

export type MutationPauseSubscriptionArgs = {
  input: PauseSubscriptionInput;
};

export type MutationPublishFeedbackArgs = {
  input: PublishFeedbackInput;
};

export type MutationPublishFeedbackBatchArgs = {
  input: PublishFeedbackBatchInput;
};

export type MutationReactivateSubscriptionArgs = {
  input: ReactivateSubscriptionInput;
};

export type MutationReactivateTrialArgs = {
  input: ReactivateTrialInput;
};

export type MutationRejectAppointmentArgs = {
  input: RejectAppointmentInput;
};

export type MutationRequestFeedbackPublicationArgs = {
  input: RequestFeedbackPublicationInput;
};

export type MutationRequestTreatwellArgs = {
  input: RequestTreatwellInput;
};

export type MutationRescheduleAppointmentArgs = {
  input: RescheduleAppointmentInput;
};

export type MutationResendMessageArgs = {
  input: ResendMessageInput;
};

export type MutationResizeAppointmentArgs = {
  input: ResizeAppointmentInput;
};

export type MutationRestoreCustomerArgs = {
  input: RestoreCustomerInput;
};

export type MutationRestoreCustomersArgs = {
  input: RestoreCustomersInput;
};

export type MutationRestoreImportedProductsArgs = {
  input: RestoreImportedProductsInput;
};

export type MutationRevertProductChangeScheduleArgs = {
  input: RevertProductChangeScheduleInput;
};

export type MutationSaveAdyenOnboardingDataArgs = {
  input: SaveOnboardingDataInput;
};

export type MutationSaveAdyenSelectedTerminalModelArgs = {
  input: SaveSelectedTerminalModelInput;
};

export type MutationSendAppointmentCancellationEmailArgs = {
  input: SendAppointmentCancellationEmailInput;
};

export type MutationSendAppointmentConfirmationEmailArgs = {
  input: SendAppointmentConfirmationEmailInput;
};

export type MutationSendAppointmentMovedEmailArgs = {
  input: SendAppointmentMovedEmailInput;
};

export type MutationSendAppointmentNoShowEmailArgs = {
  input: SendAppointmentNoShowEmailInput;
};

export type MutationSendAppointmentsOverviewArgs = {
  input: SendAppointmentsOverviewInput;
};

export type MutationSendAvailableTimeslotEmailArgs = {
  input: SendAvailableTimeslotEmailInput;
};

export type MutationSendAvailableTimeslotEmailAllArgs = {
  input: SendAvailableTimeslotEmailAllInput;
};

export type MutationSendFeedbackResponseArgs = {
  input: SendFeedbackResponseInput;
};

export type MutationSendMarketingEmailArgs = {
  input: SendMarketingEmailInput;
};

export type MutationSendTwoFactorSmsArgs = {
  input: SendTwoFactorSmsInput;
};

export type MutationSortResourcesArgs = {
  input: SortResourcesInput;
};

export type MutationSortServiceCategoriesArgs = {
  input: SortServiceCategoriesInput;
};

export type MutationSortServiceVariationGroupsArgs = {
  input: SortServiceVariationGroupsInput;
};

export type MutationSortServicesArgs = {
  input: SortServicesInput;
};

export type MutationStripeCancelSubscriptionArgs = {
  input: StripeCancelSubscriptionInput;
};

export type MutationToggleMessagesPublishedFeedbackArgs = {
  input: ToggleMessagesPublishedFeedbackInput;
};

export type MutationToggleRegisterDesignArgs = {
  input: ToggleRegisterDesignInput;
};

export type MutationTreatwellIntegrationRequestAcceptArgs = {
  input: IntegrationRequestAcceptInput;
};

export type MutationTreatwellIntegrationRequestAcceptContractArgs = {
  input: IntegrationRequestAcceptContractInput;
};

export type MutationTreatwellIntegrationRequestCreateArgs = {
  input: IntegrationRequestCreateInput;
};

export type MutationTreatwellIntegrationRequestRejectArgs = {
  input: IntegrationRequestRejectInput;
};

export type MutationTreatwellIntegrationRequestUpdateBankingDetailsArgs = {
  input: IntegrationRequestUpdateBankingDetailsInput;
};

export type MutationTreatwellIntegrationRequestUpdateContractArgs = {
  input: IntegrationRequestUpdateContractInput;
};

export type MutationTreatwellIntegrationRequestUpdateFeeArgs = {
  input: IntegrationRequestUpdateFeeInput;
};

export type MutationTreatwellUpdateDirectDebitArgs = {
  input: UpdateDirectDebitInput;
};

export type MutationTreatwellUpdateKycStatusArgs = {
  input: UpdateKycStatusInput;
};

export type MutationTreatwellUpdatePilotSubscriptionArgs = {
  input: UpdateTreatwellPilotSubscriptionInput;
};

export type MutationTreatwellUpdateTreatwellPilotSubscriptionArgs = {
  input: UpdateTreatwellPilotSubscriptionInput;
};

export type MutationTreatwellUpsertChangeRequestArgs = {
  input: UpsertChangeRequestInput;
};

export type MutationUnpauseSubscriptionArgs = {
  input: UnpauseSubscriptionInput;
};

export type MutationUnpublishFeedbackArgs = {
  input: UnpublishFeedbackInput;
};

export type MutationUpdateAbsenceArgs = {
  input: UpdateAbsenceInput;
};

export type MutationUpdateAdyenAppStateArgs = {
  input: UpdateAdyenAppStateInput;
};

export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};

export type MutationUpdateAppointmentReportArgs = {
  input: UpdateAppointmentReportInput;
};

export type MutationUpdateBillingInfoArgs = {
  input: UpdateBillingInfoInput;
};

export type MutationUpdateCalendarLabelArgs = {
  input: UpdateCalendarLabelInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyEmailTemplateArgs = {
  input: UpdateCompanyEmailTemplateInput;
};

export type MutationUpdateCompanySmsTemplateArgs = {
  input: UpdateCompanySmsTemplateInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateCustomerFilterGroupArgs = {
  input: UpdateCustomerFilterGroupInput;
};

export type MutationUpdateCustomerTagArgs = {
  input: UpdateCustomerTagInput;
};

export type MutationUpdateCustomerTagsArgs = {
  input: UpdateCustomerTagsInput;
};

export type MutationUpdateDiscountCodeArgs = {
  input: UpdateDiscountCodeInput;
};

export type MutationUpdateDrawerArgs = {
  input: UpdateDrawerInput;
};

export type MutationUpdateDynamicPriceArgs = {
  input: UpdateDynamicPriceInput;
};

export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};

export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};

export type MutationUpdateFeedbackResponseArgs = {
  input: UpdateFeedbackResponseInput;
};

export type MutationUpdateFormSubmissionArgs = {
  input: UpdateFormSubmissionInput;
};

export type MutationUpdateFormTemplateArgs = {
  input: UpdateFormTemplateInput;
};

export type MutationUpdateFranchiseAppArgs = {
  input: UpdateFranchiseAppInput;
};

export type MutationUpdateGoogleCalendarAppArgs = {
  input: UpdateGoogleCalendarAppInput;
};

export type MutationUpdateImportArgs = {
  input: UpdateImportInput;
};

export type MutationUpdateIplTreatmentArgs = {
  input: UpdateIplTreatmentInput;
};

export type MutationUpdateIplTreatmentReportArgs = {
  input: UpdateIplTreatmentReportInput;
};

export type MutationUpdateLastMinuteDiscountsArgs = {
  input: UpdateLastMinuteDiscountsInput;
};

export type MutationUpdateLastVisitArgs = {
  input: UpdateLastVisitInput;
};

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};

export type MutationUpdateMailchimpAppArgs = {
  input: UpdateMailchimpAppInput;
};

export type MutationUpdateMicroSiteArgs = {
  input: UpdateMicroSiteInput;
};

export type MutationUpdateMollieAppArgs = {
  input: UpdateMollieAppInput;
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationUpdateOnboardingTaskArgs = {
  input: UpdateOnboardingTaskInput;
};

export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

export type MutationUpdatePosTerminalArgs = {
  input: UpdatePosTerminalInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationUpdateProductCategoryArgs = {
  input: UpdateProductCategoryInput;
};

export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

export type MutationUpdateServiceArgs = {
  input: UpdateServiceInput;
};

export type MutationUpdateServiceCategoryArgs = {
  input: UpdateServiceCategoryInput;
};

export type MutationUpdateServiceGroupArgs = {
  input: UpdateServiceGroupInput;
};

export type MutationUpdateServiceVariationGroupArgs = {
  input: UpdateServiceVariationGroupInput;
};

export type MutationUpdateServicesArgs = {
  input: UpdateServicesInput;
};

export type MutationUpdateStockOrderArgs = {
  input: UpdateStockOrderInput;
};

export type MutationUpdateStripeTaxIdArgs = {
  input: UpdateStripeTaxIdInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationUpdateSubscriptionCollectionMethodArgs = {
  input: UpdateSubscriptionCollectionMethodInput;
};

export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};

export type MutationUpdateTopServiceVariationGroupsArgs = {
  input: UpdateTopServiceVariationGroupsInput;
};

export type MutationUpdateTreatwellIntentArgs = {
  input: UpdateTreatwellIntentInput;
};

export type MutationUpdateTreatwellRosterArgs = {
  input: UpdateTreatwellRosterInput;
};

export type MutationUpdateTreatwellVenueArgs = {
  input: UpdateTreatwellVenueInput;
};

export type MutationUpdateTwoFactorSettingsArgs = {
  input: UpdateTwoFactorSettingsInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateVatRatesArgs = {
  input: UpdateVatRatesInput;
};

export type MutationUpgradeSubscriptionArgs = {
  input: UpgradeSubscriptionInput;
};

export type MutationUploadImageArgs = {
  input: UploadImageInput;
};

export type MutationUploadImportArgs = {
  input: UploadImportInput;
};

export type MutationUpsertNpsFeedbackArgs = {
  input: UpsertNpsFeedbackInput;
};

export type MutationValidateImportArgs = {
  input: ValidateImportInput;
};

export type MutationVerifyDomainArgs = {
  input: VerifyDomainInput;
};

/** Autogenerated input type of NotInterested */
export type NotInterestedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of NotInterested. */
export type NotInterestedPayload = {
  __typename?: 'NotInterestedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type Note = {
  __typename?: 'Note';
  completedAt?: Maybe<Scalars['ISO8601Date']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['Int'];
  resource?: Maybe<Resource>;
  resourceId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
};

export type NotificationAttributes = {
  dayReports?: InputMaybe<Scalars['Boolean']>;
  updates?: InputMaybe<Scalars['Boolean']>;
};

export type NpsFeedback = {
  __typename?: 'NpsFeedback';
  id: Scalars['ID'];
};

export type OnboardingData = {
  __typename?: 'OnboardingData';
  industryCodes: Array<IndustryCode>;
  legalEntityTypes: Array<LegalEntity>;
};

export type OnboardingLink = {
  __typename?: 'OnboardingLink';
  expiresAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type OnboardingTask = {
  __typename?: 'OnboardingTask';
  claimedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  task: Scalars['String'];
};

export enum OnboardingTaskEnum {
  CreateAppointment = 'CREATE_APPOINTMENT',
  CreateDiscountCode = 'CREATE_DISCOUNT_CODE',
  ImportCustomers = 'IMPORT_CUSTOMERS',
  SetupCompanyDetails = 'SETUP_COMPANY_DETAILS',
  SetupNewsletter = 'SETUP_NEWSLETTER',
  SetupRebookReminder = 'SETUP_REBOOK_REMINDER',
  SetupRoster = 'SETUP_ROSTER',
  SetupWaitingList = 'SETUP_WAITING_LIST',
  TryBookingWidget = 'TRY_BOOKING_WIDGET'
}

export enum OnboardingTaskStatusEnum {
  Claim = 'CLAIM',
  Complete = 'COMPLETE'
}

export type OnboardingTaskSummary = {
  __typename?: 'OnboardingTaskSummary';
  all?: Maybe<Array<OnboardingTask>>;
  claimed?: Maybe<Array<OnboardingTask>>;
  completed?: Maybe<Array<OnboardingTask>>;
  pending?: Maybe<Array<OnboardingTask>>;
  taskType?: Maybe<Scalars['String']>;
  uncompletedCount?: Maybe<Scalars['Int']>;
};

export enum OnlinePayment {
  Disabled = 'DISABLED',
  Optional = 'OPTIONAL',
  Percentage = 'PERCENTAGE',
  Required = 'REQUIRED'
}

/** Autogenerated input type of OpenCashup */
export type OpenCashupInput = {
  cashFloatAmount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  locationId: Scalars['ID'];
};

/** Autogenerated return type of OpenCashup. */
export type OpenCashupPayload = {
  __typename?: 'OpenCashupPayload';
  cashup: Cashup;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  amountOutstanding?: Maybe<Scalars['Int']>;
  appointments?: Maybe<Array<Appointment>>;
  cashupId?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  credit: Scalars['Boolean'];
  customer?: Maybe<Customer>;
  draft: Scalars['Boolean'];
  fiscalizationFinished: Scalars['Boolean'];
  id: Scalars['ID'];
  invoiceEmailSubjectTemplate: Scalars['String'];
  invoicedAt?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<OrderItem>>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Int']>;
  medical: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  permissions: OrderPermissions;
  state?: Maybe<OrderState>;
  timelineItems?: Maybe<Array<TimelineItem>>;
  total: Scalars['Int'];
  transactions?: Maybe<Array<Transaction>>;
  vatSummary?: Maybe<Array<VatSummary>>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  appointmentPart?: Maybe<AppointmentPart>;
  description?: Maybe<Scalars['String']>;
  discountCode?: Maybe<DiscountCode>;
  employee?: Maybe<Resource>;
  employeeId?: Maybe<Scalars['Int']>;
  giftcard?: Maybe<Giftcard>;
  id: Scalars['ID'];
  loyaltyPointsAmount?: Maybe<Scalars['Int']>;
  medical: Scalars['Boolean'];
  order?: Maybe<Order>;
  originalPrice?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  soldPrepaidCard?: Maybe<PrepaidCard>;
  transactionCost?: Maybe<Scalars['Boolean']>;
  usedPrepaidCardId?: Maybe<Scalars['ID']>;
};

export type OrderItemAttributes = {
  appointmentPartId?: InputMaybe<Scalars['Int']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  discountCodeId?: InputMaybe<Scalars['ID']>;
  employeeId?: InputMaybe<Scalars['Int']>;
  giftcard?: InputMaybe<GiftcardAttributes>;
  giftcardId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  loyaltyPointsAmount?: InputMaybe<Scalars['Int']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  originalPrice?: InputMaybe<Scalars['Int']>;
  prepaidCard?: InputMaybe<PrepaidCardAttributes>;
  price?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  soldPrepaidCardId?: InputMaybe<Scalars['ID']>;
  usedPrepaidCardId?: InputMaybe<Scalars['Int']>;
};

export type OrderPermissions = {
  __typename?: 'OrderPermissions';
  credit: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  edit: Scalars['Boolean'];
  editOrderItems: Scalars['Boolean'];
};

export enum OrderState {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Outstanding = 'OUTSTANDING',
  Paid = 'PAID'
}

export enum Outline {
  None = 'NONE',
  Shadow = 'SHADOW'
}

export type PaginationAttributes = {
  currentPage?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export enum PartsOfDay {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING'
}

/** Autogenerated input type of PauseSubscription */
export type PauseSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
};

/** Autogenerated return type of PauseSubscription. */
export type PauseSubscriptionPayload = {
  __typename?: 'PauseSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  errors?: Maybe<Array<Error>>;
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum PaymentGatewayStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  MollieError = 'MOLLIE_ERROR'
}

export enum PaymentType {
  Bank = 'BANK',
  Cash = 'CASH',
  Coupon = 'COUPON',
  Creditcard = 'CREDITCARD',
  Giftcard = 'GIFTCARD',
  Online = 'ONLINE',
  Other = 'OTHER',
  Pin = 'PIN',
  Pos = 'POS',
  PrepaidCard = 'PREPAID_CARD',
  TreatwellOnlinePayment = 'TREATWELL_ONLINE_PAYMENT'
}

export type Plan = {
  __typename?: 'Plan';
  chargeableEmployeeCount: Scalars['Int'];
  chargeableEquipmentCount: Scalars['Int'];
  chargeableLocationCount: Scalars['Int'];
  chargeableResourceCount: Scalars['Int'];
  chargeableRoomCount: Scalars['Int'];
  current: Scalars['Boolean'];
  features: Scalars['JSON'];
  /** @deprecated Previous typo, use pricing instead */
  pricing: PlanPricing;
  pricings: PlanPricing;
  type: Scalars['String'];
};

export type PlanPricing = {
  __typename?: 'PlanPricing';
  basePrice: Scalars['Int'];
  currency: Scalars['String'];
  locationPrice: Scalars['Int'];
  referralReward: Scalars['Int'];
  resourcePrice: Scalars['Int'];
};

export type PortalSession = {
  __typename?: 'PortalSession';
  url?: Maybe<Scalars['String']>;
};

export type PosPayment = {
  __typename?: 'PosPayment';
  id: Scalars['ID'];
  transactionFee: Scalars['Int'];
};

export enum PosPaymentRefusalReason {
  BlockCard = 'BLOCK_CARD',
  CardExpired = 'CARD_EXPIRED',
  ConverterErrorRequest = 'CONVERTER_ERROR_REQUEST',
  Declined = 'DECLINED',
  InvalidPin = 'INVALID_PIN',
  NotEnoughBalance = 'NOT_ENOUGH_BALANCE',
  Other = 'OTHER',
  PinTriesExceeded = 'PIN_TRIES_EXCEEDED'
}

export type PosTerminal = {
  __typename?: 'PosTerminal';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  model: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status: PosTerminalStatus;
  terminalUid: Scalars['ID'];
};

/** Autogenerated return type of PosTerminalPayment. */
export type PosTerminalPaymentPayload = {
  __typename?: 'PosTerminalPaymentPayload';
  order?: Maybe<Order>;
  refusalReason?: Maybe<PosPaymentRefusalReason>;
  status: Scalars['String'];
};

export enum PosTerminalStatus {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED'
}

export enum Position {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type PrepaidCard = {
  __typename?: 'PrepaidCard';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  description: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Int'];
  pricePerUse: Scalars['Int'];
  quantity: Scalars['Int'];
  remaining: Scalars['Int'];
  soldInOrder?: Maybe<Order>;
  status: PrepaidCardStatus;
  used: Scalars['Int'];
  usedInOrders?: Maybe<Array<Order>>;
  vatRate?: Maybe<VatRate>;
};

export type PrepaidCardAttributes = {
  customerId?: InputMaybe<Scalars['ID']>;
  description: Scalars['String'];
  price: Scalars['Int'];
  vatRateId?: InputMaybe<Scalars['ID']>;
  visits: Scalars['Int'];
};

export enum PrepaidCardFilterScope {
  Empty = 'EMPTY',
  Usable = 'USABLE',
  Valid = 'VALID'
}

export enum PrepaidCardStatus {
  Active = 'ACTIVE',
  Credited = 'CREDITED',
  Empty = 'EMPTY',
  Outstanding = 'OUTSTANDING',
  Valid = 'VALID',
  Voided = 'VOIDED'
}

export enum PreventGaps {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  EnabledWithClosing = 'ENABLED_WITH_CLOSING'
}

export type PriceAdjustment = {
  __typename?: 'PriceAdjustment';
  adjustmentType: AdjustmentType;
  amount: Scalars['Int'];
  id: Scalars['ID'];
};

export type Product = RegisterSearchableItemInterface & {
  __typename?: 'Product';
  category?: Maybe<ProductCategory>;
  costPrice?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  medical?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  outOfStock: Scalars['Boolean'];
  partNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  stockAmount: Scalars['Int'];
  stockEnabled?: Maybe<Scalars['Boolean']>;
  stockMaximum: Scalars['Int'];
  stockMinimum: Scalars['Int'];
  stockValue: Scalars['BigInt'];
  supplier?: Maybe<Supplier>;
  type: RegisterSearchResultEnum;
  vatRate?: Maybe<VatRate>;
};

export type ProductCalculations = {
  __typename?: 'ProductCalculations';
  totalProducts: Scalars['Int'];
  totalValue: Scalars['BigInt'];
};

export enum ProductCategoriesFilterEnum {
  /** Filter to return only the prodcut categories which have at least one product attached to them. */
  HavingProducts = 'HAVING_PRODUCTS'
}

export type ProductCategory = {
  __typename?: 'ProductCategory';
  amountProducts: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ProductChangeAttributes = {
  newCostPrice: Scalars['Int'];
  newPrice: Scalars['Int'];
  originalCostPrice: Scalars['Int'];
  originalPrice: Scalars['Int'];
  productId: Scalars['Int'];
};

export type ProductChangeSchedule = {
  __typename?: 'ProductChangeSchedule';
  changeAppliedAt?: Maybe<Scalars['DateTime']>;
  changeRevertedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated input type of PublishFeedbackBatch */
export type PublishFeedbackBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataScope?: InputMaybe<DataScope>;
  fromDate?: InputMaybe<Scalars['ISO8601Date']>;
  locationId: Scalars['ID'];
  publishToTreatwell?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of PublishFeedbackBatch. */
export type PublishFeedbackBatchPayload = {
  __typename?: 'PublishFeedbackBatchPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of PublishFeedback */
export type PublishFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of PublishFeedback. */
export type PublishFeedbackPayload = {
  __typename?: 'PublishFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  feedback: Feedback;
};

export type Query = {
  __typename?: 'Query';
  absence: Absence;
  absenceInsights: Array<AbsenceInsight>;
  absences: Array<Absence>;
  adyenAppState?: Maybe<AdyenAppState>;
  adyenDashboardData?: Maybe<DashboardData>;
  adyenOnboardingData: OnboardingData;
  adyenVerificationErrors: Array<VerificationError>;
  appointment: Appointment;
  appointmentInsights: AppointmentInsight;
  appointmentReport: AppointmentReport;
  appointmentReports: Array<AppointmentReport>;
  appointments: Array<Appointment>;
  apps: Array<App>;
  availability: Availability;
  bookingRequests: Array<Appointment>;
  businessHours: Array<BusinessHours>;
  calendarAbsences: Array<CalendarAbsence>;
  calendarAppointments: Array<CalendarAppointment>;
  calendarDynamicPrices: Array<CalendarDynamicPrice>;
  calendarLabels: Array<CalendarLabel>;
  calendarNotes: Array<CalendarNote>;
  calendarWaitingLists: Array<CalendarWaitingList>;
  cashup?: Maybe<Cashup>;
  cashups: Array<Cashup>;
  changeLogs: Array<ChangeLog>;
  company: Company;
  companyApp?: Maybe<CompanyApp>;
  companyEmailTemplate?: Maybe<CompanyEmailTemplate>;
  companyEmailTemplates: Array<CompanyEmailTemplate>;
  companySmsTemplate?: Maybe<CompanySmsTemplate>;
  companySmsTemplates: Array<CompanySmsTemplate>;
  companyStats?: Maybe<DashboardCompanyStats>;
  companyUsers: Array<User>;
  conflictingTimes?: Maybe<Array<Scalars['DateTime']>>;
  contractTemplate: ContractTemplate;
  currentCompany?: Maybe<Company>;
  currentUser?: Maybe<User>;
  customer: Customer;
  customerFilterGroup: CustomerFilterGroup;
  customerFilterGroups: Array<CustomerFilterGroup>;
  customerOrderHistoryItems: Array<CustomerOrderHistoryItem>;
  customerSummary: CustomerSummary;
  customerTags: CustomerTags;
  customers: Array<Customer>;
  customersOverview: CustomersOverview;
  dailyReport: DailyReport;
  dashboard: Dashboard;
  discountCode: DiscountCode;
  discountCodeUsage: Array<DiscountCodeUsage>;
  discountCodes: Array<DiscountCode>;
  drawer: Drawer;
  durationInsights: Array<DurationInsight>;
  dynamicPrice: DynamicPrice;
  emailDomain?: Maybe<EmailDomain>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplates?: Maybe<Array<EmailTemplate>>;
  employeeReport: EmployeeReport;
  events: Array<EventUnion>;
  exports: Export;
  extensionPrice: Extension;
  feedback: Feedback;
  feedbackAggregate: FeedbackAggregation;
  feedbackCount: FeedbackAggregation;
  feedbackWidget: FeedbackWidget;
  feedbacks: Array<Feedback>;
  fibers: Array<EventFiber>;
  filteredCustomersCount: Scalars['Int'];
  formSubmission: FormSubmission;
  formSubmissions: Array<FormSubmission>;
  formTemplate: FormTemplate;
  formTemplates: Array<FormTemplate>;
  franchise: Franchise;
  franchiseCompanies: Array<Company>;
  giftcard?: Maybe<Giftcard>;
  giftcards: Array<Giftcard>;
  googleCalendar: GoogleCalendar;
  heatMapInsights: Array<HeatMapInsight>;
  htmlToPlainText: HtmlToPlainText;
  import?: Maybe<Import>;
  imports?: Maybe<Array<Import>>;
  incomingGoogleCalendars?: Maybe<Array<IncomingGoogleCalendar>>;
  invoices: Array<Invoice>;
  iplTreatmentReports: Array<IplTreatmentReport>;
  iplTreatments: Array<IplTreatment>;
  lastMinuteDiscounts: LastMinuteDiscounts;
  localeOptions?: Maybe<LocaleOptions>;
  location?: Maybe<Location>;
  locations?: Maybe<Array<Location>>;
  logs: Array<Logs>;
  loyaltyTransactions: LoyaltyTransactions;
  mailchimp: Mailchimp;
  marketingStats: MarketingStats;
  message: Message;
  messages: Array<Message>;
  microSite: MicroSite;
  mollie: Mollie;
  monthlyReport: MonthlyReport;
  note: Note;
  notes: Array<Note>;
  npsFeedback?: Maybe<NpsFeedback>;
  onboardingTasks: OnboardingTaskSummary;
  order: Order;
  orderItems: Array<OrderItem>;
  orders: Array<Order>;
  paymentMethods?: Maybe<Array<PaymentType>>;
  posTerminals?: Maybe<Array<PosTerminal>>;
  prepaidCard: PrepaidCard;
  prepaidCards?: Maybe<Array<PrepaidCard>>;
  product: Product;
  productCalculations: ProductCalculations;
  productCategories: Array<ProductCategory>;
  productCategory: ProductCategory;
  products: Array<Product>;
  referralRewards: Scalars['JSON'];
  referrals: Array<Referral>;
  registerItems: Array<RegisterItem>;
  registerSearch: Array<RegisterSearchableItemInterface>;
  resource: Resource;
  resources: Array<Resource>;
  /** Returns a report of either products or services sales */
  salesReport: SalesReport;
  salonizedEmployees: Array<SalonizedEmployee>;
  search: Search;
  service: Service;
  serviceAvailabilities: ServiceAvailability;
  serviceCategories: Array<ServiceCategory>;
  serviceCategory: ServiceCategory;
  serviceGroup: ServiceGroup;
  serviceGroups: Array<ServiceGroup>;
  serviceOfferings?: Maybe<ServiceOffering>;
  serviceSuggestions: Array<Service>;
  serviceVariationGroup: ServiceVariationGroup;
  serviceVariationGroups: Array<ServiceVariationGroup>;
  services: Array<Service>;
  stockMutations: Array<StockMutation>;
  stockOrder: StockOrder;
  stockOrders: Array<StockOrder>;
  stripeInvoices: Array<StripeInvoice>;
  stripePortalSessions: PortalSession;
  stripePrices: Array<SubscriptionPricing>;
  stripeSubscriptions: Array<StripeSubscription>;
  subscriptionFlowData: SubscriptionFlowData;
  supplier: Supplier;
  suppliers: Array<Supplier>;
  template: Template;
  templates: Array<Template>;
  timelineItems: Array<TimelineItem>;
  timeslots: Array<Scalars['JSON']>;
  totalsReport: TotalsReport;
  transactions: Array<Transaction>;
  treatwellCurrentIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
  treatwellDashboardStatistics: TreatwellDashboardStatistics;
  treatwellLastFeedbackPublished?: Maybe<Feedback>;
  treatwellMarketplace?: Maybe<CompanyApp>;
  treatwellStatus?: Maybe<TreatwellStatus>;
  treatwellTreatments: Array<TreatwellTreatment>;
  treatwellVenue?: Maybe<TreatwellVenue>;
  treatwellVenueTypes: Array<TreatwellVenueType>;
  treeMapInsights: Array<TreeMapInsight>;
  twoFactorOtpSecret: TwoFactorOtpSecret;
  user: User;
  users: Array<User>;
  vatRates: Array<VatRate>;
  /** Returns a yearly VAT report */
  vatReport: VatReport;
  waitingListEntries: Array<WaitingListEntry>;
  waitingListEntry: WaitingListEntry;
};

export type QueryAbsenceArgs = {
  id: Scalars['Int'];
  recurrenceStartAt?: InputMaybe<Scalars['ISO8601Date']>;
};

export type QueryAbsenceInsightsArgs = {
  endAt: Scalars['ISO8601DateTime'];
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
};

export type QueryAbsencesArgs = {
  dataScope?: InputMaybe<DataScope>;
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['ID']>;
};

export type QueryAppointmentArgs = {
  id: Scalars['Int'];
  recurrenceStartAt?: InputMaybe<Scalars['ISO8601Date']>;
};

export type QueryAppointmentInsightsArgs = {
  dataScope: DataScope;
  endAt: Scalars['ISO8601DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  period: Scalars['String'];
  startAt: Scalars['ISO8601DateTime'];
};

export type QueryAppointmentReportArgs = {
  id: Scalars['Int'];
};

export type QueryAppointmentReportsArgs = {
  customerId: Scalars['Int'];
  direction?: InputMaybe<SortDirectionEnum>;
  pagination?: InputMaybe<PaginationAttributes>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryAppointmentsArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  pagination?: InputMaybe<PaginationAttributes>;
};

export type QueryAvailabilityArgs = {
  action?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['ID']>;
  parts?: InputMaybe<Array<AppointmentPartAttributes>>;
  startAt: Scalars['DateTime'];
};

export type QueryBookingRequestsArgs = {
  dataScope?: InputMaybe<DataScope>;
  direction?: InputMaybe<SortDirectionEnum>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryBusinessHoursArgs = {
  dataScope?: InputMaybe<DataScope>;
  end: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  viewType?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarAbsencesArgs = {
  dataScope?: InputMaybe<DataScope>;
  end: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  viewType?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarAppointmentsArgs = {
  dataScope?: InputMaybe<DataScope>;
  end: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  viewType?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarDynamicPricesArgs = {
  end: Scalars['DateTime'];
  resourceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  viewType?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarNotesArgs = {
  dataScope?: InputMaybe<DataScope>;
  end: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  viewType?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarWaitingListsArgs = {
  dataScope?: InputMaybe<DataScope>;
  end: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  viewType?: InputMaybe<Scalars['String']>;
};

export type QueryCashupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryCashupsArgs = {
  dataScope?: InputMaybe<DataScope>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  direction?: InputMaybe<SortDirectionEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryChangeLogsArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  pagination?: InputMaybe<PaginationAttributes>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryCompanyAppArgs = {
  key: Scalars['String'];
};

export type QueryCompanyEmailTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryCompanySmsTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryConflictingTimesArgs = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
  partsAttributes?: InputMaybe<Array<AppointmentPartAttributes>>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type QueryContractTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryCustomerArgs = {
  id: Scalars['Int'];
};

export type QueryCustomerFilterGroupArgs = {
  id: Scalars['Int'];
};

export type QueryCustomerOrderHistoryItemsArgs = {
  customerId: Scalars['ID'];
  filter?: InputMaybe<Scalars['String']>;
};

export type QueryCustomerSummaryArgs = {
  id: Scalars['Int'];
};

export type QueryCustomerTagsArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  pagination?: InputMaybe<PaginationAttributes>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryCustomersArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  excludedId?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryCustomersOverviewArgs = {
  filter?: InputMaybe<Array<CustomersOverviewFilterAttributes>>;
  pagination?: InputMaybe<PaginationAttributes>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryDailyReportArgs = {
  dataScope?: InputMaybe<DataScope>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryDashboardArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['ID']>;
};

export type QueryDiscountCodeArgs = {
  id: Scalars['Int'];
};

export type QueryDiscountCodeUsageArgs = {
  id: Scalars['Int'];
  pagination?: InputMaybe<PaginationAttributes>;
};

export type QueryDiscountCodesArgs = {
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  state?: Array<DiscountCodeStateEnum>;
};

export type QueryDrawerArgs = {
  dataScope?: InputMaybe<DataScope>;
  locationId?: InputMaybe<Scalars['ID']>;
};

export type QueryDurationInsightsArgs = {
  endAt: Scalars['ISO8601DateTime'];
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
};

export type QueryDynamicPriceArgs = {
  id: Scalars['ID'];
};

export type QueryEmailTemplateArgs = {
  defaultValues?: InputMaybe<Scalars['Boolean']>;
  emailType: EmailTemplateEnum;
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryEmailTemplatesArgs = {
  emailType: EmailTemplateEnum;
};

export type QueryEmployeeReportArgs = {
  dataScope?: InputMaybe<DataScope>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['Int']>;
};

export type QueryFeedbackArgs = {
  id: Scalars['Int'];
};

export type QueryFeedbackAggregateArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  dataScope?: InputMaybe<DataScope>;
  fromDate?: InputMaybe<Scalars['ISO8601Date']>;
  locationId?: InputMaybe<Scalars['ID']>;
  publishMode?: InputMaybe<Scalars['Boolean']>;
  publishingStatusFilter?: InputMaybe<FeedbackPublishingStatusFilterEnum>;
  reactionFilter?: InputMaybe<FeedbackReactionFilterEnum>;
};

export type QueryFeedbackCountArgs = {
  dataScope?: InputMaybe<DataScope>;
  locationId?: InputMaybe<Scalars['ID']>;
};

export type QueryFeedbacksArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  dataScope?: InputMaybe<DataScope>;
  direction?: InputMaybe<SortDirectionEnum>;
  fromDate?: InputMaybe<Scalars['ISO8601Date']>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  publishMode?: InputMaybe<Scalars['Boolean']>;
  publishingStatusFilter?: InputMaybe<FeedbackPublishingStatusFilterEnum>;
  reactionFilter?: InputMaybe<FeedbackReactionFilterEnum>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryFibersArgs = {
  eventId: Scalars['Int'];
  eventType: Scalars['String'];
  startAt: Scalars['ISO8601Date'];
};

export type QueryFilteredCustomersCountArgs = {
  filters?: Array<CustomerFilterInput>;
};

export type QueryFormSubmissionArgs = {
  id: Scalars['Int'];
};

export type QueryFormSubmissionsArgs = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryFormTemplateArgs = {
  id: Scalars['Int'];
};

export type QueryFormTemplatesArgs = {
  withArchived?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGiftcardArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGiftcardsArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  filterScope?: InputMaybe<GiftcardFilterScope>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryHeatMapInsightsArgs = {
  dataScope: DataScope;
  endAt: Scalars['ISO8601DateTime'];
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locationId?: InputMaybe<Scalars['ID']>;
  startAt: Scalars['ISO8601DateTime'];
};

export type QueryHtmlToPlainTextArgs = {
  htmlText: Scalars['String'];
};

export type QueryImportArgs = {
  id: Scalars['ID'];
};

export type QueryImportsArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>;
  pagination?: InputMaybe<PaginationAttributes>;
  type: Scalars['String'];
};

export type QueryInvoicesArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  pagination?: InputMaybe<PaginationAttributes>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryIplTreatmentReportsArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryIplTreatmentsArgs = {
  customerId: Scalars['ID'];
};

export type QueryLocationArgs = {
  id: Scalars['Int'];
};

export type QueryLogsArgs = {
  dataScope?: InputMaybe<DataScope>;
  direction?: InputMaybe<SortDirectionEnum>;
  key?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryLoyaltyTransactionsArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
};

export type QueryMarketingStatsArgs = {
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
};

export type QueryMessageArgs = {
  id: Scalars['Int'];
};

export type QueryMessagesArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryMonthlyReportArgs = {
  dataScope?: InputMaybe<DataScope>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryNoteArgs = {
  id: Scalars['Int'];
};

export type QueryNotesArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
};

export type QueryOnboardingTasksArgs = {
  taskType?: InputMaybe<Scalars['String']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrderItemsArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  locationId?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<PaginationAttributes>;
  productId: Scalars['Int'];
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryOrdersArgs = {
  cashupId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  dataScope?: InputMaybe<DataScope>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  direction?: InputMaybe<SortDirectionEnum>;
  employeeId?: InputMaybe<Scalars['ID']>;
  filterScope?: InputMaybe<FilterScope>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  withTransactions?: InputMaybe<Scalars['Boolean']>;
  withoutDrafts?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPaymentMethodsArgs = {
  register?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPosTerminalsArgs = {
  connected?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPrepaidCardArgs = {
  id: Scalars['ID'];
};

export type QueryPrepaidCardsArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  direction?: InputMaybe<SortDirectionEnum>;
  filterScope?: InputMaybe<PrepaidCardFilterScope>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryProductArgs = {
  id: Scalars['Int'];
  locationId?: InputMaybe<Scalars['ID']>;
};

export type QueryProductCalculationsArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  locationId?: InputMaybe<Scalars['ID']>;
  supplierIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryProductCategoriesArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  filter?: InputMaybe<ProductCategoriesFilterEnum>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  supplierIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryProductCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryProductsArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  direction?: InputMaybe<SortDirectionEnum>;
  filter?: InputMaybe<Scalars['String']>;
  importId?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  reference?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  showNullCategory?: InputMaybe<Scalars['Boolean']>;
  showNullSupplier?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['String']>;
  supplierIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryRegisterItemsArgs = {
  favorite?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRegisterSearchArgs = {
  before?: InputMaybe<Scalars['ISO8601Date']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<RegisterItemTypeEnum>;
};

export type QueryResourceArgs = {
  id: Scalars['ID'];
};

export type QueryResourcesArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  locationId?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ResourceType>;
};

export type QuerySalesReportArgs = {
  dataScope?: InputMaybe<DataScope>;
  employeeId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  reportType: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QuerySearchArgs = {
  query: Scalars['String'];
};

export type QueryServiceArgs = {
  id: Scalars['Int'];
};

export type QueryServiceAvailabilitiesArgs = {
  serviceId: Scalars['ID'];
  startAt: Scalars['DateTime'];
};

export type QueryServiceCategoriesArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryServiceCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryServiceGroupArgs = {
  id: Scalars['Int'];
};

export type QueryServiceOfferingsArgs = {
  serviceId: Scalars['Int'];
};

export type QueryServiceSuggestionsArgs = {
  customerId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  serviceIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryServiceVariationGroupArgs = {
  id: Scalars['Int'];
};

export type QueryServicesArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  onlyBookable?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryStockMutationsArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  productId: Scalars['ID'];
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryStockOrderArgs = {
  id: Scalars['Int'];
};

export type QueryStockOrdersArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryStripeInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type QuerySubscriptionFlowDataArgs = {
  upgrade?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySupplierArgs = {
  id: Scalars['Int'];
};

export type QuerySuppliersArgs = {
  direction?: InputMaybe<SortDirectionEnum>;
  locationId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  showNullSupplier?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryTemplateArgs = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
};

export type QueryTemplatesArgs = {
  appointmentId?: InputMaybe<Scalars['Int']>;
};

export type QueryTimelineItemsArgs = {
  dashboard?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  recordId?: InputMaybe<Scalars['ID']>;
  recordType?: InputMaybe<RecordType>;
};

export type QueryTimeslotsArgs = {
  end: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  parts?: InputMaybe<Array<AppointmentPartAttributes>>;
  start: Scalars['DateTime'];
};

export type QueryTotalsReportArgs = {
  dataScope?: InputMaybe<DataScope>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryTransactionsArgs = {
  cashupId?: InputMaybe<Scalars['ID']>;
  dataScope?: InputMaybe<DataScope>;
  direction?: InputMaybe<SortDirectionEnum>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  onlyCash?: InputMaybe<Scalars['Boolean']>;
  onlyWithOrderId?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationAttributes>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<TransactionType>;
};

export type QueryTreatwellDashboardStatisticsArgs = {
  range?: InputMaybe<DateRange>;
};

export type QueryTreatwellLastFeedbackPublishedArgs = {
  dataScope?: InputMaybe<DataScope>;
  locationId?: InputMaybe<Scalars['ID']>;
};

export type QueryTreeMapInsightsArgs = {
  endAt: Scalars['ISO8601DateTime'];
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryVatRatesArgs = {
  pagination?: InputMaybe<PaginationAttributes>;
};

export type QueryVatReportArgs = {
  dataScope?: InputMaybe<DataScope>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QueryWaitingListEntriesArgs = {
  dataScope?: InputMaybe<DataScope>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  direction?: InputMaybe<SortDirectionEnum>;
  locationId?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type QueryWaitingListEntryArgs = {
  id: Scalars['ID'];
};

export type Quote = {
  __typename?: 'Quote';
  amountSubtotal: Scalars['Int'];
  amountTotal: Scalars['Int'];
  id: Scalars['ID'];
  lineItems?: Maybe<Array<StripeSubscriptionItem>>;
  percentageDiscount: Scalars['Int'];
  period: SubscriptionPeriod;
  plan: SubscriptionPlan;
  referralReward: Scalars['Boolean'];
};

/** Autogenerated input type of ReactivateSubscription */
export type ReactivateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ReactivateSubscription. */
export type ReactivateSubscriptionPayload = {
  __typename?: 'ReactivateSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  subscription?: Maybe<CompanySubscription>;
};

/** Autogenerated input type of ReactivateTrial */
export type ReactivateTrialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ReactivateTrial. */
export type ReactivateTrialPayload = {
  __typename?: 'ReactivateTrialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

export enum RecordType {
  Absence = 'ABSENCE',
  Appointment = 'APPOINTMENT',
  DiscountCode = 'DISCOUNT_CODE',
  FormSubmission = 'FORM_SUBMISSION',
  Mailing = 'MAILING',
  Order = 'ORDER',
  Service = 'SERVICE'
}

export type Referral = {
  __typename?: 'Referral';
  companyName: Scalars['String'];
  id: Scalars['ID'];
  rewarded: Scalars['Boolean'];
  signupAt?: Maybe<Scalars['DateTime']>;
};

export type RegisterItem = {
  __typename?: 'RegisterItem';
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  medical?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  type: RegisterSearchResultEnum;
};

export enum RegisterItemTypeEnum {
  /** All types accepted on the register */
  All = 'ALL',
  /** Appointment type */
  Appointments = 'APPOINTMENTS',
  /** Product type */
  Products = 'PRODUCTS',
  /** Service type */
  Services = 'SERVICES'
}

export enum RegisterSearchResultEnum {
  /** Appointment type */
  Appointment = 'appointment',
  /** Product type */
  Product = 'product',
  /** Service type */
  Service = 'service'
}

/** Something that can be searched on the register. */
export type RegisterSearchableItemInterface = {
  id: Scalars['Int'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  type: RegisterSearchResultEnum;
};

/** Autogenerated input type of RejectAppointment */
export type RejectAppointmentInput = {
  appointmentId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of RejectAppointment. */
export type RejectAppointmentPayload = {
  __typename?: 'RejectAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of RequestFeedbackPublication */
export type RequestFeedbackPublicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of RequestFeedbackPublication. */
export type RequestFeedbackPublicationPayload = {
  __typename?: 'RequestFeedbackPublicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  feedback: Feedback;
};

/** Autogenerated input type of RequestTreatwell */
export type RequestTreatwellInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  source: TreatwellRequestSource;
};

/** Autogenerated return type of RequestTreatwell. */
export type RequestTreatwellPayload = {
  __typename?: 'RequestTreatwellPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type Requirement = {
  __typename?: 'Requirement';
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** @deprecated Going to be removed soon */
  name?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  /** @deprecated Going to be removed soon */
  required: Scalars['Boolean'];
  resources: Array<Resource>;
  state: Scalars['String'];
  target: Scalars['String'];
  type: ResourceType;
};

export type RequirementAttributes = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  primary: Scalars['Boolean'];
  resourceIds: Array<Scalars['Int']>;
  type: ResourceType;
};

/** Autogenerated input type of RescheduleAppointment */
export type RescheduleAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  newResourceId?: InputMaybe<Scalars['Int']>;
  oldResourceId?: InputMaybe<Scalars['Int']>;
  recurrenceStartAt?: InputMaybe<Scalars['ISO8601Date']>;
  recurrenceUpdateState?: InputMaybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  viewType?: CalendarView;
};

/** Autogenerated return type of RescheduleAppointment. */
export type RescheduleAppointmentPayload = {
  __typename?: 'RescheduleAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of ResendMessage */
export type ResendMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  messageId: Scalars['ID'];
};

/** Autogenerated return type of ResendMessage. */
export type ResendMessagePayload = {
  __typename?: 'ResendMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ResidenceAttributes = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of ResizeAppointment */
export type ResizeAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  recurrenceStartAt?: InputMaybe<Scalars['ISO8601Date']>;
  recurrenceUpdateState?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  viewType?: CalendarView;
};

/** Autogenerated return type of ResizeAppointment. */
export type ResizeAppointmentPayload = {
  __typename?: 'ResizeAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

export type Resource = {
  __typename?: 'Resource';
  bookable?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  customRoster: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  initials?: Maybe<Scalars['String']>;
  locationIds: Array<Scalars['Int']>;
  /** @deprecated Use 'custom_roster' */
  manualRoster: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  rosterLocationId?: Maybe<Scalars['Int']>;
  serviceIds: Array<Scalars['Int']>;
  servicesCount: Scalars['Int'];
  sortOrder: Scalars['Int'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ResourceAdjustment = {
  __typename?: 'ResourceAdjustment';
  id?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  resourceId: Scalars['Int'];
};

export type ResourceAdjustmentAttributes = {
  id?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  resourceId: Scalars['Int'];
};

export enum ResourceSelection {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Required = 'REQUIRED'
}

export enum ResourceState {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export enum ResourceType {
  Employee = 'EMPLOYEE',
  Equipment = 'EQUIPMENT',
  Room = 'ROOM'
}

/** Autogenerated input type of RestoreCustomer */
export type RestoreCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RestoreCustomer. */
export type RestoreCustomerPayload = {
  __typename?: 'RestoreCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer: Customer;
};

/** Autogenerated input type of RestoreCustomers */
export type RestoreCustomersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<Array<CustomersOverviewFilterAttributes>>;
  query?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of RestoreCustomers. */
export type RestoreCustomersPayload = {
  __typename?: 'RestoreCustomersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of RestoreImportedProducts */
export type RestoreImportedProductsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  importId: Scalars['Int'];
};

/** Autogenerated return type of RestoreImportedProducts. */
export type RestoreImportedProductsPayload = {
  __typename?: 'RestoreImportedProductsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  import: Import;
};

/** Autogenerated input type of RevertProductChangeSchedule */
export type RevertProductChangeScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  productChangeScheduleId: Scalars['Int'];
};

/** Autogenerated return type of RevertProductChangeSchedule. */
export type RevertProductChangeSchedulePayload = {
  __typename?: 'RevertProductChangeSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  productChangeSchedule?: Maybe<ProductChangeSchedule>;
};

export type RosterItemTimeInput = {
  closed: Scalars['String'];
  open: Scalars['String'];
};

export type RruleAttributes = {
  byday?: InputMaybe<Array<Scalars['Int']>>;
  bymonthday?: InputMaybe<Array<Scalars['Int']>>;
  count?: InputMaybe<Scalars['Int']>;
  freq: Scalars['String'];
  interval?: InputMaybe<Scalars['Int']>;
  monthlyByday?: InputMaybe<Array<Array<Scalars['Int']>>>;
  until?: InputMaybe<Scalars['ISO8601Date']>;
};

export type SalesReport = {
  __typename?: 'SalesReport';
  categories: Array<SalesReportCategories>;
  data: Array<SalesReportData>;
};

export type SalesReportCategories = {
  __typename?: 'SalesReportCategories';
  amountSold: Scalars['Int'];
  categoryColor?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  categorySortOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percentageSold: Scalars['Float'];
  purchasePrice: Scalars['Float'];
  revenueExcl: Scalars['Float'];
  revenueIncl: Scalars['Float'];
};

export type SalesReportData = {
  __typename?: 'SalesReportData';
  amountSold: Scalars['Int'];
  categoryId?: Maybe<Scalars['Int']>;
  categoryName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  percentageSold: Scalars['Float'];
  purchasePrice: Scalars['Float'];
  revenueExcl: Scalars['Float'];
  revenueIncl: Scalars['Float'];
};

export type SalonizedEmployee = {
  __typename?: 'SalonizedEmployee';
  id: Scalars['ID'];
  isLastDemoGiver?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  profileImage: Scalars['String'];
};

/** Autogenerated input type of SaveOnboardingData */
export type SaveOnboardingDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyAttributes: CompanyAttributes;
  industryCodeId: Scalars['ID'];
  legalEntityType: LegalEntity;
  microSiteUrl: Scalars['String'];
  registrationNumber?: InputMaybe<Scalars['String']>;
  residenceAttributes?: InputMaybe<ResidenceAttributes>;
  residentialAddressSame?: InputMaybe<Scalars['Boolean']>;
  userAttributes: UserAttributes;
  vatExemption?: InputMaybe<Scalars['Boolean']>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SaveOnboardingData. */
export type SaveOnboardingDataPayload = {
  __typename?: 'SaveOnboardingDataPayload';
  adyenAppState?: Maybe<AdyenAppState>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of SaveSelectedTerminalModel */
export type SaveSelectedTerminalModelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  terminalModel: TerminalModel;
};

/** Autogenerated return type of SaveSelectedTerminalModel. */
export type SaveSelectedTerminalModelPayload = {
  __typename?: 'SaveSelectedTerminalModelPayload';
  adyenAppState?: Maybe<AdyenAppState>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

export type Search = {
  __typename?: 'Search';
  customers: Array<Customer>;
  internalPages: Array<InternalPage>;
};

/** Autogenerated input type of SendAppointmentCancellationEmail */
export type SendAppointmentCancellationEmailInput = {
  appointmentId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SendAppointmentCancellationEmail. */
export type SendAppointmentCancellationEmailPayload = {
  __typename?: 'SendAppointmentCancellationEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendAppointmentConfirmationEmail */
export type SendAppointmentConfirmationEmailInput = {
  appointmentId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SendAppointmentConfirmationEmail. */
export type SendAppointmentConfirmationEmailPayload = {
  __typename?: 'SendAppointmentConfirmationEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendAppointmentMovedEmail */
export type SendAppointmentMovedEmailInput = {
  appointmentId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  previousTime: Scalars['DateTime'];
};

/** Autogenerated return type of SendAppointmentMovedEmail. */
export type SendAppointmentMovedEmailPayload = {
  __typename?: 'SendAppointmentMovedEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendAppointmentNoShowEmail */
export type SendAppointmentNoShowEmailInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SendAppointmentNoShowEmail. */
export type SendAppointmentNoShowEmailPayload = {
  __typename?: 'SendAppointmentNoShowEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendAppointmentsOverview */
export type SendAppointmentsOverviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of SendAppointmentsOverview. */
export type SendAppointmentsOverviewPayload = {
  __typename?: 'SendAppointmentsOverviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailStatus?: Maybe<EmailStatusEnum>;
};

/** Autogenerated input type of SendAvailableTimeslotEmailAll */
export type SendAvailableTimeslotEmailAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['ISO8601Date'];
  ids?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of SendAvailableTimeslotEmailAll. */
export type SendAvailableTimeslotEmailAllPayload = {
  __typename?: 'SendAvailableTimeslotEmailAllPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendAvailableTimeslotEmail */
export type SendAvailableTimeslotEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['ISO8601Date'];
  waitingListEntryId: Scalars['ID'];
};

/** Autogenerated return type of SendAvailableTimeslotEmail. */
export type SendAvailableTimeslotEmailPayload = {
  __typename?: 'SendAvailableTimeslotEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendFeedbackResponse */
export type SendFeedbackResponseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedbackId: Scalars['Int'];
  message: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SendFeedbackResponse. */
export type SendFeedbackResponsePayload = {
  __typename?: 'SendFeedbackResponsePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of SendMarketingEmail */
export type SendMarketingEmailInput = {
  buttonTitle?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  deliverAt?: InputMaybe<Scalars['DateTime']>;
  discountCodeId?: InputMaybe<Scalars['ID']>;
  discountMessage?: InputMaybe<Scalars['String']>;
  dispatchType?: InputMaybe<DispatchTypeEnum>;
  filterGroupId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<CustomerFilterInput>>;
  hasButton?: InputMaybe<Scalars['Boolean']>;
  hasDeliverySchedule?: InputMaybe<Scalars['Boolean']>;
  hasDiscount?: InputMaybe<Scalars['Boolean']>;
  hasFooter?: InputMaybe<Scalars['Boolean']>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  hasLogo?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  testEmail?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SendMarketingEmail. */
export type SendMarketingEmailPayload = {
  __typename?: 'SendMarketingEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  messageId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendTwoFactorSms */
export type SendTwoFactorSmsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SendTwoFactorSms. */
export type SendTwoFactorSmsPayload = {
  __typename?: 'SendTwoFactorSmsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  smsSentAt?: Maybe<Scalars['DateTime']>;
};

export type Service = RegisterSearchableItemInterface & {
  __typename?: 'Service';
  bookable?: Maybe<Scalars['Boolean']>;
  bookableLocationIds: Array<Scalars['Int']>;
  buffer: Scalars['Int'];
  category: ServiceCategory;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  durationFinish?: Maybe<Scalars['Int']>;
  durationProcessing?: Maybe<Scalars['Int']>;
  durationSetup?: Maybe<Scalars['Int']>;
  extraInformation?: Maybe<Scalars['String']>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  medical?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offeringEnabled: Scalars['Boolean'];
  popular?: Maybe<Scalars['Boolean']>;
  price: Scalars['Int'];
  productId?: Maybe<Scalars['Int']>;
  rebookEnabled?: Maybe<Scalars['Boolean']>;
  rebookPeriod?: Maybe<Scalars['Int']>;
  requirements: Array<Requirement>;
  requiresProcessingTime: Scalars['Boolean'];
  resourceAdjustments?: Maybe<Array<ResourceAdjustment>>;
  rwgName?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  serviceOffering?: Maybe<ServiceOffering>;
  serviceVariationGroupId?: Maybe<Scalars['ID']>;
  sortOrder: Scalars['Int'];
  twTreatments?: Maybe<Array<TreatwellTreatment>>;
  type: RegisterSearchResultEnum;
  variationGroupSortOrder?: Maybe<Scalars['Int']>;
  variationName?: Maybe<Scalars['String']>;
  vatRateId?: Maybe<Scalars['Int']>;
};

export type ServiceAttributes = {
  bookable?: InputMaybe<Scalars['Boolean']>;
  bookableLocationIds: Array<Scalars['Int']>;
  buffer: Scalars['Int'];
  categoryId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  duration: Scalars['Int'];
  durationFinish?: InputMaybe<Scalars['Int']>;
  durationProcessing?: InputMaybe<Scalars['Int']>;
  durationSetup?: InputMaybe<Scalars['Int']>;
  extraInformation?: InputMaybe<Scalars['String']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  popular?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Int'];
  rebookEnabled?: InputMaybe<Scalars['Boolean']>;
  rebookPeriod?: InputMaybe<Scalars['Int']>;
  requirementsAttributes: Array<RequirementAttributes>;
  requiresProcessingTime?: InputMaybe<Scalars['Boolean']>;
  resourceAdjustmentsAttributes?: InputMaybe<
    Array<ResourceAdjustmentAttributes>
  >;
  rwgName?: InputMaybe<Scalars['String']>;
  serviceOfferingAttributes?: InputMaybe<ServiceOfferingAttributes>;
  serviceOfferingEnabled?: InputMaybe<Scalars['Boolean']>;
  variationGroupSortOrder?: InputMaybe<Scalars['Int']>;
  vatRateId?: InputMaybe<Scalars['Int']>;
};

export type ServiceAvailability = {
  __typename?: 'ServiceAvailability';
  available: Scalars['Boolean'];
  reason?: Maybe<ServiceAvailabilityReason>;
  serviceOffering?: Maybe<ServiceOffering>;
};

export enum ServiceAvailabilityReason {
  Max = 'MAX',
  Overlap = 'OVERLAP'
}

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  color: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  services?: Maybe<Array<Service>>;
  sortOrder: Scalars['Int'];
};

export type ServiceGroup = {
  __typename?: 'ServiceGroup';
  category: ServiceCategory;
  id: Scalars['ID'];
  name: Scalars['String'];
  services: Array<Service>;
  sortOrder: Scalars['Int'];
  top: Scalars['Boolean'];
};

export type ServiceOffering = {
  __typename?: 'ServiceOffering';
  id: Scalars['ID'];
  offeringType: ServiceOfferingType;
  payload: Weekdays;
  serviceId: Scalars['ID'];
};

export type ServiceOfferingAttributes = {
  offeringType?: InputMaybe<ServiceOfferingType>;
  payload?: InputMaybe<WeekdaysInput>;
  serviceId?: InputMaybe<Scalars['ID']>;
};

export enum ServiceOfferingType {
  SpecificTime = 'SPECIFIC_TIME',
  Weekdays = 'WEEKDAYS'
}

export type ServiceVariation = {
  __typename?: 'ServiceVariation';
  buffer: Scalars['Int'];
  duration: Scalars['Int'];
  durationFinish?: Maybe<Scalars['Int']>;
  durationProcessing?: Maybe<Scalars['Int']>;
  durationSetup?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  requiresProcessingTime: Scalars['Boolean'];
  sortOrder: Scalars['Int'];
  variationGroupSortOrder: Scalars['Int'];
};

export type ServiceVariationAttributes = {
  buffer?: InputMaybe<Scalars['Int']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  duration?: InputMaybe<Scalars['Int']>;
  durationFinish?: InputMaybe<Scalars['Int']>;
  durationProcessing?: InputMaybe<Scalars['Int']>;
  durationSetup?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  requiresProcessingTime?: InputMaybe<Scalars['Boolean']>;
  variationGroupSortOrder?: InputMaybe<Scalars['Int']>;
};

export type ServiceVariationGroup = {
  __typename?: 'ServiceVariationGroup';
  bookable?: Maybe<Scalars['Boolean']>;
  bookableLocationIds: Array<Scalars['Int']>;
  category: ServiceCategory;
  description?: Maybe<Scalars['String']>;
  extraInformation?: Maybe<Scalars['String']>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  medical?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offeringEnabled: Scalars['Boolean'];
  popular?: Maybe<Scalars['Boolean']>;
  rebookEnabled?: Maybe<Scalars['Boolean']>;
  rebookPeriod?: Maybe<Scalars['Int']>;
  requirements: Array<Requirement>;
  rwgName?: Maybe<Scalars['String']>;
  serviceOffering?: Maybe<ServiceOffering>;
  sortOrder: Scalars['Int'];
  top: Scalars['Boolean'];
  twTreatments?: Maybe<Array<TreatwellTreatment>>;
  variations: Array<ServiceVariation>;
  vatRateId?: Maybe<Scalars['Int']>;
};

export type Settings = {
  __typename?: 'Settings';
  abTesting: SettingsAbTesting;
  agenda: SettingsAgenda;
  betaPages: SettingsBetaPages;
  bookingWidget: SettingsBookingWidget;
  bookings: SettingsBookings;
  communication: SettingsCommunication;
  customers: SettingsCustomers;
  datev: SettingsDatev;
  fiscalization: SettingsFiscalization;
  general: SettingsGeneral;
  locale: SettingsLocale;
  onboarding: SettingsOnboarding;
  reminders: SettingsReminders;
  sales: SettingsSales;
  treatwell: SettingsTreatwell;
  treatwellSales: SettingsTreatwellSales;
  trialTasks: SettingsTrialTasks;
};

export type SettingsAbTesting = {
  __typename?: 'SettingsAbTesting';
  flags?: Maybe<Array<Scalars['String']>>;
};

export type SettingsAgenda = {
  __typename?: 'SettingsAgenda';
  allowCancellations: Scalars['Boolean'];
  allowRescheduling: Scalars['Boolean'];
  appointmentEndBuffer: Scalars['Boolean'];
  appointmentEndBufferTime: Scalars['Int'];
  appointmentInterval: Scalars['Int'];
  cancellationHours: Scalars['Int'];
  colour: Colour;
  darkerColors: Scalars['Boolean'];
  endTime?: Maybe<Scalars['String']>;
  hideClosedResources: Scalars['Boolean'];
  reschedulingHours: Scalars['Int'];
  slotInterval: Scalars['Int'];
  startTime?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  view: View;
  viewType: ViewType;
};

export type SettingsAgendaAttribute = {
  allowCancellations?: InputMaybe<Scalars['Boolean']>;
  allowRescheduling?: InputMaybe<Scalars['Boolean']>;
  appointmentEndBuffer?: InputMaybe<Scalars['Boolean']>;
  appointmentEndBufferTime?: InputMaybe<Scalars['Int']>;
  appointmentInterval?: InputMaybe<Scalars['Int']>;
  cancellationHours?: InputMaybe<Scalars['Int']>;
  colour?: InputMaybe<Colour>;
  darkerColors?: InputMaybe<Scalars['Boolean']>;
  hideClosedResources?: InputMaybe<Scalars['Boolean']>;
  reschedulingHours?: InputMaybe<Scalars['Int']>;
  slotInterval?: InputMaybe<Scalars['Int']>;
  view?: InputMaybe<View>;
  viewType?: InputMaybe<ViewType>;
};

export type SettingsAttributes = {
  agenda?: InputMaybe<SettingsAgendaAttribute>;
  betaPages?: InputMaybe<SettingsBetaPagesAttribute>;
  bookingWidget?: InputMaybe<SettingsBookingWidgetAttribute>;
  bookings?: InputMaybe<SettingsBookingsAttribute>;
  communication?: InputMaybe<SettingsCommunicationAttribute>;
  customers?: InputMaybe<SettingsCustomersAttribute>;
  datev?: InputMaybe<SettingsDatevAttribute>;
  fiscalization?: InputMaybe<SettingsFiscalizationAttribute>;
  general?: InputMaybe<SettingsGeneralAttribute>;
  locale?: InputMaybe<SettingsLocaleAttribute>;
  onboarding?: InputMaybe<SettingsOnboardingAttribute>;
  reminders?: InputMaybe<SettingsRemindersAttribute>;
  sales?: InputMaybe<SettingsSalesAttribute>;
  treatwell?: InputMaybe<SettingsTreatwellAttribute>;
  treatwellSales?: InputMaybe<SettingsTreatwellSalesAttribute>;
  trialTasks?: InputMaybe<SettingsTrialTasksAttribute>;
};

export type SettingsBetaPages = {
  __typename?: 'SettingsBetaPages';
  calendar: Scalars['Boolean'];
  customer: Scalars['Boolean'];
  marketing: Scalars['Boolean'];
  newAdmin: Scalars['Boolean'];
  newDesignMl: Scalars['Boolean'];
  products: Scalars['Boolean'];
  register: Scalars['Boolean'];
  reports: Scalars['Boolean'];
};

export type SettingsBetaPagesAttribute = {
  calendar?: InputMaybe<Scalars['Boolean']>;
  customer?: InputMaybe<Scalars['Boolean']>;
  marketing?: InputMaybe<Scalars['Boolean']>;
  newAdmin?: InputMaybe<Scalars['Boolean']>;
  newDesignMl?: InputMaybe<Scalars['Boolean']>;
  products?: InputMaybe<Scalars['Boolean']>;
  register?: InputMaybe<Scalars['Boolean']>;
  reports?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsBookingWidget = {
  __typename?: 'SettingsBookingWidget';
  allowMonthView: Scalars['Boolean'];
  appointmentOverviewRequest: Scalars['Boolean'];
  autoWidth: Scalars['Boolean'];
  buttonSize: ButtonSize;
  customColor: Scalars['String'];
  height: Scalars['Int'];
  inline: Scalars['Boolean'];
  outline: Outline;
  position: Position;
  primaryColor: Scalars['String'];
  width: Scalars['Int'];
};

export type SettingsBookingWidgetAttribute = {
  allowMonthView?: InputMaybe<Scalars['Boolean']>;
  appointmentOverviewRequest?: InputMaybe<Scalars['Boolean']>;
  autoWidth?: InputMaybe<Scalars['Boolean']>;
  buttonSize?: InputMaybe<ButtonSize>;
  customColor?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  inline?: InputMaybe<Scalars['Boolean']>;
  outline?: InputMaybe<Outline>;
  position?: InputMaybe<Position>;
  primaryColor?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type SettingsBookings = {
  __typename?: 'SettingsBookings';
  addressFields: AddressFields;
  allowMultipleServices: Scalars['Boolean'];
  allowNewCustomers: Scalars['Boolean'];
  appointmentRequestDurationMinimum: Scalars['Boolean'];
  appointmentRequestDurationMinimumValue: Scalars['Int'];
  appointmentRequestNewCustomers: Scalars['Boolean'];
  appointmentRequestPriceMinimum: Scalars['Boolean'];
  appointmentRequestPriceMinimumValue: Scalars['Int'];
  appointmentRequiresConfirmation: Scalars['Boolean'];
  blockCustomersByIp: Scalars['Boolean'];
  categoriesCollapsible: Scalars['Boolean'];
  customAlert?: Maybe<Scalars['String']>;
  customCssEnabled: Scalars['Boolean'];
  customCustomerStopMessage?: Maybe<Scalars['String']>;
  dateOfBirth: DateOfBirth;
  dynamicPricingEnabled: Scalars['Boolean'];
  employeeSelectionFirst: Scalars['Boolean'];
  lastMinuteDiscountEnabled: Scalars['Boolean'];
  lastMinuteDiscountPercentage: Scalars['Int'];
  lastMinuteDiscountWindow: Scalars['String'];
  leadTime: Scalars['Int'];
  maxTimeInAdvance: Scalars['String'];
  onlinePayment: OnlinePayment;
  onlinePaymentAll: Scalars['Boolean'];
  onlinePaymentCharge?: Maybe<Scalars['Int']>;
  onlinePaymentMinimumDuration: Scalars['Boolean'];
  onlinePaymentMinimumDurationValue?: Maybe<Scalars['Int']>;
  onlinePaymentMinimumPrice: Scalars['Boolean'];
  onlinePaymentMinimumPriceValue?: Maybe<Scalars['Int']>;
  onlinePaymentPercentage?: Maybe<Scalars['Int']>;
  paymentFailedUrl?: Maybe<Scalars['String']>;
  paymentSuccessUrl?: Maybe<Scalars['String']>;
  prepaymentsForNewCustomersOnly: Scalars['Boolean'];
  preventGaps: PreventGaps;
  privacyStatementUrl?: Maybe<Scalars['String']>;
  rebookReminderEnabled: Scalars['Boolean'];
  requiresCountry: Scalars['Boolean'];
  resourceSelection: ResourceSelection;
  showCustomAlert: Scalars['Boolean'];
  showNewsletterOptin: Scalars['Boolean'];
  showScrollArrow: Scalars['Boolean'];
  showServiceDuration: Scalars['Boolean'];
  showServicePrices: Scalars['Boolean'];
  smsNotification: Scalars['Boolean'];
  stripeIdeal: Scalars['Boolean'];
  successUrl?: Maybe<Scalars['String']>;
  termsConditionsUrl?: Maybe<Scalars['String']>;
  topServicesEnabled: Scalars['Boolean'];
  topServicesIds: Array<Scalars['Int']>;
  waitingListEnabled: Scalars['Boolean'];
};

export type SettingsBookingsAttribute = {
  addressFields?: InputMaybe<AddressFields>;
  allowMultipleServices?: InputMaybe<Scalars['Boolean']>;
  allowNewCustomers?: InputMaybe<Scalars['Boolean']>;
  appointmentRequestDurationMinimum?: InputMaybe<Scalars['Boolean']>;
  appointmentRequestDurationMinimumValue?: InputMaybe<Scalars['Int']>;
  appointmentRequestNewCustomers?: InputMaybe<Scalars['Boolean']>;
  appointmentRequestPriceMinimum?: InputMaybe<Scalars['Boolean']>;
  appointmentRequestPriceMinimumValue?: InputMaybe<Scalars['Int']>;
  appointmentRequiresConfirmation?: InputMaybe<Scalars['Boolean']>;
  blockCustomersByIp?: InputMaybe<Scalars['Boolean']>;
  categoriesCollapsible?: InputMaybe<Scalars['Boolean']>;
  customAlert?: InputMaybe<Scalars['String']>;
  customCssEnabled?: InputMaybe<Scalars['Boolean']>;
  customCustomerStopMessage?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<DateOfBirth>;
  dynamicPricingEnabled?: InputMaybe<Scalars['Boolean']>;
  employeeSelectionFirst?: InputMaybe<Scalars['Boolean']>;
  lastMinuteDiscountEnabled?: InputMaybe<Scalars['Boolean']>;
  lastMinuteDiscountPercentage?: InputMaybe<Scalars['Int']>;
  lastMinuteDiscountWindow?: InputMaybe<Scalars['String']>;
  leadTime?: InputMaybe<Scalars['Int']>;
  maxTimeInAdvance?: InputMaybe<Scalars['String']>;
  onlinePayment?: InputMaybe<OnlinePayment>;
  onlinePaymentAll?: InputMaybe<Scalars['Boolean']>;
  onlinePaymentCharge?: InputMaybe<Scalars['Int']>;
  onlinePaymentMinimumDuration?: InputMaybe<Scalars['Boolean']>;
  onlinePaymentMinimumDurationValue?: InputMaybe<Scalars['Int']>;
  onlinePaymentMinimumPrice?: InputMaybe<Scalars['Boolean']>;
  onlinePaymentMinimumPriceValue?: InputMaybe<Scalars['Int']>;
  onlinePaymentPercentage?: InputMaybe<Scalars['Int']>;
  paymentFailedUrl?: InputMaybe<Scalars['String']>;
  paymentSuccessUrl?: InputMaybe<Scalars['String']>;
  prepaymentsForNewCustomersOnly?: InputMaybe<Scalars['Boolean']>;
  preventGaps?: InputMaybe<PreventGaps>;
  privacyStatementUrl?: InputMaybe<Scalars['String']>;
  rebookReminderEnabled?: InputMaybe<Scalars['Boolean']>;
  requiresCountry?: InputMaybe<Scalars['Boolean']>;
  resourceSelection?: InputMaybe<ResourceSelection>;
  showCustomAlert?: InputMaybe<Scalars['Boolean']>;
  showNewsletterOptin?: InputMaybe<Scalars['Boolean']>;
  showScrollArrow?: InputMaybe<Scalars['Boolean']>;
  showServiceDuration?: InputMaybe<Scalars['Boolean']>;
  showServicePrices?: InputMaybe<Scalars['Boolean']>;
  smsNotification?: InputMaybe<Scalars['Boolean']>;
  stripeIdeal?: InputMaybe<Scalars['Boolean']>;
  successUrl?: InputMaybe<Scalars['String']>;
  termsConditionsUrl?: InputMaybe<Scalars['String']>;
  topServicesEnabled?: InputMaybe<Scalars['Boolean']>;
  topServicesIds?: InputMaybe<Array<Scalars['Int']>>;
  waitingListEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsCommunication = {
  __typename?: 'SettingsCommunication';
  appointmentConfirmation: Scalars['Boolean'];
  appointmentNoShow: Scalars['Boolean'];
  onlineFormSubmissions: Scalars['Boolean'];
};

export type SettingsCommunicationAttribute = {
  appointmentConfirmation?: InputMaybe<Scalars['Boolean']>;
  appointmentNoShow?: InputMaybe<Scalars['Boolean']>;
  onlineFormSubmissions?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsCustomers = {
  __typename?: 'SettingsCustomers';
  appointmentFeedback: Scalars['Boolean'];
  appointmentFeedbackDelay: Scalars['Int'];
  birthdayMessageEnabled: Scalars['Boolean'];
  custom1Label?: Maybe<Scalars['String']>;
  custom2Label?: Maybe<Scalars['String']>;
  custom3Label?: Maybe<Scalars['String']>;
  custom4Label?: Maybe<Scalars['String']>;
  custom5Label?: Maybe<Scalars['String']>;
  custom6Label?: Maybe<Scalars['String']>;
  custom7Label?: Maybe<Scalars['String']>;
  custom8Label?: Maybe<Scalars['String']>;
  custom9Label?: Maybe<Scalars['String']>;
  custom10Label?: Maybe<Scalars['String']>;
  iplEnabled: Scalars['Boolean'];
  sendOnlineForms: Scalars['Boolean'];
};

export type SettingsCustomersAttribute = {
  appointmentFeedback?: InputMaybe<Scalars['Boolean']>;
  appointmentFeedbackDelay?: InputMaybe<Scalars['Int']>;
  birthdayMessageEnabled?: InputMaybe<Scalars['Boolean']>;
  custom1Label?: InputMaybe<Scalars['String']>;
  custom2Label?: InputMaybe<Scalars['String']>;
  custom3Label?: InputMaybe<Scalars['String']>;
  custom4Label?: InputMaybe<Scalars['String']>;
  custom5Label?: InputMaybe<Scalars['String']>;
  custom6Label?: InputMaybe<Scalars['String']>;
  custom7Label?: InputMaybe<Scalars['String']>;
  custom8Label?: InputMaybe<Scalars['String']>;
  custom9Label?: InputMaybe<Scalars['String']>;
  custom10Label?: InputMaybe<Scalars['String']>;
  iplEnabled?: InputMaybe<Scalars['Boolean']>;
  sendOnlineForms?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsDatev = {
  __typename?: 'SettingsDatev';
  bankAccount: Scalars['Int'];
  billsReceivableAccount: Scalars['Int'];
  cashAccount: Scalars['Int'];
  deferredRevenueAccount: Scalars['Int'];
  revenueAccount: Scalars['Int'];
};

export type SettingsDatevAttribute = {
  bankAccount?: InputMaybe<Scalars['Int']>;
  billsReceivableAccount?: InputMaybe<Scalars['Int']>;
  cashAccount?: InputMaybe<Scalars['Int']>;
  deferredRevenueAccount?: InputMaybe<Scalars['Int']>;
  revenueAccount?: InputMaybe<Scalars['Int']>;
};

export type SettingsFiscalization = {
  __typename?: 'SettingsFiscalization';
  disabledTssIds: Array<Scalars['String']>;
  tssClientId?: Maybe<Scalars['String']>;
  tssId?: Maybe<Scalars['String']>;
};

export type SettingsFiscalizationAttribute = {
  disabledTssIds?: InputMaybe<Array<Scalars['String']>>;
  tssClientId?: InputMaybe<Scalars['String']>;
  tssId?: InputMaybe<Scalars['String']>;
};

export type SettingsGeneral = {
  __typename?: 'SettingsGeneral';
  allowImpersonation: Scalars['Boolean'];
  betaFeatures: Scalars['Boolean'];
  boostExtension: Scalars['Boolean'];
  boostExtensionRequested: Scalars['Boolean'];
  boostExtensionRequestedAt?: Maybe<Scalars['DateTime']>;
  boughtTerminalFromSubscriptionFlow: Scalars['Boolean'];
  customBillingAddress: Scalars['Boolean'];
  dailyEmailsAppointmentsLimit: Scalars['Int'];
  dailyEmailsMarketingAndCustomLimit: Scalars['Int'];
  forceEmailSenderAddress: Scalars['Boolean'];
  giftcardsAsRevenue: Scalars['Boolean'];
  ipRestrictions: Array<Scalars['String']>;
  lastChangelogVisit: Scalars['DateTime'];
  lastFeedbackVisit: Scalars['DateTime'];
  marketingTrialRequested: Scalars['Boolean'];
  reactivated: Scalars['Boolean'];
  reactivatedAt?: Maybe<Scalars['DateTime']>;
  registerNewDesign: Scalars['Boolean'];
  salonizedPayInterest: Scalars['Boolean'];
  smsCreditPricing?: Maybe<Array<Scalars['Int']>>;
  treatwellTrialRequested: Scalars['Boolean'];
};

export type SettingsGeneralAttribute = {
  allowImpersonation?: InputMaybe<Scalars['Boolean']>;
  betaFeatures?: InputMaybe<Scalars['Boolean']>;
  boostExtension?: InputMaybe<Scalars['Boolean']>;
  boostExtensionRequested?: InputMaybe<Scalars['Boolean']>;
  boostExtensionRequestedAt?: InputMaybe<Scalars['DateTime']>;
  boughtTerminalFromSubscriptionFlow?: InputMaybe<Scalars['Boolean']>;
  customBillingAddress?: InputMaybe<Scalars['Boolean']>;
  dailyEmailsAppointmentsLimit?: InputMaybe<Scalars['Int']>;
  dailyEmailsMarketingAndCustomLimit?: InputMaybe<Scalars['Int']>;
  forceEmailSenderAddress?: InputMaybe<Scalars['Boolean']>;
  giftcardsAsRevenue?: InputMaybe<Scalars['Boolean']>;
  ipRestrictions?: InputMaybe<Array<Scalars['String']>>;
  lastChangelogVisit?: InputMaybe<Scalars['DateTime']>;
  lastFeedbackVisit?: InputMaybe<Scalars['DateTime']>;
  marketingTrialRequested?: InputMaybe<Scalars['Boolean']>;
  reactivated?: InputMaybe<Scalars['Boolean']>;
  reactivatedAt?: InputMaybe<Scalars['DateTime']>;
  registerNewDesign?: InputMaybe<Scalars['Boolean']>;
  salonizedPayInterest?: InputMaybe<Scalars['Boolean']>;
  smsCreditPricing?: InputMaybe<Array<Scalars['Int']>>;
  treatwellTrialRequested?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsLocale = {
  __typename?: 'SettingsLocale';
  clock: Clock;
  firstDayOfWeek: FirstDayOfWeek;
};

export type SettingsLocaleAttribute = {
  clock?: InputMaybe<Clock>;
  firstDayOfWeek?: InputMaybe<FirstDayOfWeek>;
};

export type SettingsOnboarding = {
  __typename?: 'SettingsOnboarding';
  customerFirstName?: Maybe<Scalars['String']>;
  customerLastName?: Maybe<Scalars['String']>;
  employeeName?: Maybe<Scalars['String']>;
  flowStep?: Maybe<Scalars['Int']>;
  serviceCategoryName?: Maybe<Scalars['String']>;
  serviceDuration?: Maybe<Scalars['Int']>;
  serviceName?: Maybe<Scalars['String']>;
  servicePrice?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['String']>;
};

export type SettingsOnboardingAttribute = {
  customerFirstName?: InputMaybe<Scalars['String']>;
  customerLastName?: InputMaybe<Scalars['String']>;
  employeeName?: InputMaybe<Scalars['String']>;
  flowStep?: InputMaybe<Scalars['Int']>;
  serviceCategoryName?: InputMaybe<Scalars['String']>;
  serviceDuration?: InputMaybe<Scalars['Int']>;
  serviceName?: InputMaybe<Scalars['String']>;
  servicePrice?: InputMaybe<Scalars['Int']>;
  step?: InputMaybe<Scalars['String']>;
};

export type SettingsReminders = {
  __typename?: 'SettingsReminders';
  emailReminderEnabled: Scalars['Boolean'];
  emailReminderHours?: Maybe<Scalars['Int']>;
  smsReminderEnabled: Scalars['Boolean'];
  smsReminderHours?: Maybe<Scalars['Int']>;
};

export type SettingsRemindersAttribute = {
  emailReminderEnabled?: InputMaybe<Scalars['Boolean']>;
  smsReminderEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsSales = {
  __typename?: 'SettingsSales';
  giftcardCustomAmount: Scalars['Boolean'];
  giftcardCustomAmountMax: Scalars['Int'];
  giftcardCustomAmountMin: Scalars['Int'];
  giftcardExpiration: Scalars['Boolean'];
  giftcardExpirationValue: Scalars['Int'];
  giftcardPersonalMessage?: Maybe<Scalars['String']>;
  giftcardPicture?: Maybe<Scalars['String']>;
  giftcardValues: Array<Scalars['Int']>;
  loyaltyPointsAmount: Scalars['Int'];
  loyaltyPointsType: LoyaltyPointsType;
  paymentLink: Scalars['Boolean'];
  printNextAppointment: Scalars['Boolean'];
  showTreatmentDateOnInvoice: Scalars['Boolean'];
  showVatOnInvoice: Scalars['Boolean'];
  thermalSupport: Scalars['Boolean'];
};

export type SettingsSalesAttribute = {
  giftcardCustomAmount?: InputMaybe<Scalars['Boolean']>;
  giftcardCustomAmountMax?: InputMaybe<Scalars['Int']>;
  giftcardCustomAmountMin?: InputMaybe<Scalars['Int']>;
  giftcardExpiration?: InputMaybe<Scalars['Boolean']>;
  giftcardExpirationValue?: InputMaybe<Scalars['Int']>;
  giftcardPicture?: InputMaybe<Scalars['String']>;
  giftcardValues?: InputMaybe<Array<Scalars['Int']>>;
  loyaltyPointsAmount?: InputMaybe<Scalars['Int']>;
  loyaltyPointsType?: InputMaybe<LoyaltyPointsType>;
  paymentLink?: InputMaybe<Scalars['Boolean']>;
  printNextAppointment?: InputMaybe<Scalars['Boolean']>;
  showTreatmentDateOnInvoice?: InputMaybe<Scalars['Boolean']>;
  showVatOnInvoice?: InputMaybe<Scalars['Boolean']>;
  thermalSupport?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsTreatwell = {
  __typename?: 'SettingsTreatwell';
  automaticallyPushFeedback: Scalars['Boolean'];
  hasSubscriptionIntention: Scalars['Boolean'];
  interestedAt?: Maybe<Scalars['DateTime']>;
  interestedSource?: Maybe<InterestedSource>;
  interestedUserId?: Maybe<Scalars['Int']>;
  notInterestedAt?: Maybe<Scalars['DateTime']>;
  notInterestedUserId?: Maybe<Scalars['Int']>;
};

export type SettingsTreatwellAttribute = {
  automaticallyPushFeedback?: InputMaybe<Scalars['Boolean']>;
  hasSubscriptionIntention?: InputMaybe<Scalars['Boolean']>;
  interestedAt?: InputMaybe<Scalars['DateTime']>;
  interestedSource?: InputMaybe<InterestedSource>;
  interestedUserId?: InputMaybe<Scalars['Int']>;
  notInterestedAt?: InputMaybe<Scalars['DateTime']>;
  notInterestedUserId?: InputMaybe<Scalars['Int']>;
};

export type SettingsTreatwellSales = {
  __typename?: 'SettingsTreatwellSales';
  activatedAt?: Maybe<Scalars['DateTime']>;
  churnedAt?: Maybe<Scalars['DateTime']>;
  subscriptionStatus: SubscriptionStatus;
  trialSignup: Scalars['Boolean'];
};

export type SettingsTreatwellSalesAttribute = {
  activatedAt?: InputMaybe<Scalars['DateTime']>;
  churnedAt?: InputMaybe<Scalars['DateTime']>;
  subscriptionStatus?: InputMaybe<SubscriptionStatus>;
  trialSignup?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsTrialTasks = {
  __typename?: 'SettingsTrialTasks';
  bookingWidget: Scalars['Boolean'];
  checkout: Scalars['Boolean'];
  companyInformation: Scalars['Boolean'];
  createAppointment: Scalars['Boolean'];
  customer: Scalars['Boolean'];
  employeeInfo: Scalars['Boolean'];
  onlineBooking: Scalars['Boolean'];
  openingHours: Scalars['Boolean'];
  servicesCategories: Scalars['Boolean'];
};

export type SettingsTrialTasksAttribute = {
  bookingWidget?: InputMaybe<Scalars['Boolean']>;
  checkout?: InputMaybe<Scalars['Boolean']>;
  companyInformation?: InputMaybe<Scalars['Boolean']>;
  createAppointment?: InputMaybe<Scalars['Boolean']>;
  customer?: InputMaybe<Scalars['Boolean']>;
  employeeInfo?: InputMaybe<Scalars['Boolean']>;
  onlineBooking?: InputMaybe<Scalars['Boolean']>;
  openingHours?: InputMaybe<Scalars['Boolean']>;
  servicesCategories?: InputMaybe<Scalars['Boolean']>;
};

export enum SignupReason {
  AllTasksInOnePlace = 'all_tasks_in_one_place',
  HaveNotStartedYet = 'have_not_started_yet',
  IAmUsingOtherSoftware = 'i_am_using_other_software',
  IAmUsingPaper = 'i_am_using_paper',
  IAmUsingPhone = 'i_am_using_phone',
  JustExploring = 'just_exploring',
  ManageMyBookings = 'manage_my_bookings',
  PromoteMySalon = 'promote_my_salon'
}

export enum SortDirectionEnum {
  /** ASC sort direction */
  Asc = 'ASC',
  /** DESC sort direction */
  Desc = 'DESC'
}

/** Autogenerated input type of SortResources */
export type SortResourcesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sortableAttributes?: InputMaybe<Array<SortableAttributes>>;
};

/** Autogenerated return type of SortResources. */
export type SortResourcesPayload = {
  __typename?: 'SortResourcesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<Resource>>;
};

/** Autogenerated input type of SortServiceCategories */
export type SortServiceCategoriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sortableAttributes?: InputMaybe<Array<SortableAttributes>>;
};

/** Autogenerated return type of SortServiceCategories. */
export type SortServiceCategoriesPayload = {
  __typename?: 'SortServiceCategoriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  serviceCategories?: Maybe<Array<ServiceCategory>>;
};

/** Autogenerated input type of SortServiceVariationGroups */
export type SortServiceVariationGroupsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sortableAttributes?: InputMaybe<Array<SortableAttributes>>;
};

/** Autogenerated return type of SortServiceVariationGroups. */
export type SortServiceVariationGroupsPayload = {
  __typename?: 'SortServiceVariationGroupsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  serviceVariationGroups?: Maybe<Array<ServiceVariationGroup>>;
};

/** Autogenerated input type of SortServices */
export type SortServicesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sortableAttributes?: InputMaybe<Array<SortableAttributes>>;
};

/** Autogenerated return type of SortServices. */
export type SortServicesPayload = {
  __typename?: 'SortServicesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Service>>;
};

export type SortableAttributes = {
  id: Scalars['ID'];
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export enum Status {
  Changed = 'CHANGED',
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type StockMutation = {
  __typename?: 'StockMutation';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  orderId?: Maybe<Scalars['Int']>;
  orderNumber?: Maybe<Scalars['String']>;
  originalOrderId?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  stockOrderId?: Maybe<Scalars['Int']>;
  stockOrderStatus?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type StockMutationAttributes = {
  amount: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
};

export type StockOrder = {
  __typename?: 'StockOrder';
  cid: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  items?: Maybe<Array<StockOrderItem>>;
  notes?: Maybe<Scalars['String']>;
  orderItems: Array<StockOrderItem>;
  orderedAt: Scalars['DateTime'];
  quantity: Scalars['Int'];
  status: Scalars['String'];
  supplier?: Maybe<Supplier>;
  total: Scalars['Int'];
};

export type StockOrderItem = {
  __typename?: 'StockOrderItem';
  costPrice?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  quantityReceived?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type StockOrderItemAttributes = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  costPrice?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  quantityReceived?: InputMaybe<Scalars['Int']>;
  stockOrderId?: InputMaybe<Scalars['Int']>;
};

export type StringAnswer = {
  __typename?: 'StringAnswer';
  answer?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  questionId: Scalars['Int'];
};

/** Autogenerated input type of StripeCancelSubscription */
export type StripeCancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of StripeCancelSubscription. */
export type StripeCancelSubscriptionPayload = {
  __typename?: 'StripeCancelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  status?: Maybe<Scalars['String']>;
};

export enum StripeCollectionMethod {
  ChargeAutomatically = 'CHARGE_AUTOMATICALLY',
  SendInvoice = 'SEND_INVOICE'
}

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  amountPaid?: Maybe<Scalars['Int']>;
  amountRemaining?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601Date'];
  currency: Currency;
  hostedInvoiceUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoicePdf?: Maybe<Scalars['String']>;
  realAmountRemaining?: Maybe<Scalars['Int']>;
  realStatus: InvoiceStatus;
  sealedAt?: Maybe<Scalars['ISO8601Date']>;
  status: InvoiceStatus;
  subscription: Scalars['Boolean'];
  total?: Maybe<Scalars['Int']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['ID'];
  periodEnd: Scalars['ISO8601Date'];
  periodStart: Scalars['ISO8601Date'];
  status: StripeSubscriptionStatus;
  stripeCustomerId: Scalars['ID'];
};

export type StripeSubscriptionItem = {
  __typename?: 'StripeSubscriptionItem';
  amountSubtotal: Scalars['Int'];
  amountTotal: Scalars['Int'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemType: SubscriptionPrice;
  quantity: Scalars['Int'];
};

export enum StripeSubscriptionStatus {
  Active = 'ACTIVE',
  All = 'ALL',
  Canceled = 'CANCELED',
  Ended = 'ENDED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Paused = 'PAUSED',
  Trailing = 'TRAILING',
  Unpaid = 'UNPAID'
}

export type StripeTaxId = {
  __typename?: 'StripeTaxId';
  country: Scalars['String'];
  value: Scalars['String'];
  verification: Scalars['JSON'];
};

export type Subscription = {
  __typename?: 'Subscription';
  adyenVerificationStatus: AdyenVerificationStatusPayload;
  calendarEvents: CalendarEventsPayload;
  fiscalizedOrder: FiscalizedOrderPayload;
  posTerminalPayment: PosTerminalPaymentPayload;
  userNotification: UserNotificationPayload;
};

export type SubscriptionCalendarEventsArgs = {
  viewType?: InputMaybe<CalendarView>;
};

export type SubscriptionFlowData = {
  __typename?: 'SubscriptionFlowData';
  countries: Scalars['JSON'];
  plans: Array<Plan>;
};

export enum SubscriptionPeriod {
  Month = 'MONTH',
  Year = 'YEAR'
}

export enum SubscriptionPlan {
  Appointments = 'APPOINTMENTS',
  Basic = 'BASIC',
  Pro = 'PRO',
  Register = 'REGISTER'
}

export type SubscriptionPlanItem = {
  __typename?: 'SubscriptionPlanItem';
  plan: SubscriptionPlan;
  prices: SubscriptionPriceItem;
};

export enum SubscriptionPrice {
  Base = 'BASE',
  Boost = 'BOOST',
  Locations = 'LOCATIONS',
  Resources = 'RESOURCES'
}

export type SubscriptionPriceItem = {
  __typename?: 'SubscriptionPriceItem';
  base: Array<SubscriptionPriceRow>;
  locations?: Maybe<Array<SubscriptionPriceRow>>;
  resources: Array<SubscriptionPriceRow>;
};

export type SubscriptionPriceRow = {
  __typename?: 'SubscriptionPriceRow';
  currency: Currency;
  unitAmount: Scalars['Int'];
  upTo?: Maybe<Scalars['Int']>;
};

export type SubscriptionPricing = {
  __typename?: 'SubscriptionPricing';
  period: SubscriptionPeriod;
  plans: Array<SubscriptionPlanItem>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Churned = 'CHURNED',
  Expired = 'EXPIRED',
  Trial = 'TRIAL'
}

export type Supplier = {
  __typename?: 'Supplier';
  address?: Maybe<Scalars['String']>;
  amountProducts: Scalars['Int'];
  amountProductsWithStock: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  postalcode?: Maybe<Scalars['String']>;
  productChangeAppliedAt?: Maybe<Scalars['DateTime']>;
  productChangeScheduleId?: Maybe<Scalars['Int']>;
  productChangeScheduledAt?: Maybe<Scalars['DateTime']>;
  totalInventory?: Maybe<Scalars['Float']>;
};

export type Template = {
  __typename?: 'Template';
  content: Scalars['String'];
  contentEditable: Scalars['Boolean'];
  email: Scalars['Boolean'];
  id: Scalars['String'];
  populatedContent: Scalars['String'];
  sms: Scalars['Boolean'];
  subject: Scalars['String'];
  subjectEditable: Scalars['Boolean'];
  variables: Array<Scalars['String']>;
};

export enum TemplateVariableEnum {
  Age = 'AGE',
  CancelLink = 'CANCEL_LINK',
  CancelUrl = 'CANCEL_URL',
  CompanyAddress = 'COMPANY_ADDRESS',
  CompanyEmail = 'COMPANY_EMAIL',
  CompanyLogo = 'COMPANY_LOGO',
  CompanyName = 'COMPANY_NAME',
  CompanyPhone = 'COMPANY_PHONE',
  CompanySocial = 'COMPANY_SOCIAL',
  Custom1 = 'CUSTOM1',
  Custom2 = 'CUSTOM2',
  Custom3 = 'CUSTOM3',
  Custom4 = 'CUSTOM4',
  Custom5 = 'CUSTOM5',
  Custom6 = 'CUSTOM6',
  Custom7 = 'CUSTOM7',
  Custom8 = 'CUSTOM8',
  Custom9 = 'CUSTOM9',
  Custom10 = 'CUSTOM10',
  Date = 'DATE',
  DateShort = 'DATE_SHORT',
  DaysSinceLastAppointment = 'DAYS_SINCE_LAST_APPOINTMENT',
  DiscountCode = 'DISCOUNT_CODE',
  DiscountCodeExpiresAt = 'DISCOUNT_CODE_EXPIRES_AT',
  DiscountCodeExpiresTextFormat = 'DISCOUNT_CODE_EXPIRES_TEXT_FORMAT',
  DiscountCodePercentage = 'DISCOUNT_CODE_PERCENTAGE',
  Employee = 'EMPLOYEE',
  FirstFiveDatesList = 'FIRST_FIVE_DATES_LIST',
  FirstName = 'FIRST_NAME',
  IntroductionText = 'INTRODUCTION_TEXT',
  LastName = 'LAST_NAME',
  OnlineBookingLink = 'ONLINE_BOOKING_LINK',
  PreviousDate = 'PREVIOUS_DATE',
  PreviousDateShort = 'PREVIOUS_DATE_SHORT',
  PreviousTime = 'PREVIOUS_TIME',
  RecurringRule = 'RECURRING_RULE',
  RescheduleLink = 'RESCHEDULE_LINK',
  RescheduleUrl = 'RESCHEDULE_URL',
  Salutation = 'SALUTATION',
  SalutationShort = 'SALUTATION_SHORT',
  Services = 'SERVICES',
  ServicesExtraInfo = 'SERVICES_EXTRA_INFO',
  ServicesList = 'SERVICES_LIST',
  Time = 'TIME',
  WeeksSinceLastAppointment = 'WEEKS_SINCE_LAST_APPOINTMENT'
}

export enum TerminalModel {
  Ams1 = 'AMS1',
  V400Cplus = 'V400CPLUS'
}

export type TimelineItem = {
  __typename?: 'TimelineItem';
  changes: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  data: Scalars['JSON'];
  id: Scalars['ID'];
  key: Scalars['String'];
  meta: Scalars['JSON'];
  recordId: Scalars['Int'];
  recordType: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ToggleMessagesPublishedFeedback */
export type ToggleMessagesPublishedFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of ToggleMessagesPublishedFeedback. */
export type ToggleMessagesPublishedFeedbackPayload = {
  __typename?: 'ToggleMessagesPublishedFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  feedback?: Maybe<Feedback>;
};

/** Autogenerated input type of ToggleRegisterDesign */
export type ToggleRegisterDesignInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newDesignEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ToggleRegisterDesign. */
export type ToggleRegisterDesignPayload = {
  __typename?: 'ToggleRegisterDesignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  registerNewDesign?: Maybe<Scalars['Boolean']>;
};

export type TotalsReport = {
  __typename?: 'TotalsReport';
  overview: TotalsReportOverview;
  paymentMethods: TotalsReportPaymentMethods;
  totals: TotalsReportTotals;
  vatRates: Array<TotalsReportVatRates>;
};

export type TotalsReportOverview = {
  __typename?: 'TotalsReportOverview';
  appointments: Scalars['Float'];
  customers: Scalars['Float'];
  orders: Scalars['Float'];
};

export type TotalsReportPaymentMethods = {
  __typename?: 'TotalsReportPaymentMethods';
  bank: Scalars['Float'];
  cash: Scalars['Float'];
  coupon: Scalars['Float'];
  creditcard: Scalars['Float'];
  giftcard: Scalars['Float'];
  online: Scalars['Float'];
  other: Scalars['Float'];
  outstanding: Scalars['Float'];
  pin: Scalars['Float'];
  pos: Scalars['Float'];
  prepaidCard: Scalars['Float'];
  treatwellOnlinePayment: Scalars['Float'];
};

export type TotalsReportTotals = {
  __typename?: 'TotalsReportTotals';
  discount: Scalars['Float'];
  drawerTransactions: Scalars['Int'];
  giftcardsSold: Scalars['Int'];
  prepaidCardsSold: Scalars['Int'];
  revenue: Scalars['Float'];
  revenueIncl: Scalars['Float'];
  revenueProducts: Scalars['Float'];
  revenueServices: Scalars['Float'];
  revenueTreatwellServices: Scalars['Float'];
  vat: Scalars['Int'];
};

export type TotalsReportVatRates = {
  __typename?: 'TotalsReportVatRates';
  percentage?: Maybe<Scalars['Float']>;
  revenue: Scalars['Float'];
  vat: Scalars['Float'];
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int'];
  cashupId?: Maybe<Scalars['Int']>;
  deletable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  giftcard?: Maybe<Giftcard>;
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  orderId?: Maybe<Scalars['ID']>;
  orderNumber?: Maybe<Scalars['String']>;
  posPayment?: Maybe<PosPayment>;
  prepaidCard?: Maybe<PrepaidCard>;
  transactionAt: Scalars['DateTime'];
  type: TransactionType;
};

export type TransactionAttributes = {
  amount?: InputMaybe<Scalars['Int']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  giftcardId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  locationId: Scalars['Int'];
  prepaidCardId?: InputMaybe<Scalars['ID']>;
  transactionAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<TransactionType>;
};

export enum TransactionType {
  Bank = 'BANK',
  Cash = 'CASH',
  Coupon = 'COUPON',
  Creditcard = 'CREDITCARD',
  Giftcard = 'GIFTCARD',
  Online = 'ONLINE',
  Other = 'OTHER',
  Pin = 'PIN',
  Pos = 'POS',
  PrepaidCard = 'PREPAID_CARD',
  TreatwellOnlinePayment = 'TREATWELL_ONLINE_PAYMENT'
}

export type TreatwellBankDetails = {
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  bankCode: Scalars['String'];
  bankName: Scalars['String'];
};

export type TreatwellChangeRequest = {
  __typename?: 'TreatwellChangeRequest';
  createdAt: Scalars['DateTime'];
  descriptionAtmosphere: Scalars['String'];
  descriptionBrandsProducts: Scalars['String'];
  descriptionExperience: Scalars['String'];
  descriptionExtras: Scalars['String'];
  descriptionSpecialities: Scalars['String'];
  descriptionTransports: Scalars['String'];
  id: Scalars['ID'];
};

export type TreatwellChangeRequestDescription = {
  descriptionAtmosphere?: InputMaybe<Scalars['String']>;
  descriptionBrandsProducts?: InputMaybe<Scalars['String']>;
  descriptionExperience?: InputMaybe<Scalars['String']>;
  descriptionExtras?: InputMaybe<Scalars['String']>;
  descriptionSpecialities?: InputMaybe<Scalars['String']>;
  descriptionTransports?: InputMaybe<Scalars['String']>;
};

export enum TreatwellContractType {
  Treatwell = 'TREATWELL',
  TreatwellDualPartners = 'TREATWELL_DUAL_PARTNERS',
  TreatwellOptOutCampaign = 'TREATWELL_OPT_OUT_CAMPAIGN'
}

export type TreatwellDashboardStatistics = {
  __typename?: 'TreatwellDashboardStatistics';
  appointmentCount: Scalars['Int'];
  grossRevenue: Scalars['Float'];
};

export enum TreatwellIntegrationFee {
  WithoutProPhotoshoot = 'WITHOUT_PRO_PHOTOSHOOT',
  WithProPhotoshoot = 'WITH_PRO_PHOTOSHOOT'
}

export type TreatwellIntegrationRequest = {
  __typename?: 'TreatwellIntegrationRequest';
  accountHolderName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  changeRequest?: Maybe<TreatwellChangeRequest>;
  contractSigned: Scalars['Boolean'];
  contractType?: Maybe<TreatwellContractType>;
  createdAt: Scalars['DateTime'];
  fee: TreatwellIntegrationFee;
  freeCommissionEndDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  informationSent: Scalars['Boolean'];
  photoshootDate?: Maybe<Scalars['DateTime']>;
  requested: Scalars['Boolean'];
  salonDescription?: Maybe<Scalars['String']>;
  serviceIds?: Maybe<Array<Scalars['ID']>>;
  state: TreatwellRequestState;
  venueImages?: Maybe<Array<Scalars['String']>>;
  venueType?: Maybe<TreatwellVenueType>;
};

export type TreatwellKycStatus = {
  __typename?: 'TreatwellKycStatus';
  bankAccountSynchronised: Scalars['Boolean'];
  kycCompleted: Scalars['Boolean'];
  onboardingUrl: Scalars['String'];
  twAccountCreated: Scalars['DateTime'];
};

export type TreatwellOrder = {
  __typename?: 'TreatwellOrder';
  id: Scalars['ID'];
  reference: Scalars['String'];
};

export enum TreatwellRequestSource {
  AnalyzeToggle = 'ANALYZE_TOGGLE',
  InAppLandingPage = 'IN_APP_LANDING_PAGE',
  LandingPageNewTrial = 'LANDING_PAGE_NEW_TRIAL',
  SparklesButton = 'SPARKLES_BUTTON',
  Unknown = 'UNKNOWN'
}

export enum TreatwellRequestState {
  Enabled = 'ENABLED',
  Failed = 'FAILED',
  IntegrationCancelled = 'INTEGRATION_CANCELLED',
  IntegrationRequested = 'INTEGRATION_REQUESTED',
  PendingAdditionalInfo = 'PENDING_ADDITIONAL_INFO',
  PendingContract = 'PENDING_CONTRACT',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  RequestCancelled = 'REQUEST_CANCELLED'
}

export type TreatwellRosterDay = {
  __typename?: 'TreatwellRosterDay';
  closingTime: Scalars['String'];
  day: DaysOfWeek;
  id: Scalars['ID'];
  openingTime: Scalars['String'];
};

export type TreatwellRosterDayAttributes = {
  closingTime: Scalars['String'];
  day: DaysOfWeek;
  openingTime: Scalars['String'];
};

export type TreatwellStatus = {
  __typename?: 'TreatwellStatus';
  bankDetailsProvided: Scalars['Boolean'];
  contractSigned: Scalars['Boolean'];
  contractType?: Maybe<Scalars['String']>;
  descriptionProvided: Scalars['Boolean'];
  directDebitEnabled: Scalars['Boolean'];
  freeCommissionRemainingDays: Scalars['Int'];
  hasRequest: Scalars['Boolean'];
  hasVenue: Scalars['Boolean'];
  isPastFreeCommissionPeriod: Scalars['Boolean'];
  kycStatus?: Maybe<TreatwellKycStatus>;
  optOutCampaign: Scalars['Boolean'];
  reviewsPublished: Scalars['Boolean'];
  subscriptionIntention: Scalars['Boolean'];
  twHardNo: Scalars['Boolean'];
  venuePending: Scalars['Boolean'];
};

export type TreatwellTreatment = {
  __typename?: 'TreatwellTreatment';
  group: TreatwellTreatmentGroup;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type TreatwellTreatmentGroup = {
  __typename?: 'TreatwellTreatmentGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type TreatwellVenue = {
  __typename?: 'TreatwellVenue';
  bankAccountHolder?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  ddEnabledDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  images?: Maybe<Array<Scalars['String']>>;
  treatwellRosterDays?: Maybe<Array<TreatwellRosterDay>>;
  url?: Maybe<Scalars['String']>;
  venueType?: Maybe<Scalars['String']>;
};

export enum TreatwellVenueType {
  Barbershop = 'BARBERSHOP',
  BeautySalon = 'BEAUTY_SALON',
  BrowBar = 'BROW_BAR',
  DaySpa = 'DAY_SPA',
  HairSalon = 'HAIR_SALON',
  MassageAndTherapyCentre = 'MASSAGE_AND_THERAPY_CENTRE',
  NailSalon = 'NAIL_SALON',
  SkinClinic = 'SKIN_CLINIC',
  WaxingSalon = 'WAXING_SALON'
}

export type TreeMapInsight = {
  __typename?: 'TreeMapInsight';
  date: Scalars['ISO8601DateTime'];
  ratio: Scalars['Float'];
  sum: Scalars['Int'];
};

export enum TwoFactorMode {
  AuthenticatorApp = 'AUTHENTICATOR_APP',
  NotDefined = 'NOT_DEFINED',
  Sms = 'SMS'
}

export type TwoFactorOtpSecret = {
  __typename?: 'TwoFactorOtpSecret';
  otpQr?: Maybe<Scalars['String']>;
  otpRaw?: Maybe<Scalars['String']>;
};

export type TwoFactorSettings = {
  __typename?: 'TwoFactorSettings';
  mode?: Maybe<TwoFactorMode>;
  status: EnabledDisabled;
};

/** Autogenerated input type of UnpauseSubscription */
export type UnpauseSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UnpauseSubscription. */
export type UnpauseSubscriptionPayload = {
  __typename?: 'UnpauseSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UnpublishFeedback */
export type UnpublishFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UnpublishFeedback. */
export type UnpublishFeedbackPayload = {
  __typename?: 'UnpublishFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  feedback: Feedback;
};

/** Autogenerated input type of UpdateAbsence */
export type UpdateAbsenceInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  chore?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  deleteEntry?: InputMaybe<Scalars['Boolean']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  locationId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  recurrenceStartAt?: InputMaybe<Scalars['ISO8601Date']>;
  recurrenceUpdateState?: InputMaybe<Scalars['String']>;
  resourceId?: InputMaybe<Scalars['Int']>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of UpdateAbsence. */
export type UpdateAbsencePayload = {
  __typename?: 'UpdateAbsencePayload';
  absence?: Maybe<Absence>;
  calendarAbsences?: Maybe<Array<CalendarAbsence>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateAdyenAppState */
export type UpdateAdyenAppStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  terminalModel: TerminalModel;
};

/** Autogenerated return type of UpdateAdyenAppState. */
export type UpdateAdyenAppStatePayload = {
  __typename?: 'UpdateAdyenAppStatePayload';
  adyenAppState?: Maybe<AdyenAppState>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateAppointment */
export type UpdateAppointmentInput = {
  calendarLabelId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerAttributes?: InputMaybe<CustomerAttributes>;
  customerId?: InputMaybe<Scalars['Int']>;
  deleteEntry?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  locationId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  partsAttributes?: InputMaybe<Array<AppointmentPartAttributes>>;
  recurrenceStartAt?: InputMaybe<Scalars['ISO8601Date']>;
  recurrenceUpdateState?: InputMaybe<Scalars['String']>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<Scalars['String']>;
  viewType?: CalendarView;
};

/** Autogenerated return type of UpdateAppointment. */
export type UpdateAppointmentPayload = {
  __typename?: 'UpdateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  calendarAppointments?: Maybe<Array<CalendarAppointment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateAppointmentReport */
export type UpdateAppointmentReportInput = {
  attachmentsAttributes?: InputMaybe<Array<AttachmentAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  report?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAppointmentReport. */
export type UpdateAppointmentReportPayload = {
  __typename?: 'UpdateAppointmentReportPayload';
  appointmentReport?: Maybe<AppointmentReport>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateBillingInfo */
export type UpdateBillingInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dontHaveVatNumber?: InputMaybe<Scalars['Boolean']>;
  iban?: InputMaybe<Scalars['String']>;
  ibanBic?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateBillingInfo. */
export type UpdateBillingInfoPayload = {
  __typename?: 'UpdateBillingInfoPayload';
  billingInfo?: Maybe<BillingInfo>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateCalendarLabel */
export type UpdateCalendarLabelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCalendarLabel. */
export type UpdateCalendarLabelPayload = {
  __typename?: 'UpdateCalendarLabelPayload';
  calendarLabel?: Maybe<CalendarLabel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateCompanyEmailTemplate */
export type UpdateCompanyEmailTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCompanyEmailTemplate. */
export type UpdateCompanyEmailTemplatePayload = {
  __typename?: 'UpdateCompanyEmailTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyEmailTemplate?: Maybe<CompanyEmailTemplate>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateCompany */
export type UpdateCompanyInput = {
  address?: InputMaybe<Scalars['String']>;
  billingInfo?: InputMaybe<BillingInfoAttributes>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyType?: InputMaybe<CompanyType>;
  contactEmail?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currency>;
  emailReminderHours?: InputMaybe<Scalars['Int']>;
  facebookId?: InputMaybe<Scalars['String']>;
  giftcardPersonalMessage?: InputMaybe<Scalars['String']>;
  instagramId?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  invoiceFooter?: InputMaybe<Scalars['String']>;
  invoiceFooterThermal?: InputMaybe<Scalars['String']>;
  invoiceHeader?: InputMaybe<Scalars['String']>;
  invoiceImage?: InputMaybe<Scalars['String']>;
  invoiceRecipientTemplate?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<CompanyLanguage>;
  languagePublic?: InputMaybe<CompanyPublicLanguage>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notificationEmail?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<SettingsAttributes>;
  signupReason?: InputMaybe<SignupReason>;
  smsNotificationNumber?: InputMaybe<Scalars['String']>;
  smsReminderHours?: InputMaybe<Scalars['Int']>;
  smsSenderId?: InputMaybe<Scalars['String']>;
  termsAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  timeZone?: InputMaybe<Scalars['String']>;
  twitterId?: InputMaybe<Scalars['String']>;
  updateNameRelatedFields?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateCompany. */
export type UpdateCompanyPayload = {
  __typename?: 'UpdateCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateCompanySmsTemplate */
export type UpdateCompanySmsTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCompanySmsTemplate. */
export type UpdateCompanySmsTemplatePayload = {
  __typename?: 'UpdateCompanySmsTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companySmsTemplate?: Maybe<CompanySmsTemplate>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateCustomerFilterGroup */
export type UpdateCustomerFilterGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  filters?: Array<CustomerFilterInput>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateCustomerFilterGroup. */
export type UpdateCustomerFilterGroupPayload = {
  __typename?: 'UpdateCustomerFilterGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerFilterGroup?: Maybe<CustomerFilterGroup>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateCustomer */
export type UpdateCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerAttributes?: InputMaybe<CustomerAttributes>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCustomer. */
export type UpdateCustomerPayload = {
  __typename?: 'UpdateCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateCustomerTag */
export type UpdateCustomerTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateCustomerTag. */
export type UpdateCustomerTagPayload = {
  __typename?: 'UpdateCustomerTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerTag?: Maybe<CustomerTag>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateCustomerTags */
export type UpdateCustomerTagsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerTags: Array<CustomerTagInput>;
};

/** Autogenerated return type of UpdateCustomerTags. */
export type UpdateCustomerTagsPayload = {
  __typename?: 'UpdateCustomerTagsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customerTags?: Maybe<Array<CustomerTag>>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateDirectDebit */
export type UpdateDirectDebitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateDirectDebit. */
export type UpdateDirectDebitPayload = {
  __typename?: 'UpdateDirectDebitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  directDebitEnabled?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateDiscountCode */
export type UpdateDiscountCodeInput = {
  appliesTo?: InputMaybe<DiscountCodeAppliesToEnum>;
  category?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  expiresIn: DiscountCodeExpiresInEnum;
  hasLimit: Scalars['Boolean'];
  id: Scalars['Int'];
  oncePerCustomer: Scalars['Boolean'];
  state: DiscountCodeStateEnum;
  usageLimit?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateDiscountCode. */
export type UpdateDiscountCodePayload = {
  __typename?: 'UpdateDiscountCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  discountCode?: Maybe<DiscountCode>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateDrawer */
export type UpdateDrawerInput = {
  amount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  locationId: Scalars['ID'];
  transactionAt: Scalars['DateTime'];
};

/** Autogenerated return type of UpdateDrawer. */
export type UpdateDrawerPayload = {
  __typename?: 'UpdateDrawerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  drawer?: Maybe<Drawer>;
};

/** Autogenerated input type of UpdateDynamicPrice */
export type UpdateDynamicPriceInput = {
  adjustmentType?: InputMaybe<AdjustmentType>;
  amount?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  deleteEntry?: InputMaybe<Scalars['Boolean']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  locationId?: InputMaybe<Scalars['Int']>;
  recurrenceStartAt?: InputMaybe<Scalars['ISO8601Date']>;
  recurrenceUpdateState?: InputMaybe<Scalars['String']>;
  rrule?: InputMaybe<RruleAttributes>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of UpdateDynamicPrice. */
export type UpdateDynamicPricePayload = {
  __typename?: 'UpdateDynamicPricePayload';
  calendarDynamicPrices?: Maybe<Array<CalendarDynamicPrice>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dynamicPrice?: Maybe<DynamicPrice>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateEmailTemplate */
export type UpdateEmailTemplateInput = {
  buttonTitle?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  deliverAt?: InputMaybe<Scalars['DateTime']>;
  discountCodeId?: InputMaybe<Scalars['ID']>;
  discountMessage?: InputMaybe<Scalars['String']>;
  dispatchType?: InputMaybe<DispatchTypeEnum>;
  emailType: EmailTemplateEnum;
  filterGroupId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<CustomerFilterInput>>;
  hasButton?: InputMaybe<Scalars['Boolean']>;
  hasDeliverySchedule?: InputMaybe<Scalars['Boolean']>;
  hasDiscount?: InputMaybe<Scalars['Boolean']>;
  hasFooter?: InputMaybe<Scalars['Boolean']>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  hasLogo?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEmailTemplate. */
export type UpdateEmailTemplatePayload = {
  __typename?: 'UpdateEmailTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<EmailTemplate>;
};

/** Autogenerated input type of UpdateFeedback */
export type UpdateFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  messagesPublished?: InputMaybe<Scalars['Boolean']>;
  pushToTreatwell?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<FeedbackState>;
  twPublishedAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of UpdateFeedback. */
export type UpdateFeedbackPayload = {
  __typename?: 'UpdateFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  feedback?: Maybe<Feedback>;
};

/** Autogenerated input type of UpdateFeedbackResponse */
export type UpdateFeedbackResponseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  feedbackId: Scalars['Int'];
  id: Scalars['Int'];
  text: Scalars['String'];
};

/** Autogenerated return type of UpdateFeedbackResponse. */
export type UpdateFeedbackResponsePayload = {
  __typename?: 'UpdateFeedbackResponsePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  response?: Maybe<Message>;
};

/** Autogenerated input type of UpdateFormSubmission */
export type UpdateFormSubmissionInput = {
  answersAttributes: Array<FormAnswerAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateFormSubmission. */
export type UpdateFormSubmissionPayload = {
  __typename?: 'UpdateFormSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  formSubmission?: Maybe<FormSubmission>;
};

/** Autogenerated input type of UpdateFormTemplate */
export type UpdateFormTemplateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  introText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  questionsAttributes?: InputMaybe<Array<FormQuestionAttributes>>;
  sendAutomatically?: InputMaybe<Scalars['Boolean']>;
  serviceIds?: InputMaybe<Array<Scalars['Int']>>;
  text?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateFormTemplate. */
export type UpdateFormTemplatePayload = {
  __typename?: 'UpdateFormTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  formTemplate?: Maybe<FormTemplate>;
};

/** Autogenerated input type of UpdateFranchiseApp */
export type UpdateFranchiseAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<FranchiseAppPermissionAttributes>;
};

/** Autogenerated return type of UpdateFranchiseApp. */
export type UpdateFranchiseAppPayload = {
  __typename?: 'UpdateFranchiseAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  franchiseApp?: Maybe<FranchiseApp>;
};

/** Autogenerated input type of UpdateGoogleCalendarApp */
export type UpdateGoogleCalendarAppInput = {
  calendars?: InputMaybe<Array<GoogleCalendarCalendarAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyCalendar?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mode?: Scalars['String'];
};

/** Autogenerated return type of UpdateGoogleCalendarApp. */
export type UpdateGoogleCalendarAppPayload = {
  __typename?: 'UpdateGoogleCalendarAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  googleCalendarApp?: Maybe<GoogleCalendarApp>;
};

/** Autogenerated input type of UpdateImport */
export type UpdateImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  columnMapping?: InputMaybe<CustomerImportMappingOrder>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateImport. */
export type UpdateImportPayload = {
  __typename?: 'UpdateImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  import: Import;
};

/** Autogenerated input type of UpdateIplTreatment */
export type UpdateIplTreatmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  treatments?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateIplTreatment. */
export type UpdateIplTreatmentPayload = {
  __typename?: 'UpdateIplTreatmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  iplTreatment?: Maybe<IplTreatment>;
};

/** Autogenerated input type of UpdateIplTreatmentReport */
export type UpdateIplTreatmentReportInput = {
  amount?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdateIplTreatmentReport. */
export type UpdateIplTreatmentReportPayload = {
  __typename?: 'UpdateIplTreatmentReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  iplTreatmentReport?: Maybe<IplTreatmentReport>;
};

/** Autogenerated input type of UpdateKycStatus */
export type UpdateKycStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  kycCompleted: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateKycStatus. */
export type UpdateKycStatusPayload = {
  __typename?: 'UpdateKycStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  kycCompleted?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateLastMinuteDiscounts */
export type UpdateLastMinuteDiscountsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  discountWindow?: InputMaybe<LastMinuteDiscountWindowEnum>;
  enabled: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateLastMinuteDiscounts. */
export type UpdateLastMinuteDiscountsPayload = {
  __typename?: 'UpdateLastMinuteDiscountsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lastMinuteDiscounts: LastMinuteDiscounts;
};

/** Autogenerated input type of UpdateLastVisit */
export type UpdateLastVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  keyword: Scalars['String'];
};

/** Autogenerated return type of UpdateLastVisit. */
export type UpdateLastVisitPayload = {
  __typename?: 'UpdateLastVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateLocation */
export type UpdateLocationInput = {
  address?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bookable?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateLocation. */
export type UpdateLocationPayload = {
  __typename?: 'UpdateLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  location?: Maybe<Location>;
};

/** Autogenerated input type of UpdateMailchimpApp */
export type UpdateMailchimpAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  listId: Scalars['ID'];
};

/** Autogenerated return type of UpdateMailchimpApp. */
export type UpdateMailchimpAppPayload = {
  __typename?: 'UpdateMailchimpAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  mailchimpApp?: Maybe<CompanyApp>;
};

/** Autogenerated input type of UpdateMicroSite */
export type UpdateMicroSiteInput = {
  baseColor?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customCss?: InputMaybe<Scalars['String']>;
  customDomain?: InputMaybe<Scalars['String']>;
  gaId?: InputMaybe<Scalars['ID']>;
  headerImage?: InputMaybe<Scalars['String']>;
  headerImageTransparency?: InputMaybe<Scalars['Boolean']>;
  headerSubtitle?: InputMaybe<Scalars['String']>;
  headerTitle?: InputMaybe<Scalars['String']>;
  impressum?: InputMaybe<Scalars['String']>;
  introText?: InputMaybe<Scalars['String']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showVoucherWidget?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateMicroSite. */
export type UpdateMicroSitePayload = {
  __typename?: 'UpdateMicroSitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  microSite?: Maybe<MicroSite>;
};

/** Autogenerated input type of UpdateMollieApp */
export type UpdateMollieAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  profileId: Scalars['String'];
};

/** Autogenerated return type of UpdateMollieApp. */
export type UpdateMollieAppPayload = {
  __typename?: 'UpdateMollieAppPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  mollieApp?: Maybe<MollieApp>;
};

/** Autogenerated input type of UpdateNote */
export type UpdateNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  id: Scalars['Int'];
  resourceId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateNote. */
export type UpdateNotePayload = {
  __typename?: 'UpdateNotePayload';
  calendarNote?: Maybe<CalendarNote>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  note?: Maybe<Note>;
};

/** Autogenerated input type of UpdateOnboardingTask */
export type UpdateOnboardingTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  task: OnboardingTaskEnum;
  taskStatus: OnboardingTaskStatusEnum;
};

/** Autogenerated return type of UpdateOnboardingTask. */
export type UpdateOnboardingTaskPayload = {
  __typename?: 'UpdateOnboardingTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  onboardingTask?: Maybe<OnboardingTask>;
};

/** Autogenerated input type of UpdateOrder */
export type UpdateOrderInput = {
  appointmentIds?: InputMaybe<Array<Scalars['Int']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  draft?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  invoicedAt?: InputMaybe<Scalars['DateTime']>;
  items?: InputMaybe<Array<OrderItemAttributes>>;
  locationId?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  originalOrderId?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  transactions?: InputMaybe<Array<TransactionAttributes>>;
};

/** Autogenerated return type of UpdateOrder. */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdatePosTerminal */
export type UpdatePosTerminalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PosTerminalStatus>;
};

/** Autogenerated return type of UpdatePosTerminal. */
export type UpdatePosTerminalPayload = {
  __typename?: 'UpdatePosTerminalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  posTerminal: PosTerminal;
};

/** Autogenerated input type of UpdateProductCategory */
export type UpdateProductCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateProductCategory. */
export type UpdateProductCategoryPayload = {
  __typename?: 'UpdateProductCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  productCategory?: Maybe<ProductCategory>;
};

/** Autogenerated input type of UpdateProduct */
export type UpdateProductInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  costPrice?: InputMaybe<Scalars['Int']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  locationId?: InputMaybe<Scalars['ID']>;
  medical?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  partNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['String']>;
  stockAmount?: InputMaybe<Scalars['Int']>;
  stockEnabled?: InputMaybe<Scalars['Boolean']>;
  stockMaximum?: InputMaybe<Scalars['Int']>;
  stockMinimum?: InputMaybe<Scalars['Int']>;
  stockMutation?: InputMaybe<StockMutationAttributes>;
  supplierId?: InputMaybe<Scalars['Int']>;
  vatRateId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateProduct. */
export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  product?: Maybe<Product>;
};

/** Autogenerated input type of UpdateResource */
export type UpdateResourceInput = {
  bookable?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  customRoster?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  rosterLocationId?: InputMaybe<Scalars['Int']>;
  serviceIds?: InputMaybe<Array<Scalars['Int']>>;
  state?: InputMaybe<ResourceState>;
  type?: InputMaybe<ResourceType>;
};

/** Autogenerated return type of UpdateResource. */
export type UpdateResourcePayload = {
  __typename?: 'UpdateResourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Resource>;
};

/** Autogenerated input type of UpdateServiceCategory */
export type UpdateServiceCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateServiceCategory. */
export type UpdateServiceCategoryPayload = {
  __typename?: 'UpdateServiceCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  serviceCategory?: Maybe<ServiceCategory>;
};

/** Autogenerated input type of UpdateServiceGroup */
export type UpdateServiceGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  servicesAttributes: Array<ServiceAttributes>;
  top?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateServiceGroup. */
export type UpdateServiceGroupPayload = {
  __typename?: 'UpdateServiceGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  serviceGroup?: Maybe<ServiceGroup>;
};

/** Autogenerated input type of UpdateService */
export type UpdateServiceInput = {
  bookable?: InputMaybe<Scalars['Boolean']>;
  bookableLocationIds?: InputMaybe<Array<Scalars['Int']>>;
  buffer?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  durationFinish?: InputMaybe<Scalars['Int']>;
  durationProcessing?: InputMaybe<Scalars['Int']>;
  durationSetup?: InputMaybe<Scalars['Int']>;
  extraInformation?: InputMaybe<Scalars['String']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  medical?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  rebookEnabled?: InputMaybe<Scalars['Boolean']>;
  requirementsAttributes?: InputMaybe<Array<RequirementAttributes>>;
  requiresProcessingTime?: InputMaybe<Scalars['Boolean']>;
  resourceAdjustmentsAttributes?: InputMaybe<
    Array<ResourceAdjustmentAttributes>
  >;
  rwgName?: InputMaybe<Scalars['String']>;
  serviceOfferingEnabled?: InputMaybe<Scalars['Boolean']>;
  vatRateId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateService. */
export type UpdateServicePayload = {
  __typename?: 'UpdateServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of UpdateServiceVariationGroup */
export type UpdateServiceVariationGroupInput = {
  bookable?: InputMaybe<Scalars['Boolean']>;
  bookableLocationIds?: InputMaybe<Array<Scalars['Int']>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  extraInformation?: InputMaybe<Scalars['String']>;
  favorite?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  medical?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  rebookEnabled?: InputMaybe<Scalars['Boolean']>;
  requirementsAttributes?: InputMaybe<Array<RequirementAttributes>>;
  rwgName?: InputMaybe<Scalars['String']>;
  serviceOfferingAttributes?: InputMaybe<ServiceOfferingAttributes>;
  serviceOfferingEnabled?: InputMaybe<Scalars['Boolean']>;
  top?: InputMaybe<Scalars['Boolean']>;
  variations?: InputMaybe<Array<ServiceVariationAttributes>>;
  vatRateId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateServiceVariationGroup. */
export type UpdateServiceVariationGroupPayload = {
  __typename?: 'UpdateServiceVariationGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  serviceVariationGroup?: Maybe<ServiceVariationGroup>;
};

/** Autogenerated input type of UpdateServices */
export type UpdateServicesInput = {
  bulkUpdateServiceAttributes: Array<BulkUpdateServiceAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateServices. */
export type UpdateServicesPayload = {
  __typename?: 'UpdateServicesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  services?: Maybe<Array<Service>>;
};

/** Autogenerated input type of UpdateStockOrder */
export type UpdateStockOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  itemsAttributes?: InputMaybe<Array<StockOrderItemAttributes>>;
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateStockOrder. */
export type UpdateStockOrderPayload = {
  __typename?: 'UpdateStockOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  stockOrder?: Maybe<StockOrder>;
};

/** Autogenerated input type of UpdateStripeTaxId */
export type UpdateStripeTaxIdInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  vatNumber: Scalars['String'];
};

/** Autogenerated return type of UpdateStripeTaxId. */
export type UpdateStripeTaxIdPayload = {
  __typename?: 'UpdateStripeTaxIdPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  stripeTaxId?: Maybe<StripeTaxId>;
};

/** Autogenerated input type of UpdateSubscriptionCollectionMethod */
export type UpdateSubscriptionCollectionMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectionMethod: StripeCollectionMethod;
};

/** Autogenerated return type of UpdateSubscriptionCollectionMethod. */
export type UpdateSubscriptionCollectionMethodPayload = {
  __typename?: 'UpdateSubscriptionCollectionMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  subscription?: Maybe<CompanySubscription>;
};

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
  cancelReasons: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateSubscription. */
export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  subscription?: Maybe<CompanySubscription>;
};

/** Autogenerated input type of UpdateSupplier */
export type UpdateSupplierInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  postalcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateSupplier. */
export type UpdateSupplierPayload = {
  __typename?: 'UpdateSupplierPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  supplier?: Maybe<Supplier>;
};

/** Autogenerated input type of UpdateTopServiceVariationGroups */
export type UpdateTopServiceVariationGroupsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  serviceVariationGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateTopServiceVariationGroups. */
export type UpdateTopServiceVariationGroupsPayload = {
  __typename?: 'UpdateTopServiceVariationGroupsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  serviceVariationGroups: Array<ServiceVariationGroup>;
};

/** Autogenerated input type of UpdateTreatwellIntent */
export type UpdateTreatwellIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionIntention: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateTreatwellIntent. */
export type UpdateTreatwellIntentPayload = {
  __typename?: 'UpdateTreatwellIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of UpdateTreatwellPilotSubscription */
export type UpdateTreatwellPilotSubscriptionInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTreatwellPilotSubscription. */
export type UpdateTreatwellPilotSubscriptionPayload = {
  __typename?: 'UpdateTreatwellPilotSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  errors?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of UpdateTreatwellRoster */
export type UpdateTreatwellRosterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rosterDays: Array<TreatwellRosterDayAttributes>;
};

/** Autogenerated return type of UpdateTreatwellRoster. */
export type UpdateTreatwellRosterPayload = {
  __typename?: 'UpdateTreatwellRosterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellVenue?: Maybe<TreatwellVenue>;
};

/** Autogenerated input type of UpdateTreatwellVenue */
export type UpdateTreatwellVenueInput = {
  bankingDetails?: InputMaybe<TreatwellBankDetails>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  venueType?: InputMaybe<TreatwellVenueType>;
};

/** Autogenerated return type of UpdateTreatwellVenue. */
export type UpdateTreatwellVenuePayload = {
  __typename?: 'UpdateTreatwellVenuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellVenue?: Maybe<TreatwellVenue>;
};

/** Autogenerated input type of UpdateTwoFactorSettings */
export type UpdateTwoFactorSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<TwoFactorMode>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status: EnabledDisabled;
  twoFactorCode: Scalars['String'];
  userPassword: Scalars['String'];
};

/** Autogenerated return type of UpdateTwoFactorSettings. */
export type UpdateTwoFactorSettingsPayload = {
  __typename?: 'UpdateTwoFactorSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  twoFactorSettings?: Maybe<TwoFactorSettings>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  admin?: InputMaybe<Scalars['Boolean']>;
  basic?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentPassword?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  manager?: InputMaybe<Scalars['Boolean']>;
  notificationAttributes?: InputMaybe<NotificationAttributes>;
  owner?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  passwordConfirmation?: InputMaybe<Scalars['String']>;
  reports?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<UserSettingsAttributes>;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateVatRates */
export type UpdateVatRatesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  vatRates: Array<VatRateInput>;
};

/** Autogenerated return type of UpdateVatRates. */
export type UpdateVatRatesPayload = {
  __typename?: 'UpdateVatRatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  vatRates: Array<VatRate>;
};

/** Autogenerated input type of UpgradeSubscription */
export type UpgradeSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  plan: Scalars['String'];
};

/** Autogenerated return type of UpgradeSubscription. */
export type UpgradeSubscriptionPayload = {
  __typename?: 'UpgradeSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  subscription?: Maybe<CompanySubscription>;
};

/** Autogenerated input type of UploadImage */
export type UploadImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** base64 encoded file contents */
  file: Scalars['String'];
  filename: Scalars['String'];
  lastModified: Scalars['DateTime'];
  mimeType: Scalars['String'];
  usage: UploadedImageUsage;
};

/** Autogenerated return type of UploadImage. */
export type UploadImagePayload = {
  __typename?: 'UploadImagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  image?: Maybe<Image>;
};

/** Autogenerated input type of UploadImport */
export type UploadImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** base64 encoded file contents */
  file: Scalars['String'];
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  /** either customers or products */
  resource: Scalars['String'];
};

/** Autogenerated return type of UploadImport. */
export type UploadImportPayload = {
  __typename?: 'UploadImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  import?: Maybe<Import>;
};

export enum UploadedImageUsage {
  EmailImages = 'EMAIL_IMAGES',
  NewsletterContent = 'NEWSLETTER_CONTENT',
  SalonizedEmployees = 'SALONIZED_EMPLOYEES',
  SalonizedEmployeesBgRemoval = 'SALONIZED_EMPLOYEES_BG_REMOVAL'
}

/** Autogenerated input type of UpsertChangeRequest */
export type UpsertChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<TreatwellIntegrationFee>;
  photoshootDate?: InputMaybe<Scalars['ISO8601Date']>;
  salonDescription?: InputMaybe<TreatwellChangeRequestDescription>;
};

/** Autogenerated return type of UpsertChangeRequest. */
export type UpsertChangeRequestPayload = {
  __typename?: 'UpsertChangeRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['JSON']>;
  treatwellIntegrationRequest?: Maybe<TreatwellIntegrationRequest>;
};

/** Autogenerated input type of UpsertNpsFeedback */
export type UpsertNpsFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  remindLater?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpsertNpsFeedback. */
export type UpsertNpsFeedbackPayload = {
  __typename?: 'UpsertNpsFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  npsFeedback?: Maybe<NpsFeedback>;
};

export type User = {
  __typename?: 'User';
  admin: Scalars['Boolean'];
  basic: Scalars['Boolean'];
  calendarIcsUrl: Scalars['String'];
  company?: Maybe<Company>;
  email: Scalars['String'];
  featureFlags: Scalars['JSON'];
  feedbacks: Scalars['Int'];
  firstName: Scalars['String'];
  franchiseAdmin: Scalars['Boolean'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  impersonated: Scalars['Boolean'];
  intercomHash: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  manager: Scalars['Boolean'];
  /** @deprecated Use 'settings.notifications' instead. */
  notifications: Scalars['JSON'];
  npsFeedbackDue?: Maybe<Scalars['Boolean']>;
  owner: Scalars['Boolean'];
  realUserName?: Maybe<Scalars['String']>;
  reports: Scalars['Boolean'];
  salonizedAdmin: Scalars['Boolean'];
  settings: UserSettings;
  showNewFeaturesNotification: Scalars['Boolean'];
  treatwellAdmin: Scalars['Boolean'];
  twoFactorSettings?: Maybe<TwoFactorSettings>;
  verified: Scalars['Boolean'];
};

export type UserAttributes = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Autogenerated return type of UserNotification. */
export type UserNotificationPayload = {
  __typename?: 'UserNotificationPayload';
  scope: UserNotificationScope;
  type: UserNotificationType;
};

export enum UserNotificationScope {
  Admin = 'ADMIN',
  Base = 'BASE',
  Calendar = 'CALENDAR',
  Customers = 'CUSTOMERS',
  Dashboard = 'DASHBOARD',
  Feedback = 'FEEDBACK',
  Marketing = 'MARKETING',
  Messages = 'MESSAGES',
  Products = 'PRODUCTS',
  Promote = 'PROMOTE',
  Register = 'REGISTER',
  Reports = 'REPORTS',
  Support = 'SUPPORT'
}

export enum UserNotificationType {
  Log = 'LOG',
  Reload = 'RELOAD'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  notifications: UserSettingsNotifications;
  pushNotifications: UserSettingsPushNotifications;
  security: UserSettingsSecurity;
};

export type UserSettingsAttributes = {
  notifications?: InputMaybe<UserSettingsNotificationsAttribute>;
  pushNotifications?: InputMaybe<UserSettingsPushNotificationsAttribute>;
  security?: InputMaybe<UserSettingsSecurityAttribute>;
};

export type UserSettingsNotifications = {
  __typename?: 'UserSettingsNotifications';
  dayReports: Scalars['Boolean'];
  tradeShowDemoUser: Scalars['Boolean'];
  updates: Scalars['Boolean'];
};

export type UserSettingsNotificationsAttribute = {
  dayReports?: InputMaybe<Scalars['Boolean']>;
  tradeShowDemoUser?: InputMaybe<Scalars['Boolean']>;
  updates?: InputMaybe<Scalars['Boolean']>;
};

export type UserSettingsPushNotifications = {
  __typename?: 'UserSettingsPushNotifications';
  newAppointmentInDays: Scalars['Int'];
};

export type UserSettingsPushNotificationsAttribute = {
  newAppointmentInDays?: InputMaybe<Scalars['Int']>;
};

export type UserSettingsSecurity = {
  __typename?: 'UserSettingsSecurity';
  autoSessionTimeout: Scalars['Boolean'];
  twoFactorAuthEnabled: Scalars['Boolean'];
};

export type UserSettingsSecurityAttribute = {
  autoSessionTimeout?: InputMaybe<Scalars['Boolean']>;
  twoFactorAuthEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of ValidateImport */
export type ValidateImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  correctedData?: InputMaybe<Array<CustomerAttributes>>;
  importId: Scalars['ID'];
};

/** Autogenerated return type of ValidateImport. */
export type ValidateImportPayload = {
  __typename?: 'ValidateImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  import: Import;
  recordAttributes?: Maybe<Array<CustomerImport>>;
  successfulRecords?: Maybe<Scalars['Int']>;
  unsuccessfulRecords?: Maybe<Scalars['Int']>;
};

export type VatRate = {
  __typename?: 'VatRate';
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  percentage?: Maybe<Scalars['Float']>;
  /**
   * selectedByDefault is true when vat_rate was nil on the product model and the
   * returned vat_rate is the default from the company, and false when the returned
   * vat_rate was selected on the product.
   */
  selectedByDefault: Scalars['Boolean'];
};

export type VatRateInput = {
  default: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  /** if percentage differs from what is already stored, it will return a newly create vat_rate object */
  percentage?: InputMaybe<Scalars['Float']>;
};

export type VatReport = {
  __typename?: 'VatReport';
  monthlyTurnoverTotals: Array<Scalars['Float']>;
  monthlyVatTotals: Array<Scalars['Float']>;
  percentages: Array<VatReportPercentage>;
  quarterlyTurnoverTotals: Array<Scalars['Float']>;
  quarterlyVatTotals: Array<Scalars['Float']>;
  year: Scalars['Float'];
};

export type VatReportPercentage = {
  __typename?: 'VatReportPercentage';
  monthlyTurnover: Array<Scalars['Float']>;
  monthlyVat: Array<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  quarterlyTurnover: Array<Scalars['Float']>;
  quarterlyVat: Array<Scalars['Float']>;
};

export type VatSummary = {
  __typename?: 'VatSummary';
  percentage?: Maybe<Scalars['Float']>;
  priceExcl: Scalars['Int'];
  unitVat: Scalars['Int'];
};

export type VerificationError = {
  __typename?: 'VerificationError';
  errorCode: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
  errorType: VerificationErrorError;
  remediatingActions?: Maybe<Scalars['String']>;
};

export enum VerificationErrorError {
  DataMissing = 'DATA_MISSING',
  InvalidInput = 'INVALID_INPUT',
  Other = 'OTHER',
  PendingStatus = 'PENDING_STATUS'
}

/** Autogenerated input type of VerifyDomain */
export type VerifyDomainInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of VerifyDomain. */
export type VerifyDomainPayload = {
  __typename?: 'VerifyDomainPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  domainStatus?: Maybe<EmailDomainStatus>;
  emailDomain?: Maybe<EmailDomain>;
  errors?: Maybe<Array<Error>>;
};

export enum View {
  Resource = 'RESOURCE',
  Week = 'WEEK'
}

export enum ViewType {
  Employees = 'EMPLOYEES',
  Rooms = 'ROOMS'
}

export type WaitingListEntry = {
  __typename?: 'WaitingListEntry';
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: Customer;
  date?: Maybe<Scalars['DateTime']>;
  daysOfWeek?: Maybe<Array<DaysOfWeek>>;
  id: Scalars['ID'];
  lastEmailSentAt?: Maybe<Scalars['DateTime']>;
  location: Location;
  notes?: Maybe<Scalars['String']>;
  partsOfDay?: Maybe<Array<PartsOfDay>>;
  resource?: Maybe<Resource>;
  services: Array<Service>;
};

export type WeekdayDetails = {
  __typename?: 'WeekdayDetails';
  dayOfWeek: DaysOfWeek;
  endTime: Scalars['String'];
  max: Scalars['Int'];
  startTime: Scalars['String'];
};

export type WeekdayDetailsInput = {
  dayOfWeek: DaysOfWeek;
  endTime: Scalars['String'];
  max?: Scalars['Int'];
  startTime: Scalars['String'];
};

export type Weekdays = {
  __typename?: 'Weekdays';
  slots: Array<WeekdayDetails>;
};

export type WeekdaysInput = {
  slots?: InputMaybe<Array<WeekdayDetailsInput>>;
};

export type WeeklyRoster = {
  friday?: InputMaybe<RosterItemTimeInput>;
  monday?: InputMaybe<RosterItemTimeInput>;
  saturday?: InputMaybe<RosterItemTimeInput>;
  sunday?: InputMaybe<RosterItemTimeInput>;
  thursday?: InputMaybe<RosterItemTimeInput>;
  tuesday?: InputMaybe<RosterItemTimeInput>;
  wednesday?: InputMaybe<RosterItemTimeInput>;
};
