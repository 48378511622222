<template>
  <div
    :class="[$style.base, { [$style.expanded]: !collapsed }]"
    :style="{ boxShadow: `-3px 0px 0px 0px ${category.color}` }"
  >
    <div
      :class="[$style.row, $style.category]"
      :style="{ backgroundColor: lightColor(category.color) }"
    >
      <div :class="[$style.cell, $style.large]">
        <BaseCheckbox
          v-model="allEnabled"
          :label="category.name"
          v-test="'category-checkbox'"
        />
        <BaseText :ml="0.25" size="s" v-test="'category-enabled-amount'">
          {{ `${enabledAmount}/${services.length}` }}
        </BaseText>
      </div>
      <div v-if="allEnabled && allContainSameValue" :class="$style.cell">
        <div :class="$style.inputWrap">
          <BaseInput
            v-model="collectiveValue"
            type="number"
            :minValue="1"
            :maxValue="400"
            v-test="'category-value'"
          />
        </div>
        <BaseText grey :ml="0.5">
          {{ $t('global.items.day', collectiveValue) }}
        </BaseText>
      </div>
      <div v-else-if="enabledAmount" :class="$style.cell">
        <BaseText size="s">
          {{ $t('global.multiple') }}
        </BaseText>
      </div>
      <div :class="[$style.cell, $style.small]">
        <BaseIcon
          :name="`chevron-${collapsed ? 'up' : 'down'}`"
          color="primary"
          clickable
          @click="collapsed = !collapsed"
          v-test="'category-chevron'"
        />
      </div>
    </div>
    <div v-show="collapsed">
      <div
        v-for="(service, index) in services"
        :key="index"
        :class="[$style.row, $style.service, { [$style.collapsed]: collapsed }]"
        :style="{ borderColor: lightColor(category.color) }"
      >
        <div :class="[$style.cell, $style.large]">
          <BaseCheckbox
            v-model="service.rebookEnabled"
            :label="service.name"
            v-test="'service-checkbox'"
          />
        </div>
        <div v-if="service.rebookEnabled" :class="$style.cell">
          <div :class="$style.inputWrap">
            <BaseInput
              v-model="service.rebookPeriod"
              :minValue="1"
              :maxValue="400"
              type="number"
              v-test="'service-value'"
            />
          </div>
          <BaseText grey :ml="0.5">
            {{ $t('global.items.day', service.rebookPeriod) }}
          </BaseText>
        </div>
        <div :class="[$style.cell, $style.small]"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent } from 'vue';
import { lightColor } from '@/helpers/formatting';

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  emits: ['updateCategory'],
  setup(props) {
    const services = reactive(props.category.services);

    return {
      services,
      lightColor
    };
  },
  data() {
    return {
      collapsed: false
    };
  },
  watch: {
    services: {
      handler() {
        this.$emit('updateCategory', this.services);
      },
      deep: true
    }
  },
  computed: {
    allEnabled: {
      get() {
        return !this.services.find((service) => !service.rebookEnabled);
      },
      set(value) {
        this.services.forEach((service) => {
          service.rebookEnabled = value;
        });
      }
    },
    rowStyle() {
      return {
        borderLeftColor: this.category.color,
        backgroundColor: lightColor(this.category.color)
      };
    },
    firstItemValue() {
      return this.services[0]?.rebookPeriod || 0;
    },
    allContainSameValue() {
      return !this.services.find(
        (service) => service.rebookPeriod !== this.firstItemValue
      );
    },
    collectiveValue: {
      get() {
        return this.firstItemValue;
      },
      set(value) {
        this.services.forEach((service) => {
          service.rebookPeriod = value;
        });
      }
    },
    enabledAmount() {
      return this.services.filter((item) => item.rebookEnabled).length;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $radius;
  margin-left: 3px;
}
.row {
  padding: 0.75 * $spacing $spacing;
  display: flex;
  align-items: center;
  gap: 0.5 * $spacing;
}
.category {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;

  .base.expanded & {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}
.service {
  padding-left: $spacing * 1.5;
  border-width: 1px;
  border-style: solid;
  border-bottom: 2px solid;
  border-right: 2px solid;
  &:last-child {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}
.cell {
  display: flex;
  align-items: center;
  min-height: $input-height;

  &.large {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  &.small {
    padding-left: 0.5 * $spacing;
    margin: auto;
    flex-shrink: 0;
    width: 34px;
    align-self: center;
  }
}

.inputWrap {
  max-width: 50px;
  min-width: 50px;
  margin-left: $spacing * 2;
}
</style>
