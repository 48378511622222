<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.inner">
      <div :class="$style.links">
        <router-link
          v-for="(link, index) in links"
          :key="index"
          ref="links"
          :to="{ name: link.name }"
          :class="$style.link"
          :active-class="$style.active"
          @click="onRouteClick(link)"
          v-test="`nav-sub-${link.name}`"
          v-intercom="`nav-sub-${link.name}`"
        >
          <div :class="$style.linkText">
            {{ link.text || link.name }}
          </div>
          <div v-if="link.highlighted" :class="$style.highlight">
            <BaseIcon name="star-filled" color="primary" size="xs" />
          </div>
        </router-link>
      </div>
      <div
        v-show="showLine"
        :class="$style.line"
        :style="{
          width: lineWidth + 'px',
          left: lineLeft + 'px'
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  inheritAttrs: false,
  props: {
    links: {
      type: Array,
      required: true,
      validator: (value) =>
        !value.length || !value.find((tab) => !tab.name || !tab.text)
    }
  },
  data() {
    return {
      showLine: false,
      lineWidth: 0,
      lineLeft: 0
    };
  },
  watch: {
    '$route.name': 'setLinePosition',
    links() {
      this.$nextTick(() => {
        this.setLinePosition();
      });
    }
  },
  methods: {
    onRouteClick(link) {
      const route = this.$router.resolve({
        name: link.name
      });

      if (route?.meta.mixpanelName) {
        this.mixpanel.track(`Sub nav - ${route.meta.mixpanelName} clicked`);
      }
    },
    setLinePosition() {
      if (!this.$refs.links) {
        return;
      }
      const parentRoutes = this.$route.matched;
      const activeRoute = parentRoutes.find((route) =>
        this.links.find((link) => link.name === route.name)
      );

      if (!activeRoute) {
        this.showLine = false;
        return;
      }

      const activeLinkIndex = this.links.findIndex(
        (link) => link.name === activeRoute.name
      );
      const activeLinkEl = this.$refs.links[activeLinkIndex]?.$el;

      this.showLine = !!activeLinkEl;

      if (activeLinkEl) {
        this.lineWidth = activeLinkEl.clientWidth;
        this.lineLeft = activeLinkEl.offsetLeft;
      }
    }
  },
  mounted() {
    this.setLinePosition();
  }
});
</script>

<style lang="scss" module>
.base {
  background-color: $grey-light;
  border-bottom: 1px solid $color-border;
  padding: 0 $spacing * 0.5;
  position: relative;
  z-index: 5;

  @media print {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: $spacing * 2;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      $grey-light 90%
    );
  }
}

.inner {
  position: relative;
  height: $subnav-height;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

.links {
  display: flex;
  height: 100%;
}

.link {
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  padding: 0 $spacing;
  align-items: center;
  color: black !important;

  .base.smallScreen & {
    font-size: 12px;
    padding: 0 14px;
  }

  @include hover {
    color: black;
    background-color: $color-highlight;
  }

  &:not(.active) {
    .link-text {
      opacity: 0.6;
    }
  }
}

.highlight {
  position: relative;
  top: -2px;
  margin: 0 -4px 0 2px;
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  transition:
    left 0.2s $easeOutCubic,
    width 0.2s $easeOutCubic;
  width: 0;
  padding: 0 $spacing * 0.5;

  &:after {
    content: '';
    display: block;
    height: 2px;
    background-color: $color-primary;
  }
}
</style>
