<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's' || $screen === 'm'
      }
    ]"
  >
    <BaseSpinner v-if="!adyenAppStateLoaded" />
    <Verify v-else-if="adyenAppState.verificationStatus === 'INVALID'" />
    <Profile v-else />
  </div>
</template>

<script lang="ts" setup>
import Verify from './Verify.vue';
import Profile from './Profile.vue';
import { useAdyenStore } from '@/stores/adyen';
const { adyenAppState, adyenAppStateLoaded } = useAdyenStore();
</script>
<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: $spacing;

  &.smallScreen {
    flex-direction: column;
  }
}
</style>
