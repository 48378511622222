import gql from 'graphql-tag';

export const GET_SERVICE_GROUPS = gql`
  query getServiceGroups {
    serviceGroups {
      category {
        id
        name
      }
      id
      name
      services {
        bookable
        medical
        id
        name
        duration
        price
      }
      sortOrder
    }
  }
`;
