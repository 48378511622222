<template>
  <router-link
    :to="routerLink"
    :class="$style.base"
    :style="
      color
        ? {
            'border-color': color
          }
        : null
    "
  >
    <div :class="$style.section" v-test="'service-name'">
      {{ service.name }}
    </div>
    <div :class="$style.section">
      <div v-if="service.medical" v-test="'service-icon-medical'">
        <BaseIcon name="medical" />
      </div>
      <div
        v-if="$screen !== 's'"
        :class="$style.duration"
        v-test="'service-duration'"
      >
        {{
          `${service.duration} ${$t('global.items.minute', service.duration)}`
        }}
      </div>
      <div
        v-if="$screen !== 's'"
        :class="$style.price"
        v-test="'service-price'"
      >
        {{ filters.currency(service.price) }}
      </div>
      <div v-if="serviceCategorySortOder" :class="$style.sortOrder">
        <BaseCounter
          v-model="sortOrderValue"
          :minValue="0"
          :maxValue="listLength - 1"
          hideValue
          reverse
          v-test="'service-sort-order'"
        />
      </div>
      <div v-else :class="$style.more">
        <BaseMore
          :options="['edit', 'duplicate', 'delete', 'sort']"
          @select="onMoreSelect"
        />
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import filters from '@/filters';
import { useServicesStore } from '@/stores/services';

import { defineComponent, inject } from 'vue';

type Actions = 'edit' | 'duplicate' | 'delete' | 'sort';

export default defineComponent({
  props: {
    service: {
      type: Object,
      required: true
    },
    color: {
      type: String
    },
    listLength: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['updateSortOrder'],
  setup() {
    const { deleteService, duplicateService } = useServicesStore();
    const serviceCategorySortOder = inject('services-category-sort-order');
    return {
      deleteService,
      duplicateService,
      filters,
      serviceCategorySortOder
    };
  },
  computed: {
    routerLink() {
      return {
        name: 'admin-service',
        params: {
          serviceId: this.service.id
        }
      };
    },
    sortOrderValue: {
      get() {
        return this.index;
      },
      set(value) {
        this.$emit('updateSortOrder', value);
      }
    }
  },
  methods: {
    onMoreSelect(action: Actions) {
      switch (action) {
        case 'edit':
          this.$router.push(this.routerLink);
          break;

        case 'delete':
          this.deleteService(this.service.id);
          break;

        case 'duplicate':
          this.duplicateService(this.service.id);
          break;

        case 'sort':
          this.serviceCategorySortOder = true;
          break;

        default:
          break;
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-top-width: 0;
  min-height: 60px;
  background-color: white;

  &:global(.sortable-chosen) {
    border-top-width: 1px;
    margin-top: -1px;

    &:not(:last-child) {
      margin-bottom: -1px;
    }

    & + .base {
      border-top-width: 1px;
    }
  }
}

.section {
  display: flex;
  align-items: center;
  padding: $spacing;

  & > *:not(:first-child) {
    margin-left: $spacing;
  }
}

.sortOrder {
  margin-top: $spacing * -0.5;
  margin-bottom: $spacing * -0.5;
}

.duration {
  white-space: nowrap;
}

.price {
  width: 57px;
  text-align: right;
}

.more {
  margin: $spacing * -1;
}
</style>
