<template>
  <div
    :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]"
    v-test="'insights-heatmap'"
  >
    <div v-if="range === 'week'" :class="$style.weekly">
      <div :class="[$style.column, $style.columnY]">
        <div
          v-for="(item, index) in y"
          :key="`y_${index}`"
          :class="$style.cell"
        >
          <div :class="$style.cellContent">
            {{ formatTime(item) }}
          </div>
        </div>
        <div :class="$style.colFooter">{{ $t('reports.totals.totals') }}</div>
      </div>
      <div v-for="(week, index) in data" :key="index" :class="$style.column">
        <div :class="$style.colHeader">
          {{ x[index] }}
        </div>
        <div
          v-for="(item, index) in week.items"
          :key="`item_${index}`"
          :class="[
            $style.cell,
            {
              [$style.filled]: item.ratio > 0.8
            }
          ]"
        >
          <div :class="$style.cellContent">
            {{ item.sum }}
          </div>
          <div
            :class="[
              $style.cellBg,
              {
                [$style.cellBgEmpty]: !item.ratio
              }
            ]"
            :style="{ opacity: item.ratio || 1 }"
          />
        </div>
        <div :class="$style.colFooter">{{ week.total }}</div>
      </div>
    </div>
    <div v-else-if="range === 'month'" :class="$style.monthly">
      <div :class="[$style.column, $style.columnY]">
        <div
          v-for="(item, index) in y"
          :key="`y_${index}`"
          :class="$style.cell"
        >
          <div :class="$style.cellContent">
            {{ item }}
          </div>
        </div>
      </div>
      <div :class="$style.monthlyWrap">
        <div
          v-for="(item, index) in x"
          :key="index"
          :class="[$style.colHeader, $style.cellMonthly]"
        >
          {{ x[index] }}
        </div>
        <div
          v-for="(item, index) in firstWeekDayOfMonth"
          :key="index"
          :class="[$style.cell, $style.cellMonthly]"
        />
        <div
          v-for="(item, index) in data"
          :key="index"
          :class="[
            $style.cell,
            $style.cellMonthly,
            {
              [$style.filled]: item.totalRatio > 0.8
            }
          ]"
        >
          <div :class="$style.cellContent">
            {{ item.total }}
          </div>
          <div
            :class="[
              $style.cellBg,
              {
                [$style.cellBgEmpty]: !item.totalRatio
              }
            ]"
            :style="{ opacity: item.totalRatio || 1 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { useI18n } from 'vue-i18n';
import { formatTime } from '../helpers';

const range = inject<any>('range');

const props = defineProps<{
  data: any;
}>();

const { t } = useI18n();

const y = computed(() => {
  if (range.value === 'week') {
    return [
      '00-02',
      '02-04',
      '04-06',
      '06-08',
      '08-10',
      '10-12',
      '12-14',
      '14-16',
      '16-18',
      '18-20',
      '20-22',
      '22-24'
    ];
  } else if (range.value === 'month') {
    const firstWeek = dayjs(props.data[0].name).isoWeek();
    const lastWeek = dayjs(props.data[props.data.length - 1].name).isoWeek();

    const weeks = [];
    for (let x = firstWeek; x <= lastWeek; x++) {
      weeks.push(filters.capitalize(t('global.items.week', 1)) + ' ' + x);
    }
    return weeks;
  }
});

const x = Array.from({ length: 7 }, (x, i) => filters.weekdayShort(i + 1));

const firstWeekDayOfMonth = computed(
  () => (dayjs(props.data[0].name).day() || 7) - 1
);
</script>

<style lang="scss" module>
.weekly,
.monthly {
  display: flex;
}

.monthly {
  flex-wrap: wrap;
}

.column {
  padding: 3px;
  width: 12.5%;
}

$cellHeight: 40px;

.colHeader,
.colFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $cellHeight;

  .base.smallScreen & {
    font-size: 12px;
  }
}

.columnY {
  padding-top: $cellHeight + 3px;
  font-size: 11px;

  .base.smallScreen & {
    font-size: 10px;
  }

  .monthly & {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: $cellHeight + 6px;
  }
}

.cell {
  position: relative;
  height: $cellHeight;

  .weekly & {
    margin-bottom: 6px;
  }

  &.filled {
    color: white;
  }
}

.monthlyWrap {
  width: 87.5%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.cellMonthly {
  width: calc(14.28% - 6px);
}

.cellContent,
.cellBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.cellContent {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.cellBg {
  background-color: $color-warning;
  border-radius: 8px;
}

.cellBgEmpty {
  background-color: $color-body-bg;
}
</style>
