<template>
  <BaseModal
    :heading="$t('admin.treatwell.iban_modal.title')"
    useForm
    :loading="loading"
    :parentRoute="{ name: 'treatwell-admin' }"
    @submit="submit"
  >
    <BaseHeading :mb="0.5">
      {{ $t('admin.treatwell.iban_modal.heading') }}
    </BaseHeading>
    <BaseText>
      {{ $t('admin.treatwell.iban_modal.description') }}
    </BaseText>

    <div
      v-for="i in ['no_shows', 'payments', 'reverseable']"
      :key="i"
      :class="$style.bulletPoint"
    >
      <BaseIcon name="check" color="success" size="l" :mr="0.5" />
      {{ $t(`admin.treatwell.iban_modal.reasons.${i}`) }}
    </div>

    <div class="mt-1 mb-1" v-html="learnMoreMessage" />

    <BaseInput
      v-model="bankingDetails.accountHolderName"
      :label="$t('treatwell.bank_details.account_holder')"
      :mt="0.5"
      :disabled="treatwellStatus.bankDetailsProvided"
      v-test="'treatwell-account-holder-input'"
    />
    <BaseInput
      v-model="bankingDetails.bankName"
      :label="$t('treatwell.bank_details.bank_name')"
      :mt="0.5"
      :disabled="treatwellStatus.bankDetailsProvided"
      v-test="'treatwell-bank-name-input'"
    />
    <BaseInput
      v-model="bankingDetails.accountNumber"
      :label="$t('treatwell.bank_details.account_number')"
      :mt="0.5"
      :disabled="treatwellStatus.bankDetailsProvided"
      v-test="'treatwell-account-number-input'"
    />
    <BaseInput
      v-model="bankingDetails.bankCode"
      :label="$t('treatwell.bank_details.bank_code')"
      :mt="0.5"
      :disabled="treatwellStatus.bankDetailsProvided"
      v-test="'treatwell-bank-code-input'"
    />
    <BaseText size="s" iconBefore="info" :mt="0.5">
      {{ $t('admin.treatwell.iban_modal.bic_explanation') }}
    </BaseText>
    <BaseText v-if="bankError" color="error" mt>
      {{ bankError }}
    </BaseText>
    <BaseAlert
      v-if="treatwellStatus.bankDetailsProvided"
      color="warning"
      :text="$t('admin.treatwell.iban_modal.iban_already_set')"
      mt
      mb
    />

    <template #footer>
      <BaseButton
        submitForm
        :disabled="treatwellStatus.bankDetailsProvided"
        :loading="isSaving || isSavingRequest"
        v-test="'treatwell-iban-submit'"
      >
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import eventBus from '@/event-bus';
import type { TreatwellBankDetails } from '@/types';
import { useLazyQuery, useMutation, useQuery } from '@vue/apollo-composable';
import { computed, inject, onUnmounted, reactive, ref } from 'vue';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
  GET_CURRENT_INTEGRATION_REQUEST,
  GET_TREATWELL_VENUE,
  TREATWELL_INTEGRATION_REQUEST_UPDATE_BANKING_DETAILS,
  UPDATE_TREATWELL_VENUE
} from '../graphql';
import { useTreatwellStore } from '@/stores/treatwell';

const mixpanel = inject<any>('mixpanel');

const router = useRouter();
const { t } = useI18n();

const { treatwellStatus, getTreatwellStatus } = useTreatwellStore();

const bankingDetails = reactive<TreatwellBankDetails>({
  accountHolderName: '',
  accountNumber: '',
  bankCode: '',
  bankName: ''
});

const bankError = ref('');
const hasTreatwellVenue = ref(false);

const { load: loadIntegration, onResult: onIntegrationRequest } = useLazyQuery(
  GET_CURRENT_INTEGRATION_REQUEST
);
const { loading, onResult: onVenueResult } = useQuery(GET_TREATWELL_VENUE);
onVenueResult(({ data: { treatwellVenue: venue } }) => {
  if (venue) {
    hasTreatwellVenue.value = true;
    if (venue.bankAccountHolder) {
      bankingDetails.accountHolderName = venue.bankAccountHolder;
      bankingDetails.accountNumber = venue.bankAccountNumber;
      bankingDetails.bankCode = venue.bankCode;
      bankingDetails.bankName = venue.bankName;
    }
  } else {
    loadIntegration();
  }
});

onIntegrationRequest(
  ({ data: { treatwellCurrentIntegrationRequest: integrationRequest } }) => {
    bankingDetails.accountHolderName = integrationRequest.accountHolderName;
    bankingDetails.accountNumber = integrationRequest.accountNumber;
    bankingDetails.bankCode = integrationRequest.bankCode;
    bankingDetails.bankName = integrationRequest.bankName;
  }
);

const {
  mutate: updateBankingDetailsOnVenue,
  onDone,
  loading: isSaving
} = useMutation(UPDATE_TREATWELL_VENUE, {
  variables: {
    input: {
      bankingDetails
    }
  }
});

const {
  mutate: updateBankingDetailsOnRequest,
  onDone: onRequestDone,
  loading: isSavingRequest
} = useMutation(TREATWELL_INTEGRATION_REQUEST_UPDATE_BANKING_DETAILS, {
  variables: {
    input: {
      bankingDetails
    }
  }
});

const submit = () => {
  if (hasTreatwellVenue.value) {
    updateBankingDetailsOnVenue().then(() => getTreatwellStatus());
  } else {
    updateBankingDetailsOnRequest();
  }
};

onDone(({ data }) => {
  if (data.updateTreatwellVenue.errors) {
    bankError.value = t('admin.treatwell.iban_modal.error');
  } else {
    mixpanel.track('Treatwell dashboard - task - update iban venue');
    afterSave();
  }
});

onRequestDone(({ data }) => {
  if (data.treatwellIntegrationRequestUpdateBankingDetails.errors) {
    bankError.value = t('admin.treatwell.iban_modal.error');
  } else {
    mixpanel.track(
      'Treatwell dashboard - task - update iban integration request'
    );
    afterSave();
  }
});

const afterSave = () => {
  flash(t('global.flash.iban_saved'));
  eventBus.$emit('update-task', 'provide_bank');
  treatwellStatus.bankDetailsProvided = true;
  router.push({
    name: 'treatwell-admin'
  });
};

const learnMoreMessage = computed(() => {
  const helpUrl = `<a href="${t('admin.treatwell.iban_modal.learn_more.help.url')}">${t('admin.treatwell.iban_modal.learn_more.help.label')}</a>`;
  const supportUrl = `<a href="${t('admin.treatwell.iban_modal.learn_more.support.url')}">${t('admin.treatwell.iban_modal.learn_more.support.label')}</a>`;
  return t('admin.treatwell.iban_modal.learn_more.explanation', {
    helpUrl,
    supportUrl
  });
});

onUnmounted(() => {
  mixpanel.track('Treatwell dashboard - task - close iban modal');
});
</script>

<style lang="scss" module>
.bulletPoint {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
</style>
