<template>
  <BaseCard v-if="appointment">
    <div :class="$style.base">
      <BaseText iconBefore="calendar" bold>{{
        filters.date(appointment.startAt, {
          format: 'longYear'
        })
      }}</BaseText>
      <BaseText iconBefore="clock" bold>
        {{ filters.time(appointment.startAt) }}
        -
        {{ filters.time(appointment.endAt) }}
      </BaseText>
      <BaseText v-if="appointment.rrule" iconBefore="repeat" bold>
        {{ recurringFrequency }}
      </BaseText>
      <BaseText v-if="multiLocation && location" iconBefore="pin" bold>
        {{ location.internalName }}
      </BaseText>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  appointment: any;
}>();

const { t } = useI18n();

const recurringFrequency = computed(() =>
  filters.capitalize(
    t(
      `calendar.recurring_confirmation.day.${props.appointment.rrule.freq}.${props.appointment.rrule.interval === 1 ? 'one' : 'other'}`,
      { count: props.appointment.rrule.interval }
    )
  )
);

const { multiLocation } = useCompanyStore();
const { locationById } = useLocationsStore();

const location = computed(() =>
  props.appointment?.locationId
    ? locationById(props.appointment.locationId)
    : null
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing $spacing * 2;
}
</style>
