<template>
  <Modal size="large" :previewMode="previewMode" @pressEscape="close">
    <div
      :class="[
        $style.base,
        {
          [$style.autoHeight]: autoHeight,
          [$style.smallScreen]: $screen === 's',
          [$style.previewMode]: previewMode
        }
      ]"
      v-test="`modal-${testId || String($route.name)}`"
    >
      <component
        :is="useForm ? 'BaseForm' : 'div'"
        :fullHeight="useForm || undefined"
        @submit="$emit('submit')"
        @validationError="$emit('validationError')"
      >
        <div :class="$style.inner">
          <ModalHeader
            :class="$style.header"
            v-bind="{
              routes,
              unclosable,
              heading,
              label,
              previewMode,
              hasBackButton,
              rightAlign: headerRightAlign
            }"
            @back="$emit('back')"
            @close="close"
          >
            <slot name="header" />
            <template #right>
              <slot name="headerRight" />
            </template>
          </ModalHeader>
          <Center
            :class="$style.content"
            v-bind="{
              details,
              actions,
              noPadding,
              loading,
              previewMode,
              white
            }"
            @action="$emit('action', $event)"
          >
            <template #mobileTop>
              <slot name="mobileTop" />
            </template>
            <slot />
            <template #nav>
              <slot name="nav" />
            </template>
            <template #aside>
              <slot name="aside" />
            </template>
          </Center>
          <ModalFooter
            v-if="$slots.footer || $slots.footerSub"
            :class="$style.footer"
          >
            <template #footerSub>
              <slot name="footerSub" />
            </template>
            <template #footer>
              <slot name="footer" />
            </template>
          </ModalFooter>
        </div>
      </component>
    </div>
  </Modal>
</template>

<script lang="ts">
import Center from './Center.vue';
import Modal from '@/components/_shared/Modal.vue';
import ModalFooter from '@/components/_shared/ModalFooter.vue';
import ModalHeader from '@/components/_shared/ModalHeader.vue';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

type Route = {
  heading: string;
  label: string;
  name: string;
};

export default defineComponent({
  name: 'BaseModalLarge',
  components: {
    ModalHeader,
    Center,
    ModalFooter,
    Modal
  },
  inheritAttrs: false,
  props: {
    testId: {
      type: String
    },
    routes: {
      type: Array as PropType<Route[]>,
      default: () => []
    },
    details: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    heading: {
      type: String
    },
    headerRightAlign: {
      type: Boolean,
      default: false
    },
    hasBackButton: {
      type: Boolean,
      default: false
    },
    label: {
      type: [String, Array],
      default: ''
    },
    unclosable: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    parentRoute: {
      type: [String, Object],
      default: null
    },
    previewMode: {
      type: Boolean,
      default: false
    },
    useForm: {
      type: Boolean,
      default: false
    }
  },
  emits: ['action', 'close', 'submit', 'validationError', 'back'],
  methods: {
    close() {
      if (this.unclosable) {
        return;
      }

      if (this.parentRoute) {
        if (typeof this.parentRoute === 'string') {
          this.$router.push({
            name: this.parentRoute
          });
        } else {
          this.$router.push(this.parentRoute);
        }
      } else {
        this.$emit('close');
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  &:not(.autoHeight):not(.smallScreen) {
    height: 100%;
  }

  &.smallScreen {
    min-height: 100%;
  }

  &.autoHeight,
  &.smallScreen {
    padding-bottom: 16px;
  }

  & > * {
    height: 100%;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  width: 1200px;
  max-width: 100%;
  border-radius: $modal-radius;
  transition: box-shadow 0.3s ease-out;
  min-height: inherit;
  height: inherit;

  .base:not(.previewMode) & {
    box-shadow: $modal-shadow;
    transition-delay: 0.2s;
  }

  .base.previewMode & {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  }

  & > * {
    &:first-child {
      border-radius: $modal-radius $modal-radius 0 0;
    }
    &:last-child {
      border-radius: 0 0 $modal-radius $modal-radius;
    }
  }
}

.header,
.content,
.footer {
  min-height: 0; // Needed to prevent the container from overflowing
}

.content {
  flex-grow: 1;
}

.header,
.footer {
  flex-shrink: 0;
}
</style>
