<template>
  <BaseModal
    :heading="`${$t(`reports.totals.${variables.type.toLowerCase()}`)} ${$t('reports.ranges.transactions').toLowerCase()}`"
    small
    @close="$emit('close')"
  >
    <div :class="$style.header">
      <DateSelect
        v-bind="{ startDate, endDate }"
        :label="$t('global.actions.period')"
        @select="onDateSelect"
      />
      <BaseDropdown
        v-model="variables.type"
        :options="
          types.map((type) => ({
            value: type,
            label: $t(`reports.totals.${type.toLowerCase()}`)
          }))
        "
        :label="$t('reports.totals.payment_method')"
        v-test="'payment-method-select'"
      />
    </div>
    <div v-if="firstFetchFinished && transactions.length === 0">
      {{ $t('global.no_results') }}
    </div>
    <BaseTable
      v-else
      :headers="
        [
          $t('global.date'),
          $t('global.amount'),
          variables.type === 'POS' ? $t('reports.totals.transaction_costs') : ''
        ].filter((header) => !!header)
      "
      :rows="
        transactions.map((transaction) => ({
          id: transaction.orderId,
          cells: [
            filters.dateTime(transaction.transactionAt),
            filters.currency(transaction.amount),
            variables.type === 'POS'
              ? transaction.posPayment
                ? filters.currency(transaction.posPayment.transactionFee)
                : false
              : undefined
          ].filter((cell) => cell !== undefined),
          actions: ['pdf']
        }))
      "
      @action="showPdf"
      v-test="'transactions-table'"
    />
    <LoadMore
      v-show="firstFetchFinished && !allDataFetched"
      @shown="fetchMore"
    />
  </BaseModal>
</template>

<script setup lang="ts">
import type { PaymentMethod } from './types';
import gql from 'graphql-tag';
import { usePagination } from '@/apollo/pagination';
import filters from '@/filters';
import LoadMore from '@/components/LoadMore.vue';
import config from '@/config';
import DateSelect from '../components/DateSelect.vue';
import { useTreatwellStore } from '@/stores/treatwell';

const props = defineProps<{
  selectedMethod: PaymentMethod;
  startDate: string;
  endDate: string;
}>();

defineEmits(['close']);

const {
  transactions,
  firstFetchFinished,
  allDataFetched,
  variables,
  fetchMore
} = usePagination({
  fieldName: 'transactions',
  query: gql`
    query getTransactions(
      $type: TransactionType
      $endDate: DateTime
      $pagination: PaginationAttributes
      $startDate: DateTime
      $onlyWithOrderId: Boolean
      $direction: SortDirectionEnum
      $sortBy: String
    ) {
      transactions(
        type: $type
        endDate: $endDate
        pagination: $pagination
        startDate: $startDate
        onlyWithOrderId: $onlyWithOrderId
        sortBy: $sortBy
        direction: $direction
      ) {
        amount
        id
        orderId
        posPayment {
          transactionFee
        }
        transactionAt
      }
    }
  `,
  variables: {
    direction: 'DESC',
    endDate: props.endDate,
    onlyWithOrderId: true,
    sortBy: 'transaction_at',
    startDate: props.startDate,
    type: props.selectedMethod.key.toUpperCase()
  }
});

const types = ref([
  'BANK',
  'CASH',
  'COUPON',
  'CREDITCARD',
  'GIFTCARD',
  'ONLINE',
  'PIN',
  'PREPAID_CARD'
]);

const hasSalonizedPay = inject<any>('hasSalonizedPay');
if (hasSalonizedPay.value) {
  types.value.push('POS');
}

const { hasActiveVenue } = useTreatwellStore();
if (hasActiveVenue) {
  types.value.push('TREATWELL_ONLINE_PAYMENT');
}

const showPdf = ({ id }: { id: string }) => {
  window.open(`${config.backendUrl}/orders/${id}.pdf`);
};

const onDateSelect = ({ startDate, endDate }) => {
  variables.startDate = startDate;
  variables.endDate = endDate;
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;
  margin-bottom: $spacing;
}
</style>
