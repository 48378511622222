<template>
  <BaseSpinner v-if="adyenDashboardDataLoading" />
  <div
    v-else
    :class="[
      $style.base,
      { [$style.smallScreen]: $screen === 's' || $screen === 'm' }
    ]"
    v-test="'sp-dashboard'"
  >
    <BaseModal
      v-if="showFAQ"
      :heading="t('register.salonized_pay.landing.faq')"
      @close="showFAQ = false"
    >
      <FAQ v-test="'sp-faq-modal'" />
    </BaseModal>

    <PayoutSchedule
      v-if="showPayoutSchedule"
      @close="showPayoutSchedule = false"
    />

    <BaseHeading :mb="2" :mt="2">
      {{
        $t('register.salonized_pay.dashboard.welcome', {
          company: user?.firstName
        })
      }}
    </BaseHeading>

    <BaseVisualBanner
      v-if="
        adyenDashboardDataLength > 0 && !adyenDashboardData.hasPosTransaction
      "
      :heading="$t('register.salonized_pay.dashboard.complete_checkout')"
      imagePath="/img/salonized-pay/register.svg"
      mb
      :secondaryAction="{
        text: $t('register.salonized_pay.dashboard.register'),
        routerLink: { name: 'register' }
      }"
      variant="dark"
      color="green"
      @secondaryAction="
        mixpanel.track('Salonized Pay - Go to register clicked')
      "
      v-test="'sp-new-transaction-banner'"
    />

    <BaseVisualBanner
      v-if="showTerminalOrderConfirmedBanner"
      :heading="$t('register.salonized_pay.dashboard.order_complete')"
      :description="
        $t('register.salonized_pay.dashboard.order_complete_description')
      "
      imagePath="/img/salonized-pay/order.svg"
      color="green"
      mb
      v-test="'sp-new-terminal-order-banner'"
    />

    <div
      v-if="adyenDashboardDataLength > 0 && showBalance"
      :class="$style.statsContainer"
      v-test="'sp-dashboard-stats'"
    >
      <div :class="$style.payoutsSection">
        <BaseCard fullWidth>
          <div :class="$style.payout">
            <div>
              <BaseHeading size="s" mb>
                {{ $t('register.salonized_pay.dashboard.next_payout') }}
              </BaseHeading>
              <BaseText size="l" bold v-test="'sp-dashboard-available-balance'">
                {{ filters.currency(adyenDashboardData.availableBalance) }}
              </BaseText>
            </div>

            <img
              :class="$style.payoutSvg"
              src="/img/salonized-pay/onboarding/payout.svg"
            />
          </div>
        </BaseCard>
        <BaseCard fullWidth>
          <div :class="$style.payout">
            <div>
              <BaseHeading size="s" mb>
                {{ $t('register.salonized_pay.dashboard.processing') }}
              </BaseHeading>
              <BaseText size="l" bold v-test="'sp-dashboard-available-balance'">
                {{ filters.currency(adyenDashboardData.processingBalance) }}
              </BaseText>
            </div>
            <BaseText
              link
              @click="showPayoutSchedule = true"
              v-test="'sp-payout-schedule-link'"
            >
              {{ $t('register.salonized_pay.dashboard.schedule.link') }}
            </BaseText>
          </div>
        </BaseCard>
      </div>
    </div>

    <BaseCard>
      <div :class="$style.headerContainer">
        <div :class="$style.header">
          <img
            v-if="company.logo"
            :src="company.logo?.small!"
            :class="$style.logo"
          />
          <img
            v-else
            src="/img/salonized-pay/onboarding/NoLogo.png"
            :class="$style.logo"
          />
          <div :class="$style.companyName">
            <BaseText bold size="l" :mt="0.5" center>{{
              company.name
            }}</BaseText>
          </div>
          <BaseText>
            {{ $t('register.salonized_pay.verification.org') }}
          </BaseText>
        </div>
        <div :class="$style.optionContainer">
          <div
            :class="$style.option"
            @click="generateAdyenOnboardingLink"
            v-test="'sp-dashboard-edit-button'"
          >
            <BaseText iconBefore="external_link" bold>
              {{ $t('register.salonized_pay.dashboard.edit') }}
            </BaseText>
          </div>
          <div
            :class="$style.option"
            @click="showFAQ = true"
            v-test="'sp-dashboard-faq-button'"
          >
            <BaseText iconBefore="question-mark" bold>
              {{ $t('register.salonized_pay.verification.view_faq') }}
            </BaseText>
          </div>
        </div>
      </div>

      <BaseTask
        :mt="2"
        :text="adyenVerficationStatus.text as string"
        :heading="adyenVerficationStatus.heading"
        :state="adyenVerficationStatus.state"
        :buttonIcon="adyenVerficationStatus.buttonIcon"
        :loading="loading"
        @click="generateAdyenOnboardingLink"
        @buttonClick="generateAdyenOnboardingLink"
        v-test="`sp-verification-${adyenVerficationStatus.state}`"
      />

      <BaseButton
        v-if="
          adyenAppState.verificationStatus !== 'VALID' &&
          (company.country === 'nl' || company.country === 'de')
        "
        :href="
          company.country === 'de'
            ? 'https://vimeo.com/980575413'
            : 'https://www.youtube.com/watch?v=CbYhuSSuRKk'
        "
        mt
        color="inverted"
        @click="
          mixpanel.track('Salonized Pay - Watch KYC video', {
            step: 'Dashboard'
          })
        "
        v-test="'sp-button-youtube'"
      >
        {{ $t('register.salonized_pay.landing.watch_video') }}
      </BaseButton>

      <BaseTask
        v-if="!hasTerminal && !unleash.isEnabled('SalonizedPayTerminals')"
        mt
        :text="$t('register.salonized_pay.verification.terminal.description')"
        :heading="$t('register.salonized_pay.verification.terminal.heading')"
        state="blocked"
        v-test="'sp-dashboard-terminal-card'"
      />

      <BaseTask
        v-if="showTerminalOrderTask"
        mt
        :text="
          hasTerminal
            ? $t('register.salonized_pay.dashboard.order.description_next')
            : $t('register.salonized_pay.dashboard.order.description')
        "
        :heading="
          hasTerminal
            ? $t('register.salonized_pay.dashboard.order.title_next')
            : $t('register.salonized_pay.dashboard.order.title')
        "
        :state="hasTerminal ? 'default' : 'required'"
        @click="handleCheckoutModal"
        v-test="'sp-dashboard-order-task'"
      />

      <BaseAlert
        v-if="showFailureAlert"
        color="warning"
        mt
        :text="$t('register.salonized_pay.profile.failure_alert.text')"
        v-test="'sp-order-failure-alert'"
      />

      <Terminals
        v-if="!adyenDashboardDataLoading && adyenDashboardDataLength > 0"
        :mt="2"
        :terminals="adyenDashboardData.terminals"
      />
    </BaseCard>

    <BaseCard
      v-if="adyenDashboardDataLength < 1"
      mt
      gray
      v-test="'sp-dashboard-start'"
    >
      <div :class="$style.verifying">
        <img src="/img/salonized-pay/onboarding/verifying.svg" />
        <BaseText ml>
          {{ $t('register.salonized_pay.dashboard.verifying') }}
        </BaseText>
      </div>
    </BaseCard>

    <OrderTerminalModal
      v-if="showOrderTerminalModal"
      @close="showOrderTerminalModal = false"
    />

    <SPSuccessModal v-if="showSuccessModal" @close="showSuccessModal = false" />
  </div>
</template>

<script lang="ts" setup>
import FAQ from '../FAQ.vue';
import Terminals from './Terminals.vue';
import SPSuccessModal from './order-terminal-modal/SPSuccessModal.vue';
import { useCompanyStore } from '@/stores/company';
import { computed, ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { generateOnboardingLink } from '../helpers';
import {
  GET_ADYEN_DASHBOARD_DATA,
  GET_ADYEN_DASHBOARD_DATA_WITHOUT_BALANCE
} from './graphql';
import { useUserStore } from '@/stores/user';
import filters from '@/filters';
import PayoutSchedule from './PayoutSchedule.vue';
import { useAdyenStore } from '@/stores/adyen';
import { useRoute, useRouter } from 'vue-router';
import OrderTerminalModal from './order-terminal-modal/index.vue';
import unleash from '@/unleash';
import dayjs from '@/dayjs';

const { t } = useI18n();
const loading = ref(false);
const showFAQ = ref(false);
const showPayoutSchedule = ref(false);
const showSuccessModal = ref(false);
const showFailureAlert = ref(false);

const router = useRouter();
const route = useRoute();

const mixpanel = inject<any>('mixpanel');
const showOrderTerminalModal = ref(false);

const { company } = useCompanyStore();
const { user, hasFeatureFlag } = useUserStore();
const { adyenAppState } = useAdyenStore();

const handleCheckoutModal = () => {
  mixpanel.track('Salonized Pay Checkout - Start checkout clicked');
  showOrderTerminalModal.value = true;
  showFailureAlert.value = false;
};

const showBalance = computed(() => hasFeatureFlag('module-reports'));

const { result: dashboardDataResult, loading: adyenDashboardDataLoading } =
  useQuery(
    showBalance.value
      ? GET_ADYEN_DASHBOARD_DATA
      : GET_ADYEN_DASHBOARD_DATA_WITHOUT_BALANCE
  );

const adyenDashboardData = computed(
  () => dashboardDataResult.value?.adyenDashboardData
);
const adyenDashboardDataLength = computed(
  () =>
    adyenDashboardDataLoading &&
    adyenDashboardData.value &&
    adyenDashboardData.value.terminals.length
);
const hasTerminal = computed(
  () =>
    adyenDashboardData.value?.terminals?.length ||
    adyenDashboardData.value?.lastTerminalOrderAt
);

const generateAdyenOnboardingLink = () => {
  loading.value = true;
  mixpanel.track('KYC - Verification step edited');

  generateOnboardingLink().then(() => {
    loading.value = false;
  });
};

const adyenVerficationStatus = computed(() => {
  switch (adyenAppState.verificationStatus) {
    case 'VALID':
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.valid.description'),
        buttonIcon: 'edit',
        state: 'completed'
      };
    case 'PENDING':
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.pending.description'),
        buttonIcon: 'edit',
        state: 'pending'
      };
    case 'INVALID':
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.invalid.description'),
        state: 'attention'
      };
    default:
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.pending.description'),
        buttonIcon: 'edit',
        state: 'pending'
      };
  }
});

if (route.query.payment === 'success') {
  showSuccessModal.value = true;
  setTimeout(() => {
    showSuccessModal.value = false;
  }, 10000);
  router.replace({ name: 'salonized-pay' });
}

if (route.query.payment === 'failure') {
  showFailureAlert.value = true;
  router.replace({ name: 'salonized-pay' });
}

const showTerminalOrderTask = computed(
  () => adyenAppState.verificationStatus === 'VALID'
);

const showTerminalOrderConfirmedBanner = computed(
  () =>
    adyenDashboardData.value?.lastTerminalOrderAt &&
    dayjs(adyenDashboardData.value.lastTerminalOrderAt).isBetween(
      dayjs().subtract(4, 'days'),
      dayjs()
    )
);
</script>

<style lang="scss" module>
.statsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: multi-lines;

  margin-bottom: $spacing;
  position: relative;

  .smallScreen & {
    flex-direction: column;
  }
}

.payout {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.payoutSvg {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 82px;
}

.base {
  width: 60%;

  &.smallScreen {
    width: 100%;
  }
}

.logo {
  border-radius: 100%;
  border: 1px solid $color-border;
  max-width: 65px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;

  max-width: max-content;
  :not(.smallScreen) & {
    margin-right: $spacing;
  }
}

.companyName {
  width: 100%;
}

.option {
  display: flex;
  cursor: pointer;

  padding: $spacing 0;
  border-bottom: 1px solid $color-border;
}

.headerContainer {
  display: flex;
  align-items: flex-end;

  .smallScreen & {
    flex-direction: column;
    align-items: center;
  }
}

.optionContainer {
  display: flex;
  flex-direction: column;
  padding-left: $spacing * 2;
  width: 100%;
  flex: 1;

  .base.smallScreen & {
    padding-right: $spacing * 2;
  }
}

.verifying {
  display: flex;
  align-items: center;
}

.scheduleWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: $spacing;
}

.payoutsSection {
  display: flex;

  .smallScreen & {
    flex-direction: column;
    & > *:first-child {
      margin-bottom: $spacing;
    }
  }

  .base:not(.smallScreen) & {
    & > * {
      width: 50%;
    }

    & > *:first-child {
      margin-right: $spacing;
    }
  }
}
</style>
