<template>
  <div>
    <BaseCheckbox
      v-model="settings.topServicesEnabled"
      :label="$t('admin.booking.top_services.label_toggle')"
      v-test="'booking-settings-topServicesEnabled'"
    />
    <div v-if="settings.topServicesEnabled">
      <BaseDropdown
        v-model="topServicesIds"
        :options="
          services.map((service) => ({
            value: service.id,
            label: service.name
          }))
        "
        :label="$t('admin.booking.top_services.label')"
        :maxLength="3"
        :disabled="topServicesIds.length >= 3"
        required
        searchable
        mt
        @update:modelValue="$emit('change')"
        v-test="'booking-settings-topServicesDropdown'"
      />
      <div :class="$style.topServicesList">
        <BaseChip
          v-for="(service, index) in topServicesData"
          :key="index"
          :text="service.name"
          :color="service.category.color"
          closeable
          @close="
            () => {
              topServicesIds = topServicesIds.filter(
                (serviceId) => serviceId !== service.id
              );
              $emit('change');
            }
          "
          v-test="'booking-settings-topService'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useServicesStore } from '@/stores/services';
import unleash from '@/unleash';
import { GET_SERVICE_VARIATION_GROUPS } from './graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{
  modelValue: string[];
  settings: any;
}>();

const emit = defineEmits(['update:modelValue', 'change']);

const hasVariations = unleash.isEnabled('ServiceVariations');

const serviceVariationGroups = ref<
  {
    bookable: boolean;
    category: {
      color: string;
    };
    id: string;
    name: string;
    top: boolean;
  }[]
>([]);

if (hasVariations) {
  const { onResult } = useQuery(GET_SERVICE_VARIATION_GROUPS);
  onResult(({ data }) => {
    serviceVariationGroups.value = data.serviceVariationGroups;
    topServicesIds.value = data.serviceVariationGroups
      .filter((group) => group.top && group.bookable)
      .map((group) => group.id);
  });
}

const topServicesIds = computed({
  get() {
    if (hasVariations) {
      return props.modelValue;
    } else {
      return props.settings.topServicesIds;
    }
  },
  set(value) {
    if (hasVariations) {
      emit('update:modelValue', value);
    } else {
      props.settings.topServicesIds = value;
    }
  }
});

const services = computed(() => {
  const { services: _services } = useServicesStore();
  const services = hasVariations ? serviceVariationGroups.value : _services;
  return services.filter((service) => service.bookable);
});

const topServicesData = computed(() =>
  services.value.filter((service) => topServicesIds.value.includes(service.id))
);
</script>

<style lang="scss" module>
.topServicesList {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-top: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}
</style>
