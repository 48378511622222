<template>
  <BaseModalSmall small @close="$emit('close')">
    <BaseText size="l">
      {{ $t('marketing.email.send-template-warning-modal.heading') }}
    </BaseText>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="emit('close')"
        v-test="'send-template-warning-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton @click="emit('save')" v-test="'send-template-warning-save'">
        {{ $t('marketing.email.send-template-warning-modal.save') }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
const { activeSubscription, isTrial, isBlocked } = useCompanyStore();
const emit = defineEmits(['close', 'save']);
const mixpanel = inject<any>('mixpanel');
mixpanel.track('send-template-warning-shown', {
  plan: activeSubscription?.plan,
  stripe_status: activeSubscription?.stripeStatus,
  blocked: isBlocked,
  is_trial: isTrial
});
</script>
