<template>
  <div>
    <component
      :is="type === 'EMPLOYEE' ? 'EmployeeModal' : 'ResourceModal'"
      v-if="showCreateModal"
      :type="type.toLowerCase()"
      @created="onResourceCreated"
      @close="showCreateModal = false"
    />

    <BaseCard
      gray
      noBorder
      :closable="!primary"
      :heading="hasHeader ? $t(`service.resources.${typeKey}.needed`) : ''"
      @close="$emit('close')"
    >
      <BaseGroupedCheckboxList
        v-if="listData.length"
        v-model="listValue"
        :expandedHeader="!startCollapsed && listData.length <= 5"
        :data="listData"
        :label="$t(`service.resources.${typeKey}.all`)"
        :footer="{
          label: $t(`service.resources.${typeKey}.create`),
          icon: 'plus',
          iconColor: 'success'
        }"
        @footerClick="showCreateModal = true"
        v-test="'service-requirement-resources'"
      />
      <div
        v-else
        :class="$style.addResource"
        @click="showCreateModal = true"
        v-test="'create-resource-button'"
      >
        <BaseIcon name="plus" color="success" :mr="0.5" />
        <BaseText bold>{{
          $t(`service.resources.${typeKey}.create`)
        }}</BaseText>
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import EmployeeModal from '@/modules/admin/resources/modals/Employee.vue';
import ResourceModal from '@/modules/admin/resources/modals/Resource.vue';
import { useResourcesStore } from '@/stores/resources';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    EmployeeModal,
    ResourceModal
  },
  props: {
    id: {
      type: Number,
      required: false
    },
    primary: {
      type: Boolean,
      required: true
    },
    startCollapsed: {
      type: Boolean,
      required: false
    },
    hasHeader: {
      type: Boolean
    },
    resourceIds: {
      type: Array,
      required: true,
      validator: (value) =>
        !value.length || !value.find((id) => typeof id !== 'number')
    },
    type: {
      type: String,
      required: true,
      validator: (value) =>
        value === 'EMPLOYEE' || value === 'ROOM' || value === 'EQUIPMENT'
    }
  },
  emits: ['close', 'updateSelectedIds'],
  data() {
    return {
      showCreateModal: false
    };
  },
  computed: {
    typeKey() {
      return this.type.toLowerCase();
    },
    listValue: {
      get() {
        return this.resourceIds;
      },
      set(value) {
        this.$emit('updateSelectedIds', value);
      }
    },
    listData() {
      const { resourcesByType } = useResourcesStore();
      const data = resourcesByType(this.type.toLowerCase());

      return data.map((resource) => ({
        label: resource.name,
        color: resource.color,
        id: resource.id,
        picture: resource.picture,
        hasAvatar: true
      }));
    }
  },
  methods: {
    onResourceCreated(resourceId) {
      this.listValue = [...this.listValue, resourceId];
    }
  }
});
</script>

<style lang="scss" module>
.top {
  padding-bottom: $spacing;
  margin-bottom: $spacing;
  border-bottom: 1px solid $color-border;
}

.error {
  margin-top: $spacing;
  color: $color-error;
  font-size: 12px;
}

.multipleButton {
  display: flex;
  justify-content: flex-end;
}

.multipleInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: $spacing;
}

.addResource {
  cursor: pointer;
  display: flex;
  padding: $spacing;
  background-color: $white;
  border-radius: $radius;
  border: 1px solid $color-border;
}
</style>
