<template>
  <BaseModal
    :heading="$t('waiting_list.subscription_heading')"
    :loading="!waitingListEntry"
    :parentRoute="parentRouteName"
    :actions="[
      {
        name: 'appointment',
        icon: 'calendar',
        label: $t('waiting_list.book_appointment')
      },
      {
        name: 'email',
        icon: 'paper-plane',
        label: $t('waiting_list.send_email')
      },
      {
        name: 'delete',
        icon: 'delete',
        label: $t('waiting_list.unsubscribe')
      }
    ]"
    :customer="waitingListEntry ? waitingListEntry.customer : null"
    grey
    testId="waiting-list-entry"
    @action="onActionClick"
  >
    <div :class="[$style.base, { [$style.isMobile]: $screen === 's' }]">
      <BaseCard :mb="2">
        <div :class="$style.summary">
          <div v-if="waitingListEntry.date" v-test="'waiting-list-date'">
            <BaseText bold>{{ $t('global.date') }}:</BaseText>
            {{ filters.date(waitingListEntry.date) }}
          </div>
          <div
            v-if="waitingListEntry.daysOfWeek?.length"
            v-test="'waiting-list-daysofweek'"
          >
            <BaseText bold>{{ $t('waiting_list.available_days') }}:</BaseText>
            {{ entryDate(waitingListEntry, true) }}
          </div>
          <div
            v-if="!waitingListEntry.date && waitingListEntry.partsOfDay?.length"
            v-test="'waiting-list-partsofday'"
          >
            <BaseText bold>{{ $t('waiting_list.available_time') }}:</BaseText>
            {{ entryTimes(waitingListEntry) }}
          </div>
        </div>
        <div :class="$style.summary">
          <div v-test="'waiting-list-services'">
            <BaseText bold
              >{{
                filters.capitalize($t('global.items.service', 2))
              }}:</BaseText
            >
            {{ serviceNames(waitingListEntry) }}
          </div>
          <div v-test="'waiting-list-duration'">
            <BaseText bold>{{ $t('global.duration') }}:</BaseText>
            {{ servicesDuration(waitingListEntry) }}
            {{ $t('global.minute_short') }}
          </div>
          <div v-test="'waiting-list-employee'">
            <BaseText bold
              >{{ $t('waiting_list.employee_preference') }}:</BaseText
            >
            {{
              waitingListEntry.resource?.name ||
              $t('waiting_list.no_employee_preference')
            }}
          </div>
        </div>
        <div v-if="waitingListEntry.notes" v-test="'waiting-list-notes'">
          <BaseText bold
            >{{ filters.capitalize($t('global.items.note', 1)) }}:</BaseText
          >
          {{ waitingListEntry.notes }}
        </div>
      </BaseCard>
      <BaseHeading mb mt>
        {{ $t('global.history') }}
      </BaseHeading>
      <BaseCard>
        <Timeline :data="timelineItems" v-test="'timelineItems'" />
      </BaseCard>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import { entryFragment } from './graphql';
import { useRouteDate } from '@/helpers/routing';
import {
  deleteEntry,
  emailCustomer,
  createAppointment,
  serviceNames,
  servicesDuration,
  entryDate,
  entryTimes
} from './helpers';

import { defineComponent } from 'vue';

import Timeline from '@/components/Timeline.vue';

export default defineComponent({
  components: {
    Timeline
  },
  props: {
    parentRouteName: {
      type: String,
      required: true
    }
  },
  setup() {
    const { date } = useRouteDate({
      updateRouteQuery: true
    });

    return {
      date,
      serviceNames,
      servicesDuration,
      entryDate,
      entryTimes,
      filters
    };
  },
  computed: {
    timelineItems() {
      const items = this.waitingListEntry.lastEmailSentAt
        ? [
            {
              key: 'waiting_list_entry_email_sent',
              createdAt: this.waitingListEntry.lastEmailSentAt,
              user: this.waitingListEntry.customer,
              meta: {
                gateway: 'email',
                message_id: true,
                email: this.waitingListEntry.customer.email
              }
            }
          ]
        : [];

      return [
        ...items,
        {
          key: 'waiting_list_entry_created',
          createdAt: this.waitingListEntry.createdAt,
          user: this.waitingListEntry.customer,
          meta: {}
        }
      ];
    }
  },
  apollo: {
    waitingListEntry: {
      query: gql`
        query getWaitingListEntry($id: ID!) {
          waitingListEntry(id: $id) {
            ...waitingListEntry
          }
        }
        ${entryFragment}
      `,
      variables() {
        return {
          id: this.$route.params.waitingListEntryId
        };
      },
      skip() {
        return !this.$route.params.waitingListEntryId;
      }
    }
  },
  methods: {
    onActionClick(action) {
      switch (action) {
        case 'appointment':
          createAppointment(this.waitingListEntry);
          break;
        case 'email':
          emailCustomer(this.waitingListEntry.id, this.date);
          break;
        case 'delete':
          deleteEntry(this.waitingListEntry.id).then(() => {
            this.$router.push({ name: this.parentRouteName });
          });
          break;
      }
    }
  }
});
</script>

<style lang="scss" module>
.summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &:first-child > * {
    margin-bottom: 2 * $spacing;
  }

  .base.isMobile & {
    grid-template-columns: 1fr;
  }
}

.historyEntry {
  display: flex;
  align-items: center;
}
</style>
