<template>
  <div :class="$style.base" @click="expand" v-test="'nav-expand'">
    <BaseIcon
      :name="isNavExpanded ? 'arrow-left' : 'arrow-right'"
      color="inverted"
      size="s"
    />
  </div>
</template>

<script setup lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';
import { storeToRefs } from 'pinia';

const { isNavExpanded } = storeToRefs(usePageLayoutStore());

const expand = () => {
  isNavExpanded.value = !isNavExpanded.value;
};
</script>

<style lang="scss" module>
.base {
  position: absolute;
  bottom: 100px;
  right: -7px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $color-primary;

  @include hover {
    background-color: $color-primary-dark;
  }
}
</style>
