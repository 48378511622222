<template>
  <BaseModalLarge
    :heading="$t('admin.treatwell.contract_modal.heading')"
    useForm
    :loading="loadingVenue"
    :parentRoute="parentRouteName"
    testId="treatwell-contract"
    @close="$emit('close')"
    @submit="submit"
  >
    <div
      :class="[
        $style.contractCardHolder,
        { [$style.smallScreen]: $screen === 's' }
      ]"
    >
      <BaseCard
        mb
        white
        isSmall
        :class="$style.contractCard"
        v-test="'treatwell-contract-text'"
      >
        <div
          :class="$style.contractContent"
          v-html="contractTemplate?.content || ''"
        />
        <div :class="$style.contractCheckbox">
          <BaseCheckbox
            v-model="sepaAccepted"
            mt
            :label="$t('treatwell.contract.sepa_agree_label')"
            required
            v-test="'treatwell-contract-accept-checkbox'"
          />
          <BaseCheckbox
            v-model="readAndAccepted"
            mt
            :label="$t('treatwell.contract.agree_label')"
            required
            v-test="'treatwell-contract-accept-checkbox'"
          />
        </div>
      </BaseCard>
    </div>
    <template #footer>
      <BaseText
        v-if="contractTemplate && contractTemplate.downloadUrl"
        :href="contractTemplate.downloadUrl"
        download
        v-test="'tw-onboarding-download-contract'"
      >
        {{ $t('treatwell.contract.download') }}
      </BaseText>
      <BaseButton
        :disabled="loadingVenue"
        :loading="isSaving"
        submitForm
        v-test="'sign-contract'"
      >
        {{ $t('treatwell.cta.step_2') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script setup lang="ts">
import eventBus from '@/event-bus';
import { computed, inject, onUnmounted, ref } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import {
  GET_CONTRACT_TEMPLATE,
  TREATWELL_INTEGRATION_REQUEST_ACCEPT_CONTRACT,
  TREATWELL_INTEGRATION_REQUEST_UPDATE_CONTRACT
} from '../graphql';
import type { ContractTemplate } from '@/types';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useTreatwellStore } from '@/stores/treatwell';

defineProps<{
  parentRouteName?: string;
}>();

const emit = defineEmits(['close']);

const mixpanel = inject<any>('mixpanel');
const router = useRouter();
const { t } = useI18n();

const contractTemplate = ref<ContractTemplate | null>(null);

const readAndAccepted = ref(false);
const sepaAccepted = ref(false);

const { loading: loadingVenue, onResult } = useQuery(GET_CONTRACT_TEMPLATE, {
  type: 'treatwell'
});

onResult(({ data }) => {
  contractTemplate.value = data.contractTemplate;
});

const { treatwellStatus } = useTreatwellStore();

const isSaving = computed(() => isSavingAccept.value || isSavingUpdate.value);

const {
  mutate: updateContract,
  onDone: onUpdateContractDone,
  loading: isSavingUpdate
} = useMutation(TREATWELL_INTEGRATION_REQUEST_UPDATE_CONTRACT, () => ({
  variables: {
    input: {
      contractTemplateId: contractTemplate.value?.id
    }
  }
}));

const {
  mutate: acceptContract,
  onDone: onAcceptContractDone,
  loading: isSavingAccept
} = useMutation(TREATWELL_INTEGRATION_REQUEST_ACCEPT_CONTRACT, () => ({
  variables: {
    input: {
      contractTemplateId: contractTemplate.value?.id
    }
  }
}));

const submit = () => {
  if (
    treatwellStatus.optOutCampaign &&
    treatwellStatus.contractType === 'treatwell_opt_out_campaign'
  ) {
    updateContract();
  } else {
    acceptContract();
  }
};

onUpdateContractDone(({ data }) => {
  if (data.errors) {
    flash('error');
  } else {
    mixpanel.track('Treatwell dashboard - task - update opt out contract');
    afterUpdate();
  }
});

onAcceptContractDone(({ data }) => {
  if (data.errors) {
    flash('error');
  } else {
    mixpanel.track('Treatwell dashboard - task - accept contract');
    afterUpdate();
  }
});

const afterUpdate = () => {
  flash(t('treatwell.update_profile.status.signed_contract'));
  eventBus.$emit('update-task', 'sign_contract');
  treatwellStatus.contractSigned = true;

  router.push({
    name: 'treatwell-admin'
  });

  emit('close');
};

onUnmounted(() => {
  mixpanel.track('Treatwell dashboard - task - close contract modal');
});
</script>

<style lang="scss" module>
.contractCardHolder {
  max-width: 700px;
  margin: auto;
}
.contractContent,
.contractCheckbox {
  padding: 0 $spacing;
  font-size: 14px;
  p,
  li {
    line-height: 1.25;
  }
  ol {
    font-size: 18px;
  }
  img {
    margin: 2 * $spacing 0 0.5 * $spacing;
  }
}
.contractCheckbox {
  .contractCardHolder:not(.smallScreen) & {
    padding: $spacing 4 * $spacing 2 * $spacing;
  }
}
.contractContent {
  .contractCardHolder:not(.smallScreen) & {
    padding: 0 4 * $spacing;
  }
}
</style>
