<template>
  <div
    :class="[$style.base, { [$style.fullHeight]: fullHeight }]"
    v-test="'empty-page'"
  >
    <transition appear>
      <div v-show="!imageName || imageLoaded" :class="$style.inner">
        <div
          v-if="imageName || slots.visual"
          :class="$style.imageWrap"
          v-test="'empty-page-visual'"
        >
          <img
            v-if="imageName"
            :class="$style.image"
            :src="`/img/empty-states/${imageName}.svg`"
            @load="imageLoaded = true"
          />
          <slot v-else name="visual" />
        </div>
        <div :class="$style.textWrap">
          <div v-if="text">
            <BaseText v-if="text.title" size="l" bold center mb>
              {{ text.title }}
            </BaseText>
            <BaseText v-if="text.description" center>
              {{ text.description }}
            </BaseText>
            <div :class="$style.buttons">
              <BaseButton
                v-if="text.btnSecondary"
                color="inverted"
                :href="hrefLink"
                :icon="iconSecondary"
                @click="$emit('click', 'secondary')"
                v-test="'empty-page-btn-secondary'"
              >
                {{ text.btnSecondary }}
              </BaseButton>
              <BaseButton
                v-if="text.btnPrimary"
                :href="hrefLinkPrimary"
                :icon="iconPrimary"
                :loading="loading"
                @click="$emit('click', 'primary')"
                v-test="'empty-page-btn-primary'"
              >
                {{ text.btnPrimary }}
              </BaseButton>
            </div>
            <BaseText
              v-if="moreLink && moreLink.url && moreLink.text"
              :href="moreLink.url"
              target="_blank"
              :class="$style.moreLink"
              v-test="'empty-page-more-link'"
            >
              {{ moreLink.text }}
            </BaseText>
            <div
              v-if="videoId && company.language === 'nl' && isTrial"
              :class="$style.video"
            >
              <BaseText
                ml
                iconBefore="play-circle"
                :href="`https://vimeo.com/${videoId}`"
                target="_blank"
              >
                {{ $t('global.watch_video') }}
              </BaseText>
            </div>
          </div>
          <div v-else>
            <BaseText>
              {{ $t('global.no_results') }}
            </BaseText>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue';
import { useCompanyStore } from '@/stores/company';
const { company, isTrial } = useCompanyStore();
const slots = useSlots();
defineProps<{
  imageName?: string;
  text?: Record<string, string>;
  moreLink?: { text: string; url: string };
  noResults?: boolean;
  fullHeight?: boolean;
  videoId?: string;
  hrefLink?: string;
  hrefLinkPrimary?: string;
  iconPrimary?: string;
  iconSecondary?: string;
  loading?: boolean;
}>();

defineEmits(['click']);

const imageLoaded = ref(false);
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: center;
  padding: $spacing * 2 $spacing;
  text-align: center;
  max-width: 100%;

  &.fullHeight {
    align-items: center;
    height: 100%;
  }
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing;
}

.inner {
  width: 480px;
  max-width: 100%;

  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition: opacity 0.1s;
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}

.imageWrap {
  display: flex;
  justify-content: center;
}

.textWrap {
  margin-top: $spacing * 2;
}

.image {
  width: 250px;
  max-width: 50%;
  max-height: 250px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: $spacing;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }

  & > * {
    margin: 0 $spacing * 0.5 $spacing;
  }
}

.more {
  display: flex;
  justify-content: center;
  margin-top: $spacing;
}
</style>
