<template>
  <BaseCard v-if="showComponent" heading="Salonized Pay">
    <div :class="$style.base">
      <img :class="$style.visual" src="/img/salonized-pay/verification-2.svg" />
      {{ $t('register.salonized_pay.verification.issues_found') }}
      <BaseButton color="inverted" :routerLink="{ name: 'salonized-pay' }">
        {{ $t('register.salonized_pay.verification.complete') }}
      </BaseButton>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { useAdyenStore } from '@/stores/adyen';
const showComponent = computed(() => {
  const { adyenAppState, adyenAppStateLoaded } = useAdyenStore();
  return adyenAppStateLoaded && adyenAppState.verificationStatus === 'INVALID';
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing;
}
.visual {
  width: 130px;
}
</style>
