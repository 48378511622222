<template>
  <Requirement
    v-for="item in visibleRequirements"
    :id="item.id"
    :key="`item-${item.id || item._uid}`"
    :primary="item.primary"
    :resourceIds="item.resourceIds"
    :type="item.type"
    :startCollapsed="startCollapsed"
    :hasHeader="!startCollapsed"
    mb
    @updateSelectedIds="updateSelectedIds($event, item._uid)"
    @close="deleteRequirement(item._uid)"
    v-test="'service-requirement'"
  />
  <div v-if="buttons.length">
    <BaseChip
      v-for="button in buttonList"
      :key="button.value"
      :text="button.label"
      :icon="button.icon"
      clickable
      :mr="0.5"
      :mt="0.5"
      @click="handleResourceOptionSelect(button.value)"
      v-test="`add-resource-${button.value}`"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ServiceVariationsRequirementsView'
});
</script>

<script lang="ts" setup>
import type { ServiceVariation } from '../types';
import type { IconName } from '@/components/base-icon/types';

import { useI18n } from 'vue-i18n';
import { useResourcesStore } from '@/stores/resources';

import Requirement from './ResourceType.vue';

type ResourceType = 'employee' | 'room' | 'equipment';
type ButtonItem = {
  type: string;
  icon: string;
};

let uid = 0;

const props = defineProps<{
  variation: ServiceVariation;
  startCollapsed?: boolean;
}>();

const showResourceOptions = ref(false);

const visibleRequirements = computed(
  () =>
    props.variation.requirementsAttributes?.filter((r: any) => !r.destroy) || []
);

const { resourcesByType } = useResourcesStore();
const { t } = useI18n();

const buttons: ComputedRef<ButtonItem[]> = computed(() => {
  if (!visibleRequirements.value.length) {
    return [];
  } else {
    return [
      {
        type: 'employee',
        icon: 'person'
      },
      {
        type: 'room',
        icon: 'room'
      },
      {
        type: 'equipment',
        icon: 'monitor'
      }
    ].filter((button) => {
      const resourceAmount = resourcesByType(button.type).length;
      const requirementAmount = visibleRequirements.value.filter(
        (req: any) => req.type === button.type.toUpperCase()
      ).length;
      return requirementAmount === 0 || requirementAmount < resourceAmount;
    });
  }
});

const buttonList = computed(() =>
  buttons.value.map((button: ButtonItem) => ({
    label: t(`service.resources.${button.type}.link_add`),
    value: button.type as ResourceType,
    icon: button.icon as IconName
  }))
);

const handleResourceOptionSelect = (type: ResourceType) => {
  showResourceOptions.value = false;
  addItem(type.toUpperCase());
};

const updateSelectedIds: (ids: number[], uid: number) => void = (ids, uid) => {
  const requirement = props.variation.requirementsAttributes?.find(
    (r) => r._uid === uid
  );
  if (requirement) {
    requirement.resourceIds = ids;
  }
};

const deleteRequirement: (uid: number) => void = (uid) => {
  const requirement = props.variation.requirementsAttributes?.find(
    (r) => r._uid === uid
  );
  if (requirement) {
    requirement.destroy = true;
  }
};

const addItem: (type: string) => void = (type) => {
  const resources = resourcesByType(type.toLowerCase());
  const resourceIds = resources.map((resource) => resource.id);

  if (props.variation.requirementsAttributes) {
    uid++;
    props.variation.requirementsAttributes = [
      ...props.variation.requirementsAttributes,
      {
        _uid: uid,
        destroy: false,
        primary: !props.variation.requirementsAttributes.length, // Only the first requirement should be primary
        resourceIds,
        type
      }
    ];
  }
};

if (!props.variation.requirementsAttributes?.length) {
  // Add a default requirement when creating a new service
  addItem('EMPLOYEE');
} else {
  // When editing a service, add uids to existing requirements
  props.variation.requirementsAttributes =
    props.variation.requirementsAttributes.map((r) => {
      uid++;
      return {
        ...r,
        _uid: uid
      };
    });
}
</script>
