<template>
  <div
    :class="[
      $style.base,
      { [$style.isSmall]: isSmall, [$style.smallScreen]: $screen === 's' }
    ]"
  >
    <div :class="$style.inner">
      <Header />
      <div :class="$style.newsletter" v-test="'preview-container'">
        <BaseImage
          v-show="hasLogo"
          v-if="company.logo"
          :cloudinaryId="company.logo.large"
          :width="100"
          mt
          mb
          v-test="'previewLogo'"
        />
        <div
          v-show="hasImage"
          :class="$style.placeholderImage"
          v-test="'previewBanner'"
        >
          <BaseImage
            v-if="imageUrl"
            :cloudinaryId="imageUrl"
            :height="isSmall ? null : 280"
            cover
          />
        </div>
        <div :class="$style.content">
          <Message :mt="2" />
          <DiscountField
            v-show="showDiscount"
            mb
            v-test="'previewDiscountField'"
          />
          <EmailButton v-show="hasButton" v-test="'previewMainButton'" />
        </div>
        <Footer :company="company" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import Message from './Message.vue';
import DiscountField from './DiscountField.vue';
import EmailButton from './EmailButton.vue';
import Footer from './Footer.vue';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import Header from './Header.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Message,
    DiscountField,
    EmailButton,
    Footer,
    Header
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    isSmall() {
      return this.$screen === 's' || this.selectedView === 'MOBILE';
    },
    showDiscount() {
      return this.hasDiscount;
    },
    ...mapGetters('marketing', [
      'hasLogo',
      'hasDiscount',
      'hasButton',
      'hasImage',
      'imageUrl'
    ]),
    ...mapGetters('marketing/builder', ['selectedView'])
  }
});
</script>

<style lang="scss" module>
.base {
  background: $grey-light;
  height: calc(100vh - 100px);
  overflow-y: auto;

  &.smallScreen {
    overflow: unset;
  }
}

.inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: $spacing;
}

.placeholderImage {
  background: $grey-light;
  height: 280px;
  width: 100%;

  .isSmall & {
    height: 100%;
    max-height: 280px;
  }
}

.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $shadow;
  background: $white;
  max-width: 800px;
  width: 100%;

  .isSmall & {
    max-width: 340px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: $spacing * 2;
  max-width: 640px;
  padding: 0 $spacing;
}
</style>
