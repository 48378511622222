<template>
  <router-link
    :to="routerLink"
    :class="$style.base"
    :style="
      color
        ? {
            'border-color': color
          }
        : null
    "
  >
    <div :class="$style.section" v-test="'service-group-name'">
      <BaseText
        :iconBefore="serviceGroup.services.length > 1 ? 'layers' : null"
      >
        {{ serviceGroup.name }}
      </BaseText>
    </div>
    <div :class="$style.section">
      <div
        v-if="serviceGroup.services[0].medical"
        v-test="'service-group-icon-medical'"
      >
        <BaseIcon name="medical" />
      </div>
      <BaseText
        v-if="serviceGroup.services.length > 1"
        color="secondary"
        v-test="'service-group-variations-amount-text'"
      >
        {{
          $screen === 's'
            ? serviceGroup.services.length +
              ' ' +
              $t('global.items.variation', serviceGroup.services.length)
            : $t('services.variations_amount', {
                amount: serviceGroup.services.length
              })
        }}
      </BaseText>
      <div
        v-if="$screen !== 's'"
        :class="$style.duration"
        v-test="'service-group-duration'"
      >
        {{ `${duration} ${$t('global.items.minute', duration)}` }}
      </div>
      <div
        v-if="$screen !== 's'"
        :class="$style.price"
        v-test="'service-group-price'"
      >
        {{ filters.currency(price) }}
      </div>
      <div v-if="serviceCategorySortOder" :class="$style.sortOrder">
        <BaseCounter
          v-model="sortOrderValue"
          :minValue="0"
          :maxValue="listLength - 1"
          hideValue
          reverse
          v-test="'service-group-sort-order'"
        />
      </div>
      <div v-else :class="$style.more">
        <BaseMore
          :options="['edit', 'duplicate', 'delete', 'sort']"
          @select="onMoreSelect"
          v-test="'service-group-more'"
        />
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent, inject } from 'vue';
import {
  deleteServiceVariationGroup,
  duplicateServiceVariationGroup
} from '../../actions';
import { flash } from '@/helpers/ui';

type Actions = 'edit' | 'duplicate' | 'delete' | 'sort';

export default defineComponent({
  props: {
    serviceGroup: {
      type: Object,
      required: true
    },
    color: {
      type: String
    },
    listLength: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['updateSortOrder'],
  setup() {
    const serviceCategorySortOder = inject('services-category-sort-order');
    const { refetch, appendServiceGroup } = inject<any>('serviceGroupActions');
    const mixpanel = inject('mixpanel');
    return {
      filters,
      serviceCategorySortOder,
      refetch,
      appendServiceGroup,
      flash,
      mixpanel,
      deleteServiceVariationGroup,
      duplicateServiceVariationGroup
    };
  },
  computed: {
    routerLink() {
      return {
        name: 'admin-service',
        params: {
          serviceId: this.serviceGroup.id
        }
      };
    },
    sortOrderValue: {
      get() {
        return this.index;
      },
      set(value) {
        this.$emit('updateSortOrder', value);
      }
    },
    duration() {
      if (!this.serviceGroup?.services[0]?.duration) {
        return 0;
      }

      let minDuration = this.serviceGroup.services[0].duration;
      this.serviceGroup.services.forEach((variation) => {
        if (variation.duration < minDuration) {
          minDuration = variation.duration;
        }
      });
      return minDuration;
    },
    price() {
      if (!this.serviceGroup?.services[0]?.price) {
        return 0;
      }

      let minPrice = this.serviceGroup.services[0].price;
      this.serviceGroup.services.forEach((variation) => {
        if (variation.price < minPrice) {
          minPrice = variation.price;
        }
      });
      return minPrice;
    }
  },
  methods: {
    onMoreSelect(action: Actions) {
      switch (action) {
        case 'edit':
          this.$router.push(this.routerLink);
          break;

        case 'delete':
          this.deleteServiceVariationGroup(this.serviceGroup.id).then(() => {
            this.flash(this.$t('global.flash.service_deleted'));
            this.mixpanel.track('Service group deleted');
            this.refetch();
          });
          break;

        case 'duplicate':
          this.duplicateServiceVariationGroup(this.serviceGroup.id).then(
            ({
              data: {
                duplicateServiceGroup: { serviceGroup }
              }
            }) => {
              this.flash(this.$t('global.flash.service_duplicated'));
              this.mixpanel.track('Service group duplicated');
              this.appendServiceGroup(serviceGroup);
            }
          );
          break;

        case 'sort':
          this.serviceCategorySortOder = true;
          break;

        default:
          break;
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-top-width: 0;
  min-height: 60px;
  background-color: white;

  &:global(.sortable-chosen) {
    border-top-width: 1px;
    margin-top: -1px;

    &:not(:last-child) {
      margin-bottom: -1px;
    }

    & + .base {
      border-top-width: 1px;
    }
  }
}

.section {
  display: flex;
  align-items: center;
  padding: $spacing;

  & > *:not(:first-child) {
    margin-left: $spacing;
  }
}

.sortOrder {
  margin-top: $spacing * -0.5;
  margin-bottom: $spacing * -0.5;
}

.duration {
  white-space: nowrap;
}

.price {
  width: 57px;
  text-align: right;
}

.more {
  margin: $spacing * -1;
}
</style>
