<template>
  <BaseModal
    :heading="$t('marketing.email.automated_emails.rebook_setup.title')"
    :loading="$apollo.loading"
    small
    :parentRoute="{
      name: parentRouteName
    }"
  >
    <BaseText mb>
      {{ $t('marketing.email.automated_emails.rebook_setup.info') }}
    </BaseText>
    <CategoryRow
      v-for="(category, index) in categories"
      :key="index"
      :category="category"
      :mb="0.5"
    />
    <BaseAlert
      v-if="!numberOfServicesWithRebookEnabled"
      color="warning"
      :text="$t('marketing.email.automated_emails.rebook_setup.alert')"
      :primaryAction="
        $t('marketing.email.automated_emails.rebook_setup.alert_action')
      "
      @primaryAction="disableRebook"
      v-test="'rebook-setup-alert'"
    />
    <template #footerSub>
      <BaseText
        v-if="hasServicesWithoutRebook"
        link
        iconBefore="refresh"
        @click="resetToDefault"
      >
        {{ $t('marketing.email.automated_emails.rebook_setup.reset') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="$router.push({ name: parentRouteName })"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        :disabled="!numberOfServicesWithRebookEnabled"
        @click="onSave"
        v-test="'rebook-setup-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { flash } from '@/helpers/ui';
import CategoryRow from './CategoryRow.vue';
import { useServicesStore } from '@/stores/services';
import { useCompanyStore } from '@/stores/company';
import { useRouter } from 'vue-router';
import { useMutation } from '@vue/apollo-composable';
import { UPDATE_SERVICES } from './graphql';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useLeaveConfirmation } from '@/helpers/routing';

import { useStore } from 'vuex';
const store = useStore();

const { categoriesWithServices } = storeToRefs(useServicesStore());
const { updateCompany } = useCompanyStore();

const { t } = useI18n();
const router = useRouter();

const hasServicesWithoutRebook = computed(
  () =>
    categories.value
      .map((category: { services: any }) => category.services)
      .reduce((acc: string | any[], val: any) => acc.concat(val), [])
      .filter((service: { rebookEnabled: any }) => !service.rebookEnabled)
      .length
);
const numberOfServicesWithRebookEnabled = ref(
  categoriesWithServices.value
    .map((category: { services: any }) => category.services)
    .reduce((acc: string | any[], val: any) => acc.concat(val), [])
    .filter((service: { rebookEnabled: any }) => service.rebookEnabled).length
);

const categories = ref(
  categoriesWithServices.value
    .map(
      (category: { id: any; name: any; color: string; services: any[] }) => ({
        id: category.id,
        name: category.name,
        color: category.color,
        services: category.services.map(
          (service: {
            id: any;
            name: any;
            rebookEnabled: any;
            rebookPeriod: any;
          }) => ({
            id: service.id,
            name: service.name,
            rebookEnabled:
              !numberOfServicesWithRebookEnabled.value ||
              service.rebookEnabled ||
              false,
            rebookPeriod: service.rebookPeriod || 30
          })
        )
      })
    )
    .filter(
      (category: { services: string | any[] }) => !!category.services.length
    )
);

const { resetConfirmation, confirmLeave } = useLeaveConfirmation(
  reactive({
    categories
  })
);

watch(categories, () => (confirmLeave.value = true), { deep: true });

watch(
  categories,
  () => {
    numberOfServicesWithRebookEnabled.value = categories.value
      .map((category: { services: any }) => category.services)
      .reduce((acc: string | any[], val: any) => acc.concat(val), [])
      .filter(
        (service: { rebookEnabled: any }) => service.rebookEnabled
      ).length;
  },
  {
    immediate: true,
    deep: true
  }
);

const parentRouteName = 'rebook-reminder-template';
const isLoading = ref(false);

const resetToDefault = () => {
  categories.value.forEach(
    (category: { services: { rebookEnabled: boolean }[] }) => {
      category.services.forEach((service: { rebookEnabled: boolean }) => {
        service.rebookEnabled = true;
      });
    }
  );
};

const disableRebook = () => {
  confirmLeave.value = false;
  const input = {
    settings: {
      bookings: {
        rebookReminderEnabled: false
      }
    }
  };

  updateCompany(input).then(() => {
    store.commit('marketing/builder/SET_ENABLED', false);
    router.push({ name: parentRouteName });
    flash(t('global.flash.email_disabled'));
  });
};

const { mutate } = useMutation(UPDATE_SERVICES);

const onSave = () => {
  resetConfirmation();
  const bulkUpdateServiceAttributes = categories.value
    .map((category: { services: any }) => category.services)
    .reduce((acc: string | any[], val: any) => acc.concat(val), []);

  isLoading.value = true;
  mutate({
    input: {
      bulkUpdateServiceAttributes
    }
  })
    .then(() => {
      const { getServices } = useServicesStore();
      getServices();

      flash(t('global.flash.services_updated'));
      router.push({ name: parentRouteName });
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
