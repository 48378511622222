<template>
  <div
    v-show="$screen !== 's' || showMobileMenu"
    :class="[
      $style.base,
      {
        [$style.isSmall]: $screen === 's',
        [$style.isTreatwell]: isTreatwellUser,
        [$style.expanded]: isNavExpanded
      }
    ]"
    v-test="'the-main-nav'"
  >
    <BlockedInfoModal
      v-if="hasBlockedInfoModal"
      @close="hasBlockedInfoModal = false"
    />
    <transition name="slide-down">
      <div v-show="showMobileMenu || $screen !== 's'" :class="$style.inner">
        <div :class="$style.section">
          <NavItem
            v-if="$screen !== 's'"
            :link="{
              routeName: 'dashboard',
              tooltip: $t('nav.dashboard')
            }"
            dark
            @click="onNavItemClick"
          >
            <div :class="$style.logo">
              <BaseLogo />
            </div>
          </NavItem>
          <div :class="$style.mainItems">
            <NavItem
              v-for="(link, index) in links"
              :key="index"
              :link="link"
              :tooltip="
                isNavExpanded
                  ? undefined
                  : {
                      position: 'right',
                      text: link.tooltip
                    }
              "
              @click="onNavItemClick"
            />
          </div>
        </div>
        <Expand
          v-if="$screen !== 's'"
          :tooltip="{
            position: 'right',
            text: isNavExpanded
              ? $t('global.actions.collapse')
              : $t('global.actions.expand')
          }"
        />
        <div v-if="!company.blocked" :class="$style.section">
          <NavItem
            v-if="showMobileDesktopButton"
            :link="{
              icon: isMobileDesktop ? 'mobile' : 'monitor'
            }"
            @click="toggleMobileDesktop"
          />
          <NavItem
            v-if="isRouteAllowed('admin')"
            :link="{
              icon: 'settings',
              routeName: 'admin',
              tooltip: $t('nav.settings')
            }"
            :tooltip="
              isNavExpanded
                ? undefined
                : {
                    position: 'right',
                    text: $t('nav.settings')
                  }
            "
            @click="onNavItemClick"
          />
          <NavItem
            v-if="!browser.isUIWebview"
            ref="navItemInfo"
            :link="{
              icon: 'info',
              routeName: 'new-features',
              notification: {
                show: user?.showNewFeaturesNotification,
                value: ''
              },
              tooltip: $t('nav.support_hub')
            }"
            :tooltip="
              isNavExpanded
                ? undefined
                : {
                    position: 'right',
                    text: $t('nav.support_hub')
                  }
            "
            @click="onNavItemClick"
            v-test="'nav-support'"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import NavItem from './NavItem.vue';
import { isRouteAllowed } from '@/router/helpers';
import { useLocationsStore } from '@/stores/locations';
import { mapState, mapActions } from 'pinia';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useVersionMismatchStore } from '@/stores/version-mismatch';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { device, browser } from '@/user-context';
import { useStorage } from '@vueuse/core';

import { defineComponent } from 'vue';
import BlockedInfoModal from './BlockedInfoModal.vue';
import { useTreatwellStore } from '@/stores/treatwell';
import { BlockReason } from '@/types';
import Expand from './Expand.vue';

export default defineComponent({
  components: {
    NavItem,
    BlockedInfoModal,
    Expand
  },
  inject: ['mixpanel'],
  setup() {
    const { isTreatwellUser } = useCompanyStore();

    return {
      isTreatwellUser,
      browser,
      isRouteAllowed
    };
  },
  data() {
    return {
      hasBlockedInfoModal: false
    };
  },
  watch: {
    $screen() {
      this.$nextTick(() => {
        this.setNavbarWidth();
      });
    },
    isNavExpanded() {
      setTimeout(() => {
        this.setNavbarWidth();
      }, 500);
    },
    isMobileDesktop: {
      handler(newValue, oldValue) {
        const pageLayoutStore = usePageLayoutStore();

        if (!device.mobile) {
          pageLayoutStore.isMobileDesktop = false;
          return;
        }

        const tag = document.querySelector('meta[name="viewport"]');

        if (newValue) {
          // Set user-scalable=no to reset the "zoom", and then remove it to allow the user to zoom in again.
          tag.content = 'width=920,initial-scale=0.38,user-scalable=no';
          setTimeout(() => {
            tag.content = 'width=920,initial-scale=0.38';
          }, 0);

          this.setScreenSize(920);
        } else if (oldValue) {
          tag.content = 'width=device-width,initial-scale=1.0,user-scalable=no';
          this.setScreenSize();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useVersionMismatchStore, ['versionMismatchReload']),
    ...mapState(useLocationsStore, ['locationId']),
    ...mapState(usePageLayoutStore, [
      'showMobileMenu',
      'isMobileDesktop',
      'isNavExpanded'
    ]),
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    ...mapState(useCompanyStore, ['company', 'isTrial']),
    marketingTrialHasEnded() {
      return this.company.marketingTrialEndsAt < dayjs().format();
    },
    showMobileDesktopButton() {
      if (!this.hasFeatureFlag('mobile-desktop-button')) {
        return false;
      }

      return device.mobile && (!browser.isUIWebview || browser.isAndroid);
    },
    stockWarningCount() {
      let warningCount = 0;
      if (this.company?.stockWarnings) {
        const warning = this.company.stockWarnings.find(
          (warning) => warning.location_id === this.locationId
        );
        if (warning) {
          warningCount = warning.count;
        }
      }
      return warningCount;
    },
    showTWOnboardingNotification() {
      const { completedOnboardingTasks, hasDashboard } = useTreatwellStore();

      const tw_linked_account_at = useStorage('tw_linked_account_at', 0);
      const tw_linked_account_at_2_days_ago =
        dayjs().diff(dayjs(tw_linked_account_at.value), 'day') >= 2;
      const tw_linked_account_at_16_days_ago =
        dayjs().diff(dayjs(tw_linked_account_at.value), 'day') >= 16;

      return (
        hasDashboard &&
        !completedOnboardingTasks &&
        tw_linked_account_at_2_days_ago &&
        !tw_linked_account_at_16_days_ago
      );
    },
    links() {
      const { showTreatwell, hasDashboard } = useTreatwellStore();

      const links = [
        {
          icon: 'calendar',
          routeName: 'calendar'
        },
        {
          icon: 'people',
          routeName: 'customers'
        },
        {
          icon: 'shopping-cart',
          routeName: 'register'
        },
        {
          icon: 'pricetag',
          routeName: 'products',
          notification: {
            show: this.stockWarningCount > 0,
            value: this.stockWarningCount
          }
        },
        {
          icon: 'email',
          routeName: 'messages'
        },
        {
          icon: 'message',
          routeName: 'feedback',
          notification: {
            show: this.user.feedbacks > 0,
            value: this.user.feedbacks
          }
        },
        {
          icon: 'activity',
          routeName: 'reports',
          tooltip: 'reports'
        },
        {
          icon: 'marketing',
          routeName: 'marketing'
        },
        {
          name: hasDashboard ? 'treatwell-admin' : 'treatwell-landing',
          icon: 'treatwell',
          routeName: hasDashboard ? 'treatwell-admin' : 'treatwell-landing',
          tooltip: 'treatwell',
          hide: !showTreatwell,
          notification: {
            show: this.showTWOnboardingNotification,
            value: '!'
          }
        }
      ].map((link) => {
        const route = this.$router.resolve({
          name: link.routeName
        });

        return {
          ...link,
          tooltip: this.$t(
            `nav.${link.tooltip ? link.tooltip : link.routeName}`
          ),
          show: !!route && !link.hide && isRouteAllowed(route)
        };
      });

      return links.filter((link) => link.show);
    }
  },
  methods: {
    ...mapActions(usePageLayoutStore, ['setScreenSize']),
    redirect(url) {
      if (window.location.port) {
        url = url.replace(/:[\d*]+/, '');
        url = url + ':' + window.location.port;
      }
      window.location.replace(url + window.location.pathname);
    },
    onNavItemClick() {
      if (
        this.company.blocked &&
        this.company.blockReason !== BlockReason.PausedCollection
      ) {
        this.hasBlockedInfoModal = true;
        return;
      }
    },
    setNavbarWidth() {
      const pageLayoutStore = usePageLayoutStore();
      pageLayoutStore.navbarWidth =
        this.$screen === 's' ? 0 : this.$el.offsetWidth;
    },
    toggleMobileDesktop() {
      const pageLayoutStore = usePageLayoutStore();
      pageLayoutStore.isMobileDesktop = !this.isMobileDesktop;
    }
  },
  mounted() {
    this.setNavbarWidth();
  }
});
</script>

<style lang="scss" module>
.tooltip {
  white-space: nowrap;
}

.base {
  position: fixed;
  left: 0;
  top: 0;
  width: $nav-width;
  height: 100%;
  z-index: 220;
  transition: width 0.3s $easeOutExpo;

  &.isSmall {
    top: $nav-width;
    height: calc(100% - #{$nav-width});
  }

  &.expanded {
    width: $nav-width-expanded;
  }

  @media print {
    display: none;
  }
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: $color-primary;

  .base.isTreatwell & {
    background: url('/img/treatwell/bg.png') 48% center no-repeat;
    background-size: cover;
    background-color: $treatwell-primary;
  }

  .base.isSmall & {
    &:global(.slide-down-leave-active),
    &:global(.slide-down-enter-active) {
      transition: transform 0.3s ease;
    }
    &:global(.slide-down-enter),
    &:global(.slide-down-leave-to) {
      transform: translateY(-100%);
    }
  }
}

.section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.menu {
  position: absolute;
  top: 0;
  right: $spacing * 0.5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: $nav-width * 0.25;
}

.mainItems {
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  flex-shrink: 0;
}

.infoNav {
  left: $nav-width;
  bottom: 4px;

  .isSmall & {
    bottom: 1px;
  }
}
</style>
