<template>
  <div>
    <ConfirmationModal
      v-if="showConfirmationModal"
      @submit="download"
      @close="showConfirmationModal = false"
    />
    <BaseCard :heading="heading">
      <template #header>
        <div
          v-if="isMonthly && multiLocation && dataScope === 'LOCAL'"
          v-test="'export-location'"
        >
          {{ location.internalName }}
        </div>
      </template>
      <BaseDropdown
        v-model="dataType"
        :options="dataTypes"
        :label="$t('export_page.report_information')"
        :error="error"
        mb
        v-test="'export-type'"
      />
      <BaseDropdown
        v-if="isMonthly"
        v-model="month"
        :label="$t('export_page.month')"
        :options="monthItems"
        v-test="'export-month'"
      />
      <div :class="$style.bottom">
        <div>
          <BaseInputLabel :text="$t('export_page.file_type')" />
          <BaseRadio
            v-if="dataFormats.length > 1"
            v-model="dataFormat"
            :options="dataFormats"
            buttons
            v-test="'export-format'"
          />
          <div v-else>.{{ dataFormat }}</div>
        </div>
        <BaseButton
          :loading="isSaving"
          :disabled="!!error"
          @click="hasAsyncExports ? (showConfirmationModal = true) : download()"
          v-test="'export-btn-download'"
        >
          {{ $t('global.actions.export') }}
        </BaseButton>
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import config from '@/config';
import { defineComponent } from 'vue';
import unleash from '@/unleash';
import gql from 'graphql-tag';
import { flash, modal } from '@/helpers/ui';
import ConfirmationModal from './ConfirmationModal.vue';

export default defineComponent({
  components: { ConfirmationModal },
  inject: ['mixpanel'],
  props: {
    heading: {
      type: String
    },
    months: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      dataFormat: 'xls',
      month: null,
      dataType: null,
      isSaving: false,
      lastExport: dayjs.unix(0),
      lastExportType: '',
      showConfirmationModal: false
    };
  },
  watch: {
    dataFormats: {
      handler(value) {
        // When selecting a different dataType, the selected dataFormat might not be available
        // If so, select the first format
        const values = value.map((item) => item.value);
        if (values.length && !values.includes(this.dataFormat)) {
          this.dataFormat = values[0];
        }
      },
      immediate: true,
      deep: true
    },
    dataScope: {
      handler() {
        this.resetExportTimer();
      }
    },
    location: {
      handler() {
        this.resetExportTimer();
      }
    },
    dataType: {
      handler() {
        this.resetExportTimer();
      }
    },
    dataFormat: {
      handler() {
        this.resetExportTimer();
      }
    },
    month: {
      handler() {
        this.resetExportTimer();
      }
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['company', 'multiLocation']),
    ...mapState(useLocationsStore, ['location', 'dataScope']),
    isMonthly() {
      return this.months.length;
    },
    hasAsyncExports() {
      return unleash.isEnabled('AsyncExports');
    },
    error() {
      return this.dataScope === 'GLOBAL' && this.dataType === 'worked_hours'
        ? this.$t('export_page.error_global_worked_hours')
        : '';
    },
    dataFormats() {
      return [
        {
          label: 'XLS',
          value: 'xls',
          disabled: this.dataType === 'datev' || this.dataType === 'gobd'
        },
        {
          label: 'CSV',
          value: 'csv',
          disabled: this.dataType === 'gobd'
        },
        {
          label: 'ZIP',
          value: 'zip',
          disabled: this.dataType !== 'gobd'
        }
      ].filter((item) => !item.disabled);
    },
    monthItems() {
      return this.months.map((month) => ({
        value: month,
        label: this.filters.date(month, { format: 'monthYear' })
      }));
    },
    dataTypes() {
      const lifetimeItems = [
        {
          value: 'appointments',
          label: this.$t('global.items.appointment', 2),
          disabled: !this.hasFeatureFlag('module-appointments')
        },
        {
          value: 'absences',
          label: this.$t('global.items.absence', 2)
        },
        {
          value: 'customers',
          label: this.$t('global.items.customer', 2)
        },
        {
          value: 'products',
          label: this.$t('global.items.product', 2),
          disabled: !this.hasFeatureFlag('module-register')
        },
        {
          value: 'current_product_stock',
          label: this.$t('export_page.data_types.current_product_stock'),
          disabled: !this.hasFeatureFlag('module-register')
        },
        {
          value: 'treatment_reports',
          label: this.$t('global.items.treatment_report', 2)
        }
      ];
      if (this.company.country !== 'be') {
        lifetimeItems.push({
          value: 'deleted_appointments',
          label: this.$t('export_page.data_types.deleted_appointments'),
          disabled: !this.hasFeatureFlag('module-appointments')
        });
      }
      const montlyItems = [
        {
          value: 'day_totals',
          label: this.$t('export_page.data_types.day_totals'),
          disabled: !this.hasFeatureFlag('module-register')
        },
        {
          value: 'orders',
          label: this.$t('global.items.order', 2),
          disabled: !this.hasFeatureFlag('module-register')
        },
        {
          value: 'order_items',
          label: this.$t('export_page.data_types.order_items'),
          disabled: !this.hasFeatureFlag('module-register')
        },
        {
          value: 'drawer_transactions',
          label: this.$t('export_page.data_types.drawer_transactions'),
          disabled: !this.hasFeatureFlag('module-register')
        },
        {
          value: 'worked_hours',
          label: this.$t('export_page.data_types.worked_hours')
        },
        {
          value: 'appointments',
          label: this.$t('global.items.appointment', 2),
          disabled:
            !this.hasFeatureFlag('module-appointments') ||
            !this.hasFeatureFlag('module-register')
        },
        {
          value: 'datev',
          label: 'Datev',
          disabled:
            !this.hasFeatureFlag('admin-export-datev') ||
            !this.hasFeatureFlag('module-register')
        },
        {
          value: 'gobd',
          label: 'GoBD',
          disabled:
            !this.hasFeatureFlag('admin-export-gobd') ||
            !this.hasFeatureFlag('module-register')
        }
      ];
      const items = !this.isMonthly ? lifetimeItems : montlyItems;
      return items
        .filter((item) => !item.disabled)
        .map((item) => ({
          value: item.value,
          label: this.filters.capitalize(item.label)
        }));
    }
  },
  methods: {
    resetExportTimer() {
      this.lastExport = dayjs.unix(0);
      this.lastExportType = '';
    },
    asyncExport(email?: string) {
      if (
        dayjs().diff(dayjs(this.lastExport), 'minute') < 5 &&
        this.lastExportType === this.dataFormat
      ) {
        this.mixpanel.track('export-requested-multiple-times');
        modal('warning', {
          message: this.$t('customers.export_warning.message'),
          subMessage: this.$t('customers.export_warning.subMessage', {
            email: this.company.notificationEmail
          })
        });
        return;
      }
      const { locationId, dataScope } = useLocationsStore();
      this.isSaving = true;
      this.lastExport = dayjs();
      this.lastExportType = this.dataFormat;
      return new Promise((resolve) => {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createExport($input: CreateExportInput!) {
                createExport(input: $input) {
                  status
                }
              }
            `,
            variables: {
              input: {
                locationId,
                dataScope,
                exportType: this.dataType ? this.dataType.toUpperCase() : null,
                fileFormat: this.dataFormat
                  ? this.dataFormat.toUpperCase()
                  : null,
                month: this.month,
                recipientEmail: email
              }
            }
          })
          .then((response) => {
            flash(this.$t('global.flash.export_mailed'));
            resolve(response.data.status);
          })
          .finally(() => {
            this.isSaving = false;
          });
      });
    },
    download(email?: string) {
      if (this.hasAsyncExports) {
        this.asyncExport(email);
      } else {
        let url;
        const { locationId, dataScope } = useLocationsStore();
        const year = dayjs(this.month).year();
        if (this.isMonthly) {
          const parentScope = dataScope === 'GLOBAL' ? 'company' : 'location';
          url = `${config.backendUrl}/api/exports/${this.dataType}.${this.dataFormat}?month=${this.month}&year=${year}&location_id=${locationId}&parent_scope=${parentScope}`;
        } else {
          url = `${config.backendUrl}/api/exports/${this.dataType}.${this.dataFormat}`;
        }
        window.open(url);
      }
    }
  },
  created() {
    if (this.isMonthly) {
      this.month = this.months[0];
    }
    if (this.dataTypes.length) {
      this.dataType = this.dataTypes[0].value;
    }
  }
});
</script>

<style lang="scss" module>
.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $spacing;

  & > *:not(:last-child) {
    margin-right: $spacing * 0.5;
  }
}

.bottom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;

  & > * {
    margin-top: $spacing;

    &:last-child {
      margin-left: $spacing;
    }
  }
}
</style>
