<template>
  <div v-test="'service-variation-advanced'">
    <BaseHeading mb>
      {{ $t('service.bookability') }}
    </BaseHeading>
    <BaseCard gray mb>
      <BaseCheckbox
        v-model="formData.bookable"
        :label="$t('service.bookable.heading')"
        :description="$t('service.bookable.description')"
        v-test="'service-bookable'"
      />
      <BaseText
        v-if="onlineBookableError"
        color="error"
        size="s"
        :mt="0.5"
        v-test="'service-location-bookable-error'"
      >
        {{ $t('service.error_bookable_location') }}
      </BaseText>
      <BaseAlert
        v-if="formData.duration <= 0"
        color="warning"
        :mt="0.5"
        size="s"
        :text="$t('service.warning_bookable_duration')"
        v-test="'service-duration-unbookable'"
      />
    </BaseCard>
    <BaseCard
      v-if="multiLocation"
      v-show="formData.bookable"
      gray
      mb
      v-test="'service-locations'"
    >
      <BaseText bold>
        {{ $t('service.locations.heading') }}
      </BaseText>
      <BaseText size="s" mb>
        {{ $t('service.locations.description') }}
      </BaseText>
      <LocationSelect
        v-model="formData.bookableLocationIds"
        v-test="'service-locations-list'"
      />
    </BaseCard>
    <Availability
      v-if="unleash.isEnabled('ServiceAvailability')"
      v-model="formData.serviceOfferingEnabled"
    />
    <BaseCard v-if="formData.bookable" gray mb>
      <BaseCheckbox
        v-model="hasDescription"
        :label="$t('service.description.heading')"
        :description="$t('service.description.description')"
        v-test="'service-description-toggle'"
      />
      <BaseInput
        v-if="hasDescription"
        v-model="formData.description"
        type="textarea"
        mt
        v-test="'service-description'"
      />
    </BaseCard>
    <BaseHeading mb>
      {{ $t('service.communication') }}
    </BaseHeading>
    <BaseCard gray mb>
      <BaseCheckbox
        v-model="hasExtraInformation"
        :label="$t('service.additional_information.heading')"
        :description="$t('service.additional_information.description')"
        v-test="'service-extra-information-toggle'"
      />
      <BaseEditor
        v-if="hasExtraInformation"
        v-model="formData.extraInformation"
        mt
        v-test="'service-extra-information'"
      />
    </BaseCard>
    <BaseCard
      v-if="
        showRebookSetting &&
        company.settings.bookings.rebookReminderEnabled &&
        rebookPeriod
      "
      gray
      mb
    >
      <BaseCheckbox
        v-model="formData.rebookEnabled"
        :label="$t('service.rebook_reminder.heading')"
        :description="$t('service.rebook_reminder.description')"
        v-test="'service-rebook'"
      />
    </BaseCard>
    <BaseCard
      v-if="
        showRebookSetting &&
        (!company.settings.bookings.rebookReminderEnabled || !rebookPeriod)
      "
      gray
      mb
    >
      <BaseText bold :mb="0.5">{{
        $t('service.rebook_reminder.heading')
      }}</BaseText>
      <BaseText>{{ $t('service.rebook_reminder.enable_info') }}</BaseText>
    </BaseCard>
    <BaseCard v-if="rwgEnabled" gray mb>
      <BaseInput
        v-model="formData.rwgName"
        :label="$t('service.google_reserve.heading')"
        :info="$t('service.google_reserve.description')"
        v-test="'service-google-reserve'"
      />
    </BaseCard>
    <BaseHeading v-if="company.medical" mb>
      {{ $t('service.other_options') }}
    </BaseHeading>
    <BaseCard v-if="company.medical" gray mb>
      <BaseCheckbox
        v-model="formData.medical"
        :label="$t('service.medical')"
        :description="$t('service.medical_description')"
        v-test="'service-medical-toggle'"
      />
    </BaseCard>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';

import unleash from '@/unleash';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import type { ServiceFormData } from './types';

import LocationSelect from '@/modules/admin/_shared/LocationSelect.vue';
import Availability from '../availability/index.vue';

const formData = inject<ServiceFormData>('serviceFormData')!;
const onlineBookableError = inject('onlineBookableError')!;
const rebookPeriod = inject<Ref<number | null>>('rebookPeriod');

const route = useRoute();
const { company, multiLocation, getApp } = useCompanyStore();
const { hasFeatureFlag } = useUserStore();

const rwgEnabled = ref(false);
const hasDescription = ref(false);
const hasExtraInformation = ref(false);

getApp('google_reserve').then((app) => {
  rwgEnabled.value = !!app;
});

watch(hasDescription, (value: boolean) => {
  if (!value) {
    formData.description = '';
  }
});

watch(
  () => formData.description,
  (value: string) => {
    hasDescription.value = !!value;
  },
  { immediate: true }
);

watch(hasExtraInformation, (value: boolean) => {
  if (!value) {
    formData.extraInformation = '';
  }
});

watch(
  () => formData.extraInformation,
  (value: string) => {
    hasExtraInformation.value = !!value;
  },
  { immediate: true }
);

const showRebookSetting = computed(
  () => hasFeatureFlag('module-marketing') && !!route.params.serviceId
);
</script>
