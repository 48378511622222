<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.small]: small,
        [$style.disabled]: disabled,
        [$style.vertical]: vertical
      }
    ]"
  >
    <BaseInputLabel v-if="label" :text="label" v-test="'_base-switch-label'" />
    <div :class="$style.buttons" v-test="'_base-switch-buttons'">
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="[
          $style.button,
          {
            [$style.isActive]: modelValue === option.value,
            'cy-active': modelValue === option.value
          }
        ]"
        @click="setValue(option.value)"
        v-test="'_base-switch-option'"
      >
        <BaseIcon
          v-if="option.icon"
          :name="option.icon"
          :color="modelValue === option.value ? 'primary' : 'default'"
          v-test="'_base-switch-icon'"
        />
        <BaseText v-else>
          {{ filters.capitalize(option.label) }}
        </BaseText>
        <BaseNotification
          v-if="option.notification"
          :ml="0.25"
          inline
          v-test="'_base-switch-notification'"
        >
          {{ option.notification }}
        </BaseNotification>
        <BaseLabel
          v-if="option.info"
          :color="option.info.color === 'green' ? 'success' : 'default'"
          :ml="0.5"
        >
          {{ option.info.label }}
        </BaseLabel>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true
    },
    options: {
      type: Array,
      required: true,
      validator: (value) =>
        !value.find(
          (item) => (!item.label && !item.icon) || item.value === undefined
        )
    },
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      filters
    };
  },
  methods: {
    setValue(value) {
      if (value !== this.modelValue) {
        this.$emit('update:modelValue', value);
      }
    }
  }
});
</script>

<style lang="scss" module>
.buttons {
  display: flex;

  .base.vertical & {
    flex-direction: column;
  }

  .base.disabled & {
    pointer-events: none;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  min-height: 42px;
  padding: 0 $spacing;
  border: 1px solid $color-border-input;
  white-space: nowrap;
  cursor: pointer;

  .base.smallScreen & {
    flex-grow: 1;
  }

  .base:not(.vertical) & {
    &:first-child {
      border-radius: $radius 0 0 $radius;
    }

    &:last-child {
      border-radius: 0 $radius $radius 0;
    }

    &:not(:last-child) {
      border-right: 0;
    }
  }

  .base.vertical & {
    &:first-child {
      border-radius: $radius $radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $radius $radius;
    }

    &:not(:last-child) {
      border-bottom: 0;
    }
  }

  &.isActive {
    background-color: $grey-light;
    color: $color-primary;
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
  }

  &:not(.isActive) {
    background-color: white;
  }

  .base.small & {
    min-height: 31px;
    font-size: 12px;
  }
}
</style>
