<template>
  <div ref="el" :class="$style.base" v-test="'new-feature'">
    <BaseHeading v-if="data.date" size="s" :mb="0.5">
      {{ filters.date(data.date) }}
    </BaseHeading>
    <div :class="$style.heading">
      <BaseHeading v-if="data.title" size="xl" mr>
        {{ data.title }}
      </BaseHeading>
      <BaseLabel
        v-if="data.category"
        :color="data.category === 'new' ? 'success' : 'default'"
      >
        {{ $t(`change_logs.${data.category}`) }}
      </BaseLabel>
    </div>
    <div :class="$style.description" v-html="data.description" />
    <BaseButton
      v-if="data.helpPageUrl"
      :href="data.helpPageUrl"
      color="inverted"
      mt
      @click="
        mixpanel.track('NewFeatures-ReadMore', {
          item: data.id,
          title: data.title
        })
      "
    >
      {{ $t('new_features.button') }}
    </BaseButton>
    <BaseImage
      v-if="data.imagePublicId"
      :cloudinaryId="data.imagePublicId"
      mt
    />
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { useIntersectionObserver } from '@vueuse/core';
import { useRouter } from 'vue-router';

const mixpanel = inject<any>('mixpanel');
const props = defineProps<{
  data: any;
}>();

const el = ref(null);
const isVisible = ref(false);

useIntersectionObserver(el, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting;
});

const urlHash = computed(
  () =>
    '#' +
    props.data.title
      .toLowerCase() // Convert to lowercase
      .replace(/[^\p{L}0-9]+/gu, '-') // Replace non-alphanumeric characters (including emojis) with dashes
      .replace(/^-+|-+$/g, '') // Remove leading and trailing dashes
      .replace(/-{2,}/g, '-') // Replace multiple dashes with a single dash
);

const router = useRouter();
const enableUpdate = ref(false);
setTimeout(() => {
  enableUpdate.value = true;
}, 500);

onMounted(() => {
  setTimeout(() => {
    if (router.currentRoute.value.hash === urlHash.value) {
      el.value.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, 300);

  watch(isVisible, (value) => {
    if (value && enableUpdate.value) {
      router.replace({
        path: router.currentRoute.value.path,
        hash: urlHash.value
      });
    }
  });
});
</script>

<style lang="scss" module>
.description {
  a {
    color: $color-primary;

    @include hover {
      color: $color-primary;
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
}

.base {
  padding-top: $spacing * 2;

  &:not(:last-child) {
    padding-bottom: $spacing * 2;
    border-bottom: 1px solid $color-border;
  }
}
</style>
