<template>
  <div
    :class="[
      $style.base,
      {
        [$style.deletable]: deletable,
        [$style.default]: color === 'default',
        [$style.success]: success,
        [$style.warning]: warning,
        [$style.error]: error,
        [$style.primary]: primary
      }
    ]"
  >
    <div :class="$style.inner">
      <template v-if="state">
        {{ $t(`global.${state}`) }}
      </template>
      <slot v-else />
      <div
        v-if="deletable"
        :class="$style.close"
        @click="$emit('delete')"
        v-test="'_base-label-delete'"
      >
        <BaseIcon name="close" color="inverted" size="xs" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
export interface BaseLabelProps {
  color?: 'success' | 'warning' | 'error' | 'default' | 'primary';
  state?: string;
  deletable?: boolean;
}

defineEmits(['delete']);
withDefaults(defineProps<BaseLabelProps>(), {
  color: 'default'
});
</script>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  data() {
    return {
      states: {
        success: [
          'active',
          'valid',
          'paid',
          'upcoming',
          'new',
          'enabled',
          'complete'
        ],
        warning: [
          'pending',
          'outstanding',
          'no_show',
          'backorder',
          'requested',
          'overdue'
        ],
        error: ['expired', 'deleted', 'late', 'failed', 'cancelled'],
        primary: ['ordered', 'open']
      }
    };
  },
  computed: {
    success() {
      return this.state
        ? this.states.success.includes(this.state)
        : this.color === 'success';
    },
    warning() {
      return this.state
        ? this.states.warning.includes(this.state)
        : this.color === 'warning';
    },
    error() {
      return this.state
        ? this.states.error.includes(this.state)
        : this.color === 'error';
    },
    primary() {
      return this.state
        ? this.states.primary.includes(this.state)
        : this.color === 'primary';
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: inline-block;
  padding: 5px 8px;
  border-radius: 16px;
  font-size: 11px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;

  &.default {
    background-color: #aaa;
  }

  &.success {
    background-color: $color-success;
  }

  &.warning {
    background-color: $color-warning;
  }

  &.error {
    background-color: $color-error;
  }

  &.primary {
    background-color: $color-primary;
  }
}

.inner {
  display: flex;
  align-items: center;
  position: relative;
  color: $color-text-inverted;

  .base.deletable & {
    padding-right: 16px;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: calc(50% - 7px);
  width: 14px;
  height: 14px;
}
</style>
