<template>
  <div :class="$style.base">
    <BaseCard noPadding>
      <div>
        <Action
          v-for="(action, index) in actions"
          :key="index"
          :action="action"
          :tooltip="
            action.disabled && action.disabledTooltip
              ? {
                  text: action.disabledTooltip,
                  touch: true
                }
              : null
          "
          @click="$emit('select', action.name)"
        />
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import Action from './Action.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalActions',
  components: {
    Action
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => [],
      validator: (value) =>
        !value.length || !value.find((item) => !item.name || !item.label)
    }
  },
  emits: ['select']
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing;
}
</style>
