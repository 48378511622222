<template>
  <div v-if="customerNotifications.length">
    <BaseCard :heading="filters.capitalize($t('global.items.notification', 2))">
      <ul :class="$style.list" v-test="'customerNotifications'">
        <li
          v-for="(notification, index) in customerNotifications"
          :key="index"
          v-test="notification.testKey"
        >
          {{ notification.text }}
        </li>
      </ul>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    customer: {
      type: Object,
      require: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    customerNotifications() {
      const notifications = [];

      if (this.customer) {
        if (
          this.customer.email &&
          this.customer.lastEmailStatus &&
          !(
            this.customer.lastEmailStatus === 'sent' ||
            this.customer.lastEmailStatus === 'delivered'
          )
        ) {
          notifications.push({
            text: this.$t(
              'appointment.customer_notifications.last_email_failed'
            ),
            testKey: 'lastEmailFailedNotification'
          });
        }
        if (
          this.customer.mobilePhone &&
          this.customer.lastSmsStatus &&
          this.customer.lastSmsStatus !== 'sent'
        ) {
          notifications.push({
            text: this.$t(
              'appointment.customer_notifications.last_text_failed'
            ),
            testKey: 'lastTextFailedNotification'
          });
        }
      }
      return notifications;
    }
  }
});
</script>

<style lang="scss" module>
.list {
  margin: 0;
  padding-left: 18px;
}
</style>
