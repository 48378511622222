<template>
  <div v-if="topItems.length">
    <BaseHeading size="s" mb> {{ $t('insights.top_booked') }} </BaseHeading>
    <div>
      <div v-for="(item, index) in topItems" :key="index" class="mb-1">
        <div v-if="range === 'week'">
          <BaseHeading v-test="'top-item-date'">
            {{ filters.weekdayLong(dayjs(item.date).day()) }},
            {{ formatTime(item.part) }}
          </BaseHeading>
          <BaseText size="s" v-test="'top-item-amount'">
            {{ item.sum }} {{ $t('global.items.appointment', 2) }}
          </BaseText>
        </div>
        <div v-else>
          <BaseHeading v-test="'top-item-date'">
            {{ filters.date(item.name, { format: 'long' }) }}
          </BaseHeading>
          <BaseText size="s" v-test="'top-item-amount'">
            {{ item.total }} {{ $t('global.items.appointment', 2) }}
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { formatTime } from '../helpers';

const props = defineProps<{
  data: any;
}>();

const range = inject<any>('range');

const topItems = computed(() => {
  if (range.value === 'week') {
    return props.data
      .reduce((acc, val) => acc.concat(val.items), [])
      .sort((a, b) => b.sum - a.sum)
      .slice(0, 3)
      .filter((item) => item.sum);
  } else {
    return props.data
      .sort((a, b) => b.total - a.total)
      .slice(0, 3)
      .filter((item) => item.total);
  }
});
</script>
