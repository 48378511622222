<template>
  <transition appear :duration="1000">
    <PageWrap
      wide
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.mediumScreen]: $screen === 'm' || $screen === 's'
        }
      ]"
    >
      <div :class="$style.upperSection">
        <div :class="$style.left">
          <div :class="$style.cta">
            <BaseHeading
              size="s"
              :mb="0.5"
              :center="$screen === 'm' || $screen === 's'"
            >
              {{ $t('treatwell.landing.preheading') }}
            </BaseHeading>
            <BaseHeading
              size="xl"
              mb
              :center="$screen === 'm' || $screen === 's'"
            >
              {{ averageBookingsPerMonth }}
            </BaseHeading>
            <BaseText size="l">
              {{ $t('treatwell.landing.heading') }}
            </BaseText>
            <div :class="$style.ctaHolder">
              <Button
                :class="$style.button"
                :mt="$screen === 's' ? 1 : 2"
                :mb="1.5"
              />
              <div :class="$style.links">
                <BaseText
                  :href="$t('treatwell.landing.link_video.url')"
                  iconBefore="play-circle"
                  @click="mixpanel.track('TWLandingPage-MoreInfo')"
                >
                  {{ $t('treatwell.landing.link_video.text') }}
                </BaseText>
                <BaseText
                  :href="$t('treatwell.landing.link_conditions.url')"
                  iconBefore="book-open"
                  @click="mixpanel.track('TWLandingPage-Costs')"
                >
                  {{ $t('treatwell.landing.link_conditions.text') }}
                </BaseText>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.right">
          <CalendarImage
            v-if="
              unleash.getVariant('NewTreatwellLanding').name === 'TestGroup'
            "
          />
          <img
            v-else
            src="/img/treatwell/treatwell-page.png"
            :class="$style.image"
          />
        </div>
      </div>
      <div :class="$style.lowerSection">
        <BaseHeading size="s" mt mb :class="$style.reasonsHeader">
          {{ $t('treatwell.landing.reasons.header') }}
        </BaseHeading>
        <div :class="$style.reasons">
          <div
            v-for="reason in reasons"
            :key="reason.id"
            :class="$style.reasonCardHolder"
          >
            <BaseCard hasHover fullWidth fullHeight>
              <div :class="$style.reasonCard">
                <img
                  :src="`/img/treatwell/${reason.icon}.svg`"
                  :class="$style.reasonIcon"
                />
                <BaseText bold :mb="0.5" mt>
                  {{ reason.title }}
                </BaseText>
                <BaseText color="secondary">
                  {{ reason.text }}
                </BaseText>
              </div>
            </BaseCard>
          </div>
        </div>
      </div>
      <div :class="$style.blob" />
      <div :class="$style.blob2" />
    </PageWrap>
  </transition>
</template>

<script setup lang="ts">
import PageWrap from '@/modules/admin/PageWrap.vue';
import Button from '../content/Button.vue';
import CalendarImage from './CalendarImage.vue';
import { useCompanyStore } from '@/stores/company';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
const { t } = useI18n();
import unleash from '@/unleash';

const mixpanel = inject<any>('mixpanel');
mixpanel.track('TWLandingPage-Viewed', {
  variant: unleash.getVariant('NewTreatwellLanding').name
});

const { company } = useCompanyStore();

const averageBookingsPerMonth = computed(() => {
  switch (company.companyType) {
    case 'BARBERSHOP':
      return t('treatwell.landing.salon_specific.barbershop.more_bookings_2');
    case 'NAIL_STUDIO':
    case 'MANI_PEDICURE':
      return t('treatwell.landing.salon_specific.nail_studio.more_bookings_2');
    default:
      return t('treatwell.landing.more_bookings_2');
  }
});

const reasons = [
  {
    id: 1,
    title: t('treatwell.landing.reasons.reason_1'),
    text: t('treatwell.landing.reasons.reason_1_text'),
    icon: 'graph'
  },
  {
    id: 2,
    title: t('treatwell.landing.reasons.reason_2'),
    text: t('treatwell.landing.reasons.reason_2_text'),
    icon: 'money'
  },
  {
    id: 3,
    title: t('treatwell.landing.reasons.reason_3'),
    text: t('treatwell.landing.reasons.reason_3_text'),
    icon: 'diamond'
  }
];
</script>

<style lang="scss" module>
.base {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;

  &.smallScreen {
    padding-bottom: 12 * $spacing;
  }

  &:global(.v-enter-active) {
    transition: opacity 0.1s;
  }

  &:global(.v-enter-from) {
    opacity: 0;
  }
}

.upperSection {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 2 * $spacing;
  z-index: 2;

  .base.mediumScreen & {
    padding: $spacing;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .base.smallScreen & {
    padding: 0 $spacing;
    margin-bottom: $spacing;
  }
}
.links {
  display: flex;
  flex-wrap: wrap;

  gap: 2 * $spacing;
  .base.mediumScreen & {
    align-items: center;
    justify-content: center;
    gap: $spacing;
  }
}
.ctaHolder {
  .base.smallScreen & {
    border-top: 1px solid var(--Typography-Placeholder, #dbdbdb);

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: white;
    padding: $spacing $spacing 2 * $spacing;
  }
}
.cta {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 388px;
  max-width: 100%;
  .base.mediumScreen & {
    text-align: center;
    margin: auto;
    padding: 4 * $spacing $spacing 2 * $spacing;
    width: initial;
  }
}
.image {
  position: relative;
  max-width: 100%;
  border-radius: 16px;
  filter: drop-shadow(0 0 20px rgb(0, 0, 0, 0.14));
  max-width: 100%;
  width: 660px;
  margin-left: auto;
  padding-top: 2 * $spacing;
  padding-left: 2 * $spacing;
  .base.mediumScreen & {
    max-width: 100%;
    margin: auto;
    padding-left: 0;
  }
}

.left,
.right {
  flex-grow: 1;
  display: flex;
  align-items: center;

  .base:global(.v-enter-active) & {
    transition:
      transform 0.5s $easeOutCirc,
      opacity 0.2s linear;
    transition-delay: 0.1s;
  }
}
.right {
  flex-grow: 3;
  .base:global(.v-enter-from) & {
    transform: scale(0.8);
    opacity: 0.2;
    transition-delay: 0.2s;
  }
  .base.mediumScreen & {
    width: 100%;
  }
}

.lowerSection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2 * $spacing;
  z-index: 1;

  .base.mediumScreen & {
    padding: 0.5 * $spacing $spacing;
  }

  .base.smallScreen & {
    margin-top: 2 * $spacing;
  }
}

.reasonsHeader {
  .base:global(.v-enter-active) & {
    transition: opacity 0.2s linear;
  }
  .base:global(.v-enter-from) & {
    opacity: 0;
  }
}
.reasons {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
  justify-content: space-between;

  .base.smallScreen & {
    flex-direction: column;
    justify-content: center;
  }

  & > * {
    .base:global(.v-enter-active) & {
      transition:
        transform 0.5s $easeOutCirc,
        opacity 0.2s linear;
      transition-delay: 0.3s;

      &:nth-child(1) {
        transition-delay: 0.3s;
      }
      &:nth-child(2) {
        transition-delay: 0.35s;
      }
      &:nth-child(3) {
        transition-delay: 0.4s;
      }
      &:nth-child(4) {
        transition-delay: 0.45s;
      }
    }

    .base:global(.v-enter-from) & {
      transform: translateY(50px);
      opacity: 0;
    }
  }
}

.reasonCardHolder {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(33% - $spacing);
  border-radius: $radius;
  @include hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s $easeInOutCubic;
  }
  .base.mediumScreen & {
    flex-basis: calc(33% - $spacing);
  }
  .base.smallScreen & {
    flex-basis: calc(100% - $spacing);
  }
}
.reasonCard {
  padding: 0.5 * $spacing;
  .base.mediumScreen & {
    padding: 0.5 * $spacing 0.25 * $spacing;
  }
  .base.smallScreen & {
    padding: 0.5 * $spacing 0.25 * $spacing;
  }
}

.reasonIcon {
  .reasonCard:hover & {
    transform: scale(1.1);
  }
  transition: transform 0.3s $easeInOutCubic;
}

.blob {
  position: fixed;
  left: calc(200px + $nav-width);
  right: 0;
  width: calc(100% - 400px - $nav-width);
  height: 100%;
  top: 50%;
  border-radius: 5000px;
  opacity: 0.1;
  background: #4c49c5;
  filter: blur(200px);
  z-index: 0;
}
.blob2 {
  position: fixed;
  top: -40%;
  left: 60%;
  width: 80%;
  height: 80%;
  border-radius: 5000px;
  opacity: 0.1;
  background: rgba(3, 207, 165);
  filter: blur(200px);
  z-index: 0;
}
</style>
