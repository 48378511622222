<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.inner">
      <div :class="$style.top">
        <BaseText bold :iconBefore="part.service ? 'scissors' : undefined">
          {{ part.name || part.service.name }}
        </BaseText>
        <div :class="$style.duration">
          <BaseText iconBefore="clock">
            {{ `${part.duration} ${$t('global.minute_short')}` }}
          </BaseText>
        </div>
      </div>
      <div v-if="!hasSingleResource" :class="$style.bottom">
        <div
          v-for="(resource, index) in resources"
          :key="`resource-${index}`"
          :class="$style.resource"
          v-test="'appointment-part-resource'"
        >
          <div :class="$style.resourceImage">
            <BaseAvatar
              v-if="resource.type === 'employee'"
              :resource="resource"
            />
            <BaseIcon v-if="resource.type === 'room'" name="pin" />
            <BaseIcon v-if="resource.type === 'equipment'" name="monitor" />
          </div>
          <div>
            {{ resource.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useResourcesStore } from '@/stores/resources';

const props = defineProps<{
  part: any;
}>();

const { hasSingleResource } = useResourcesStore();

const resources = computed(() => {
  if (!props.part.resources?.length) {
    return [];
  }

  const resources = [...props.part.resources];
  return resources.sort((a, b) =>
    a.type === 'employee' && b.type !== 'employee' ? -1 : 1
  );
});
</script>

<style lang="scss" module>
.base {
  padding: 0 $spacing;
}

.inner {
  padding: $spacing 0;

  .base:not(:last-child) & {
    border-bottom: 1px solid $color-border;
  }
}

.top {
  display: flex;
  gap: $spacing;
  justify-content: space-between;
}

.bottom {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
  margin-top: $spacing * 0.5;

  &:empty {
    display: none;
  }
}

.duration {
  flex-shrink: 0;
}

.resource {
  display: flex;
  align-items: center;
}

.resourceImage {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing * 0.25;
}
</style>
